import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActionType } from '../store/action';
import { getSingleUserSubAdmins } from './api/usersubadmin';
import { changeLanguage } from './translations';
import { UserData } from './api/login';
import { ContributionKiosque } from '../entities/ContributionKiosque';
import { ContributionVente } from '../entities/ContributionVente';
import { ContributionEvenement } from '../entities/ContributionEvenement';
import { ContributionCovoiturage } from '../entities/ContributionCovoiturage';
import { ContributionHobbie } from '../entities/ContributionHobbie';
import { ContributionService } from '../entities/ContributionService';
import { ContributionSignalement } from '../entities/ContributionSignalement';
import { CBoiteIdee } from '../entities/CBoiteIdee';
import { CEcoLocale } from '../entities/CEcoLocale';
import { CSondage } from '../entities/CSondage';
import { MActuPost } from '../entities/MActuPost';
import { MActuNews } from '../entities/MActuNews';
import { ContributionType } from '../types';
import { MessagingState } from '../store/messaging';
import { alertInfo, isMobile } from './utils';
import { fetchMinimumApiVersion, VersionState } from '../store/version';
import { Dispatch } from 'redux';
import { setUser, UserState } from '../store/user';
import { getMe } from './api/user.api';

declare var Storage: any;

export const loadAppState = async (dispatch: Dispatch<ActionType>) => {
  // Check if browser supports HTML5 localStorage to avoid issues with smartTVs
  if (!isMobile() && typeof Storage === 'undefined') {
    return Promise.resolve();
  }

  // Fetch minimum supported API version
  await fetchMinimumApiVersion(dispatch);

  // Load user token
  // It use user.token cause of legacy code
  const storedUser = await AsyncStorage.getItem('user');

  const token = storedUser ? JSON.parse(storedUser)?.token || null : null;

  if (token) {
    await fetchUserData(token, dispatch);
  }
};

export async function fetchUserData(token: string, dispatch: Dispatch<ActionType>) {
  try {
    const user = await getMe(token);
    const userSubAdmins = await getSingleUserSubAdmins(token);

    let userData = {
      token: token,
      userSubAdmins,
      ...user,
    };

    setUser(userData, dispatch);
  } catch (err: any) {
    alertInfo(err.message);
  }
}

export function saveToken(token: string) {
  // Check if browser supports HTML5 localStorage to avoid issues with smartTVs
  if (!isMobile() && typeof Storage === 'undefined') {
    return Promise.resolve();
  }

  return AsyncStorage.setItem('user', JSON.stringify({ token }));
}

export function forgetToken() {
  // Check if browser supports HTML5 localStorage to avoid issues with smartTVs
  if (!isMobile() && typeof Storage === 'undefined') {
    return Promise.resolve();
  }

  return AsyncStorage.removeItem('user');
}

export const CURRENT_SAVE_VERSION = 5;

export interface UserSaved extends UserData {}

export interface Contributions {
  kiosques?: ContributionKiosque[];
  ventes?: ContributionVente[];
  evenements?: ContributionEvenement[];
  formations?: ContributionEvenement[];
  covoiturages?: ContributionCovoiturage[];
  hobbies?: ContributionHobbie[];
  services?: ContributionService[];
  signalements?: ContributionSignalement[];
  cBoiteIdees?: CBoiteIdee[];
  cEcoLocales?: CEcoLocale[];
  cSondages?: CSondage[];
  mActuPosts?: MActuPost[];
  mActuNews?: MActuNews[];
}

export type RootState = {
  isLoading?: boolean;
  detailContributionType?: ContributionType;
  currentContribution?: any;
  isNotif?: boolean;
  forceBack?: boolean;
  isPhoneMode: boolean;
  contributions: Contributions;
  contributionsListNeedsRefresh?: boolean;
  validationMapListNeedsRefresh?: boolean;
  contributeursListNeedsRefresh?: boolean;
  directoryListNeedsRefresh?: boolean;
  user: UserState;
  messaging: MessagingState;
  version: VersionState;
};

export const saveLanguage = (lng: string) => {
  // Check if browser supports HTML5 localStorage to avoid issues with smartTVs
  if (!isMobile() && typeof Storage === 'undefined') {
    return Promise.resolve();
  }

  if (lng === null || lng === undefined) {
    return Promise.resolve();
  }

  changeLanguage(lng);
  return AsyncStorage.setItem('language', lng);
};

export const retrievedSavedLanguage = () => {
  // Check if browser supports HTML5 localStorage to avoid issues with smartTVs
  if (!isMobile() && typeof Storage === 'undefined') {
    return Promise.resolve();
  }

  return AsyncStorage.getItem('language');
};
