import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { t } from '../../services/translations';
import { CompanyGeoSiteReduced, CompanyServiceReduced } from '../../services/api/company.api';
import { ContributionsByIntervalLineChart } from '../atomic/ContributionsByIntervalLineChart';
import { FormFieldPicker } from '../atomic/FormField';
import { getNewsByIntervalStatistics } from '../../services/api/statistics.api';
import { alertInfo } from '../../services/utils';
import useLoggedUser from '../../hooks/useLoggedUser';

export type NewsCountStatisticsProps = {
  years: {
    label: string;
    value: number;
  }[];
  services: CompanyServiceReduced[];
  siteGeos: CompanyGeoSiteReduced[];
};

export function NewsCountStatistics({ years, services, siteGeos }: NewsCountStatisticsProps) {
  const user = useLoggedUser();

  const [year, setYear] = useState<null | number>(null);
  const [contributionsServiceId, setContributionsServiceId] = useState<null | number>(null);
  const [contributionsSiteGeoId, setContributionsSiteGeoId] = useState<null | number>(null);

  const {
    data: contributionsData,
    isLoading: isLoadingContributions,
    isError,
  } = useQuery(['news-count-by-interval', year, contributionsServiceId, contributionsSiteGeoId], () =>
    getNewsByIntervalStatistics(user, {
      year,
      serviceId: contributionsServiceId,
      siteGeoId: contributionsSiteGeoId,
    })
  );

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <>
      <View style={styles.filtersContainer}>
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={year}
          onChangeText={(value: number | string) => {
            setYear(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={years.map((year) => ({ label: year.label, value: year.value })) || []}
          title={t('time_interval')}
          defaultButtonText={t('since_launch')}
        />
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={contributionsServiceId}
          onChangeText={(value: number | string) => {
            setContributionsServiceId(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={
            services?.map((service) => ({
              label: service.libelle,
              value: service.id,
            })) || []
          }
          title={t('service')}
        />
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={contributionsSiteGeoId}
          onChangeText={(value: number | string) => {
            setContributionsSiteGeoId(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={
            siteGeos?.map((siteGeo) => ({
              label: siteGeo.geoSite,
              value: siteGeo.id,
            })) || []
          }
          title={t('geo_site')}
        />
      </View>
      <ContributionsByIntervalLineChart data={contributionsData} isLoading={isLoadingContributions} />
    </>
  );
}

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
  },
  filtersDropdown: {
    padding: 10,
    flex: 1,
  },
});
