import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useField } from 'formik';
import { FormField } from '../FormField';
import AppText, { Mode } from '../AppText';
import { AppDateTimePicker, AppDateTimePickerProps } from '../AppDateTimePicker';

export type DateFieldProps = Omit<Omit<AppDateTimePickerProps, 'formattedDate'>, 'onChangeText'> & {
  name: string;
  title: string;
  required?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function DateField({ name, title, required, style, ...rest }: DateFieldProps) {
  const [field, meta, helper] = useField(name);

  return (
    <FormField style={style} title={required ? `${title}*` : title}>
      <AppDateTimePicker
        formattedDate={field.value}
        onChangeText={(isoDate: string) => {
          helper.setValue(new Date(isoDate));
        }}
        {...rest}
      />
      {meta.touched && meta.error && <AppText mode={Mode.ERROR}>{meta.error}</AppText>}
    </FormField>
  );
}
