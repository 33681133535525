export enum ScreenSocialPostType {
  Facebook,
  LinkedIn,
}

export interface ScreenSocialPost {
  url: string;
  type: ScreenSocialPostType;
  description: string;
  imageUrl: string;
  dateCreated: string;
}
