import { ScreenNames } from '../ScreenNames';
import { navigationRef } from '../services/utils';

export function navigateToAdminCompanyGroup() {
  navigationRef.current?.navigate(ScreenNames.CompanyGroupAdmin);
}

export function navigateToAdminCreateCompanyGroup() {
  navigationRef.current?.navigate(ScreenNames.CompanyGroupAdminCreate);
}

export function navigateToAdminUpdateCompanyGroup(id: number) {
  navigationRef.current?.navigate(ScreenNames.CompanyGroupAdminUpdate, {
    id,
  });
}
