import React, { useCallback, useEffect, useState } from 'react';
import { Image, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { vh } from '../../utils/Utils';

declare var document: any;

const canFullscreen = (function () {
  return document && (document.fullscreenEnabled || document.webkitFullscreenEnabled);
})();

export type FullScreenButtonProps = {
  style: StyleProp<ViewStyle>;
};

export default function FullScreenButton({ style }: FullScreenButtonProps) {
  const [, setFullScreenUpdate] = useState(0);
  const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;

  const enableFullScreen = useCallback(() => {
    const elem = document.getElementById('root');
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }
  }, []);

  // Listen for fullscreen events
  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreenUpdate((fullScreenUpdate) => fullScreenUpdate + 1);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };
  }, []);

  // Hide the cursor after 5 seconds when in fullscreen
  // Show it again when the mouse moves
  useEffect(() => {
    if (isFullScreen) {
      let timeout: any = null;

      const hideCursor = () => {
        document.body.style.cursor = 'none';
      };

      const showCursor = () => {
        document.body.style.cursor = 'auto';
        clearTimeout(timeout);
        timeout = setTimeout(hideCursor, 5000);
      };

      document.addEventListener('mousemove', showCursor);
      document.addEventListener('touchmove', showCursor);
      hideCursor();

      return () => {
        document.body.style.cursor = 'auto';
        document.removeEventListener('mousemove', showCursor);
        document.removeEventListener('touchmove', showCursor);
        clearTimeout(timeout);
      };
    }
  }, [isFullScreen]);

  return canFullscreen && !isFullScreen ? (
    <View style={style}>
      <TouchableOpacity onPress={enableFullScreen}>
        <Image
          style={{ width: vh(7), height: vh(7) }}
          resizeMode="contain"
          source={require('../../../assets/fullscreen.svg')}
        />
      </TouchableOpacity>
    </View>
  ) : null;
}
