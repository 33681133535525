import React, { useMemo, useState } from 'react';
import { StyleSheet, StyleProp, ViewStyle, Pressable, View } from 'react-native';
import Animated, { ZoomIn, ZoomOut } from 'react-native-reanimated';

export type ZoomableProps = {
  children: React.ReactNode | React.ReactNode[];
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

const ZOOM_LEVEL = 2;

export default function Zoomable({ children, style, contentContainerStyle }: ZoomableProps) {
  const [[x, y], setXY] = useState([0, 0]);
  const [containerScreenPosition, setContainerScreenPosition] = useState({ x: 0, y: 0 });
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);

  const magnifierWidth = useMemo(() => Math.min(imgWidth, imgHeight) * 0.4, [imgWidth, imgHeight]);
  const magnifierHeight = magnifierWidth;
  const containerStyle: ViewStyle = showMagnifier ? styles.zoomOutCursor : styles.zoomInCursor;

  const magnifierContainerStyle: ViewStyle = {
    position: 'absolute',
    height: magnifierHeight,
    width: magnifierWidth,
    top: y - magnifierHeight / 2,
    left: x - magnifierWidth / 2,
    pointerEvents: 'none',
    overflow: 'hidden',
    borderRadius: 10000,
  };

  const magnifierDivStyle: React.CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    boxShadow: `inset 0px 0px ${magnifierWidth * 0.1}px rgba(0,0,0,0.3)`,
    zIndex: 10,
    borderRadius: 10000,
  };

  const magnifierStyle: ViewStyle = {
    width: imgWidth,
    height: imgHeight,
    opacity: 1,
    transformOrigin: 'top left',
    transform: [{ scale: ZOOM_LEVEL }],
    backgroundColor: 'white',
    position: 'absolute',
    left: -x * ZOOM_LEVEL + magnifierWidth / 2,
    top: -y * ZOOM_LEVEL + magnifierHeight / 2,
  };

  return (
    <Pressable
      style={[containerStyle, styles.container, style]}
      collapsable={false}
      onLayout={(e) => {
        const { width, height, left, top } = e.nativeEvent.layout;

        setSize([width, height]);
        setContainerScreenPosition({ x: left, y: top });
      }}
      onPress={() => {
        setShowMagnifier(!showMagnifier);
      }}
      onPointerMove={(e) => {
        const { clientX, clientY } = e.nativeEvent;
        setXY([clientX - containerScreenPosition.x, clientY - containerScreenPosition.y]);
      }}
      onPointerLeave={() => {
        setShowMagnifier(false);
      }}
    >
      <View style={[contentContainerStyle, styles.contentContainer]}>
        {children}
        {showMagnifier && (
          <Animated.View style={magnifierContainerStyle} entering={ZoomIn} exiting={ZoomOut}>
            <div style={magnifierDivStyle} />
            <View style={magnifierStyle}>{children}</View>
          </Animated.View>
        )}
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  contentContainer: {
    flex: 1,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  magnifier: {
    position: 'absolute',
    pointerEvents: 'none',
    opacity: 1,
    borderWidth: 1,
    borderColor: 'red',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  zoomInCursor: {
    cursor: 'zoom-in',
  },
  zoomOutCursor: {
    cursor: 'zoom-out',
  },
});
