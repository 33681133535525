import React, { useMemo } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { t } from '../../services/translations';
import SubmitButton from '../atomic/formik/SubmitButton';
import TextField from '../atomic/formik/TextField';
import { UserAutocompleteField } from '../atomic/formik/UserAutocompleteField';
import useLoggedUser from '../../hooks/useLoggedUser';

export const schema = Yup.object({
  label: Yup.string().min(1).required(t('field_required')),
});

export type CompanyGroupFormFieldsProps = {
  isEditing?: boolean;
};

export function CompanyGroupFormFields({ isEditing }: CompanyGroupFormFieldsProps) {
  const user = useLoggedUser();
  const lockedUsers = useMemo(() => (isEditing ? [] : [user.id]), [isEditing, user]);

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" style={{ padding: 10 }}>
      <TextField name="label" mandatory required title={t('title')} />
      <UserAutocompleteField name="users" lockedUsers={lockedUsers} />
      <View style={{ marginTop: 30, marginBottom: 100 }}>
        <SubmitButton text={t('publish_button')} />
      </View>
    </KeyboardAwareScrollView>
  );
}
