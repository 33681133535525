import React, { useMemo, useState } from 'react';
import { Platform, TextInput, TextInputProps, TextStyle } from 'react-native';
import { match } from 'ts-pattern';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';

export type AutoExpandingTextInputProps = Omit<Omit<TextInputProps, 'multiline'>, 'onContentSizeChange'>;

export default function AutoExpandingTextInput({ style, children, ...rest }: AutoExpandingTextInputProps) {
  const [height, setHeight] = useState(0);

  const internStyle = useMemo<TextStyle>(
    () =>
      match(Platform.OS)
        .with('android', () => ({
          width: '100%',
          height,
          maxHeight: 150,
          fontFamily: FONT_FAMILY_DEFAULT.regular,
          fontSize: 14,
          paddingHorizontal: 15,
          paddingVertical: 7.5,
          borderRadius: 8,
        }))
        .with('ios', () => ({
          width: '100%',
          height: height + 10,
          maxHeight: 150,
          fontFamily: FONT_FAMILY_DEFAULT.regular,
          fontSize: 14,
          paddingHorizontal: 15,
          paddingVertical: 0,
          borderRadius: 8,
          outlineStyle: 'none',
        }))
        .with('web', () => ({
          width: '100%',
          height,
          maxHeight: 150,
          fontFamily: FONT_FAMILY_DEFAULT.regular,
          fontSize: 14,
          paddingHorizontal: 15,
          paddingVertical: 15,
          borderRadius: 8,
          outlineStyle: 'none',
        }))
        .otherwise(() => ({})),
    [height]
  );

  return (
    <TextInput
      multiline
      onContentSizeChange={(event) => {
        setHeight(event.nativeEvent.contentSize.height);
      }}
      style={[style, internStyle]}
      {...rest}
    />
  );
}
