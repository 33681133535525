import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { ScreenNames } from '../ScreenNames';
import { isMobile } from '../services/utils';
import { t } from '../services/translations';
import ScreenPlaylistListScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenPlaylistListScreen';
import ScreenSettingsListScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenSettingsListScreen';
import ScreenMediasListScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenMediasListScreen';

const Tab = createMaterialTopTabNavigator();

export default function AdminScreenTabsRoute() {
  return (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.ScreenSettingsList}
        component={ScreenSettingsListScreen}
        options={{ title: t('my_screens') }}
      />
      <Tab.Screen
        name={ScreenNames.ScreenPlaylistList}
        component={ScreenPlaylistListScreen}
        options={{ title: t('playlists') }}
      />
      <Tab.Screen
        name={ScreenNames.ScreenMediasList}
        component={ScreenMediasListScreen}
        options={{ title: t('media_library') }}
      />
    </Tab.Navigator>
  );
}
