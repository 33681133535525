import React from 'react';
import {
  ActivityIndicator,
  FlatList,
  RefreshControl,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { deletePlaylistScreenSettings, listPlaylistScreenSettings } from '../../../../services/api/tv.api';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { ALERT_COLOR, LIGHT_GRAY, PRIMARY_COLOR, WHITE } from '../../../../styles/appColor';
import { t } from '../../../../services/translations';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { isMobile, openURL } from '../../../../services/utils';
import { SearchTextInput } from '../../../../components/atomic/SearchTextInput';
import { useCallback, useEffect, useState } from 'react';
import { generateTVPublicLink } from '../../../../services/url.utils';
import { HypertextLink } from '../../../../components/atomic/HypertextLink';
import Separator from '../../../../components/atomic/Separator';
import Feather from 'react-native-vector-icons/Feather';
import { TwoActionsGenericPopup } from '../../../../components/containers/TwoActionsButtonPopup';
import { useNavigation } from '@react-navigation/native';
import { FAB } from '@rneui/base';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { ScreenNames } from '../../../../ScreenNames';

export default function ScreenSettingsListScreen() {
  const [search, setSearch] = useState('');
  const navigation = useNavigation<any>();
  const user = useLoggedUser();
  const {
    data,
    isLoading: isLoadingList,
    refetch: refresh,
  } = useQuery(['listPlaylistScreenSettings', search], () => listPlaylistScreenSettings(user, search), {
    cacheTime: 0,
  });

  // Refresh when coming back to this screen
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refresh();
    });

    return unsubscribe;
  }, [navigation, refresh]);

  const handleDelete = useCallback(
    async (id: number) => {
      await deletePlaylistScreenSettings(user, id);
      refresh();
    },
    [user]
  );

  const isLoading = isLoadingList;

  return (
    <>
      <ScrollView style={styles.container}>
        <AppScreen
          style={{
            paddingHorizontal: isMobile() ? 0 : 10,
          }}
        >
          <SearchTextInput onChangeText={setSearch} value={search} style={styles.searchInput} />
          {isLoading ? (
            <ActivityIndicator color={PRIMARY_COLOR} size="large" />
          ) : (
            <FlatList
              refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
              data={data}
              keyExtractor={(item) => item.id.toString()}
              renderItem={({ item }) => (
                <View style={styles.itemContainer}>
                  <View style={styles.itemContentContainer}>
                    <AppText mode={Mode.HEADER2} style={styles.itemTitle}>
                      {item.title}
                    </AppText>
                    <View style={styles.columnContainer}>
                      <View style={styles.column0}>
                        <AppText mode={Mode.BOLD} style={styles.columnRow}>
                          {t('playlists')}
                        </AppText>
                        <AppText mode={Mode.BOLD}>{t('screen_url')}</AppText>
                      </View>
                      <View style={styles.column}>
                        <AppText style={styles.columnRow}>{item.pLaylistName}</AppText>
                        <HypertextLink
                          text={generateTVPublicLink(item.code)}
                          onPress={() => {
                            openURL(generateTVPublicLink(item.code));
                          }}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={styles.actionsContainer}>
                    <TouchableOpacity
                      style={styles.actionIcon}
                      onPress={() => {
                        navigation.navigate(ScreenNames.ScreenSettingsUpdate, {
                          id: item.id,
                        });
                      }}
                    >
                      <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.actionIcon} />
                    </TouchableOpacity>
                    <TwoActionsGenericPopup
                      title={t('wish_to_remove')}
                      button1Title={t('yes')}
                      button2Title={t('no')}
                      onPressButton1={() => handleDelete(item.id)}
                      onPressButton2={() => {}}
                    >
                      <Feather size={24} name="trash" color={ALERT_COLOR} style={styles.actionIcon} />
                    </TwoActionsGenericPopup>
                  </View>
                </View>
              )}
              ItemSeparatorComponent={() => <Separator />}
              ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_screens')}</AppText>}
            />
          )}
        </AppScreen>
      </ScrollView>
      <FAB
        color={PRIMARY_COLOR}
        icon={<FontAwesome6 size={15} name="plus" color="#fff" />}
        onPress={() => {
          navigation.navigate(ScreenNames.ScreenSettingsCreate);
        }}
        placement="left"
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
  searchInput: {
    marginBottom: 10,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  columnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  column0: {
    paddingRight: 10,
  },
  column: {
    flex: 1,
  },
  columnRow: {
    paddingBottom: 10,
  },
  itemContainer: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  itemContentContainer: {
    flex: 1,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemTitle: {
    color: PRIMARY_COLOR,
    marginBottom: 10,
  },
  actionIcon: {
    marginRight: 10,
  },
});
