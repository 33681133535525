import { Icon } from '@rneui/base';
import React from 'react';
import { ColorValue, TouchableOpacity, ViewStyle } from 'react-native';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../styles/appColor';

export type IconButtonProps = {
  icon: string;
  disabled?: boolean;
  reverse?: boolean;
  color?: number | ColorValue;
  style?: ViewStyle;
  size?: number;
  onPress: () => void;
};

export function IconButton({ icon, disabled, style, reverse, color, size, onPress }: IconButtonProps) {
  let internalColor = disabled ? LIGHT_GRAY : color || PRIMARY_COLOR;

  return (
    <TouchableOpacity onPress={onPress}>
      <Icon
        size={size || (reverse ? 20 : 30)}
        name={icon}
        color={internalColor}
        reverse={reverse}
        containerStyle={Object.assign({ margin: 0, padding: 0 }, style)}
      />
    </TouchableOpacity>
  );
}
