import { RootState } from '../services/storage';
import { ActionType } from './action';
import { defaultMessagingState, messagingReducer } from './messaging';
import { defaultUserState, userReducer } from './user';
import { defaultVersionState, versionReducer } from './version';

export const initialState: RootState = {
  contributions: {},
  isPhoneMode: false,
  user: defaultUserState,
  messaging: defaultMessagingState,
  version: defaultVersionState,
};

export const appStateReducer = (state: RootState = initialState, action: ActionType): RootState => {
  switch (action.type) {
    case 'SET_CONTRIBUTIONS':
      return {
        ...state,
        contributions: action.payload,
      };
    case 'SET_DETAIL_CONTRIBUTION_TYPE':
      return {
        ...state,
        detailContributionType: action.payload,
      };
    case 'SET_IS_NOTIF':
      return {
        ...state,
        isNotif: action.payload,
      };
    case 'SET_FORCE_BACK':
      return {
        ...state,
        forceBack: action.payload,
      };
    case 'SET_CURRENT_CONTRIBUTION':
      return {
        ...state,
        currentContribution: action.payload,
      };
    case 'SET_PHONE_MODE':
      return {
        ...state,
        isPhoneMode: action.payload,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'setContributionsListNeedsRefresh':
      return {
        ...state,
        contributionsListNeedsRefresh: action.payload,
      };
    case 'setValidationMapListNeedsRefresh':
      return {
        ...state,
        validationMapListNeedsRefresh: action.payload,
      };
    case 'setContributeurListNeedsRefresh':
      return {
        ...state,
        contributeursListNeedsRefresh: action.payload,
      };
    case 'setDirectoryListNeedsRefresh':
      return {
        ...state,
        directoryListNeedsRefresh: action.payload,
      };
    default:
      return userReducer(messagingReducer(versionReducer(state, action), action), action);
  }
};
