import React from 'react';
import { useField } from 'formik';
import { FormField } from '../FormField';
import { FilePicker, FilePickerProps } from '../FilePicker';

export type FileFieldProps = Omit<Omit<Omit<FilePickerProps, 'file'>, 'setFile'>, 'setError'> & {
  name: string;
  title: string;
  required?: boolean;
};

export default function FileField({ name, title, required, ...rest }: FileFieldProps) {
  const [field, meta, helper] = useField(name);

  return (
    <FormField title={required ? `${title}*` : title}>
      <FilePicker
        {...rest}
        setFile={(file) => {
          helper.setValue(file, true);
        }}
        file={field.value}
        error={meta.touched ? meta.error : undefined}
        setError={helper.setError}
      />
    </FormField>
  );
}
