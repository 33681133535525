import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { format } from 'date-fns';
import { SVGRenderer, SvgChart } from '@wuba/react-native-echarts';
import {
  ContributionsCountByIntervalStatistics,
  ContributionsCountByIntervalStatisticsType,
} from '../../entities/ContributionsCountByIntervalStatistics';
import { Platform, StyleSheet, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { ECBasicOption } from 'echarts/types/dist/shared';
import { dateLocale } from '../../services/translations';
import { match } from 'ts-pattern';

echarts.use([SVGRenderer, LineChart, GridComponent, TooltipComponent]);

export type ContributionsByIntervalLineChartProps = {
  data: ContributionsCountByIntervalStatistics | undefined;
  isLoading?: boolean;
};

function getOptionsFromData(data: ContributionsCountByIntervalStatistics | undefined, locale: Locale): ECBasicOption {
  if (!data) {
    return {
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: [],
          type: 'line',
        },
      ],
    };
  } else {
    return {
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: data.items.map((e) =>
          match(data.type)
            .with(ContributionsCountByIntervalStatisticsType.MONTH, () => format(new Date(e.date), 'MMMM', { locale }))
            .with(ContributionsCountByIntervalStatisticsType.YEAR, () => format(new Date(e.date), 'yyyy', { locale }))
            .otherwise(() => format(new Date(e.date), 'MMMM', { locale }))
        ),
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: data.items.map((e) => e.count),
          type: 'line',
        },
      ],
    };
  }
}

export function ContributionsByIntervalLineChart({ data, isLoading }: ContributionsByIntervalLineChartProps) {
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [chart, setChart] = useState<echarts.ECharts | undefined>(undefined);
  const locale = dateLocale();

  const svgRef = useRef<any>(null);
  useEffect(() => {
    let chart: echarts.ECharts | undefined;
    if (svgRef.current) {
      chart = echarts.init(svgRef.current, 'light', {
        renderer: 'svg',
        width: containerWidth,
        height: 400,
      });

      chart.setOption(getOptionsFromData(data, locale));

      if (!!isLoading) {
        chart.showLoading({ text: '' });
      }

      setChart(chart);
    }

    return () => chart?.dispose();
  }, []);

  useEffect(() => {
    chart?.setOption(getOptionsFromData(data, locale));
  }, [data]);

  useEffect(() => {
    if (chart) {
      if (!!isLoading) {
        chart.showLoading({ text: '' });
      } else {
        chart.hideLoading();
      }
    }
  }, [!!isLoading]);

  useEffect(() => {
    if (chart) {
      chart.resize({ width: containerWidth, height: 400 });
    }
  }, [containerWidth]);

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <View
        style={styles.container}
        onLayout={(event) => {
          setContainerWidth(event.nativeEvent.layout.width);
        }}
      >
        <SvgChart ref={svgRef} useRNGH={Platform.OS !== 'android'} />
      </View>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
