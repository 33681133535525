import React, { useEffect, useMemo, useState } from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import useCategories from '../../../../hooks/useCategories';
import { NEWS_PUBLICATION_MODE_ENABLED } from '../../../../services/features';
import { t } from '../../../../services/translations';
import { isMobile } from '../../../../services/utils';
import { ContributionType } from '../../../../types';
import ContributionAuthorizationsField from '../../../../components/atomic/formik/ContributionAuthorizationsField';
import ContributionSpreadingField from '../../../../components/atomic/formik/ContributionSpreadingField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import TextField from '../../../../components/atomic/formik/TextField';
import RichTextField from '../../../../components/atomic/formik/RichTextField';
import PhotoField from '../../../../components/atomic/formik/PhotoField';
import { SelectField } from '../../../../components/atomic/formik/SelectField';
import CheckboxField from '../../../../components/atomic/formik/CheckboxField';
import PublicationModeField from '../../../../components/atomic/formik/PublicationModeField';
import TranslationsField from '../../../../components/atomic/formik/TranslationsField';
import { SpreadingType } from '../../../../entities/SpreadingType';
import { useFormikContext } from 'formik';
import { CompanyNewsUpload } from '../../../../models/CompanyNews';

export const schema = Yup.object({
  titre: Yup.string().required(t('field_required')).min(1),
  texte: Yup.string().required(t('field_required')).min(1),
  spreadingType: Yup.number().required(),
  userIds: Yup.array(Yup.number()).when('spreadingType', (spreadingType, schema) => {
    if (spreadingType === SpreadingType.Users) {
      return schema.required().min(1);
    }
  }),
  entrepriseSiteGeoIds: Yup.array(Yup.number()).when('spreadingType', (spreadingType, schema) => {
    if ([SpreadingType.Sites, SpreadingType.SitesAndServices].includes(spreadingType)) {
      return schema.required().min(1);
    }
  }),
  entrepriseServiceIds: Yup.array(Yup.number()).when('spreadingType', (spreadingType, schema) => {
    if ([SpreadingType.Services, SpreadingType.SitesAndServices].includes(spreadingType)) {
      return schema.required().min(1);
    }
  }),
  entrepriseGroupIds: Yup.array(Yup.number()).when('spreadingType', (spreadingType, schema) => {
    if (spreadingType === SpreadingType.Groups) {
      return schema.required().min(1);
    }
  }),
});

type NewsFormFieldsProps = {
  isEditing?: boolean | undefined;
  defaultPhoto: ImageSourcePropType | undefined;
};

export default function NewsFormFields({ isEditing, defaultPhoto }: NewsFormFieldsProps) {
  const [needsValidation, setNeedsValidation] = useState(false);
  const { categories } = useCategories(ContributionType.COMPANY_NEWS);

  const { values, setFieldValue } = useFormikContext<CompanyNewsUpload>();

  const showEmailActivated = useMemo(() => {
    const now = new Date();
    const visibleStartDate = values.visibleStartDate && new Date(values.visibleStartDate);

    return (
      values.publicationMode === 'immediate_posting' || !visibleStartDate || visibleStartDate.getTime() < now.getTime()
    );
  }, [values.publicationMode, values.visibleStartDate]);

  useEffect(() => {
    if (!showEmailActivated) {
      setFieldValue('emailActivated', false);
    }
  }, [showEmailActivated, setFieldValue]);

  return (
    <DefaultBackground style={styles.background}>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={styles.container}
        keyboardDismissMode="on-drag"
      >
        <PhotoField name="image" defaultPhoto={defaultPhoto} allowPdf />
        <TextField name="titre" mandatory required title={t('title')} />
        <RichTextField name="texte" title={t('description')} required />
        <TranslationsField
          name="translations"
          prefillFields={[
            { source: 'titre', destination: 'titre' },
            { source: 'texte', destination: 'description' },
          ]}
          render={({ name, lng }) => (
            <>
              <TextField required name={`${name}[titre]`} title={t('title') + ' (' + lng + ')*'} />
              <RichTextField required name={`${name}[description]`} title={t('description') + ' (' + lng + ')*'} />
            </>
          )}
        />
        <View style={styles.featuredContainer}>
          <CheckboxField name="featured" title={t('featured')} />
        </View>
        <PublicationModeField
          modeName="publicationMode"
          visibleStartDateName="visibleStartDate"
          visibleEndDateName="visibleEndDate"
        />
        <ContributionSpreadingField onNeedsValidationChange={setNeedsValidation} />
        {categories && categories.length > 0 && (
          <SelectField
            name="categoryId"
            style={styles.categoryField}
            title={t('categories')}
            displayOnly={NEWS_PUBLICATION_MODE_ENABLED}
            data={categories.map((category) => ({ label: category.name, value: category.id }))}
          />
        )}
        <ContributionAuthorizationsField hideEmailActivated={!showEmailActivated} />
        <View style={styles.submitContainer}>
          <SubmitButton
            text={isEditing ? t('update_button') : needsValidation ? t('send_for_validation') : t('publish_button')}
          />
        </View>
      </KeyboardAwareScrollView>
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  background: { padding: isMobile() ? 10 : 30 },
  container: { padding: 10 },
  featuredContainer: { marginVertical: 20 },
  categoryField: { marginTop: 24 },
  submitContainer: { marginTop: 30, marginBottom: 100 },
});
