import React, { useEffect } from 'react';
import { KeyboardAvoidingView, Platform, View } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import { ContentWithImageHeaderScreen } from '../../../../components/containers/ContentWithImageHeaderScreen';
import { CommentList } from '../../../../components/molecules/CommentList';
import { AppButton } from '../../../../components/atomic/AppButton';
import { t } from '../../../../services/translations';
import { UserSubRow } from '../../../../components/atomic/UserSubRow';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { alertInfo, getPlural, getReadableDate, isMobile } from '../../../../services/utils';
import { useNavigation } from '@react-navigation/native';
import { BigDetailRow, BigDetailRowInline, ReportButton, SimpleDetailRow } from '../ContributionItemDetailScreen';
import { HtmlText } from '../../../../components/atomic/HtmlText';
import { ContributionType } from '../../../../types';
import {
  getSurvey,
  getSurveyExportCsvUrl,
  getSurveyExportPdfUrl,
  recallSurveyNotification,
} from '../../../../services/api/survey.contributions.api';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { useQuery } from '@tanstack/react-query';
import { AppLoader } from '../../../../components/atomic/AppLoader';
import { TwoActionsGenericPopup } from '../../../../components/containers/TwoActionsButtonPopup';
import { FONT_FAMILY_DEFAULT, globalStyle } from '../../../../styles/globalStyle';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { PRIMARY_COLOR } from '../../../../styles/appColor';
import SpreadingChipList from '../../../../components/atomic/SpreadingChipsList';
import { useDispatch } from 'react-redux';
import { setCurrentContribution, setLoading } from '../../../../store/action';
import { CSondageQuestionType } from '../../../../entities/CSondageQuestion';
import SurveyRespondForm from '../../../../components/molecules/SurveyRespondForm';

declare var window: any;

export type SurveyDetailsScreenProps = {
  id: number;
};

export function SurveyDetailsScreen({ id }: SurveyDetailsScreenProps) {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const { data, isLoading, refetch } = useQuery(['survey', id], () => getSurvey(user, id));

  // Unlogic navigation needs that
  useEffect(() => {
    if (data) {
      dispatch(setCurrentContribution(data));
    }
  }, [data, dispatch]);

  async function exportToPdf() {
    if (data == null) {
      return;
    }

    let fileUrl = getSurveyExportPdfUrl(user, data.id);

    if (!isMobile()) {
      window.open(fileUrl, '_blank');
    } else {
      let uri = FileSystem.documentDirectory + 'export.pdf';
      await FileSystem.downloadAsync(fileUrl, uri);
      await Sharing.shareAsync(uri, { dialogTitle: 'PDF' });
    }
  }

  async function exportToCSV() {
    if (data == null) return;

    let fileUrl = getSurveyExportCsvUrl(user, data.id);

    if (!isMobile()) {
      window.open(fileUrl, '_blank');
    } else {
      let uri = FileSystem.documentDirectory + 'export.csv';
      await FileSystem.downloadAsync(fileUrl, uri);
      await Sharing.shareAsync(uri, { dialogTitle: 'CSV' });
    }
  }

  async function recallNotification() {
    if (data == null) return;

    dispatch(setLoading(true));
    try {
      await recallSurveyNotification(user, data.id);
    } catch (e) {
      alertInfo(t('error_occurred'));
    } finally {
      dispatch(setLoading(false));
    }
  }

  return isLoading ? (
    <AppLoader isVisible={isLoading} />
  ) : (
    data && (
      <DefaultBackground>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <ContentWithImageHeaderScreen
            contribution={data}
            contributionType={ContributionType.SURVEY}
            bottomContent={
              <View style={{ flexDirection: 'row', justifyContent: 'flex-end', margin: 18 }}>
                <ReportButton buttonTitle={t('report')} id={data.contributionId} title={data.title ?? '?'} />
              </View>
            }
            underImageContent={
              <View
                style={{
                  flexDirection: 'row',
                  paddingTop: 10,
                  paddingLeft: 10,
                  paddingRight: 10,
                  width: isMobile() ? undefined : 540,
                }}
              >
                <UserSubRow user={data?.owner} withPicture={true} style={{ flex: 1 }} />
                <AppText style={{ width: isMobile() ? undefined : 200, textAlign: 'right' }}>
                  {getReadableDate(data?.creationDate, true, true)}
                </AppText>
              </View>
            }
            navigation={navigation}
          >
            <View style={{ padding: 18 }}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <AppText
                  mode={Mode.BOLD}
                  style={{
                    flex: 1,
                    fontSize: 20,
                    textAlign: 'center',
                  }}
                >
                  {data.title}
                </AppText>
              </View>
            </View>
            <BigDetailRow
              borderBottomWidth={1}
              content={<HtmlText text={data.description} />}
              title={t('description')}
            />
            {!data?.isOwner ? (
              <>
                <SimpleDetailRow content={getReadableDate(data.endDate)} title={t('due_date')} />
                <SurveyRespondForm item={data} onSubmit={refetch} />
              </>
            ) : (
              <>
                <SimpleDetailRow
                  content={`${Math.round(data.participationRate * 100)} %`}
                  title={t('participation_rate')}
                />
                <SimpleDetailRow content={getReadableDate(data.endDate)} title={t('due_date')} />
                <SimpleDetailRow content={data.invitedCount} title={t('invited_count')} />
                <SimpleDetailRow content={data.answersCount} title={t('answers_count')} />
                {data.questions.map((question) => (
                  <View key={question.id}>
                    <BigDetailRow content={<AppText>{question.text}</AppText>} title={t('question')} />
                    <BigDetailRow
                      content={
                        question.type !== CSondageQuestionType.TEXT ? (
                          question.answers.map((answer) => {
                            return (
                              <AppText key={answer.id}>
                                {answer.text} - {answer.selectedCount}{' '}
                                {getPlural(answer.selectedCount, t('vote'), t('votes'))} -{' '}
                                {`${Math.round(answer.selectedRate * 100)} %`}
                              </AppText>
                            );
                          })
                        ) : (
                          <AppText>{t('x_answers', { value: question.respondCount })}</AppText>
                        )
                      }
                      title={t('answers')}
                    />
                  </View>
                ))}
                <BigDetailRow
                  content={
                    <>
                      <AppButton
                        title={t('to_pdf')}
                        icon="file-pdf"
                        onPress={() => exportToPdf()}
                        style={{ marginBottom: 10 }}
                      />
                      <AppButton
                        title={t('to_csv')}
                        icon="file-csv"
                        onPress={() => exportToCSV()}
                        style={{ marginBottom: 10 }}
                      />
                      {data.notifActivated && (
                        <TwoActionsGenericPopup
                          title={t('recall_notification')}
                          button1Title={t('yes')}
                          button2Title={t('no')}
                          onPressButton1={() => recallNotification()}
                          onPressButton2={() => {}}
                          style={[globalStyle.button, globalStyle.button_light, globalStyle.button_content]}
                        >
                          <FontAwesome6
                            size={18}
                            name="bell"
                            color={PRIMARY_COLOR}
                            style={{ marginLeft: 10, marginRight: 10 }}
                          />
                          <AppText
                            style={{
                              textAlign: 'center',
                              fontSize: 20,
                              color: PRIMARY_COLOR,
                              fontFamily: FONT_FAMILY_DEFAULT.regular,
                              flex: 1,
                              textTransform: 'uppercase',
                              paddingTop: 2,
                            }}
                          >
                            {t('recall_notification')}
                          </AppText>
                        </TwoActionsGenericPopup>
                      )}
                    </>
                  }
                  title={t('export_results')}
                />
              </>
            )}
            <BigDetailRowInline
              content={
                <>
                  <SpreadingChipList item={data as any} monocolor={true} inline={true} />
                </>
              }
              title={t('who_can_see')}
            />
            {!!data?.commentsActivated && <CommentList contributionId={data.contributionId} />}
          </ContentWithImageHeaderScreen>
        </KeyboardAvoidingView>
      </DefaultBackground>
    )
  );
}
