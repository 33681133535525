import { useCallback, useState } from 'react';
import { ActivityIndicator, FlatList, Image, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import Feather from 'react-native-vector-icons/Feather';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { alertInfo, getReadableDate, isMobile } from '../../../../services/utils';
import { SearchTextInput } from '../../../../components/atomic/SearchTextInput';
import CreateScreenPlaylistMedia from '../../../../components/molecules/CreateScreenPlaylistMedia';
import Separator from '../../../../components/atomic/Separator';
import { TwoActionsGenericPopup } from '../../../../components/containers/TwoActionsButtonPopup';
import { t } from '../../../../services/translations';
import { ALERT_COLOR, LIGHT_GRAY, PRIMARY_COLOR, WHITE } from '../../../../styles/appColor';
import AppText from '../../../../components/atomic/AppText';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { deleteScreenMedia, listScreenMedias } from '../../../../services/api/tv.api';
import { setLoading } from '../../../../store/action';
import { getPictureSource } from '../../../../services/api/helper.api';

export default function ScreenMediasListScreen() {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const {
    data,
    isLoading: isLoadingList,
    refetch: refresh,
  } = useQuery(['listScreenMedias', search], () => listScreenMedias(user, search), { cacheTime: 0 });

  const handleDelete = useCallback(
    async (id: number) => {
      dispatch(setLoading(true));
      try {
        await deleteScreenMedia(user, id);
        refresh();
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
    [user]
  );

  return (
    <ScrollView style={styles.container}>
      <AppScreen>
        <CreateScreenPlaylistMedia onSubmitEnd={refresh} />
        <SearchTextInput onChangeText={setSearch} value={search} style={styles.searchInput} />
        {isLoadingList ? (
          <ActivityIndicator color={PRIMARY_COLOR} size="large" />
        ) : (
          <FlatList
            data={data}
            keyExtractor={(item) => item.id.toString()}
            ListHeaderComponent={
              <View style={styles.itemContainer}>
                <AppText style={styles.previewHeader}>{t('preview')}</AppText>
                <AppText style={styles.tableHeader}>{t('my_medias')}</AppText>
                <AppText style={styles.tableHeader}>{t('published_date')}</AppText>
                <View style={styles.itemActions}></View>
              </View>
            }
            renderItem={({ item }) => (
              <View style={styles.itemContainer}>
                <Image style={styles.itemPreview} source={getPictureSource(item.imageId)} />
                <AppText style={styles.itemColumn}>{item.name}</AppText>
                <AppText style={styles.itemColumn}>
                  {getReadableDate(item.dateCreated, undefined, undefined, false)}
                </AppText>
                <View style={styles.itemActions}>
                  <TwoActionsGenericPopup
                    title={t('wish_to_remove')}
                    button1Title={t('yes')}
                    button2Title={t('no')}
                    onPressButton1={() => handleDelete(item.id)}
                    onPressButton2={() => {}}
                  >
                    <Feather size={24} name="trash" color={ALERT_COLOR} />
                  </TwoActionsGenericPopup>
                </View>
              </View>
            )}
            ItemSeparatorComponent={() => <Separator />}
            ListEmptyComponent={() => <AppText style={styles.emptyText}>{t('no_document')}</AppText>}
          />
        )}
      </AppScreen>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
  searchInput: {
    marginBottom: 10,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  itemContainer: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
  },
  previewHeader: {
    color: PRIMARY_COLOR,
    width: 64,
    marginRight: 10,
  },
  tableHeader: {
    color: PRIMARY_COLOR,
    flex: 1,
  },
  itemPreview: {
    width: 64,
    height: 64,
    marginRight: 10,
    resizeMode: 'contain',
  },
  itemColumn: {
    flex: 1,
  },
  itemActions: {
    minWidth: 24,
  },
});
