import React from 'react';
import { StyleSheet, KeyboardAvoidingView, Platform, AppState, View } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import useMessagingThreadMessages from '../../hooks/useMessagingThreadMessages';
import { MessageList } from '../../components/molecules/MessageList';
import { NewMessageForm } from '../../components/molecules/NewMessageForm';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

export type MessagingThreadScreenProps = {
  route: RouteProp<any, any>;
};

export function MessagingThreadScreen({ route }: MessagingThreadScreenProps) {
  const paramThreadId: number | null | undefined = route?.params?.threadId;
  const contactId: number | null | undefined = route?.params?.contactId;

  const { isLoading, messages, members, fetchNext, refresh, sendNewMessage } = useMessagingThreadMessages(
    paramThreadId,
    contactId
  );

  // Refresh when this screen is displayed by a notification on iOS
  React.useEffect(() => {
    if (Platform.OS === 'ios') {
      const subscription = AppState.addEventListener('change', (nextAppState) => {
        if (nextAppState === 'active') {
          refresh();
        }
      });

      return () => {
        subscription.remove();
      };
    }
  }, [refresh]);

  return (
    <View style={{ backgroundColor: '#fff', flex: 1 }}>
      <SafeAreaProvider>
        <SafeAreaView edges={['bottom']} style={styles.safeAreaView}>
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={Platform.OS === 'ios' ? 100 : 80}
            style={styles.container}
          >
            <MessageList
              messages={messages}
              members={members}
              isLoading={isLoading}
              refresh={refresh}
              fetchNext={fetchNext}
            />
            <NewMessageForm sendNewMessage={sendNewMessage} />
          </KeyboardAvoidingView>
        </SafeAreaView>
      </SafeAreaProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: '#ffffff',
  },
});
