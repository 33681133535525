import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Feather from 'react-native-vector-icons/Feather';
import { useDispatch, useSelector } from 'react-redux';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { User } from '../../../../entities/User';
import { UserSubAdminMap } from '../../../../entities/UserSubAdminMap';
import { ScreenNames } from '../../../../ScreenNames';
import { getUserSubAdmins } from '../../../../services/api/usersubadmin';
import { RootState } from '../../../../services/storage';
import { t } from '../../../../services/translations';
import { alertInfo, navigationRef } from '../../../../services/utils';
import { setLoading, setValidationMapListNeedsRefresh } from '../../../../store/action';
import { DARK_GRAY, LIGHT_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../../../styles/globalStyle';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export default function ListValidationMapScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<UserSubAdminMap>();
  const user = useLoggedUser();
  const validationMapListNeedsRefresh = useSelector<RootState, boolean>(
    (state) => !!state.validationMapListNeedsRefresh
  );
  const dispatch = useDispatch();

  const refresh = useCallback(async () => {
    setIsLoading(true);
    try {
      setItems(await getUserSubAdmins(user));
    } catch (e) {
      alertInfo(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useEffect(() => {
    if (validationMapListNeedsRefresh) {
      refresh();
      dispatch(setValidationMapListNeedsRefresh(false));
    }
  }, [validationMapListNeedsRefresh, refresh]);

  return (
    <AppScreen>
      {items && (
        <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
          <View style={styles.itemContainer}>
            <View style={{ ...styles.subContainerTitle, flex: 1 }}>
              <Text style={styles.title}>{t('validation_map_all')}</Text>
              <NameList keyProps="all" users={items.all} />
              <TouchableOpacity
                containerStyle={styles.contentContainer}
                style={styles.contentContainer}
                onPress={() => navigateToAdminUpdateSubAdmin('all', t('validation_map_all'), 0)}
              >
                <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.itemContainer}>
            <View style={{ flexDirection: 'column', width: '100%' }}>
              <View style={styles.subContainerTitle}>
                <Text style={styles.title}>{t('services')}</Text>
              </View>
              <View style={styles.subContainer}>
                {items.services.map((e) => (
                  <View key={e.id} style={styles.lineContainer}>
                    <Text style={styles.itemLabel}>{e.name}</Text>
                    <NameList keyProps={'services_' + e.id} users={e.users.length > 0 ? e.users : items.all} />
                    <TouchableOpacity
                      containerStyle={styles.contentContainer}
                      style={styles.contentContainer}
                      onPress={() => navigateToAdminUpdateSubAdmin('services', e.name, e.id)}
                    >
                      <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={styles.itemContainer}>
            <View style={{ flexDirection: 'column', width: '100%' }}>
              <View style={styles.subContainerTitle}>
                <Text style={styles.title}>{t('geo_sites')}</Text>
              </View>
              <View style={styles.subContainer}>
                {items.siteGeo.map((e) => (
                  <View key={e.id} style={styles.lineContainer}>
                    <Text style={styles.itemLabel}>{e.name}</Text>
                    <NameList keyProps={'sitegeo_' + e.id} users={e.users.length > 0 ? e.users : items.all} />
                    <TouchableOpacity
                      containerStyle={styles.contentContainer}
                      style={styles.contentContainer}
                      onPress={() => navigateToAdminUpdateSubAdmin('sitegeo', e.name, e.id)}
                    >
                      <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={styles.itemContainer}>
            <View style={{ flexDirection: 'column', width: '100%' }}>
              <View style={styles.subContainerTitle}>
                <Text style={styles.title}>{t('groups')}</Text>
              </View>
              <View style={styles.subContainer}>
                {items.groups.map((e) => (
                  <View key={e.id} style={styles.lineContainer}>
                    <Text style={styles.itemLabel}>{e.name}</Text>
                    <NameList keyProps={'groups_' + e.id} users={e.users.length > 0 ? e.users : items.all} />
                    <TouchableOpacity
                      containerStyle={styles.contentContainer}
                      style={styles.contentContainer}
                      onPress={() => navigateToAdminUpdateSubAdmin('groups', e.name, e.id)}
                    >
                      <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      )}
    </AppScreen>
  );
}

function NameList(props: { users: User[]; keyProps: string }) {
  return (
    <View style={styles.nameListView}>
      {props.users.map((user, i) => (
        <Text key={props.keyProps + '_name_' + i} style={styles.itemLabelName}>
          {user.prenom} {user.nom}
        </Text>
      ))}
    </View>
  );
}

function navigateToAdminUpdateSubAdmin(type: 'all' | 'services' | 'sitegeo' | 'groups', key: string, id: number) {
  navigationRef.current?.navigate(ScreenNames.ValidationMapUpdate, {
    type,
    key,
    id,
  });
}

const styles = StyleSheet.create({
  nameListView: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    marginLeft: 20,
    marginRight: 20,
  },
  subContainerTitle: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    flex: 0.2,
    // width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    borderBottomColor: LIGHT_GRAY,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  subContainer: {
    flexDirection: 'column',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  lineContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  contentContainer: {
    marginLeft: 'auto',
    marginRight: 20,
  },
  title: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 18,
    color: PRIMARY_COLOR,
  },
  itemLabel: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
    flex: 0.3,
  },
  itemLabelName: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
    paddingRight: 30,
  },
  editButton: {
    marginRight: 10,
  },
});
