import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { UserSaved } from '../storage';

export async function sendSuggestion(user: UserSaved, title: string, description: string, anonymous: boolean) {
  return axios.post(
    apiUrlBase + 'ContributionIdees',
    {
      contribution: {
        entrepriseId: user.entreprise.id,
        userIdCreateur: user.id,
        visibleZone: false,
        entreprise: null,
        lovIdEtatNavigation: null,
        userIdCreateurNavigation: null,
      },
      cBoiteIdee: {
        texte: description,
        titre: title,
        anonyme: anonymous,
      },
    },
    {
      headers: headers(user.token),
    }
  );
}
