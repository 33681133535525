import { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation, useQuery } from '@tanstack/react-query';
import { contributionSpreadingFieldValidators } from '../../../components/atomic/formik/ContributionSpreadingField';
import { t } from '../../../services/translations';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { SendTemplate } from '../../../entities/SendTemplate';
import { getSmsEmergencyTemplates, sendSmsEmergencyTemplate } from '../../../services/api/sms.emergency.api';
import { alertInfo } from '../../../services/utils';
import { AppScreen } from '../../../components/containers/AppScreen';
import Form from '../../../components/atomic/formik/Form';
import SmsSendContributionSpreadingField from '../../../components/atomic/formik/SmsSendContributionSpreadingField';
import SubmitButton from '../../../components/atomic/formik/SubmitButton';
import { setLoading } from '../../../store/action';
import TextField from '../../../components/atomic/formik/TextField';

export type EmergencySmsSendTemplateScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export const schema = Yup.object({
  ...contributionSpreadingFieldValidators,
});

export default function EmergencySmsSendTemplateScreen({ route }: EmergencySmsSendTemplateScreenProps) {
  const user = useLoggedUser();
  const id = parseInt(route.params.id);
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingDetails,
    isFetched,
  } = useQuery(['getSmsEmergencyTemplates', id], () => getSmsEmergencyTemplates(user, id), { cacheTime: 0 });

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: SendTemplate) => {
      await sendSmsEmergencyTemplate(user, id, values);
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoadingDetails));
  }, [isLoadingDetails, dispatch]);

  return data ? (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        keyboardDismissMode="on-drag"
      >
        <Form<SendTemplate>
          initialValues={{ message: data.message } as any}
          onSubmit={mutateAsync as any}
          validationSchema={schema}
          validateOnMount
        >
          <TextField name="message" mandatory required title={t('message')} maxLength={160} multiline displayOnly />
          <SmsSendContributionSpreadingField />
          <SubmitButton text={t('send')} />
        </Form>
      </KeyboardAwareScrollView>
    </AppScreen>
  ) : (
    <View />
  );
}
