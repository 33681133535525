import React from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { t } from '../services/translations';
import { isMobile } from '../services/utils';
import { ScreenNames } from '../ScreenNames';
import { SurveyToValidateAdminListScreen } from '../screens/loggedIn/contribution/survey/SurveyToValidateAdminListScreen';
import { SurveyValidatedAdminListScreen } from '../screens/loggedIn/contribution/survey/SurveyValidatedAdminListScreen';

export function SurveyAdministrationRoute() {
  const Tab = createMaterialTopTabNavigator();
  return (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.SurveysToApproveList}
        component={SurveyToValidateAdminListScreen}
        options={{ title: t('to_approve') }}
      />
      <Tab.Screen
        name={ScreenNames.ApprovedSurveysList}
        component={SurveyValidatedAdminListScreen}
        options={{ title: t('approved') }}
      />
    </Tab.Navigator>
  );
}
