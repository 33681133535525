import React from 'react';
import { Image } from 'react-native';
import { AppModalOverlay } from './AppModalOverlay';
import { isMobile } from '../../services/utils';

export function AppModalGallery(props: { onBackdropPress: () => void; isVisible: boolean; imageSource: any }) {
  if (!props.isVisible) return null;
  return (
    <AppModalOverlay isVisible={props.isVisible} onBackdropPress={props.onBackdropPress}>
      <Image source={props.imageSource} style={{ width: isMobile() ? 300 : 700, height: 600 }} resizeMode="contain" />
    </AppModalOverlay>
  );
}
