import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessagingMessage } from '../entities/MessagingMessage';
import { MessagingThreadMember } from '../entities/MessagingThreadMember';
import { getThreadIdByContactId, postNewThread } from '../services/api/messaging.api';
import { RootState } from '../services/storage';
import {
  ThreadMessages,
  defaultThreadMessageState,
  fetchMessagingThreadMembers,
  fetchNextMessagingThreadMessages,
  markMessagingThreadAsSeen,
  resetMessagingThreadMessages,
  sendNewMessagingMessage,
} from '../store/messaging';
import useLoggedUser from './useLoggedUser';

export type MessagingThreadMessagesResult = {
  messages: (MessagingMessage & {
    isLoading: boolean;
  })[];
  members: MessagingThreadMember[];
  nextCursor: string | null;
  isLoading: boolean;
  threadId: number | null;
  fetchNext: () => Promise<void>;
  refresh: () => Promise<void>;
  sendNewMessage: (body: { message: string | null; file: Blob | null }) => Promise<void>;
};

export default function useMessagingThreadMessages(
  paramThreadId: number | null | undefined,
  contactId: number | null | undefined
): MessagingThreadMessagesResult {
  const user = useLoggedUser();
  const threadMessages = useSelector<RootState, ThreadMessages>((state) => state.messaging.threadMessages);

  const [lastMessageIdentifier, setLastMessageIdentifier] = useState(0);
  const [threadId, setThreadId] = useState(paramThreadId || null);
  const dispatch = useDispatch();
  const state = (threadId && threadMessages[threadId]) || defaultThreadMessageState;

  useEffect(() => {
    setThreadId(paramThreadId || null);
  }, [paramThreadId]);

  const generateNewMessageIdentifier = useCallback(() => {
    let identifier = lastMessageIdentifier + 1;
    setLastMessageIdentifier(identifier);

    return identifier;
  }, [lastMessageIdentifier]);

  const fetchNext = useCallback(async () => {
    if (!threadId) {
      return;
    }

    if (!state.nextCursor) {
      return;
    }

    fetchNextMessagingThreadMessages(dispatch, user, threadId, state.nextCursor);
  }, [dispatch, user, threadId, state.nextCursor]);

  const refresh = useCallback(async () => {
    if (!threadId) {
      return;
    }

    fetchMessagingThreadMembers(dispatch, user, threadId);
    resetMessagingThreadMessages(dispatch, user, threadId);
  }, [dispatch, user, threadId]);

  const sendNewMessage = useCallback(
    async (body: { message: string | null; file: Blob | null }) => {
      if (threadId) {
        const identifier = generateNewMessageIdentifier();

        sendNewMessagingMessage(dispatch, user, identifier, threadId, body);
      } else if (contactId) {
        const result = await postNewThread(user, contactId, body);
        setThreadId(result);
      }
    },
    [generateNewMessageIdentifier, contactId, user, threadId]
  );

  const refreshContactThread = useCallback(async () => {
    if (!threadId && contactId) {
      const result = await getThreadIdByContactId(user, contactId);
      setThreadId(result);
    }
  }, [user, threadId, contactId]);

  // Initialize by fetching if empty
  useEffect(() => {
    if (state.messages.length === 0) {
      refresh();
    }
  }, [threadId, state.messages.length]);

  useEffect(() => {
    if (!threadId && contactId) {
      refreshContactThread();
    }
  }, [threadId, contactId]);

  // Fetch members
  useEffect(() => {
    if (threadId && !state.members?.length) {
      fetchMessagingThreadMembers(dispatch, user, threadId);
    }
  }, [dispatch, user, threadId, state.members]);

  // Mark as seen if the thread change or a message is received
  useEffect(() => {
    if (threadId) {
      markMessagingThreadAsSeen(dispatch, user, threadId);
    }
  }, [threadId, state.messages.length]);

  return {
    ...state,
    threadId,
    fetchNext,
    refresh,
    sendNewMessage,
  };
}
