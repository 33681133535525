import AppText, { AppTextProps } from './AppText';
import { globalStyle } from '../../styles/globalStyle';
import React from 'react';

export type HypertextLinkProps = Omit<AppTextProps, 'children'> & {
  text: string;
};

export function HypertextLink({ style, text, ...props }: HypertextLinkProps) {
  return (
    <AppText style={[globalStyle.hypertextLink, style]} {...props}>
      {text}
    </AppText>
  );
}
