import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { Comment } from '../../entities/Comment';
import { Paginated } from '../../entities/Paginated';
import { UserSaved } from '../storage';

export async function getNewsComments(
  user: UserSaved,
  newsId: number,
  cursor: string | null,
  limit: number | null
): Promise<Paginated<Comment, string>> {
  return axios
    .get(`${apiUrlBase}Comment/news/${newsId}`, {
      headers: headers(user.token),
      params: { cursor, limit },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getContributionComments(
  user: UserSaved,
  contributionId: number,
  cursor: string | null,
  limit: number | null
): Promise<Paginated<Comment, string>> {
  return axios
    .get(`${apiUrlBase}Comment/contribution/${contributionId}`, {
      headers: headers(user.token),
      params: { cursor, limit },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function postNewsComment(user: UserSaved, newsId: number, text: string): Promise<Comment> {
  return axios
    .post(
      `${apiUrlBase}Comment/news/${newsId}`,
      { text },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function postContributionComment(user: UserSaved, contributionId: number, text: string): Promise<Comment> {
  return axios
    .post(
      `${apiUrlBase}Comment/contribution/${contributionId}`,
      { text },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateComment(user: UserSaved, commentId: number, text: string): Promise<Comment> {
  return axios
    .post(
      `${apiUrlBase}Comment/${commentId}`,
      { text },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function deleteComment(user: UserSaved, commentId: number): Promise<void> {
  return axios
    .delete(`${apiUrlBase}Comment/${commentId}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
