import React from 'react';
import { View } from 'react-native';
import { MediaComponent, SplitMedia } from '../../utils/Medias';

export interface MediaProps {
  media: string;
}

const Media = ({ media }: MediaProps) => {
  const [mediaName, url] = SplitMedia(media);

  return (
    <View>
      {MediaComponent(mediaName, url)}
    </View>
  )
}

export default Media;