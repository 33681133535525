import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export function useScreenOrientation() {
  let dimensions = useWindowDimensions();

  return useMemo(() => {
    if (dimensions.width < dimensions.height) {
      return 'portrait';
    } else {
      return 'landscape';
    }
  }, [dimensions]);
}
