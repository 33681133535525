import sanitizeHtml from 'sanitize-html';
import { zonedTimeToUtc } from 'date-fns-tz';

export function htmlTextToNeutralText(text: string) {
  let filteredText = text;
  filteredText = sanitizeHtml(text, {
    allowedTags: [],
  });
  return filteredText;
}

export function removeAccentsAndLower(str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

// Transform a list into a list of list following the given predicate
// For example, this [1, 3, 5, 2, 4, 6, 7, 8, 9, 10, 42] become [[1, 3, 5], [2, 4, 6], [7], [8], [9], [10, 42]]
// Given the following predicate: function (a) { return a % 2; }
export function groupByAdjacent<T, C>(array: T[], fn: (val: T) => C): T[][] {
  let fn_prev = null;
  let acc: T[][] = [];

  for (let val of ([] as T[]).concat(array)) {
    let fn_curr = fn(val);

    if (!acc.length || fn_curr !== fn_prev) {
      acc.push([val]);
    } else {
      acc[acc.length - 1].push(val);
    }
    fn_prev = fn_curr;
  }

  return acc;
}

export function newDateWithTimezone(timeZone: string) {
  const date = new Date();
  return zonedTimeToUtc(date, timeZone);
}

export function formatUrl(url: string) {
  if (!/^https?:\/\//i.test(url)) {
    return 'http://' + url;
  } else {
    return url;
  }
}

export function compareVersionStrings(str1: string, str2: string) {
  const split1 = str1.split('.');
  const split2 = str2.split('.');

  for (let i = 0; i < split1.length; i++) {
    const num1 = parseInt(split1[i]);
    const num2 = parseInt(split2[i]);
    if (num1 > num2) {
      return 1;
    } else if (num1 < num2) {
      return -1;
    }
  }

  return 0;
}

// Returns a vh value, but returns it at as number whereas it's a string to fake the linter
// This function should be used only in the context of the TV app
export function vh(value: number): number {
  return `${value}vh` as any;
}
