import React, { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import { FormField } from '../FormField';
import { getFolderDetails } from '../../../services/api/document.contributions.api';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { DARK_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import DocumentItemRow from '../DocumentItemRow';
import AppText from '../AppText';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FONT_FAMILY_DEFAULT } from '../../../styles/globalStyle';
import { t } from 'i18next';
import BrowseFolderDialog from '../BrowseFolderDialog';
import useLoggedUser from '../../../hooks/useLoggedUser';

export type FolderPickerFieldProps = {
  name: string;
  title: string;
  required?: boolean;
  parentFolder?: boolean;
};

export default function FolderPickerField({ name, title, required, parentFolder }: FolderPickerFieldProps) {
  const [field, meta, helper] = useField(name);
  const user = useLoggedUser();
  const [dialogVisible, setDialogVisible] = useState(false);

  const queryFunction = useCallback(
    async () => (field.value ? getFolderDetails(user, field.value) : null),
    [field.value, user]
  );

  const { data, isLoading } = useQuery(['folderDetails', field.value], queryFunction, { cacheTime: 0 });

  const handlePress = useCallback(() => {
    setDialogVisible(true);
  }, []);

  return (
    <FormField title={required ? `${title}*` : title}>
      <TouchableOpacity onPress={handlePress} style={styles.container}>
        {isLoading ? (
          <ActivityIndicator color={PRIMARY_COLOR} size="large" />
        ) : data ? (
          <DocumentItemRow
            item={{
              type: 'folder',
              id: data.id,
              contributionId: data.contributionId,
              title: data.title,
              dateCreated: data?.contribution?.dateCreation,
              user: {
                id: data?.contribution?.userIdCreateur,
                profileFileId: data?.contribution?.userIdCreateurNavigation?.fichierIdProfil,
                firstName: data?.contribution?.userIdCreateurNavigation?.prenom,
                lastName: data?.contribution?.userIdCreateurNavigation?.nom,
              },
            }}
            actions={[]}
            isAdminView={false}
            onPress={handlePress}
          />
        ) : (
          <>
            <AppText style={styles.rootFolderTitle} numberOfLines={1}>
              {t('root_folder')}
            </AppText>
            <AppText style={styles.rootFolderDescription}>
              {parentFolder ? t('root_folder_description_for_document') : t('root_folder_description_for_folder')}
            </AppText>
          </>
        )}
      </TouchableOpacity>
      <BrowseFolderDialog
        isVisible={dialogVisible}
        type="add"
        onFolderSelected={(newFolderId) => {
          helper.setValue(newFolderId);
          setDialogVisible(false);
        }}
        onClose={() => setDialogVisible(false)}
      />
    </FormField>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: PRIMARY_COLOR,
  },
  rootFolderTitle: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  rootFolderDescription: {
    flex: 1,
    fontSize: 12,
    fontStyle: 'italic',
    color: 'black',
    paddingRight: 7,
  },
});
