import React from 'react';
import { useField } from 'formik';
import { Image, ImageSourcePropType, View } from 'react-native';
import { PhotoPickButtons } from '../PhotoPicker';
import { t } from 'i18next';

export type AvatarFieldProps = {
  name: string;
};

export default function AvatarField({ name }: AvatarFieldProps) {
  const [field, , helper] = useField(name);

  return (
    <View
      style={{
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 45,
      }}
    >
      <Image
        source={!!field?.value?.uri ? { uri: field?.value?.uri } : require('../../../../assets/av1.png')}
        resizeMode="contain"
        style={{
          alignSelf: 'center',
          borderRadius: 75,
          width: 90,
          height: 90,
          marginBottom: 15,
        }}
      />
      <PhotoPickButtons
        modalText={t('pick_an_avatar')}
        images={avatars}
        setPhoto={(photo) => {
          helper.setValue(photo);
        }}
      />
    </View>
  );
}

export const avatars = [
  {
    file: require('../../../../assets/av1.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av1.png',
  },
  {
    file: require('../../../../assets/av2.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av2.png',
  },
  {
    file: require('../../../../assets/av3.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av3.png',
  },
  {
    file: require('../../../../assets/av4.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av4.png',
  },
  {
    file: require('../../../../assets/av5.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av5.png',
  },
  {
    file: require('../../../../assets/av6.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av6.png',
  },
  {
    file: require('../../../../assets/av7.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av7.png',
  },
  {
    file: require('../../../../assets/av8.png'),
    uri: 'https://www.konecteam.fr/wp-content/uploads/2021/02/av8.png',
  },
] as {
  file: ImageSourcePropType;
  uri: string;
}[];
