import React from 'react';
import { View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import ContributionSpreadingField from './ContributionSpreadingField';
import { isMobile } from '../../../services/utils';
import AppText, { Mode } from '../AppText';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import { t } from '../../../services/translations';
import { sendUserCount } from '../../../services/api/sms.emergency.api';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { useField } from 'formik';
import { SpreadingPostData } from '../../../entities/SpreadingPostData';

export default function SmsSendContributionSpreadingField() {
  const user = useLoggedUser();

  const [{ value: spreadingType }, ,] = useField('spreadingType');
  const [{ value: userIds }, ,] = useField('userIds');
  const [{ value: entrepriseServiceIds }, ,] = useField('entrepriseServiceIds');
  const [{ value: entrepriseSiteGeoIds }, ,] = useField('entrepriseSiteGeoIds');
  const [{ value: entrepriseGroupIds }, ,] = useField('entrepriseGroupIds');

  const { data: userCount } = useQuery(
    ['sendUserCount', spreadingType, userIds, entrepriseServiceIds, entrepriseSiteGeoIds, entrepriseGroupIds],
    () =>
      sendUserCount(user, {
        spreadingType,
        userIds,
        entrepriseServiceIds,
        entrepriseSiteGeoIds,
        entrepriseGroupIds,
      } as SpreadingPostData),
    {
      cacheTime: 0,
    }
  );

  return (
    <ContributionSpreadingField
      left={
        userCount ? (
          <View style={{ flexDirection: 'row', ...(isMobile() ? { gap: 20 } : {}) }}>
            <View
              style={{
                flexDirection: 'column',
                ...(isMobile() ? { marginTop: 20 } : { marginLeft: 50, marginTop: 10 }),
              }}
            >
              <AppText style={{ fontSize: 12, marginBottom: 10, color: PRIMARY_COLOR }} mode={Mode.BOLD}>
                {t('chosen_audience')}
              </AppText>
              <AppText style={{ fontSize: 12, marginBottom: 10 }} mode={Mode.BOLD}>
                {userCount.userCount} contributeur{userCount.userCount > 1 ? 's' : ''}
              </AppText>
            </View>
            <View
              style={{
                flexDirection: 'column',
                ...(isMobile() ? { marginTop: 20 } : { marginLeft: 50, marginTop: 10 }),
              }}
            >
              <AppText style={{ fontSize: 12, marginBottom: 10, color: PRIMARY_COLOR }} mode={Mode.BOLD}>
                {t('phone_count')}
              </AppText>
              <AppText style={{ fontSize: 12, marginBottom: 10 }} mode={Mode.BOLD}>
                {userCount.phoneUserCount} contributeur{userCount.phoneUserCount > 1 ? 's' : ''}
              </AppText>
            </View>
          </View>
        ) : undefined
      }
    />
  );
}
