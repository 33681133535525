import React from 'react';
import { View } from 'react-native';
import AppText from './components/atomic/AppText';
import { globalStyle } from './styles/globalStyle';
import { AppProviders } from '../App';

export default function Main() {
  const searchParams = new URLSearchParams(document.location.search);

  return (
    <AppProviders>
      <View style={globalStyle.screen}>
        <AppText>Email= {searchParams.get('email')}</AppText>
        <AppText>Id= {searchParams.get('id')}</AppText>
      </View>
    </AppProviders>
  );
}
