import {
  Keyboard,
  KeyboardType,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputFocusEventData,
  TextInputSelectionChangeEventData,
  TextInputSubmitEditingEventData,
  View,
  ViewStyle,
} from 'react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { globalStyle } from '../../styles/globalStyle';
import AppText from './AppText';
import { t } from '../../services/translations';
import { match } from 'ts-pattern';
import { FORM_LIGHT_GRAY, GRAY, LIGHT_GRAY } from '../../styles/appColor';

export type AppTextInputProps = {
  mandatory?: boolean;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  selectionPosition?: { start: number; end?: number | undefined };
  maxLength?: number;
  displayOnly?: boolean;
  multiline?: boolean;
  onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
  onChangeText: (text: string) => void;
  onSelectionChange?: (e: NativeSyntheticEvent<TextInputSelectionChangeEventData>) => void;
  defaultValue?: string;
  value?: string;
  variant?: 'default' | 'contained';
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters' | undefined;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
  onFocus?: () => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  autoFocus?: boolean;
};

export function AppTextInput(props: AppTextInputProps) {
  const textLength = !props.value ? 0 : props.value.length;
  const [filledField, setFilledField] = useState(true);

  const _onChangeText = (text: string) => {
    setFilledField(text !== '');
    props.onChangeText(text);
  };

  const localInputRef = useRef<TextInput>();

  const keyboardDidHideCallback = useCallback(() => {
    if (!props.autoFocus) {
      localInputRef.current?.blur();
    }
  }, [props.autoFocus]);

  useEffect(() => {
    if (props.autoFocus && localInputRef.current) {
      localInputRef.current.focus();
    }
  }, [props.autoFocus]);

  useEffect(() => {
    const keyboardDidHideSubscription = Keyboard.addListener('keyboardDidHide', keyboardDidHideCallback);

    return () => {
      keyboardDidHideSubscription?.remove();
    };
  }, [keyboardDidHideCallback]);

  const style = match(props.variant)
    .with('default', () => globalStyle.textInput)
    .with('contained', () => globalStyle.textInputContained)
    .otherwise(() => globalStyle.textInput);

  return (
    <View style={props.style}>
      <TextInput
        ref={(ref) => {
          localInputRef && (localInputRef.current = ref as any);
        }}
        autoCapitalize={
          props.autoCapitalize ? props.autoCapitalize : props.keyboardType === 'email-address' ? 'none' : undefined
        }
        placeholder={props.placeholder}
        multiline={props.multiline}
        onFocus={props.onFocus}
        editable={!props.displayOnly}
        secureTextEntry={props.secureTextEntry}
        keyboardType={props.keyboardType}
        style={[style, props.multiline ? globalStyle.textInputMultiline : null]}
        defaultValue={props.defaultValue}
        value={props.value}
        onChangeText={_onChangeText}
        maxLength={props.maxLength}
        onSubmitEditing={props.onSubmitEditing}
        onSelectionChange={props.onSelectionChange}
        selection={props.selectionPosition}
        autoFocus={props.autoFocus}
        onBlur={props.onBlur}
        placeholderTextColor={GRAY}
      />
      {props.mandatory && !filledField && <AppText style={styles.errorMessage}>{t('please_fill_this_field')}</AppText>}
      {props.maxLength !== undefined ? (
        <AppText style={styles.lengthText}>
          {textLength}/{props.maxLength}
        </AppText>
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  errorMessage: {
    color: 'red',
  },
  lengthText: {
    textAlign: 'right',
    marginTop: 7,
  },
});
