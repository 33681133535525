import { TouchableOpacity } from 'react-native';
import { Icon } from '@rneui/base';
import React from 'react';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../styles/appColor';
import { LinearGradient } from 'expo-linear-gradient';
import { AppSafeAreaView } from '../containers/AppSafeAreaView';

export function CloseButton(props: { onPress }) {
  return (
    <AppSafeAreaView
      style={{
        position: 'absolute',
        bottom: 10,
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <TouchableOpacity onPress={props.onPress} style={{ width: 80, height: 80 }}>
        <LinearGradient
          colors={[PRIMARY_COLOR, SECONDARY_COLOR]}
          style={{ flex: 1, justifyContent: 'center', borderRadius: 40 }}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 1, y: 0.5 }}
        >
          <Icon name="close" color="white" />
        </LinearGradient>
      </TouchableOpacity>
    </AppSafeAreaView>
  );
}
