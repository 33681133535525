import { useEffect, useState } from 'react';
import { language } from '../services/translations';

const ephemeris = require('../ephemeris.json');

export default function useTodayDateTime() {
  const [date, setDate] = useState(new Date());
  const ephemerisOfTheDay = ephemeris[date.getMonth()][date.getDate() - 1];

  useEffect(() => {
    const timer = setTimeout(() => {
      setDate(new Date());
    }, 60000);
    return () => clearTimeout(timer);
  }, [date]);

  return {
    date: new Intl.DateTimeFormat(language(), {
      day: '2-digit',
      year: 'numeric',
      month: 'long',
      weekday: 'long',
    }).format(date),
    shortDate: new Intl.DateTimeFormat(language(), {
      day: '2-digit',
      year: 'numeric',
      month: '2-digit',
    }).format(date),
    time: new Intl.DateTimeFormat(language(), {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date),
    ephemerisOfTheDay: ephemerisOfTheDay[1] + ' ' + ephemerisOfTheDay[0],
  };
}
