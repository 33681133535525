import React from 'react';
import { Image } from 'react-native';
import { globalStyle } from '../../styles/globalStyle';

export default function Logo(props: { style?: any }) {
  return (
    <Image
      style={[globalStyle.logo, props.style]}
      resizeMode="contain"
      resizeMethod="auto"
      source={require('../../../assets/logo-text.png')}
    />
  );
}
