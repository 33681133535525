import React from 'react';
import * as Yup from 'yup';
import { t } from '../../services/translations';
import { AppButton } from '../../components/atomic/AppButton';
import { alertInfo } from '../../services/utils';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import AppText, { Mode } from '../../components/atomic/AppText';
import { Space1, Space2 } from '../../styles/spaces';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { ScreenNames } from '../../ScreenNames';
import Form from '../../components/atomic/formik/Form';
import { emailRegex } from '../../services/formUtil';
import { useMutation } from '@tanstack/react-query';
import { resetPasswordSendCode } from '../../services/api/login';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import TextField from '../../components/atomic/formik/TextField';
import SubmitButton from '../../components/atomic/formik/SubmitButton';

const registrationBackground = require('../../../assets/registration-background.png');

export const schema = Yup.object({
  email: Yup.string().min(1).required(t('field_required')).matches(emailRegex, t('invalid_email')),
});

export type FormValues = {
  email: string;
};

export function ResetPasswordSendCode() {
  const navigation = useNavigation<NavigationProp<any>>();

  const { mutateAsync } = useMutation({
    mutationFn: async (values: FormValues) => {
      await resetPasswordSendCode(values.email);
      navigation.navigate(ScreenNames.ResetPasswordSendCodeSuccess);
    },
    onError: () => {
      alertInfo(t('unknown_error'));
    },
  });

  return (
    <Form
      initialValues={{
        email: '',
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <TwoColumnsLayout background={registrationBackground}>
        <FormBody>
          <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_1_title')}</AppText>
          <AppText>{t('forgotten_password_step_1_text')}</AppText>
          <Space1 />
          <TextField keyboardType="email-address" name="email" title={t('email_address')} />
        </FormBody>
        <FormButtons>
          <SubmitButton text={t('send_button')} />
          <Space2 />
          <AppButton title={t('back_button')} onPress={() => navigation.navigate(ScreenNames.Login)} enabled={true} />
        </FormButtons>
      </TwoColumnsLayout>
    </Form>
  );
}
