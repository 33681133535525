import React from 'react';
import { UsersList } from '../../components/molecules/UsersList';
import { navigateToMessagingNewThread } from '../../utils/Messaging';
import { NavigationProp, useNavigation } from '@react-navigation/native';

export function MessagingCreateThreadScreen() {
  const navigation = useNavigation<NavigationProp<any>>();

  return <UsersList allowEdit={false} onPress={(user) => navigateToMessagingNewThread(navigation, user)} />;
}
