import React, { useEffect, useMemo, useState } from 'react';
import { AppScreen } from '../../../components/containers/AppScreen';
import { AppButton, Type } from '../../../components/atomic/AppButton';
import { dateLocale, t } from '../../../services/translations';
import {
  setContributionsListNeedsRefresh,
  setCurrentContribution,
  setDetailContributionType,
  setForceBack,
  setLoading,
} from '../../../store/action';
import { useDispatch } from 'react-redux';
import {
  ContributionType,
  IntToContributionType,
  SelectModeToSpreadingType,
  SpreadingType,
  SelectMode,
  ContributionTypesInfos,
  spreadingTypeToSelectMode,
} from '../../../types';
import {
  DateField,
  FormField,
  FormFieldPicker,
  NumberFormField,
  RTEFormField,
  TextFormField,
} from '../../../components/atomic/FormField';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import AddressAutocomplete from '../../../components/atomic/AddressAutocomplete';
import AppText, { Mode } from '../../../components/atomic/AppText';
import { PickUsers } from '../../../components/atomic/PickUsers';
import { AppTextInput } from '../../../components/atomic/AppTextInput';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getDataContentByType, getDescriptionByType, getTitleByType } from '../../../services/api/contributions';
import { getContributionItem, sendContribution, sendUpdateContribution } from '../../../services/api/contribution.api';
import {
  alertInfo,
  forceUTCToLocal,
  hasSubAdminRight,
  isAdmin,
  isDateValid,
  PickPhotoOrDocumentResult,
} from '../../../services/utils';
import { AppListSwitch } from '../../../components/atomic/AppListSwitch';
import { getContributionImageSource } from '../../../services/api/helper.api';
import AppSwitch from '../../../components/atomic/AppSwitch';
import { ALL_CONTRIBUTIONS_BROADCAST_ENABLED, NEWS_PUBLICATION_MODE_ENABLED } from '../../../services/features';
import { DateTime } from 'luxon';
import { hasCategories } from '../../../utils/Category';
import { Category } from '../../../entities/Category';
import { getCategoriesByType } from '../../../services/api/category.api';
import { useDeviceSize } from '../../../hooks/useDeviceSize';
import AppRadioGroup from '../../../components/atomic/AppRadioGroup';
import { formatDistanceStrict } from 'date-fns';
import { match } from 'ts-pattern';
import { DrawerScreenProps } from '@react-navigation/drawer';
import PhotoFormField from '../../../components/containers/PhotoFormField';
import useLoggedUser from '../../../hooks/useLoggedUser';
import UpdateSurveyScreen from './survey/UpdateSurveyScreen';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

function priceToString(price: number) {
  if (!price) return '';
  return price.toString();
}

export function ContributionFormScreen({ navigation, route }: DrawerScreenProps<any>) {
  const dispatch = useDispatch();
  const id = route.params?.id;
  const contributionType: ContributionType = IntToContributionType(route.params?.contributionType);

  useEffect(() => {
    dispatch(setDetailContributionType(contributionType));
    if (!!route.params?.isAdminView) dispatch(setForceBack(true));

    return () => {
      dispatch(setDetailContributionType(undefined));
      dispatch(setForceBack(false));
    };
  }, [contributionType, dispatch]);

  if (contributionType === ContributionType.SURVEY) {
    return <UpdateSurveyScreen id={parseInt(id)} />;
  }

  const [photo, setPhoto] = useState<PickPhotoOrDocumentResult | null>(null);
  const [originalPictureId, setOriginalPictureId] = useState(route.params?.pictureId);
  const [title, setTitle] = useState(route.params?.title ?? '');
  const [description, setDescription] = useState(route.params?.description);
  const [brand, setBrand] = useState(route.params?.brand);
  const [price, setPrice] = useState(route.params?.price);
  const [start, setStart] = useState(route.params?.start);
  const [startCoordinates, setStartCoordinates] = useState(route.params?.startCoordinates);
  const [end, setEnd] = useState(route.params?.end);
  const [endCoordinates, setEndCoordinates] = useState(route.params?.endCoordinates);
  const [permanent, setPermanent] = useState(route.params?.permanent);
  const [location, setLocation] = useState(route.params?.location);
  const [date, setDate] = useState<string | undefined>(new Date().toISOString());
  const [endDate, setEndDate] = useState<string | undefined>(
    DateTime.fromISO(new Date().toISOString()).plus({ hour: 1 }).toISO()
  );
  const [anonymous, setAnonymous] = useState(route.params?.anonymous);
  const [dueDate, setDueDate] = useState(route.params?.dueDate);
  const [moneyPool, setMoneyPool] = useState(route.params?.moneyPool ?? '');
  const [place, setPlace] = useState<null | string>('');
  const [videoUrl, setVideoUrl] = useState<string | null>();
  const [url, setUrl] = useState<null | string>(null);
  const [availableSeats, setAvailableSeats] = useState<null | number>(null);
  const [questions, setQuestions] = useState(route.params?.question);
  const [lost, setLost] = useState(route.params?.lost);
  const [users, setUsers] = useState<number[]>(route.params?.users);
  const [services, setServices] = useState<number[]>([]);
  const [geoSites, setGeoSites] = useState<number[]>([]);
  const [groups, setGroups] = useState<number[]>([]);
  const [originalFilename, setOriginalFilename] = useState(undefined);
  const [documentTag, setDocumentTag] = useState('');
  const [initialPickedUsers, setInitialPickedUsers] = useState(new Set());
  const [answers, setAnswers] = useState(route.params?.answers !== undefined ? route.params?.answers : [['', '']]);
  const [selectMode, setSelectMode] = useState<SelectMode | undefined>();
  const spreadingType: SpreadingType | undefined = useMemo(() => SelectModeToSpreadingType(selectMode), [selectMode]);
  // Company news specific fields
  const [featured, setFeatured] = useState(route.params?.featured);
  const [visibleStartDate, setVisibleStartDate] = useState(route.params?.visibleStartDate);
  const [visibleEndDate, setVisibleEndDate] = useState(route.params?.visibleEndDate);
  const [publicationMode, setPublicationMode] = useState(route.params?.publicationMode);
  const [translations, setTranslations] = useState(
    route.params?.translations === undefined ? [] : route.params?.translations
  );
  const [categoryId, setCategoryId] = useState<undefined | number>(undefined);
  const [categories, setCategories] = useState<Category[]>([]);
  const [commentsActivated, setCommentsActivated] = useState(true);
  const [notifActivated, setNotifActivated] = useState(true);
  const [emailActivated, setEmailActivated] = useState(false);

  const eventType = useMemo(() => {
    if (place === null && url === null) {
      return null;
    } else if (place !== null && url === null) {
      return 'place';
    } else if (place === null && url !== null) {
      return 'remote';
    } else {
      return 'both';
    }
  }, [place, url]);

  const dateObject = date && new Date(date);
  const endDateObject = endDate && new Date(endDate);
  const { isLargeDevice, deviceSize } = useDeviceSize();

  useEffect(() => {
    if (!!route.params?.date) {
      setDate(route.params?.date);
      setEndDate(DateTime.fromISO(route.params?.date).plus({ hour: 1 }).toISO());
    }
  }, [route.params?.date]);

  // Document specific fields
  const [generateAnEmail, setGenerateAnEmail] = useState(false);

  const user = useLoggedUser();
  const locale = dateLocale();

  useEffect(() => {
    if (id !== undefined) {
      dispatch(setLoading(true));
      getContributionItem(user, id, contributionType)
        .then((json) => {
          const dataContent = getDataContentByType(json, contributionType);
          dispatch(setCurrentContribution(dataContent));

          setTitle(getTitleByType(dataContent, contributionType));
          setDescription(getDescriptionByType(dataContent, contributionType));
          if (contributionType === ContributionType.COMPANY_NEWS) setOriginalPictureId(dataContent.imageId);
          else setOriginalPictureId(dataContent.fichierIdPhoto);
          setPhoto(null);
          setCommentsActivated(dataContent?.contribution?.commentsActivated);
          setNotifActivated(dataContent?.contribution?.notifActivated);
          setEmailActivated(dataContent?.contribution?.emailActivated);
          setSelectMode(spreadingTypeToSelectMode(dataContent?.contribution?.spreadingType));
          setUsers(dataContent?.contribution?.userTargets.map((e) => e.userId) ?? []);
          setServices(dataContent?.contribution?.entrepriseServices.map((e) => e.entrepriseServiceId) ?? []);
          setGeoSites(dataContent?.contribution?.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId) ?? []);
          setGroups(dataContent?.contribution?.entrepriseGroups.map((e) => e.entrepriseGroupId) ?? []);

          if (hasCategories(contributionType))
            setCategoryId(ContributionTypesInfos[contributionType].categoryIdSelector!(dataContent));
          switch (contributionType) {
            case ContributionType.SELL:
              setBrand(dataContent.marque);
              setPrice(dataContent.prix);
              break;
            case ContributionType.CARPOOL:
              setStart(dataContent.libelleDepart);
              setEnd(dataContent.libelleArrivee);
              setStartCoordinates(dataContent.adresseDepart);
              setEndCoordinates(dataContent.adresseArrivee);
              setPermanent(dataContent.lovIdType === 22);
              break;
            case ContributionType.LOST_PROPERTY:
              setLocation(dataContent.lieu);
              setLost(dataContent.lovIdType === 19);
              setDate(forceUTCToLocal(dataContent.date) || undefined);
              break;
            case ContributionType.EVENT:
            case ContributionType.FORMATION:
              let endDate = new Date(forceUTCToLocal(dataContent.date));
              endDate.setSeconds(endDate.getSeconds() + dataContent.duration);
              setDate(forceUTCToLocal(dataContent.date) || undefined);
              setEndDate(endDate.toISOString());
              setMoneyPool(dataContent.cagnotte);
              setPlace(dataContent.place || null);
              setUrl(dataContent.url || null);
              setAvailableSeats(dataContent.availableSeats);
              break;
            case ContributionType.SURVEY:
              setAnonymous(dataContent.canAnonyme);
              setDueDate(dataContent.dateLimite);
              setAnswers(dataContent.cSondageQuestion.map((e) => e.cSondageReponse.map((a, k) => a.titre)));
              setQuestions(dataContent.cSondageQuestion.map((e) => e.titre));
              setUsers(dataContent.cSondageUsers.map((u, k) => u.userId));
              setInitialPickedUsers(dataContent.cSondageUsers.map((u, k) => u.userId));
              break;
            case ContributionType.COMPANY_NEWS:
              setFeatured(dataContent.featured);
              setVisibleStartDate(dataContent.dateDebutPublication);
              setVisibleEndDate(dataContent.dateFinPublication);
              setPublicationMode(
                dataContent.dateDebutPublication == null
                  ? 'immediate_posting'
                  : dataContent.dateFinPublication == null
                  ? 'planned_with_start_date'
                  : 'planned_with_start_and_end_date'
              );
              setTranslations(
                dataContent.traduction != null && dataContent.traduction !== undefined
                  ? JSON.parse(dataContent.traduction)
                  : []
              );
              break;
          }
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      resetForm();
    }

    return () => {
      dispatch(setCurrentContribution(undefined));
    };
  }, [route.params?.id, route]);

  useEffect(() => {
    if (hasCategories(contributionType))
      getCategoriesByType(user.entreprise?.id!, contributionType, user).then(setCategories);
  }, [user.entreprise?.id!, contributionType, route, user]);

  function resetForm() {
    setPhoto(null);
    setTitle('');
    setDescription('');
    setOriginalPictureId(undefined);
    setBrand('');
    setPrice('');
    setStart('');
    setEnd('');
    setStartCoordinates('');
    setEndCoordinates('');
    setPermanent(false);
    setLocation('');
    setLost(false);
    setDate(new Date().toISOString());
    setEndDate(DateTime.fromISO(new Date().toISOString()).plus({ hour: 1 }).toISO());
    setMoneyPool('');
    setPlace(null);
    setUrl(null);
    setAvailableSeats(null);
    setUsers([]);
    setInitialPickedUsers([]);
    setAnonymous(false);
    setDueDate(undefined);
    setAnswers([['', '']]);
    setQuestions(['']);
    setOriginalFilename(undefined);
    setFeatured(false);
    setVisibleStartDate(undefined);
    setVisibleEndDate(undefined);
    setPublicationMode(undefined);
    setTranslations([]);
    setDocumentTag('');
    setGenerateAnEmail(false);
    setCommentsActivated(true);
    setNotifActivated(true);
    setEmailActivated(false);
  }

  function _send() {
    dispatch(setLoading(true));
    sendContribution(
      {
        user,
        userId: user.id,
        companyId: user.entreprise?.id!,
        description,
        title,
        price,
        brand,
        start,
        startCoordinates,
        end,
        endCoordinates,
        permanent,
        location,
        date,
        endDate,
        lost,
        users,
        anonymous,
        dueDate,
        questions,
        answers,
        videoUrl,
        moneyPool,
        place,
        url,
        availableSeats,
        featured,
        commentsActivated,
        notifActivated,
        emailActivated,
        translations,
        tag: documentTag,
        generateAnEmail,
        visibleStartDate,
        visibleEndDate,
        services,
        siteGeos: geoSites,
        groups,
        spreadingType,
        categoryId,
      },
      user.token,
      users,
      photo,
      contributionType
    )
      .then(() => {
        dispatch(setLoading(false));
        alertInfo(t('send_contribution_success'));
        resetForm();
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      })
      .catch(() => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => dispatch(setLoading(false)));
  }

  function _getUpdateSuccessText() {
    switch (contributionType) {
      case ContributionType.COMPANY_NEWS:
        return t('update_company_news_success');
    }
    return t('update_contribution_success');
  }
  function _update() {
    dispatch(setLoading(true));
    sendUpdateContribution(
      id,
      {
        user,
        userId: user.id,
        companyId: user.entreprise?.id,
        description,
        title,
        price,
        brand,
        start,
        startCoordinates,
        end,
        endCoordinates,
        permanent,
        location,
        date,
        endDate,
        lost,
        moneyPool,
        place,
        url,
        availableSeats,
        users,
        anonymous,
        dueDate,
        questions,
        answers,
        videoUrl,
        featured,
        commentsActivated,
        notifActivated,
        emailActivated,
        translations,
        tag: documentTag,
        generateAnEmail,
        visibleStartDate,
        visibleEndDate,
        services,
        siteGeos: geoSites,
        groups,
        spreadingType,
        categoryId,
      },
      user.token,
      users,
      photo,
      contributionType
    )
      .then((response) => {
        dispatch(setLoading(false));
        alertInfo(_getUpdateSuccessText());
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      })
      .catch((e) => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => dispatch(setLoading(false)));
  }

  let hasPhoto =
    contributionType === ContributionType.SELL ||
    contributionType === ContributionType.ACTIVITY ||
    contributionType === ContributionType.LOST_PROPERTY ||
    contributionType === ContributionType.EVENT ||
    contributionType === ContributionType.FORMATION ||
    contributionType === ContributionType.MUTUAL_AID ||
    contributionType === ContributionType.SURVEY;
  let hasTitle =
    contributionType === ContributionType.SELL ||
    contributionType === ContributionType.ACTIVITY ||
    contributionType === ContributionType.MUTUAL_AID ||
    contributionType === ContributionType.LOST_PROPERTY ||
    contributionType === ContributionType.EVENT ||
    contributionType === ContributionType.FORMATION ||
    contributionType === ContributionType.SURVEY ||
    contributionType === ContributionType.COMPANY_NEWS;
  let hasDescription =
    contributionType !== ContributionType.LOST_PROPERTY && contributionType !== ContributionType.COMPANY_NEWS;

  let isFormReady = true;
  if (hasTitle) isFormReady = isFormReady && title?.length > 0;

  if (hasDescription) isFormReady = isFormReady && description?.length > 0;

  if (contributionType === ContributionType.CARPOOL) {
    isFormReady = isFormReady && start?.length > 0;
    isFormReady = isFormReady && startCoordinates?.length > 0;
    isFormReady = isFormReady && end?.length > 0;
    isFormReady = isFormReady && endCoordinates?.length > 0;
  }
  if (contributionType === ContributionType.LOST_PROPERTY) {
    isFormReady = isFormReady && isDateValid(date, true);
    isFormReady = isFormReady && location?.length > 0;
  }

  if (contributionType === ContributionType.EVENT || contributionType === ContributionType.FORMATION) {
    isFormReady = isFormReady && isDateValid(date, true);
    isFormReady = isFormReady && (availableSeats === null || !isNaN(availableSeats));
    isFormReady =
      isFormReady &&
      match(eventType)
        .with('both', () => (url?.length || 0) > 0 && (place?.length || 0) > 0)
        .with('place', () => (place?.length || 0) > 0)
        .with('remote', () => (url?.length || 0) > 0)
        .otherwise(() => false);
  }

  if (contributionType === ContributionType.SURVEY) {
    isFormReady = isFormReady && isDateValid(dueDate, true);
    isFormReady = isFormReady && questions?.every((e) => e?.length > 0);
    isFormReady = isFormReady && answers.every((e) => e?.length >= 2);
    for (const answersKey in answers) {
      isFormReady = isFormReady && answers[answersKey].every((e) => e.length > 0);
    }
  }

  if (selectMode === undefined) {
    isFormReady = false;
  } else {
    if (selectMode === SelectMode.BY_SERVICE) {
      isFormReady = isFormReady && services != undefined && Array.from(services).length > 0;
    }

    if (selectMode === SelectMode.BY_GEOSITE) {
      isFormReady = isFormReady && geoSites != undefined && Array.from(geoSites).length > 0;
    }

    if (selectMode === SelectMode.BY_GEOSITE_AND_SERVICE) {
      isFormReady = isFormReady && geoSites != undefined && Array.from(geoSites).length > 0;
      isFormReady = isFormReady && services != undefined && Array.from(services).length > 0;
    }

    if (selectMode === SelectMode.BY_GROUP) {
      isFormReady = isFormReady && groups != undefined && Array.from(groups).length > 0;
    }

    if (selectMode === SelectMode.MANUAL) {
      isFormReady = isFormReady && users != undefined && Array.from(users).length > 0;
    }
  }

  useEffect(() => {
    if (publicationMode === 'immediate_posting') {
      setVisibleStartDate(undefined);
      setVisibleEndDate(undefined);
    }
  }, [publicationMode]);

  function _needsValidation() {
    switch (contributionType) {
      case ContributionType.SURVEY:
      case ContributionType.COMPANY_NEWS:
        return !isAdmin(user) && !hasSubAdminRight(user, services, geoSites, groups);
    }
    return false;
  }

  function _sendButtonText() {
    if (!id) {
      switch (contributionType) {
        default:
          if (_needsValidation()) return t('send_for_validation');
          else return t('publish_button');
      }
    } else {
      return t('update_button');
    }
  }

  function _updatePrice(value: string) {
    setPrice(value.replace(/[^\d.-]/g, ''));
  }

  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        enableAutomaticScroll={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        {hasPhoto ? (
          <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
            <PhotoFormField
              defaultPhoto={getContributionImageSource(originalPictureId, contributionType)}
              photo={photo}
              videoUrl={videoUrl}
              allowPdf
              onPhotoChanged={setPhoto}
              onVideoUrlChanged={setVideoUrl}
            />
          </View>
        ) : null}
        {hasTitle ? <TextFormField mandatory onChangeText={setTitle} value={title} title={t('title') + '*'} /> : null}
        {hasDescription ? (
          <RTEFormField onChangeText={setDescription} defaultValue={description} title={t('description') + '*'} />
        ) : null}
        {contributionType === ContributionType.SELL ? (
          <>
            <TextFormField onChangeText={setBrand} value={brand == undefined ? '' : brand} title={t('brand')} />
            <TextFormField
              onChangeText={_updatePrice}
              keyboardType="decimal-pad"
              value={priceToString(price)}
              title={t('price')}
            />
          </>
        ) : null}
        {contributionType === ContributionType.CARPOOL ? (
          <>
            <AddressAutocomplete
              title={t('from') + '*'}
              value={start}
              setValue={setStart}
              setCoordinates={setStartCoordinates}
              style={{ zIndex: 10000001 }}
            />
            <AddressAutocomplete
              title={t('to') + '*'}
              value={end}
              setValue={setEnd}
              setCoordinates={setEndCoordinates}
            />
            <AppListSwitch
              onValueChange={setPermanent}
              value={permanent}
              firstOptionText={t('temporary')}
              secondOptionText={t('permanent')}
            />
          </>
        ) : null}
        {contributionType === ContributionType.LOST_PROPERTY ? (
          <>
            <TextFormField mandatory onChangeText={setLocation} value={location} title={t('where') + '*'} />
            <AppListSwitch
              onValueChange={setLost}
              value={lost}
              firstOptionText={t('found_s')}
              secondOptionText={t('lost_s')}
            />
            <DateField onChangeText={setDate} defaultValue={date} title={t('when') + '*'} />
          </>
        ) : null}
        {contributionType === ContributionType.EVENT || contributionType === ContributionType.FORMATION ? (
          <>
            <View style={[style.eventDateContainer, !isLargeDevice ? style.eventDateContainerSmall : undefined]}>
              <DateField
                style={!isLargeDevice ? style.eventDateElemSmall : style.eventDateElem}
                withTime={true}
                onChangeText={setDate}
                defaultValue={date}
                title={t('date') + '*'}
              />
              <DateField
                style={!isLargeDevice ? style.eventDateElemSmall : style.eventDateElem}
                withTime={true}
                onChangeText={setEndDate}
                defaultValue={endDate}
                title={t('end_date') + '*'}
              />
              {!!dateObject && !!endDateObject && dateObject < endDateObject && (
                <FormField style={!isLargeDevice ? style.eventDateElemSmall : style.eventDateElem} title="Durée">
                  <AppText style={style.eventDateDuree} mode={Mode.BOLD}>
                    {formatDistanceStrict(dateObject, endDateObject, { locale, roundingMethod: 'ceil' })}
                  </AppText>
                </FormField>
              )}
            </View>
            <AppRadioGroup<'both' | 'remote' | 'place'>
              title={t('event_type') + '*'}
              value={eventType}
              onChange={(value) =>
                match(value)
                  .with('both', () => {
                    setPlace(place || '');
                    setUrl(url || '');
                  })
                  .with('place', () => {
                    setPlace(place || '');
                    setUrl(null);
                  })
                  .with('remote', () => {
                    setPlace(null);
                    setUrl(url || '');
                  })
                  .exhaustive()
              }
              options={[
                {
                  label: t('event_type_place'),
                  value: 'place',
                },
                {
                  label: t('event_type_remote'),
                  value: 'remote',
                },
                {
                  label: t('event_type_both'),
                  value: 'both',
                },
              ]}
              renderLeft={() => (
                <View
                  style={[style.eventDateElem, deviceSize != 'extra-large' ? style.eventDateLastElemSmall : undefined]}
                >
                  {['place', 'both'].includes(eventType) && (
                    <View style={style.eventMoneyPool}>
                      <AppText style={style.eventMoneyPoolLabel} mode={Mode.BOLD}>
                        {t('event_place') + '*'}
                      </AppText>
                      <AppTextInput onChangeText={setPlace} value={place || ''} style={style.eventMoneyPoolInput} />
                    </View>
                  )}
                  {['remote', 'both'].includes(eventType) && (
                    <View style={style.eventMoneyPool}>
                      <AppText style={style.eventMoneyPoolLabel} mode={Mode.BOLD}>
                        {t('event_url') + '*'}
                      </AppText>
                      <AppTextInput onChangeText={setUrl} value={url || ''} style={style.eventMoneyPoolInput} />
                    </View>
                  )}
                </View>
              )}
            />
            <View
              style={[!isLargeDevice ? style.eventAvailableSeatsContainerSmall : style.eventAvailableSeatsContainer]}
            >
              <FormField
                title={t('event_available_seats') + '*'}
                style={[style.eventAvailableSeats]}
                children={undefined}
              />
              <AppListSwitch
                onValueChange={(value) => {
                  setAvailableSeats(value ? 10 : null);
                }}
                value={availableSeats !== null}
                firstOptionText={t('event_unlimited')}
                secondOptionText={t('event_limited')}
                design="ios"
              />
              {availableSeats !== null && (
                <NumberFormField
                  onChange={(value) => {
                    setAvailableSeats(value);
                  }}
                  value={availableSeats || null}
                  title={t('event_available_seats')}
                  keyboardType="numeric"
                  style={!isLargeDevice ? style.eventSeatsNumberInputSmall : style.eventSeatsNumberInput}
                />
              )}
            </View>
          </>
        ) : null}
        {contributionType === ContributionType.SURVEY ? (
          <>
            <FormField title={t('allow_anonymous')}>
              <AppSwitch value={anonymous} onValueChange={setAnonymous} />
            </FormField>
            <DateField onChangeText={setDueDate} defaultValue={dueDate} title={t('due_date') + '*'} />
            {questions?.map((question, i) => {
              return (
                <>
                  <FormField key={i} title={t('question') + ' ' + (i + 1) + '*'}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <AppTextInput
                        key={i}
                        // defaultValue={v}
                        style={{ flex: 1 }}
                        mandatory
                        onChangeText={(updateQuestion) =>
                          setQuestions([...questions.slice(0, i), updateQuestion, ...questions.slice(i + 1)])
                        }
                        value={question}
                      />
                      {questions.length > 1 && (
                        <TouchableOpacity
                          key={i}
                          style={{ marginLeft: 10 }}
                          onPress={() => {
                            setAnswers([...answers.slice(0, i), ...answers.slice(i + 1)]);
                            setQuestions([...questions.slice(0, i), ...questions.slice(i + 1)]);
                          }}
                        >
                          <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
                        </TouchableOpacity>
                      )}
                    </View>
                  </FormField>

                  {answers[i]?.map((v, k) => (
                    <FormField key={k} title={t('answer') + ' ' + (k + 1) + '*'}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <AppTextInput
                          key={k}
                          // defaultValue={v}
                          style={{ flex: 1 }}
                          onChangeText={(value) =>
                            setAnswers([
                              ...answers.slice(0, i),
                              [...answers[i].slice(0, k), value, ...answers[i].slice(k + 1)],
                              ...answers.slice(i + 1),
                            ])
                          }
                          value={v}
                        />
                        <TouchableOpacity
                          key={k}
                          style={{ marginLeft: 10 }}
                          onPress={() => {
                            setAnswers([
                              ...answers.slice(0, i),
                              [...answers[i].slice(0, k), ...answers[i].slice(k + 1)],
                              ...answers.slice(i + 1),
                            ]);
                          }}
                        >
                          <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
                        </TouchableOpacity>
                      </View>
                    </FormField>
                  ))}
                  <AppButton
                    icon="plus"
                    title={t('new_answer')}
                    onPress={() => setAnswers([...answers.slice(0, i), [...answers[i], ''], ...answers.slice(i + 1)])}
                  />
                </>
              );
            })}
            <AppButton
              icon="plus"
              title={t('new_question')}
              style={{ marginTop: 30 }}
              onPress={() => {
                setAnswers([...answers, ['', '']]);
                setQuestions([...questions, '']);
              }}
            />
          </>
        ) : null}
        <PickUsers
          enabled={ALL_CONTRIBUTIONS_BROADCAST_ENABLED}
          spreadingType={SelectModeToSpreadingType(selectMode)}
          pickedUsers={users}
          pickedServices={services}
          pickedGeoSites={geoSites}
          pickedGroups={groups}
          setSpreadingType={(value) => setSelectMode(spreadingTypeToSelectMode(value))}
          setPickedUsers={setUsers}
          setPickedServices={setServices}
          setPickedGeoSites={setGeoSites}
          setPickedGroups={setGroups}
        />
        {hasCategories(contributionType) && (
          <FormFieldPicker<string>
            style={{ marginTop: 24 }}
            title={t('categories')}
            displayOnly={NEWS_PUBLICATION_MODE_ENABLED}
            defaultValue={categoryId?.toString()}
            onChangeText={(value) => {
              if (value === t('select_an_item') || value === '' || value === undefined || value === null) {
                setCategoryId(undefined);
              } else {
                setCategoryId(parseInt(value));
              }
            }}
            data={categories.map((category) => ({ label: category.name, value: category.id.toString() }))}
          />
        )}
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
            <AppSwitch onValueChange={setCommentsActivated} value={commentsActivated} style={{ marginRight: 28 }} />
            <AppText>{t('authorize_comments')}</AppText>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
            <AppSwitch onValueChange={setNotifActivated} value={notifActivated} style={{ marginRight: 28 }} />
            <AppText>{t('generate_notif')}</AppText>
          </View>
          {isAdmin(user) && (
            <View style={{ flexDirection: 'row', marginTop: 40 }}>
              <AppSwitch onValueChange={setEmailActivated} value={emailActivated} style={{ marginRight: 28 }} />
              <AppText>{t('generate_email')}</AppText>
            </View>
          )}
        </View>
        {(contributionType === ContributionType.EVENT || contributionType === ContributionType.FORMATION) && id && (
          <AppText style={{ marginTop: 40 }}>
            (Attention en modifiant cet évènement / formation vous allez écraser les réponses, merci de faire un export
            des résultats avant la modification)
          </AppText>
        )}
        <View style={{ marginTop: 30, marginBottom: 100 }}>
          <AppButton
            title={_sendButtonText()}
            type={Type.PRIMARY}
            onPress={!id ? _send : _update}
            enabled={isFormReady}
          />
        </View>
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}

const style = StyleSheet.create({
  eventDateContainer: {
    flexDirection: 'row',
  },
  eventDateContainerSmall: {
    flexDirection: 'column',
  },
  eventDateElem: {
    flex: 1,
    paddingRight: 20,
  },
  eventDateElemSmall: {
    paddingRight: 20,
  },
  eventDateLastElemSmall: {
    paddingVertical: 20,
    width: '100%',
  },
  eventDateDuree: {},
  eventMoneyPool: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    flex: 1,
  },
  eventMoneyPoolLabel: {
    paddingRight: 10,
    fontSize: 12,
    color: PRIMARY_COLOR,
  },
  eventMoneyPoolInput: {
    flex: 1,
  },
  eventAvailableSeatsContainer: { alignItems: 'flex-start', flexDirection: 'row' },
  eventAvailableSeatsContainerSmall: {
    padding: 0,
    paddingBottom: 10,
    alignItems: 'flex-start',
  },
  eventAvailableSeats: { paddingRight: 40 },
  eventAvailableSeatsSwitch: { paddingRight: 40 },
  eventSeatsNumberInput: { paddingLeft: 40 },
  eventSeatsNumberInputSmall: { width: '100%' },
});
