import React, { useCallback } from 'react';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { useDispatch } from 'react-redux';
import { AppScreen } from '../../../components/containers/AppScreen';
import { AppSafeAreaView } from '../../../components/containers/AppSafeAreaView';
import Form from '../../../components/atomic/formik/Form';
import { ScrollView } from 'react-native-gesture-handler';
import { BackButton } from '../../../components/atomic/BackButton';
import { EditUserForm } from '../../../components/molecules/EditUserForm';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { t } from '../../../services/translations';
import { setDirectoryListNeedsRefresh } from '../../../store/action';

export type EditUserScreenProps = DrawerScreenProps<any>;

export default function EditUserScreen(props: EditUserScreenProps) {
  const userId = props.route.params?.id;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const handleSubmitEnd = useCallback(async (values: any) => {
    navigation.goBack();
    dispatch(setDirectoryListNeedsRefresh(true));
  }, []);

  return (
    <AppScreen>
      <AppSafeAreaView style={{ flex: 1 }}>
        <Form onSubmit={() => {}} initialValues={{}}>
          <ScrollView>
            <EditUserForm userId={userId} onSubmitEnd={handleSubmitEnd} title={t('edit_user')} allowEmailEdit />
            <BackButton />
          </ScrollView>
        </Form>
      </AppSafeAreaView>
    </AppScreen>
  );
}
