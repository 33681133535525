import React from 'react';
import { Platform, SafeAreaView, StatusBar } from 'react-native';

export function AppSafeAreaView(props: { style: any; children: any }) {
  return (
    <SafeAreaView
      style={{ flex: 1, paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0, ...props.style }}
    >
      {props.children}
    </SafeAreaView>
  );
}
