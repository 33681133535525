import { useSelector } from 'react-redux';
import { RootState, UserSaved } from '../services/storage';

export default function useLoggedUser() {
  return useSelector<RootState, UserSaved | null>((state) => {
    if (!state || !state.user.user) {
      return null;
    } else {
      return state.user.user;
    }
  }) as UserSaved;
}
