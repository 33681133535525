import React from 'react';
import { TouchableOpacity } from 'react-native';
import { ScreenNames } from '../../ScreenNames';
import { getIdFromItem } from '../../services/api/contributions';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { ContributionType, ContributionTypesInfos } from '../../types';
import { NavigationProp, ParamListBase, useNavigation } from '@react-navigation/native';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export function EditButton(props: {
  item: any;
  contributionType: ContributionType;
  navigation: NavigationProp<ParamListBase>;
}) {
  const item = props.item;
  const contributionType = props.contributionType;
  const id = getIdFromItem(item, contributionType);
  useNavigation();
  return (
    <TouchableOpacity
      onPress={() =>
        props.navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
          screen: ContributionTypesInfos[contributionType].formScreen ?? ScreenNames.ContributionEditFormScreen,
          params: {
            id: id,
            contributionType: props.contributionType,
          },
        })
      }
    >
      <FontAwesome6 size={18} name="edit" color={PRIMARY_COLOR} />
    </TouchableOpacity>
  );
}
