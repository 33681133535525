import React from 'react';
import { Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { isMobile, openURL } from '../../services/utils';
import { generateTeams } from '../../services/url.utils';
import { teams } from '../../services/assets';

export type TeamsButtonProps = {
  email: string;
  visible: boolean;
};

export function TeamsButton({ email, visible }: TeamsButtonProps) {
  if (!visible) {
    return null;
  } else {
    return (
      <TouchableOpacity style={{ width: isMobile() ? 250 : undefined }} onPress={() => openURL(generateTeams(email))}>
        <Image source={teams} resizeMode="contain" style={{ width: isMobile() ? 250 : undefined, height: 56 }} />
      </TouchableOpacity>
    );
  }
}
