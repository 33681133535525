import React, { useCallback, useEffect, useState } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AppText from './components/atomic/AppText';
import { DARK_GRAY, PRIMARY_COLOR_LIGHT } from './styles/appColor';
import { t } from './services/translations';
import { AppModalOverlay } from './components/atomic/AppModalOverlay';
import { qrcodeStoreImage } from './services/assets';
import { AppTextInput } from './components/atomic/AppTextInput';
import { AppButton } from './components/atomic/AppButton';
import { Icon } from '@rneui/base';
import { isMobile } from './services/utils';

export function OfferAppDownloadBanner() {
  const [showPopup, setShowPopup] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    (async () => {
      const showBanner = await AsyncStorage.getItem('offerAppDownloadBanner');
      if (showBanner === 'false') {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    })();
  }, []);

  const closeBanner = useCallback(() => {
    AsyncStorage.setItem('offerAppDownloadBanner', 'false');
    setShowBanner(false);
  }, []);

  if (!showBanner || isMobile()) {
    return null;
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={closeBanner} style={styles.closeButton}>
        <Icon size={18} name="close" color={DARK_GRAY} />
      </TouchableOpacity>
      <AppText>{t('download_our_application')}</AppText>
      <AppButton
        title={t('show_the_link')}
        style={styles.linkButton}
        textStyle={styles.linkButtonText}
        onPress={() => setShowPopup(true)}
      />
      <AppModalOverlay isVisible={showPopup} onBackdropPress={() => setShowPopup(false)}>
        <Image source={qrcodeStoreImage} style={styles.qrcode} />
        <AppTextInput defaultValue="http://onelink.to/x9fqhu" onChangeText={() => {}} />
      </AppModalOverlay>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: PRIMARY_COLOR_LIGHT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    marginRight: 10,
  },
  linkButton: {
    marginLeft: 10,
    width: 200,
  },
  linkButtonText: {
    fontSize: 12,
  },
  qrcode: {
    width: 200,
    height: 200,
  },
});
