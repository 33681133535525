import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { WHITE } from '../../styles/appColor';
import { vh } from '../../utils/Utils';
import WeatherIcon from './WeatherIcon';
import { useScreenOrientation } from '../../hooks/useScreenOrientation';

export type WeatherVerticalCardProps = {
  hour: number;
  temperature: number;
  rain: boolean;
  showers: boolean;
  snowfall: boolean;
  cloudCover: number;
  isDay: boolean;
};

export default function WeatherVerticalCard({
  hour,
  temperature,
  rain,
  showers,
  snowfall,
  cloudCover,
  isDay,
}: WeatherVerticalCardProps) {
  const orientation = useScreenOrientation();

  return (
    <View style={orientation === 'landscape' ? styles.container : styles.verticalContainer}>
      <Text style={orientation === 'landscape' ? styles.text : styles.verticalText}>
        {hour}
        <Text style={orientation === 'landscape' ? styles.subText : styles.verticalSubText}>h</Text>
      </Text>
      <WeatherIcon
        rain={rain}
        showers={showers}
        snowfall={snowfall}
        cloudCover={cloudCover}
        isDay={isDay}
        color={WHITE}
        style={orientation === 'landscape' ? styles.icon : styles.verticalIcon}
      />
      <Text style={orientation === 'landscape' ? styles.text : styles.verticalText}>
        {temperature}
        <Text style={orientation === 'landscape' ? styles.subText : styles.verticalSubText}>°</Text>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.15)',
    paddingVertical: vh(2),
    paddingHorizontal: vh(4),
    margin: vh(2),
  },
  verticalContainer: {
    backgroundColor: 'rgba(0,0,0,0.15)',
    paddingVertical: vh(1.5),
    paddingHorizontal: vh(3),
    margin: vh(1),
  },
  text: {
    color: WHITE,
    fontSize: vh(4),
  },
  verticalText: {
    color: WHITE,
    fontSize: vh(3),
  },
  subText: {
    color: WHITE,
    fontSize: vh(2),
  },
  verticalSubText: {
    color: WHITE,
    fontSize: vh(1.5),
  },
  icon: {
    width: vh(6),
    height: vh(6),
    marginTop: 10,
  },
  verticalIcon: {
    width: vh(4.5),
    height: vh(4.5),
    marginTop: 10,
  },
});
