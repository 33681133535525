import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { OrganizationalChart } from '../../entities/OrganizationalChart';
import { UserSaved } from '../storage';

export async function getOrganizationalCharts(user: UserSaved): Promise<OrganizationalChart[]> {
  return axios
    .get(`${apiUrlBase}OrganizationalChart`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getOrganizationalChartDetails(user: UserSaved, id: number): Promise<OrganizationalChart> {
  return axios
    .get(`${apiUrlBase}OrganizationalChart/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function createOrganizationalChart(
  user: UserSaved,
  label: string,
  file: any
): Promise<OrganizationalChart> {
  let formData = new FormData();
  formData.append('label', label);
  formData.append('file', file);

  return axios
    .post(`${apiUrlBase}OrganizationalChart`, formData, {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateOrganizationalChart(
  user: UserSaved,
  id: number,
  label: string,
  file: any
): Promise<OrganizationalChart> {
  let formData = new FormData();
  formData.append('label', label);
  formData.append('file', file);

  return axios
    .put(`${apiUrlBase}OrganizationalChart/${id}`, formData, {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateOrganizationalChartOrder(
  user: UserSaved,
  id: number,
  index: number
): Promise<OrganizationalChart[]> {
  return axios
    .put(
      `${apiUrlBase}OrganizationalChart/${id}/order`,
      { index },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function deleteOrganizationalChart(user: UserSaved, id: number): Promise<OrganizationalChart> {
  return axios
    .delete(`${apiUrlBase}OrganizationalChart/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
