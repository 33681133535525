import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from '@rneui/base';
import { t } from '../services/translations';
import { ScreenNames } from '../ScreenNames';
import createResponsiveStackNavigator from '../components/atomic/ReponsiveStackNavigator';
import { MessagingMainScreen } from '../screens/messaging/MessagingMainScreen';
import { MessagingCreateThreadScreen } from '../screens/messaging/MessagingCreateThread';
import { MessagingThreadScreen } from '../screens/messaging/MessagingThreadScreen';

const ResponsiveStack = createResponsiveStackNavigator();

export function MessagingRoute() {
  return (
    <ResponsiveStack.Navigator initialRouteName={ScreenNames.MessagingMain}>
      <ResponsiveStack.Screen
        name={ScreenNames.MessagingMain}
        component={MessagingMainScreen}
        options={({ navigation }) => ({
          headerShown: true,
          title: t('messaging'),
          headerLeft: () => (
            <TouchableOpacity
              onPress={() => navigation.openDrawer()}
              style={{
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255,255,255,0.4)',
                borderRadius: 60,
                margin: 10,
              }}
            >
              <Icon name="menu" />
            </TouchableOpacity>
          ),
        })}
      />
      <ResponsiveStack.Screen
        name={ScreenNames.MessagingCreateThread}
        component={MessagingCreateThreadScreen}
        options={{
          headerShown: true,
          title: t('messaging_create_thread'),
          headerBackTitle: t('back'),
        }}
      />
      <ResponsiveStack.Screen
        name={ScreenNames.MessagingThread}
        component={MessagingThreadScreen}
        options={({ route }: any) => ({
          headerShown: true,
          title: route?.params?.threadName || t('messaging'),
          headerBackTitle: t('back'),
        })}
      />
    </ResponsiveStack.Navigator>
  );
}
