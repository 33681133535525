import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useField } from 'formik';
import { AppPickerSelect, FormField, FormFieldPickerProps } from '../FormField';
import AppText, { Mode } from '../AppText';

export type SelectFieldProps<T> = Omit<Omit<Omit<FormFieldPickerProps<T>, 'defaultValue'>, 'onChangeText'>, 'title'> & {
  name: string;
  title?: string;
  required?: boolean;
  style?: StyleProp<ViewStyle>;
  onSelected?: (value: T) => void;
};

export function SelectField<T>({
  name,
  title,
  required,
  style,
  displayOnly,
  onSelected,
  ...rest
}: SelectFieldProps<T>) {
  const [field, meta, helper] = useField(name);

  const handleChangeText = (value: T) => {
    helper.setValue(value);
    if (onSelected) {
      onSelected(value);
    }
  };

  const selectComponent = (
    <AppPickerSelect<T>
      {...rest}
      displayOnly={displayOnly}
      defaultValue={field.value}
      onChangeText={handleChangeText}
    />
  );

  if (!title) {
    return selectComponent;
  } else {
    return (
      <FormField style={style} title={required ? `${title}*` : title}>
        <View style={{ opacity: !displayOnly ? 1 : 0.5 }}>{selectComponent}</View>
        {meta.touched && meta.error && <AppText mode={Mode.ERROR}>{meta.error}</AppText>}
      </FormField>
    );
  }
}
