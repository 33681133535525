import React from 'react';
import { ImageBackground, Platform, SafeAreaView, StyleSheet, View } from 'react-native';
import { appleStoreUrl, googlePlayUrl } from '../../configuration';
import { AppButton, Type } from '../components/atomic/AppButton';
import AppText, { Mode } from '../components/atomic/AppText';
import Logo from '../components/atomic/Logo';
import { t } from '../services/translations';
import { openURL } from '../services/utils';
import { PRIMARY_COLOR } from '../styles/appColor';

const registrationBackground = require('../../assets/background-white-shapes.png');

export function UnsupportedVersionScreen() {
  const navigateToUpdatePage = () => {
    if (Platform.OS === 'android') {
      openURL(googlePlayUrl);
    } else if (Platform.OS === 'ios') {
      openURL(appleStoreUrl);
    } else {
      openURL(googlePlayUrl);
    }
  };

  return (
    <ImageBackground source={registrationBackground} style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <View style={styles.logoContainer}>
          <Logo style={{ width: '70%', alignSelf: 'center' }} />
        </View>
        <View style={styles.textContainer}>
          <AppText style={styles.header} mode={Mode.HEADER2}>
            {t('unsupported_version_header')}
          </AppText>
          <AppText style={styles.text} mode={Mode.NORMAL}>
            {t('unsupported_version_text')}
          </AppText>
        </View>
        <AppButton title={t('update_app')} type={Type.PRIMARY} onPress={navigateToUpdatePage} />
      </SafeAreaView>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    margin: 20,
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 40,
  },
  textContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  header: {
    color: PRIMARY_COLOR,
  },
  text: {
    maxWidth: 330,
    marginBottom: 40,
    flex: 1,
  },
});
