import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { Fichier } from '../../entities/Fichier';
import { UserSaved } from '../storage';
import { headers } from './base';

export async function getFileDetails(user: UserSaved, id: number): Promise<Fichier> {
  return axios
    .get(`${apiUrlBase}Fichiers/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
