import { useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { createSmsEmergencyTemplate } from '../../../services/api/sms.emergency.api';
import { CreateSmsEmergencyTemplate } from '../../../entities/CreateSmsEmergencyTemplate';
import { alertInfo } from '../../../services/utils';
import { t } from '../../../services/translations';
import Form from '../../../components/atomic/formik/Form';
import EmergencySmsTemplateFormFields, { schema } from './EmergencySmsTemplateFormFields';

export default function EmergencySmsCreateTemplateScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: CreateSmsEmergencyTemplate) => {
      await createSmsEmergencyTemplate(user, values);
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={{
        title: '',
        message: '',
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <EmergencySmsTemplateFormFields />
    </Form>
  );
}
