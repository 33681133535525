import React, { createContext, useContext, useState } from 'react';
import { LayoutChangeEvent, ScrollView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import AppText, { Mode } from './AppText';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { isMobile } from '../../services/utils';

type TableContextType = {
  minColumnWidths: number[];
  setMinColumnWidth: (column: number, width: number) => void;
};

const TableContext = createContext<TableContextType>({
  minColumnWidths: [],
  setMinColumnWidth: () => {},
});

export type TableProps = {
  children: React.ReactNode | React.ReactNode[];
  onLayout?: (event: LayoutChangeEvent) => void;
  style?: StyleProp<ViewStyle>;
};

export default function Table({ children, onLayout, style }: TableProps) {
  const [minColumnWidths, setMinColumnWidths] = useState<number[]>([]);

  const setMinColumnWidth = (column: number, width: number) => {
    setMinColumnWidths((prev) => {
      const next = [...prev];
      next[column] = Math.max(next[column] || 0, width);
      return next;
    });
  };

  return (
    <TableContext.Provider value={{ minColumnWidths, setMinColumnWidth }}>
      <ScrollView
        horizontal
        style={styles.tableContainer}
        contentContainerStyle={[styles.table, style]}
        onLayout={onLayout}
      >
        {children}
      </ScrollView>
    </TableContext.Provider>
  );
}

export type TableRowProps = {
  children: React.ReactNode | React.ReactNode[];
  style?: StyleProp<ViewStyle>;
};

const TableRow = ({ children, style }: TableRowProps) => {
  return <View style={[styles.row, style]}>{children}</View>;
};

export type TableHeaderProps = {
  column: number;
  text: string;
  ignoreMinWidth?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
  style?: StyleProp<ViewStyle>;
};

const TableHeader = ({ column, text, ignoreMinWidth, onLayout, style }: TableHeaderProps) => {
  return (
    <TableCell column={column} style={style} ignoreMinWidth={ignoreMinWidth} onLayout={onLayout}>
      <AppText mode={Mode.BOLD} style={styles.headerText}>
        {text}
      </AppText>
    </TableCell>
  );
};

export type TableCellProps = {
  column: number;
  children: React.ReactNode | React.ReactNode[];
  ignoreMinWidth?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
  style?: StyleProp<ViewStyle>;
};

const TableCell = ({ column, children, ignoreMinWidth, onLayout, style }: TableCellProps) => {
  const { minColumnWidths, setMinColumnWidth } = useContext(TableContext);

  return (
    <View
      style={[styles.cell, style, !ignoreMinWidth && { minWidth: minColumnWidths[column] }]}
      onLayout={(ev) => {
        const width = ev.nativeEvent.layout.width;
        setMinColumnWidth(column, width);

        if (onLayout) {
          onLayout(ev);
        }
      }}
    >
      {children}
    </View>
  );
};

Table.Cell = TableCell;
Table.Row = TableRow;
Table.Header = TableHeader;

const styles = StyleSheet.create({
  tableContainer: {
    flexGrow: 0,
  },
  table: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: isMobile() ? 10 : 30,
  },
  row: {
    flexDirection: 'row',
  },
  cell: {
    padding: 8,
  },
  headerText: {
    color: PRIMARY_COLOR,
  },
});
