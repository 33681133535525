import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useNavigationBuilder, createNavigatorFactory, ParamListBase, StackRouter } from '@react-navigation/native';
import { Header } from '@rneui/base';
import { StackNavigationProp, StackView } from '@react-navigation/stack';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { isMobile } from '../../services/utils';

export type ResponsiveStackNavigatorProps<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = keyof ParamList,
  NavigatorID extends string | undefined = undefined
> = {
  children: ReactNode;
  initialRouteName: string;
  leftRender: string;
} & StackNavigationProp<ParamList, RouteName, NavigatorID>;

function ResponsiveStackNavigator<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = keyof ParamList,
  NavigatorID extends string | undefined = undefined
>({ children, initialRouteName, ...rest }: ResponsiveStackNavigatorProps<ParamList, RouteName, NavigatorID>) {
  const { state, navigation, descriptors, NavigationContent } = useNavigationBuilder(StackRouter, {
    initialRouteName,
    children,
  });

  const { isSmallMediumDevice } = useDeviceSize();

  const descriptorValues = Object.values(descriptors);
  const rightDescriptor = descriptorValues.length > 1 ? descriptorValues[descriptorValues.length - 1] : undefined;
  const rightComponent = rightDescriptor?.render() || <></>;

  // Reinitialize history of the navigator
  const reinitializeHistory = useCallback(() => {
    const mainRootName = state.routeNames[0];
    const lastRoute = state.routes[state.routes.length - 1];

    if (lastRoute.name === mainRootName) {
      navigation.reset({
        index: 0,
        routes: [{ name: mainRootName }],
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [{ name: mainRootName }, { name: lastRoute.name, params: lastRoute.params }],
      });
    }
  }, [navigation, state]);

  // If the device width break, we reinitialize the history
  const [lastSmallMediumDevice, setLastSmallMediumDevice] = useState<boolean | null>(null);
  useEffect(() => {
    if (isSmallMediumDevice !== lastSmallMediumDevice) {
      reinitializeHistory();
      setLastSmallMediumDevice(isSmallMediumDevice);
    }
  }, [reinitializeHistory, isSmallMediumDevice, lastSmallMediumDevice, descriptors]);

  // Reinitialize the history on web page load
  // Needs to be reworked to use something else than timeout,
  // Reinitialize don't works on component mount, i don't know why
  if (!isMobile()) {
    useEffect(() => {
      setTimeout(function () {
        reinitializeHistory();
      }, 10);
    }, []);
  }

  // Stack View for small device, thread list of left otherwise
  if (isSmallMediumDevice) {
    return (
      <NavigationContent>
        <View style={styles.mobile}>
          <StackView state={state} navigation={navigation} descriptors={descriptors} {...rest} />
        </View>
      </NavigationContent>
    );
  } else {
    return (
      <NavigationContent>
        <View style={styles.container}>
          <View style={styles.left}>
            <Header
              backgroundColor="#fff"
              centerComponent={{ text: descriptorValues[0].options?.title || '', style: styles.heading }}
            />

            {descriptorValues[0] ? descriptorValues[0].render() : <></>}
          </View>
          <View style={styles.right}>
            {rightDescriptor && (
              <Header
                containerStyle={styles.headingContainer}
                backgroundColor="#fff"
                centerComponent={{ text: rightDescriptor.options?.title || '', style: styles.heading }}
              />
            )}
            {rightComponent}
          </View>
        </View>
      </NavigationContent>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  headingContainer: {},
  headingLeft: {
    marginVertical: 'auto',
  },
  heading: {
    paddingVertical: 16,
    fontSize: 20,
    marginVertical: 'auto',
  },
  left: {},
  right: { flex: 1, paddingBottom: !isMobile() ? 60 : 0, backgroundColor: 'white' },
  mobile: { flex: 1, paddingBottom: !isMobile() ? 60 : 0, backgroundColor: 'white' },
});

const createResponsiveStackNavigator = createNavigatorFactory(ResponsiveStackNavigator);

export default createResponsiveStackNavigator;
