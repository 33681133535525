import React from 'react';
import * as Yup from 'yup';
import { t } from '../../../services/translations';
import { AppScreen } from '../../../components/containers/AppScreen';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import TextField from '../../../components/atomic/formik/TextField';
import SubmitButton from '../../../components/atomic/formik/SubmitButton';

export const schema = Yup.object({
  title: Yup.string().min(1).required(t('field_required')),
  message: Yup.string().min(1).max(160).required(t('field_required')),
});

export type EmergencySmsTemplateFormFieldsProps = {
  isEditing?: boolean | undefined;
};

export default function EmergencySmsTemplateFormFields({ isEditing }: EmergencySmsTemplateFormFieldsProps) {
  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <TextField name="title" mandatory required title={t('title')} />
        <TextField name="message" mandatory required title={t('message')} maxLength={160} multiline />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
