import React, { useEffect, useState } from 'react';
import { Category } from '../../entities/Category';
import { GLOBAL_CATEGORY } from '../../utils/Category';
import { CategoryChip } from '../atomic/CategoryChip';
import HorizontalFlatList from './HorizontalList';
import { StyleSheet } from 'react-native';
import Separator from '../atomic/Separator';
import { match } from 'ts-pattern';

export interface CategoriesListProps {
  categories: (Category | 'separator')[];
  onSelect?: (category?: Category) => void;
}

export const CategoriesList = ({ categories, onSelect }: CategoriesListProps) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();

  const onPress = (category: Category) => {
    if (category.id === GLOBAL_CATEGORY.id || category.id === selectedCategory?.id) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(category);
    }
  };

  useEffect(() => {
    onSelect?.(selectedCategory);
  }, [selectedCategory, onSelect]);

  return (
    <HorizontalFlatList
      data={[GLOBAL_CATEGORY, ...categories]}
      renderItem={({ item: category }) =>
        match(category)
          .with('separator', () => <Separator vertical style={styles.separator} />)
          .otherwise((category: Category) => (
            <CategoryChip
              style={styles.chip}
              selected={selectedCategory?.id === category.id}
              onPress={() => onPress(category)}
              category={category}
            />
          ))
      }
      keyExtractor={(category) =>
        match(category)
          .with('separator', () => 'separator')
          .otherwise((category: Category) => category.id.toString())
      }
    />
  );
};

const styles = StyleSheet.create({
  chip: {
    marginEnd: 10,
  },
  separator: {
    marginEnd: 10,
    height: 32,
  },
});
