import { useEffect, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { FormField } from '../FormField';
import AddressAutocomplete from '../AddressAutocomplete';
import { useField } from 'formik';
import { AppMapView } from '../AppMapView';
import { getGoogleAPIKey } from '../../../services/utils';
import axios from 'axios';

export type LocationFieldProps = {
  latitudeName: string;
  longitudeName: string;
  label: string;
  style?: StyleProp<ViewStyle>;
};

export default function LocationField({ latitudeName, longitudeName, label, style }: LocationFieldProps) {
  const [autocompleteValue, setAutocompleteValue] = useState('');
  const [latitudeField, , latitudeHelper] = useField<number | null>(latitudeName);
  const [longitudeField, , longitudeHelper] = useField<number | null>(longitudeName);

  // If latitude and longitude are set, set the autocomplete value by using the Google API
  // to get the city name at the given coordinates
  useEffect(() => {
    if (latitudeField.value && longitudeField.value) {
      const googleApiKey = getGoogleAPIKey();
      if (googleApiKey) {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitudeField.value},${longitudeField.value}&key=${googleApiKey}&language=fr&result_type=locality`
          )
          .then((response) => {
            if (response?.data?.results?.length > 0) {
              setAutocompleteValue(response.data.results[0].formatted_address);
            }
          });
      }
    }
  }, []);

  return (
    <FormField style={style} title={label}>
      <AddressAutocomplete
        title=""
        value={autocompleteValue}
        setValue={setAutocompleteValue}
        setCoordinates={(a) => {
          const [latitude, longitude] = a.split(';');
          latitudeHelper.setValue(parseFloat(latitude));
          longitudeHelper.setValue(parseFloat(longitude));
        }}
      />
      <View style={styles.mapContainer}>
        <AppMapView
          markers={
            latitudeField.value && longitudeField.value ? [`${latitudeField.value};${longitudeField.value}`] : []
          }
          setSelectedItem={() => {}}
        />
      </View>
    </FormField>
  );
}

const styles = StyleSheet.create({
  mapContainer: {
    width: 300,
    height: 300,
  },
});
