import React from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import * as Localization from 'expo-localization';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import {
  DARK_GRAY,
  PRIMARY_COLOR,
  PRIMARY_COLOR_DARKEN,
  PRIMARY_COLOR_LIGHT,
  SECONDARY_COLOR_DARKEN,
  SECONDARY_COLOR_LIGHT,
} from '../../styles/appColor';
import { apiTimeZone } from '../../../configuration';
import { FilePreview } from './FilePreview';
import { getDocumentURI } from '../../services/api/helper.api';
import { isMobile, openURL } from '../../services/utils';
import Feather from 'react-native-vector-icons/Feather';

export type ChatBubbleProps = {
  message: string | null;
  fileId: number | null;
  me?: boolean;
  date?: Date;
  isLoading?: boolean;
};

export function ChatBubble({ message, fileId, me, date, isLoading }: ChatBubbleProps) {
  const zonedDate = date && zonedTimeToUtc(date, apiTimeZone);

  return (
    <View
      style={[
        me ? styles.meContainer : styles.notMeContainer,
        styles.commonContainer,
        isLoading ? styles.isLoadingContainer : null,
      ]}
    >
      {fileId && (
        <View style={styles.filePreviewContainer}>
          <FilePreview style={styles.filePreview} source={{ id: fileId }} />
          <TouchableOpacity style={styles.fileActionContainer} onPress={() => openURL(getDocumentURI(fileId))}>
            <Feather size={30} name="download" color={PRIMARY_COLOR} />
          </TouchableOpacity>
        </View>
      )}
      {message && <Text style={[me ? styles.meText : styles.notMeText, styles.commonText]}>{message}</Text>}
      {zonedDate && !isNaN(zonedDate.getTime()) && (
        <Text style={styles.dateText}> {formatInTimeZone(zonedDate, Localization.timezone, 'HH:mm')}</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  meContainer: {
    backgroundColor: PRIMARY_COLOR_LIGHT,
  },
  notMeContainer: {
    backgroundColor: SECONDARY_COLOR_LIGHT,
  },
  isLoadingContainer: {
    opacity: 0.7,
  },
  commonContainer: {
    padding: 8,
    marginVertical: 4,
    borderRadius: 8,
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...(!isMobile() ? { overflowWrap: 'break-word' } : {}),
  },
  filePreviewContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  fileActionContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginHorizontal: 10,
  },
  filePreview: {
    width: 80,
    marginBottom: 20,
  },
  meText: {
    color: PRIMARY_COLOR_DARKEN,
  },
  notMeText: {
    color: SECONDARY_COLOR_DARKEN,
  },
  commonText: {
    fontSize: 14,
    flexWrap: 'wrap',
    width: '100%',
    ...(!isMobile() ? { overflowWrap: 'break-word' } : {}),
  },
  dateText: {
    color: DARK_GRAY,
    fontSize: 12,
    paddingLeft: 8,
    textAlign: 'right',
    marginLeft: 'auto',
  },
});
