import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation, useQuery } from '@tanstack/react-query';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setCurrentContribution, setLoading } from '../../../../store/action';
import { t } from '../../../../services/translations';
import Form from '../../../../components/atomic/formik/Form';
import SurveyFormFields, { schema } from './SurveyFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { getSurvey, updateSurvey } from '../../../../services/api/survey.contributions.api';
import { UpdateSurvey } from '../../../../entities/UpdateSurvey';
import { View } from 'react-native';
import { getPictureURI } from '../../../../services/api/helper.api';
import { surveyImage } from '../../../../services/assets';

export type UpdateSurveyScreenProps = {
  id: number;
};

export default function UpdateSurveyScreen({ id }: UpdateSurveyScreenProps) {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingList,
    isFetched,
    isError,
  } = useQuery(['getSurvey', id], () => getSurvey(user, id), {
    cacheTime: 0,
  });

  useEffect(() => {
    if (data) {
      dispatch(setCurrentContribution(data));
    }
  }, [data, dispatch]);

  const mutation = useMutation({
    mutationFn: async (values: UpdateSurvey) => {
      await updateSurvey(user, id, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  const isLoading = isLoadingList || mutation.isLoading;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError || mutation.isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError || mutation.isError]);

  const initialValues = useMemo(
    () => ({
      title: data?.title,
      description: data?.description,
      isAnonym: data?.isAnonymous,
      endDate: data?.endDate ? new Date(data?.endDate) : new Date(),
      questions: data?.questions.map((question) => ({
        id: question.id,
        text: question.text,
        answers: question.answers.map((e) => e.text),
        type: question.type,
      })),
      commentsActivated: data?.commentsActivated,
      notifActivated: data?.notifActivated,
      emailActivated: data?.emailActivated,
      spreadingType: data?.spreadingType,
      userIds: data?.userTargets.map((e) => e.userId),
      entrepriseServiceIds: data?.entrepriseServices.map((e) => e.entrepriseServiceId),
      entrepriseSiteGeoIds: data?.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
      entrepriseGroupIds: data?.entrepriseGroups.map((e) => e.entrepriseGroupId),
    }),
    [data]
  );

  return isFetched && data ? (
    <Form
      initialValues={initialValues}
      onSubmit={mutation.mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <SurveyFormFields isEditing defaultPhoto={data.imageId ? getPictureURI(data.imageId) : surveyImage} />
    </Form>
  ) : (
    <View />
  );
}
