import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useLoggedUser from '../../hooks/useLoggedUser';
import TopAuthorChart from './TopAuthorChart';
import { StyleSheet, View } from 'react-native';
import { FormFieldPicker } from '../atomic/FormField';
import { isMobile } from '../../services/utils';
import { UserSaved } from '../../services/storage';
import { GetTopAuthors } from '../../entities/GetTopAuthors';
import AppText, { Mode } from '../atomic/AppText';
import { PRIMARY_COLOR } from '../../styles/appColor';

export type TopAuthorStatisticsProps = {
  queryKey: string;
  messageBuilder?: (data: GetTopAuthors) => string;
  fetch: (user: UserSaved, args: { limit: number }) => Promise<GetTopAuthors | undefined>;
};

export default function TopAuthorStatistics({ queryKey, messageBuilder, fetch }: TopAuthorStatisticsProps) {
  const user = useLoggedUser();
  const [limit, setLimit] = useState(10);

  const { data, isLoading, refetch } = useQuery([user.token, queryKey], () => fetch(user, { limit }));

  useEffect(() => {
    refetch();
  }, [limit, refetch]);

  return (
    <View>
      <View style={styles.filters}>
        <FormFieldPicker
          defaultChoiceOnMobile
          defaultValue={limit}
          onChangeText={(value: number | string) => {
            setLimit(!Number.isNaN(parseInt(value.toString(), 10)) ? parseInt(value.toString(), 10) : 10);
          }}
          data={[
            { label: 'Top 10', value: 10 },
            { label: 'Top 25', value: 25 },
            { label: 'Top 50', value: 50 },
          ]}
          title={''}
        />
        {messageBuilder && data && (
          <AppText mode={Mode.NORMAL} style={styles.message}>
            {messageBuilder(data)}
          </AppText>
        )}
      </View>
      <TopAuthorChart data={data?.authors} isLoading={isLoading} />
    </View>
  );
}

const styles = StyleSheet.create({
  filters: {
    paddingHorizontal: isMobile() ? 10 : 30,
    paddingTop: 20,
    flexDirection: 'row',
    gap: 20,
  },
  message: {
    color: PRIMARY_COLOR,
    alignContent: 'flex-end',
    marginBottom: 20,
  },
});
