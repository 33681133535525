import axios, { AxiosError } from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { MessagingMessage } from '../../entities/MessagingMessage';
import { MessagingThread } from '../../entities/MessagingThread';
import { MessagingThreadMember } from '../../entities/MessagingThreadMember';
import { Paginated } from '../../entities/Paginated';
import { UserSaved } from '../storage';
import { objectToFormData } from '../formUtil';

export async function getMessagingThreads(
  user: UserSaved,
  cursor: string | null,
  search?: string | null
): Promise<Paginated<MessagingThread, string>> {
  return axios
    .get(`${apiUrlBase}Messagerie`, {
      headers: headers(user.token),
      params: { cursor, search },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getMessagingThreadMessages(
  user: UserSaved,
  threadId: number,
  cursor: string | null
): Promise<Paginated<MessagingMessage, string>> {
  return axios
    .get(`${apiUrlBase}Messagerie/${threadId}`, {
      headers: headers(user.token),
      params: { cursor },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getThreadIdByContactId(user: UserSaved, contactId: number): Promise<number> {
  return axios
    .get(`${apiUrlBase}Messagerie/contact/${contactId}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getThreadMembers(user: UserSaved, threadId: number): Promise<MessagingThreadMember[]> {
  return axios
    .get(`${apiUrlBase}Messagerie/${threadId}/members`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getUnseenMessageCount(user: UserSaved): Promise<number> {
  return axios
    .get(`${apiUrlBase}Messagerie/unseen_count`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function postNewThread(
  user: UserSaved,
  contactId: number,
  body: { message: string | null; file: Blob | null }
): Promise<number> {
  return axios
    .post(`${apiUrlBase}Messagerie/thread`, objectToFormData({ contactId, ...body }), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function postThreadMessage(
  user: UserSaved,
  threadId: number,
  body: { message: string | null; file: Blob | null }
): Promise<MessagingMessage> {
  return axios
    .post(`${apiUrlBase}Messagerie/${threadId}`, objectToFormData(body), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    })
    .catch((error: AxiosError) => {
      return null;
    });
}

export async function markThreadAsSeen(user: UserSaved, threadId: number): Promise<number> {
  return axios
    .put(
      `${apiUrlBase}Messagerie/${threadId}/seen`,
      {},
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return 0;
    });
}

export async function deleteMessagingThread(user: UserSaved, threadId: number): Promise<void> {
  return axios
    .delete(`${apiUrlBase}Messagerie/${threadId}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
