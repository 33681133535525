import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { apiUrlBase } from '../../configuration';

declare var window: any;

type RssFeedItem = {
  title: string;
  description: string;
  link: string;
  pubDate: string;
};

type RssFeed = {
  title: string;
  description: string;
  link: string;
  items: RssFeedItem[];
};

async function fetchRssFeed(feedUrl: string): Promise<RssFeed> {
  const response = await axios.get(feedUrl, {});
  const data = response.data;
  const parser = new window.DOMParser();
  const doc = parser.parseFromString(data, 'application/xml');
  const items = Array.from(doc.querySelectorAll('item')).map((item: any) => {
    const title = item.querySelector('title')?.textContent || '';
    const description = item.querySelector('description')?.textContent || '';
    const link = item.querySelector('link')?.textContent || '';
    const pubDate = item.querySelector('pubDate')?.textContent || '';
    return { title, description, link, pubDate };
  });
  const title = doc.querySelector('title')?.textContent || '';
  const description = doc.querySelector('description')?.textContent || '';
  const link = doc.querySelector('link')?.textContent || '';

  return { title, description, link, items };
}

// We use the server to get the RSS feed to avoid CORS issues
function generateRssFeedUrl(feedUrl: string) {
  return `${apiUrlBase}Rss?rssUrl=${encodeURIComponent(feedUrl)}`;
}

export default function useRssFeed(feedUrl: string) {
  const [rssFeed, setRssFeed] = useState<RssFeed | null>(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [error, setError] = useState<Error | null>(null);
  const internFeedUrl = generateRssFeedUrl(feedUrl);

  useEffect(() => {
    fetchRssFeed(internFeedUrl)
      .then((feed) => setRssFeed(feed))
      .catch((e) => setError(e));
  }, [internFeedUrl]);

  // Refresh every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      fetchRssFeed(internFeedUrl)
        .then((feed) => setRssFeed(feed))
        .catch((e) => setError(e));
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [internFeedUrl]);

  // Go to the next item
  const next = useCallback(() => {
    if (rssFeed) {
      setCurrentItemIndex((currentItemIndex) => {
        if (currentItemIndex < rssFeed.items.length - 1) {
          return currentItemIndex + 1;
        } else {
          return 0;
        }
      });
    }
  }, [rssFeed]);

  const currentItem = rssFeed?.items[currentItemIndex];

  return { currentItem, error, next };
}
