import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import DocumentFormFields, { schema } from './DocumentFormFields';
import { getDocumentDetails, updateDocument } from '../../../../services/api/document.contributions.api';
import { DocumentCreate } from '../../../../entities/DocumentCreate';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { View } from 'react-native';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type UpdateDocumentScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function UpdateDocumentScreen({ route }: UpdateDocumentScreenProps) {
  const user = useLoggedUser();
  const id = parseInt(route.params.id);
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingList,
    isFetched,
    isError,
  } = useQuery(['documentDetails', id], () => getDocumentDetails(user, id), { cacheTime: 0 });

  const mutation = useMutation({
    mutationFn: async (values: Omit<DocumentCreate, 'folder'>) => {
      await updateDocument(user, id, { ...values });
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  const isLoading = isLoadingList || mutation.isLoading;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  const initialValues = useMemo(
    () => ({
      title: data?.title,
      description: data?.description,
      document: data?.fichierIdDocumentNavigation,
      folderId: data?.folderId,
      commentsActivated: data?.contribution?.commentsActivated,
      notifActivated: data?.contribution?.notifActivated,
      emailActivated: data?.contribution?.emailActivated,
      spreadingType: data?.contribution?.spreadingType,
      userIds: data?.contribution?.userTargets.map((e) => e.userId),
      entrepriseServiceIds: data?.contribution?.entrepriseServices.map((e) => e.entrepriseServiceId),
      entrepriseSiteGeoIds: data?.contribution?.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
      entrepriseGroupIds: data?.contribution?.entrepriseGroups.map((e) => e.entrepriseGroupId),
    }),
    [data]
  );

  return isFetched ? (
    <Form
      initialValues={initialValues}
      onSubmit={mutation.mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <DocumentFormFields />
    </Form>
  ) : (
    <View />
  );
}
