import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import FolderFormFields, { schema } from './FolderFormFields';
import { createFolder } from '../../../../services/api/document.contributions.api';
import { FolderCreate } from '../../../../entities/FolderCreate';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type CreateFolderScreenProps = {
  route: {
    params: {
      folderId?: string;
    };
  };
};

export default function CreateFolderScreen({ route }: CreateFolderScreenProps) {
  const user = useLoggedUser();
  const initialFolderId = (route.params.folderId && parseInt(route.params.folderId)) || null;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: Omit<FolderCreate, 'folder'>) => {
      await createFolder(user, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={{
        parentId: initialFolderId,
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <FolderFormFields />
    </Form>
  );
}
