import axios from 'axios';
import { headers } from './base';
import { UserSubAdmin } from '../../entities/UserSubAdmin';
import { getUserProfile } from './user.api';
import { apiUrlBase } from '../../../configuration';
import { User } from '../../entities/User';
import { setUser } from '../../store/user';
import { ActionType } from '../../store/action';
import { Dispatch } from 'redux';
import { forgetToken } from '../storage';
import { openURL } from '../utils';
import { AuthenticationMode } from '../../entities/AuthenticationMode';
import { Entreprise } from '../../entities/Entreprise';

const authUrl = apiUrlBase + 'Auth/';

export const logout = async (token: string, dispatch: Dispatch<ActionType>) => {
  await setUser(null, dispatch);
  await forgetToken();
};

export enum ErrorReason {
  INVALID_CREDENTIALS = 'invalid_credentials',
  UNKNOWN_ERROR = 'unknown_error',
  MAIL_NOT_VALIDATED = 'mail_not_validated',
  INVALID_COMPANY_CODE = 'invalide_company_code',
  ACCOUNT_ALREADY_EXISTS = 'account_already_exists',
  EMAIL_ALREADY_REGISTERED = 'email_already_registered',
}

export interface UserData extends User {
  token: string;
  userSubAdmins?: UserSubAdmin[];
}

export async function getAuthenticationMode(email: string) {
  return axios
    .get(`${apiUrlBase}Auth/authentication-mode`, {
      headers: headers(),
      params: {
        email: email,
      },
    })
    .then((response) => {
      return response.data as AuthenticationMode;
    });
}

export async function login(username: string, password: string): Promise<UserData> {
  try {
    const response = await axios.post(
      `${apiUrlBase}Auth/authenticate`,
      {
        username: username,
        password: password,
      },
      {
        headers: headers(),
      }
    );

    if (response.data.title === 'Mail non confirmé') {
      throw Error(ErrorReason.MAIL_NOT_VALIDATED);
    }

    let userData = {
      id: response.data.userId,
      token: response.data.token,
    };

    const userProfile = await getUserProfile(userData.id, userData.token);
    const userSaved = {
      ...userProfile,
      ...userData,
    };

    return userSaved;
  } catch (err: any) {
    if (err?.response?.status === 401) {
      throw Error(ErrorReason.INVALID_CREDENTIALS);
    } else if (err?.message === ErrorReason.MAIL_NOT_VALIDATED) {
      throw err;
    } else {
      throw Error(ErrorReason.UNKNOWN_ERROR);
    }
  }
}

export function redirectToAuthenticateWithSso(email: string) {
  openURL(`${authUrl}authenticate/sso?email=${email}`, false);
}

export async function register(
  emailAddress: string,
  password: string,
  hasCGUBeenApproved: boolean,
  hasRGDPConsent: boolean
) {
  return axios
    .post(
      `${apiUrlBase}Auth/register`,
      {
        Email: emailAddress,
        MotDePasse: password,
        CGU: hasCGUBeenApproved,
        RGPD: hasRGDPConsent,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      return response.data.id;
    })
    .catch((error) => {
      if (error.response.status === 400 && error.response.data.code === 'account_already_exists') {
        throw Error(ErrorReason.ACCOUNT_ALREADY_EXISTS);
      }

      throw new Error(ErrorReason.UNKNOWN_ERROR);
    });
}

export async function registerWithCompanyCode(
  emailAddress: string,
  password: string,
  hasCGUBeenApproved: boolean,
  hasRGDPConsent: boolean,
  code: string
) {
  return axios
    .post(
      `${authUrl}register-code`,
      {
        Email: emailAddress,
        MotDePasse: password,
        CGU: hasCGUBeenApproved ? 'true' : 'false',
        RGPD: hasRGDPConsent ? 'true' : 'false',
        Code: code,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(ErrorReason.UNKNOWN_ERROR);
      }

      return response.data;
    })
    .then((json) => {
      return Promise.resolve(json.id);
    });
}

export async function generateValidationCode(mail: string) {
  return axios.get(apiUrlBase + 'auth/generation/' + mail, {
    headers: headers(),
  });
}

export async function validate(emailAddress: string, validationCode: string) {
  return axios
    .post(
      `${apiUrlBase}Auth/confirmation`,
      {
        Email: emailAddress,
        Code: validationCode,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      return response.data;
    })
    .then((text) => {
      if (text !== 'Erreur') {
        return Promise.resolve();
      } else {
        throw Error(ErrorReason.UNKNOWN_ERROR);
      }
    });
}

export async function checkCompanyCode(code: string) {
  return axios
    .get(`${authUrl}check-code/${code}`, {
      headers: headers(),
    })
    .then((response) => {
      if (response.status !== 200) {
        throw Error(ErrorReason.INVALID_COMPANY_CODE);
      }

      return response.data as Entreprise;
    })
    .catch(() => {
      throw Error(ErrorReason.INVALID_COMPANY_CODE);
    });
}

export async function sendRequestForgottenPassword(emailAddress: string) {
  return axios
    .post(
      `${apiUrlBase}Auth/confirmationperdumail`,
      {
        Email: emailAddress,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      return response.data;
    })
    .then((json) => {
      if (json.title === 'Mail Confirmé !') {
        return Promise.resolve();
      } else {
        throw Error(ErrorReason.UNKNOWN_ERROR);
      }
    });
}

export async function resetPasswordSendCode(email: string) {
  return axios
    .post(
      apiUrlBase + 'Auth/reset-password/send-code',
      {
        email,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function resetPasswordCheckCode(email: string, code: string) {
  return axios
    .post(
      `${apiUrlBase}Auth/reset-password/check-code`,
      {
        email,
        code,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      return response.data;
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function resetPassword(email: string, code: string, newPassword: string) {
  return axios
    .post(
      `${apiUrlBase}Auth/reset-password`,
      {
        email,
        code,
        newPassword,
      },
      {
        headers: headers(),
      }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}
