import React from 'react';
import { useField } from 'formik';
import { AppCheckbox, AppCheckboxProps } from '../AppCheckbox';

export type CheckboxFieldProps = Omit<Omit<AppCheckboxProps, 'value'>, 'onValueChange'> & {
  name: string;
};

export default function CheckboxField({ name, ...rest }: CheckboxFieldProps) {
  const [field, , helper] = useField(name);

  return <AppCheckbox {...rest} onValueChange={helper.setValue} value={!!field.value} />;
}
