import React from 'react';
import {ContributionListScreen} from '../screens/loggedIn/contribution/ContributionListScreen';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {ContributionType} from '../types';
import {t} from '../services/translations';
import {GetContributionsMode} from '../services/api/getContributionsMode';
import {isMobile} from '../services/utils';
import {ScreenNames} from '../ScreenNames';

export function SuggestionsAdministrationRoute(props: {route}) {
  const Tab = createMaterialTopTabNavigator();
  return (
    <Tab.Navigator screenOptions={{swipeEnabled: isMobile()}}>
      <Tab.Screen
        name={ScreenNames.SuggestionsToApproveList}
        component={ContributionListScreen}
        options={{title: t('the_suggestions')}}
        initialParams={{
          getContributionsMode: GetContributionsMode.toApprove,
          contributionType: ContributionType.SUGGESTION,
          isAdminView: props.route.params.isAdminView,
        }}
      />
      <Tab.Screen
        name={ScreenNames.ReviewedSuggestionsList}
        component={ContributionListScreen}
        options={{title: t('approved_suggestions')}}
        initialParams={{
          getContributionsMode: GetContributionsMode.reviewed,
          contributionType: ContributionType.SUGGESTION,
          isAdminView: props.route.params.isAdminView,
        }}
      />
    </Tab.Navigator>
  );
}
