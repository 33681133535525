import { useEffect, useRef } from 'react';

export type UseIntervalConfig = {
  delay?: number;
  paused?: boolean;
  dependencies?: any[];
};

export default function useInterval(
  callback: () => void,
  { delay = 1000, paused = false, dependencies = [] }: UseIntervalConfig = {}
) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (paused) return;

    function tick() {
      if (savedCallback.current) savedCallback.current();
    }

    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, paused, ...(dependencies || [])]);
}
