import { FONT_FAMILY_DEFAULT, globalStyle } from '../../styles/globalStyle';
import { Dimensions } from 'react-native';
import React, { useMemo } from 'react';
import { Html } from './Html';
import { MixedStyleDeclaration } from 'react-native-render-html';

export type HtmlTextProps = {
  text: string;
  style?: MixedStyleDeclaration;
};

export function HtmlText({ text, style }: HtmlTextProps) {
  let html = useMemo(() => {
    let filteredText = text;
    filteredText = filteredText.replace(/<br>/gm, '');
    filteredText = filteredText.replace(/<em>/gm, '<em> ');
    filteredText = filteredText.replace(/<strong>/gm, '<strong> ');
    filteredText = filteredText.replace(/\n/gm, '<br/>');
    filteredText = filteredText.replace(/<p>/gm, '');
    filteredText = filteredText.replace(/<\/p>/gm, '<br/>');

    // Add target="_blank" to links
    filteredText = filteredText.replace(/<a href="([^"]*)">([^<]*)<\/a>/gm, '<a target="_blank" href="$1">$2</a>');

    return filteredText;
  }, [text]);

  return (
    <Html
      source={{ html }}
      tagsStyles={{ strong: { fontFamily: FONT_FAMILY_DEFAULT.bold } }}
      systemFonts={[FONT_FAMILY_DEFAULT.regular, FONT_FAMILY_DEFAULT.bold, FONT_FAMILY_DEFAULT.light]}
      baseStyle={Object.assign({}, globalStyle.appText, style)}
      contentWidth={Dimensions.get('window').width}
    />
  );
}
