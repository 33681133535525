import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import React, { useMemo } from 'react';
import { t } from '../services/translations';
import Logo from '../components/atomic/Logo';
import { useDispatch } from 'react-redux';
import AppText from '../components/atomic/AppText';
import { combineNames, drawerIcon, isMobile, openURL } from '../services/utils';
import { ProfilePicture } from '../components/atomic/ProfilePicture';
import { Dimensions, Image, TouchableOpacity, View } from 'react-native';
import { getPictureURI } from '../services/api/helper.api';
import { LIGHT_GRAY } from '../styles/appColor';
import { logout } from '../services/api/login';
import useTodayDateTime from '..//hooks/useTodayDateTime';
import { ScreenNames } from '../ScreenNames';
import useLoggedUser from '../hooks/useLoggedUser';

function Separator() {
  return <View style={{ height: 1, backgroundColor: LIGHT_GRAY }} />;
}

export function CustomDrawerContent(props: DrawerContentComponentProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const todayDateTime = useTodayDateTime();

  let windowHeight = Dimensions.get('window').height;

  useMemo(() => {
    windowHeight = Dimensions.get('window').height;
  }, [Dimensions.get('window').height]);

  const { state, ...rest } = props;

  return (
    <DrawerContentScrollView {...props} style={{ flex: 1, maxHeight: windowHeight }}>
      <TouchableOpacity
        onPress={() => {
          props.navigation.navigate(ScreenNames.Profile);
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            width: '90%',
            alignSelf: 'center',
            padding: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ProfilePicture pictureId={user.fichierIdProfil} style={{ marginRight: 5 }} size="small-medium" />
          <AppText style={{ fontSize: 20, flex: 1 }} numberOfLines={1}>
            {combineNames(user)}
          </AppText>
        </View>
        <Image
          source={getPictureURI(user.entreprise.fichierIdLogo)}
          resizeMode="contain"
          style={{ width: '100%', height: 64, marginBottom: 10 }}
        />
      </TouchableOpacity>
      {!isMobile() && (
        <View style={{ marginVertical: 10 }}>
          <AppText style={{ textAlign: 'center', marginBottom: 5, textTransform: 'capitalize' }}>
            {todayDateTime.date}
          </AppText>
          <AppText style={{ textAlign: 'center' }}>{todayDateTime.time}</AppText>
          <AppText style={{ textAlign: 'center' }}>{todayDateTime.ephemerisOfTheDay}</AppText>
        </View>
      )}
      <Separator />
      <DrawerItemList state={state} {...rest} />

      <Separator />

      <DrawerItem
        label={t('my_profile')}
        icon={drawerIcon('user')}
        onPress={() => {
          props.navigation.navigate(ScreenNames.Profile);
        }}
      />

      <DrawerItem
        label={t('user_guide')}
        icon={drawerIcon('book')}
        onPress={() => {
          openURL(
            'https://support-konecteam.notion.site/Guide-contributeur-KonecTeam-2d68f95557e34a26ae7bf0cc84ca4255?pvs=4'
          );
        }}
      />

      <DrawerItem
        label={t('logout')}
        icon={drawerIcon('arrow-right-from-bracket')}
        onPress={() => {
          logout(user.token, dispatch);
        }}
      />
      <Logo style={{ width: 100, alignSelf: 'center' }} />
    </DrawerContentScrollView>
  );
}
