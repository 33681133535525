import React from 'react';
import { t } from '../../services/translations';
import { AppButton } from '../../components/atomic/AppButton';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import AppText, { Mode } from '../../components/atomic/AppText';
import { Space2 } from '../../styles/spaces';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { ScreenNames } from '../../ScreenNames';
import { NavigationProp, useNavigation } from '@react-navigation/native';

const registrationBackground = require('../../../assets/registration-background.png');

export function ResetPasswordSendCodeSuccess() {
  const navigation = useNavigation<NavigationProp<any>>();

  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_2_title')}</AppText>
        <AppText>{t('forgotten_password_step_2_text')}</AppText>
      </FormBody>
      <FormButtons>
        <Space2 />
        <AppButton title={t('back_button')} onPress={() => navigation.navigate(ScreenNames.Login)} enabled={true} />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
