import React from 'react';
import { useField } from 'formik';
import PublicationModePicker from '../PublicationModePicker';

export type PublicationModeFieldProps = {
  modeName: string;
  visibleStartDateName: string;
  visibleEndDateName: string;
};

export default function PublicationModeField({
  modeName,
  visibleStartDateName,
  visibleEndDateName,
}: PublicationModeFieldProps) {
  const [modeField, , modeHelper] = useField(modeName);
  const [visibleStartDateField, , visibleStartDateHelper] = useField(visibleStartDateName);
  const [visibleEndDateField, , visibleEndDateNameHelper] = useField(visibleEndDateName);

  return (
    <PublicationModePicker
      mode={modeField.value}
      visibleStartDate={visibleStartDateField.value || undefined}
      visibleEndDate={visibleEndDateField.value || undefined}
      onChangeMode={modeHelper.setValue}
      onChangeStartDate={visibleStartDateHelper.setValue}
      onChangeEndDate={visibleEndDateNameHelper.setValue}
    />
  );
}
