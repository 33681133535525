import React from 'react';
import { useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import { t } from '../../services/translations';
import LocationField from '../atomic/formik/LocationField';
import TextField from '../atomic/formik/TextField';
import SubmitButton from '../atomic/formik/SubmitButton';
import { listScreenPlaylist } from '../../services/api/tv.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import { SelectField } from '../atomic/formik/SelectField';

export const schema = Yup.object({
  title: Yup.string().min(1, t('field_required')).required(t('field_required')),
  playlistId: Yup.number().nullable(),
  latitude: Yup.number().nullable(),
  longitude: Yup.number().nullable(),
  rssFeed: Yup.string().nullable(),
  facebookPageUrl: Yup.string().nullable(),
  linkedInPageUrl: Yup.string().nullable(),
});

export default function ScreenSettingsFormFields() {
  const user = useLoggedUser();
  const { data: playlists } = useQuery(['listScreenPlaylist'], () => listScreenPlaylist(user), { cacheTime: 0 });

  return (
    <>
      <TextField name="title" title={t('title')} />
      <TextField name="backgroundColor" mandatory required title={t('custom_color')} />
      <SelectField
        name="playlistId"
        title={t('playlists')}
        required
        style={{ flex: 1 }}
        data={
          playlists?.map((e) => ({
            label: e.name,
            value: e.id,
          })) || []
        }
      />
      <LocationField latitudeName="latitude" longitudeName="longitude" label={t('location')} />
      <TextField name="rssFeed" title={t('news_url')} />
      {/*<TextField name="facebookPageUrl" title={t('facebook_page_url')} />*/}
      <TextField name="linkedInPageUrl" title={t('linkedin_page_url')} />
      <SubmitButton text={t('create')} />
    </>
  );
}
