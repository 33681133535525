import React from 'react';
import { useField } from 'formik';
import { StyleSheet, View } from 'react-native';
import { AppButton } from '../AppButton';

export type RadioButtonGroupFieldProps<T extends string | number | bigint> = {
  name: string;
  options: { label: string; value: T }[];
  disabled?: boolean;
  multiple?: boolean;
};

export default function RadioButtonGroupField<T extends string | number | bigint>({
  name,
  options,
  disabled,
  multiple,
}: RadioButtonGroupFieldProps<T>) {
  const [field, , helper] = useField(name);

  return (
    <View style={styles.container}>
      {options.map((option) => {
        const isChecked = multiple ? (field.value || []).includes(option.value) : field.value === option.value;

        return (
          <AppButton
            key={option.value}
            icon={isChecked ? 'check' : undefined}
            title={option.label}
            style={styles.button}
            enabled={!disabled}
            onPress={() => {
              if (multiple) {
                if ((field.value || []).includes(option.value)) {
                  helper.setValue((field.value || []).filter((e: T) => e !== option.value));
                } else {
                  helper.setValue([...(field.value || []), option.value]);
                }
              } else {
                helper.setValue(option.value);
              }
            }}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    marginBottom: 10,
  },
});
