import { useNavigation } from '@react-navigation/core';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { OrganizationalChartForm } from '../../../../components/molecules/OrganizationalChartForm';
import {
  getOrganizationalChartDetails,
  updateOrganizationalChart,
} from '../../../../services/api/organizational.chart.api';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type UpdateOrganizationalChartScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function UpdateOrganizationalChartScreen({ route }: UpdateOrganizationalChartScreenProps) {
  const id = parseInt(route.params.id);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<{
    label?: string;
    fileName?: string;
  }>({});

  const refresh = useCallback(async () => {
    setIsLoading(true);

    try {
      const item = await getOrganizationalChartDetails(user, id);
      setInitialValues({ label: item.label, fileName: item.file.name });
    } catch (e) {
      alertInfo(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Submit form
  const handleSubmit = useCallback(
    async ({ label, file }: { label: string; file: any }) => {
      setIsLoading(true);

      try {
        await updateOrganizationalChart(user, id, label, file);
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      } catch (e) {
        alertInfo(t('unknown_error'));
      } finally {
        setIsLoading(false);
      }
    },
    [id, user]
  );

  return (
    <AppScreen>
      <OrganizationalChartForm initialValues={initialValues} onSubmit={handleSubmit} />
    </AppScreen>
  );
}
