import { AxiosRequestHeaders } from 'axios';
import { apiVersion } from '../../../configuration';
import { language } from '../../services/translations';

export type ContentType = 'application/json' | 'multipart/form-data';

export function headers(token?: string, contentType?: ContentType): AxiosRequestHeaders {
  if (token) {
    return {
      Accept: 'application/json',
      'Content-Type': contentType || 'application/json',
      Authorization: `Bearer ${token}`,
      'Language-Code': language(),
      'X-Version': apiVersion,
    };
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': contentType || 'application/json',
      'Language-Code': language(),
      'X-Version': apiVersion,
    };
  }
}
