export function isValidColor(col: string | null | undefined) {
  // Check if is a valid color
  if (!col || !col.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
    return false;
  }

  return true;
}

export function lightenDarkenColor(col: string, amt: number) {
  // Check if is a valid color
  if (!col.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
    return null;
  }

  // Remove the sharp if needed
  col = col.replace('#', '');

  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  var b = ((num >> 8) & 0x00ff) + amt;
  var g = (num & 0x0000ff) + amt;
  var newColor = g | (b << 8) | (r << 16);
  return `#${newColor.toString(16)}`;
}

export function addAlpha(col: string | null, alpha: number) {
  // Check if is a valid color
  if (!col || !col.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)) {
    return `#000000${Math.round(alpha * 255).toString(16)}`;
  }

  // Remove the sharp if needed
  col = col.replace('#', '');

  var num = parseInt(col, 16);
  var r = num >> 16;
  var b = (num >> 8) & 0x00ff;
  var g = num & 0x0000ff;
  var newColor = g | (b << 8) | (r << 16);
  return `#${newColor.toString(16)}${Math.round(alpha * 255).toString(16)}`;
}
