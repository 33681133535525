import { useNavigation } from '@react-navigation/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import Form from '../../../../components/atomic/formik/Form';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { CompanyGroupFormFields, schema } from '../../../../components/molecules/CompanyGroupFormFields';
import { User } from '../../../../entities/User';
import { getCompanyGroupDetails, updateCompanyGroup } from '../../../../services/api/company.group.api';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type UpdateCompanyGroupScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function UpdateCompanyGroupScreen({ route }: UpdateCompanyGroupScreenProps) {
  const id = parseInt(route.params.id);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const {
    data,
    isLoading: isLoadingDetails,
    isFetched,
    isError,
  } = useQuery(['companyGroupDetails', id], () => getCompanyGroupDetails(user, id), { cacheTime: 0 });

  const mutation = useMutation({
    mutationFn: async (values: { label: string; users: User[] }) => {
      try {
        await updateCompanyGroup(user, id, { label: values.label, userIds: values.users.map((e) => e.id) });
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      } catch (e) {
        alertInfo(t('unknown_error'));
      }
    },
  });

  const isLoading = isLoadingDetails || mutation.isLoading;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const initialValues = useMemo(
    () => ({
      label: data?.label || '',
      users: data?.users.map((e) => e.user) || [],
    }),
    [data]
  );

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return isFetched ? (
    <AppScreen>
      <Form
        initialValues={initialValues}
        onSubmit={mutation.mutateAsync as any}
        validationSchema={schema}
        validateOnMount
      >
        <CompanyGroupFormFields isEditing />
      </Form>
    </AppScreen>
  ) : (
    <View />
  );
}
