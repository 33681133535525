import { isValidYoutubeUrl } from '../services/url.utils';

export interface BaseMediaProps {
  url: string;
}

export function getYoutubeIdFromURL(url: string): string | undefined {
  // Replace https://www.youtube.com/watch?v=VIDEO_ID with VIDEO_ID youtu.be/VIDEO_ID
  if (url.includes('youtube.com') && url.includes('watch') && url.includes('v=')) {
    const arr = url.split('?');
    const query = arr[1];
    const pairs = query.split('&').map((pair) => pair.split('='));
    const vParam = pairs.find((pair) => pair[0] === 'v');
    if (vParam) {
      return vParam[1];
    }
  }

  // Check if the url is a youtube url
  if (!isValidYoutubeUrl(url)) {
    return undefined;
  }

  if (url.includes('?')) {
    const arr = url.split('?');
    arr.pop();

    url = arr[0];
  }
  const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/);

  const youtubeId = undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];

  return youtubeId;
}
