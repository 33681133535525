import React, { useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { postContributionComment, postNewsComment } from '../../services/api/comment.api';
import { t } from '../../services/translations';
import { PRIMARY_COLOR_DARKEN } from '../../styles/appColor';
import { IconButton } from '../atomic/IconButton';
import { TextInput } from '../atomic/TextInput';
import useLoggedUser from '../../hooks/useLoggedUser';

export type NewCommentFormProps = {
  newsId?: number;
  contributionId?: number;
  onSent?: () => void;
};

export function NewCommentForm({ newsId, contributionId, onSent }: NewCommentFormProps) {
  const user = useLoggedUser();
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const send = useCallback(async () => {
    setIsLoading(true);

    if (newsId) {
      await postNewsComment(user, newsId, text);
    } else if (contributionId) {
      await postContributionComment(user, contributionId, text);
    }

    setText('');
    setIsLoading(false);
    onSent && onSent();
  }, [text, user, newsId, contributionId, setText, onSent]);

  return (
    <View style={styles.container}>
      <TextInput
        multiline
        numberOfLines={2}
        value={text}
        onChangeText={(text) => setText(text)}
        textColor={PRIMARY_COLOR_DARKEN}
        style={styles.input}
        placeholder={t('add_comment')}
      />
      <IconButton icon="send" onPress={send} disabled={!text || isLoading} style={styles.button} reverse />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 10,
  },
  input: {
    flex: 1,
  },
  button: {
    marginLeft: 10,
    marginVertical: 2,
  },
});
