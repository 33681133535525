import { View, ViewStyle } from 'react-native';
import { ProfilePicture } from './ProfilePicture';
import AppText from './AppText';
import { combineNames } from '../../services/utils';
import React from 'react';
import { globalStyle } from '../../styles/globalStyle';
import { User } from '../../entities/User';

export function UserSubRow(props: {
  user: { fichierIdProfil: number; nom: string; prenom: string };
  withPicture?: boolean;
  style?: ViewStyle;
  textColor?: string;
  textSize?: number;
}) {
  if (props.user == undefined) return <></>;
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', ...props.style }}>
      {props.withPicture ? (
        <ProfilePicture pictureId={props.user.fichierIdProfil} style={{ marginRight: 10 }} size="small" />
      ) : null}
      <View style={{ flex: 1 }}>
        <AppText
          numberOfLines={1}
          style={{
            color: props.textColor === undefined ? 'black' : props.textColor,
            fontSize: props.textSize === undefined ? globalStyle.appText.fontSize : props.textSize,
          }}
        >
          {combineNames(props.user)}
        </AppText>
      </View>
    </View>
  );
}
