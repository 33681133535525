import { Contributions } from '../services/storage';
import { ContributionType } from '../types';
import { MessagingActionType } from './messaging';
import { UserActionType } from './user';
import { VersionActionType } from './version';

export type ActionType =
  | {
      type: 'SET_CONTRIBUTIONS';
      payload: Contributions;
    }
  | {
      type: 'SET_DETAIL_CONTRIBUTION_TYPE';
      payload: ContributionType | undefined;
    }
  | {
      type: 'SET_IS_NOTIF';
      payload: boolean;
    }
  | {
      type: 'SET_FORCE_BACK';
      payload: boolean;
    }
  | {
      type: 'SET_CURRENT_CONTRIBUTION';
      payload: any;
    }
  | {
      type: 'SET_PHONE_MODE';
      payload: boolean;
    }
  | {
      type: 'SET_LOADING';
      payload: boolean;
    }
  | {
      type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_END';
      payload: number;
    }
  | {
      type: 'setContributionsListNeedsRefresh';
      payload: boolean;
    }
  | {
      type: 'setDirectoryListNeedsRefresh';
      payload: boolean;
    }
  | {
      type: 'setValidationMapListNeedsRefresh';
      payload: boolean;
    }
  | {
      type: 'setContributeurListNeedsRefresh';
      payload: boolean;
    }
  | UserActionType
  | MessagingActionType
  | VersionActionType;

export const setContributions = (contributions: Contributions): ActionType => {
  return { type: 'SET_CONTRIBUTIONS', payload: contributions };
};

export const setDetailContributionType = (contributionType: ContributionType | undefined): ActionType => {
  return { type: 'SET_DETAIL_CONTRIBUTION_TYPE', payload: contributionType };
};

export const setIsNotif = (notif: boolean): ActionType => {
  return { type: 'SET_IS_NOTIF', payload: notif };
};

export const setForceBack = (forceBack: boolean): ActionType => {
  return { type: 'SET_FORCE_BACK', payload: forceBack };
};

export const setCurrentContribution = (contribution: any): ActionType => {
  return { type: 'SET_CURRENT_CONTRIBUTION', payload: contribution };
};

export const setPhoneMode = (isPhoneMode: boolean): ActionType => {
  return {
    type: 'SET_PHONE_MODE',
    payload: isPhoneMode,
  };
};

export const setLoading = (isLoading: boolean): ActionType => {
  return {
    type: 'SET_LOADING',
    payload: isLoading,
  };
};

export const setContributionsListNeedsRefresh = (value: boolean): ActionType => {
  return {
    type: 'setContributionsListNeedsRefresh',
    payload: value,
  };
};

export const setValidationMapListNeedsRefresh = (value: boolean): ActionType => {
  return {
    type: 'setValidationMapListNeedsRefresh',
    payload: value,
  };
};

export const setContributeurListNeedsRefresh = (value: boolean): ActionType => {
  return {
    type: 'setContributeurListNeedsRefresh',
    payload: value,
  };
};

export const setDirectoryListNeedsRefresh = (value: boolean): ActionType => {
  return {
    type: 'setDirectoryListNeedsRefresh',
    payload: value,
  };
};
