import React from 'react';
import { useField } from 'formik';
import { FormField } from '../FormField';
import AppText, { Mode } from '../AppText';
import { AppRTEEditor } from '../AppRTEEditor';

export type RichTextFieldProps = {
  name: string;
  title: string;
  required?: boolean;
};

export default function RichTextField({ name, title, required, ...rest }: RichTextFieldProps) {
  const [field, meta, helper] = useField(name);

  return (
    <FormField title={required ? `${title}*` : title}>
      <AppRTEEditor defaultValue={field.value} onChangeText={helper.setValue} />
      {meta.touched && meta.error && <AppText mode={Mode.ERROR}>{meta.error}</AppText>}
    </FormField>
  );
}
