import { ActivityIndicator, StyleSheet, View } from 'react-native';
import React from 'react';
import { PRIMARY_COLOR } from '../../styles/appColor';

export function AppLoader() {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <ActivityIndicator color={PRIMARY_COLOR} size="large" />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#00000066',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  content: {
    backgroundColor: 'white',
    padding: 30,
    flex: 0,
    borderRadius: 10,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
});
