import { useNavigation } from '@react-navigation/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import { FormField } from '../../../../components/atomic/FormField';
import { ProfileRow } from '../../../../components/atomic/ProfileRow';
import UserAutocomplete from '../../../../components/atomic/UserAutocomplete';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { User } from '../../../../entities/User';
import { getUsersByUserSubAdmin, updateValidationMap } from '../../../../services/api/usersubadmin';
import { alertInfo } from '../../../../services/utils';
import { setLoading, setValidationMapListNeedsRefresh } from '../../../../store/action';
import { DARK_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { t } from '../../../../services/translations';
import { FONT_FAMILY_DEFAULT } from '../../../../styles/globalStyle';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

type UpdateValidationMapProps = {
  route: {
    params: {
      type: 'all' | 'services' | 'sitegeo' | 'groups';
      key: string;
      id: string;
    };
  };
};

type ValidationMapFormProps = {
  baseUsers: User[];
  onSubmit: (userIds: number[]) => Promise<void>;
};

export default function UpdateValidationMapScreen({ route }: UpdateValidationMapProps) {
  const id = parseInt(route.params.id);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>();

  const refresh = useCallback(async () => {
    setIsLoading(true);

    try {
      const item = await getUsersByUserSubAdmin(user, id, route.params.type);
      setUsers(item);
    } catch (e) {
      alertInfo(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Submit form
  const handleSubmit = useCallback(
    async (userIds: number[]) => {
      setIsLoading(true);

      try {
        await updateValidationMap(user, id, route.params.type, userIds);
        dispatch(setValidationMapListNeedsRefresh(true));
        navigation.goBack();
      } catch (e) {
        alertInfo(t('unknown_error'));
      } finally {
        setIsLoading(false);
      }
    },
    [id, user, route.params.type]
  );

  return (
    <AppScreen>
      <Text style={styles.title}>{route.params.key}</Text>
      {users && <ValidationMapForm baseUsers={users} onSubmit={handleSubmit} />}
    </AppScreen>
  );
}

function ValidationMapForm({ baseUsers, onSubmit }: ValidationMapFormProps) {
  const [users, setUsers] = useState<User[]>(baseUsers);

  const removeUser = useCallback((user: User) => setUsers(users.filter((e) => e.id !== user.id)), [users]);

  // Submit form
  const handleSubmit = useCallback(() => {
    return onSubmit(users.map((user) => user.id));
  }, [onSubmit, users]);

  // Update form datas
  useEffect(() => {
    if (baseUsers) {
      setUsers(baseUsers);
    }
  }, [JSON.stringify(baseUsers)]);

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" style={{ padding: 10 }}>
      <UserAutocomplete
        title=""
        onSelect={(user) => {
          if (users.map((u) => u.id).includes(user.id)) {
            return;
          }
          setUsers([...users, user]);
        }}
        selectedIds={Array.from(users.map((e) => e.id))}
      />
      <FormField title={t('add_group_member')} children={undefined} />
      <FlatList
        data={users}
        renderItem={(u) => (
          <View style={styles.profileRow}>
            <View style={styles.profileContainer}>
              <ProfileRow user={u.item} allowEdit={false} />
            </View>
            <TouchableOpacity onPress={() => removeUser(u.item)}>
              <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} style={styles.trashIcon} />
            </TouchableOpacity>
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
      />

      <View style={{ marginTop: 30, marginBottom: 100 }}>
        <AppButton title={t('save')} type={Type.PRIMARY} onPress={handleSubmit} />
      </View>
    </KeyboardAwareScrollView>
  );
}

const styles = StyleSheet.create({
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileContainer: {
    flex: 1,
  },
  trashIcon: {
    marginLeft: 10,
    marginRight: 24,
  },
  title: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 18,
    color: DARK_GRAY,
    paddingTop: 20,
    paddingBottom: 20,
  },
});
