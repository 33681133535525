import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BottomSheet, ListItem, SpeedDial } from '@rneui/themed';
import { DraxProvider, DraxView, DraxScrollView } from 'react-native-drax';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';
import { StyleSheet, View } from 'react-native';
import { SearchTextInput } from '../../../../components/atomic/SearchTextInput';
import { ALERT_COLOR, LIGHT_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { removeAccentsAndLower } from '../../../../utils/Utils';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import Separator from '../../../../components/atomic/Separator';
import { setLoading } from '../../../../store/action';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  listFolder,
  listFolderAsAdmin,
  updateFolder,
  updateDocument,
} from '../../../../services/api/document.contributions.api';
import { DocumentSummary } from '../../../../entities/DocumentSummary';
import { FolderSummary } from '../../../../entities/FolderSummary';
import { match } from 'ts-pattern';
import DocumentItemRow from '../../../../components/atomic/DocumentItemRow';
import { useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../../../ScreenNames';
import { isMobile, openURL } from '../../../../services/utils';
import Feather from 'react-native-vector-icons/Feather';
import { AppModalOverlay } from '../../../../components/atomic/AppModalOverlay';
import { AppButton } from '../../../../components/atomic/AppButton';
import { removeContribution } from '../../../../services/api/contribution.api';
import { getDocumentURI } from '../../../../services/api/helper.api';
import BrowseFolderDialog from '../../../../components/atomic/BrowseFolderDialog';
import { ContributionType, ContributionTypesInfos } from '../../../../types';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../../hooks/useContributionListRefresh';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type DocumentsMainScreenProps = {
  route: {
    params: {
      folderId?: string;
      isAdminView?: string | boolean;
    };
  };
};

export type DocumentsMainScreenItem =
  | ({
      type: 'folder';
    } & FolderSummary)
  | ({
      type: 'document';
    } & DocumentSummary);

export function DocumentsMainScreen({ route }: DocumentsMainScreenProps) {
  const navigation = useNavigation<any>();
  const folderId = (route.params.folderId && parseInt(route.params.folderId)) || null;
  const isAdminView = route.params.isAdminView === true || route.params.isAdminView === 'true';
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [openSpeedDialog, setOpenSpeedDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DocumentsMainScreenItem | null>(null);
  const [wishToRemoveItem, setWishToRemoveItem] = useState<DocumentsMainScreenItem | null>(null);
  const [movedItem, setMovedItem] = useState<DocumentsMainScreenItem | null>(null);

  const queryIdentifier = isAdminView ? `folderListAdmins` : `folderList`;
  const queryFunction = useCallback(() => {
    if (isAdminView) {
      return listFolderAsAdmin(user, folderId);
    } else {
      return listFolder(user, folderId);
    }
  }, [folderId, user, isAdminView]);

  const deselectItem = useCallback(() => {
    setSelectedItem(null);
    setWishToRemoveItem(null);
  }, []);

  const {
    data,
    isLoading: isLoadingList,
    refetch: refresh,
  } = useQuery([queryIdentifier, folderId], queryFunction, { cacheTime: 0 });
  const updateMutation = useMutation({
    mutationFn: async ([item, folderId]: [item: DocumentsMainScreenItem, folderId: number | null]) => {
      switch (item.type) {
        case 'folder':
          await updateFolder(user, item.id, { parentId: folderId ?? null });
          break;
        case 'document':
          await updateDocument(user, item.id, { folderId: folderId ?? null });
          break;
      }

      await refresh();
    },
  });
  const removeMutation = useMutation({
    mutationFn: async (item: DocumentsMainScreenItem) => {
      await removeContribution(user, item.contributionId);
      await refresh();
    },
  });

  const isLoading = isLoadingList || updateMutation.isLoading || removeMutation.isLoading;

  // Update title
  useEffect(() => {
    if (data?.title) {
      navigation.setOptions({
        title: data.title,
      });
    }
  }, [data, navigation]);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  // Format items from fetched data
  const items = useMemo(() => {
    const documents =
      data?.documents
        ?.filter((e) => removeAccentsAndLower(e.title).includes(removeAccentsAndLower(search)))
        .map((e) => ({ type: 'document', ...e })) ?? ([] as DocumentsMainScreenItem[]);

    const folders =
      data?.folders
        ?.filter((e) => removeAccentsAndLower(e.title).includes(removeAccentsAndLower(search)))
        .map((e) => ({ type: 'folder', ...e })) ?? ([] as DocumentsMainScreenItem[]);

    return [...folders, ...documents] as DocumentsMainScreenItem[];
  }, [data, search]);

  const onItemPress = useCallback(
    (item: DocumentsMainScreenItem) => {
      if (item.type === 'folder') {
        navigation.push(ScreenNames.KiosqueFolder, {
          folderId: item.id,
          isAdminView,
        });
      } else {
        navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
          screen:
            ContributionTypesInfos[ContributionType.DOCUMENT].detailScreen ?? ScreenNames.ContributionItemDetailScreen,
          params: {
            id: item.id,
            contributionType: ContributionType.DOCUMENT,
            isNotif: false,
          },
        });
      }
    },
    [navigation, isAdminView]
  );

  const createDocument = useCallback(() => {
    setOpenSpeedDialog(false);
    navigation.navigate(ScreenNames.KiosqueCreateDocument, {
      folderId,
      isAdminView,
    });
  }, [navigation, folderId, isAdminView]);

  const createFolder = useCallback(() => {
    setOpenSpeedDialog(false);
    navigation.navigate(ScreenNames.KiosqueCreateFolder, {
      folderId,
      isAdminView,
    });
  }, [navigation, folderId, isAdminView]);

  const editItem = useCallback(
    (item: DocumentsMainScreenItem) => {
      deselectItem();

      switch (item.type) {
        case 'folder':
          return navigation.navigate(ScreenNames.KiosqueUpdateFolder, {
            id: item.id,
          });
        case 'document':
          return navigation.navigate(ScreenNames.KiosqueUpdateDocument, {
            id: item.id,
          });
      }
    },
    [navigation]
  );

  const moveItem = useCallback(
    (item: DocumentsMainScreenItem, folderId: number | null) => {
      deselectItem();
      return updateMutation.mutate([item, folderId]);
    },
    [navigation]
  );

  const removeItem = useCallback(
    (item: DocumentsMainScreenItem) => {
      deselectItem();
      return removeMutation.mutate(item);
    },
    [navigation]
  );

  const getItemActions = useCallback(
    (item: DocumentsMainScreenItem) =>
      isAdminView
        ? [
            ...(item.type === 'document'
              ? [
                  {
                    label: t('download'),
                    renderIcon: (size: number) => <Feather size={size} name="download" color={PRIMARY_COLOR} />,
                    onPress: () => {
                      setSelectedItem(null);
                      item.fileId && openURL(getDocumentURI(item.fileId));
                    },
                  },
                ]
              : []),
            ...(folderId !== null
              ? [
                  {
                    label: t('move_to_parent_folder'),
                    renderIcon: (size: number) => <Feather size={size} name="folder-minus" color={PRIMARY_COLOR} />,
                    onPress: () => {
                      setSelectedItem(null);
                      moveItem(item, data?.parentId || null);
                    },
                  },
                ]
              : []),
            {
              label: t('move'),
              renderIcon: (size: number) => <Feather size={size} name="move" color={PRIMARY_COLOR} />,
              onPress: () => {
                setSelectedItem(null);
                setMovedItem(item);
              },
            },
            {
              label: t('edit'),
              renderIcon: (size: number) => <FontAwesome6 size={size} name="edit" color={PRIMARY_COLOR} />,
              onPress: () => {
                setSelectedItem(null);
                editItem(item);
              },
            },
            {
              label: t('delete'),
              renderIcon: (size: number) => <Feather size={size} name="trash" color={ALERT_COLOR} />,
              onPress: () => {
                setSelectedItem(null);
                setWishToRemoveItem(item);
              },
            },
          ]
        : item.type === 'document'
        ? [
            {
              label: t('download'),
              renderIcon: (size: number) => <Feather size={size} name="download" color={PRIMARY_COLOR} />,
              onPress: () => {
                setSelectedItem(null);
                item.fileId && openURL(getDocumentURI(item.fileId));
              },
            },
          ]
        : [],
    [isAdminView, data?.parentId]
  );

  const padding = isMobile() ? 0 : 10;

  return (
    <AppScreen
      style={{
        paddingHorizontal: padding,
      }}
    >
      <SearchTextInput onChangeText={setSearch} value={search} style={styles.searchInput} />
      <DraxProvider>
        <DraxScrollView style={styles.scrollView}>
          {items && items.length > 0 ? (
            items.map((item, index) => (
              <View key={`${item.type}_${item.id}`}>
                {index > 0 && <Separator />}
                {match(item.type)
                  .with('folder', () => (
                    <DraxView
                      draggable={isAdminView}
                      style={styles.draxView}
                      lockDragXPosition
                      draggingStyle={styles.dragging}
                      dragReleasedStyle={styles.dragging}
                      hoverDraggingStyle={styles.hoverDragging}
                      dragPayload={item}
                      longPressDelay={500}
                      receivingStyle={styles.receiving}
                      onReceiveDragDrop={({ dragged: { payload: draggedItem } }) => {
                        moveItem(draggedItem, item.id);
                      }}
                    >
                      <DocumentItemRow
                        item={item}
                        actions={getItemActions(item)}
                        isAdminView={isAdminView}
                        onPress={() => onItemPress(item)}
                        onSelect={() => {
                          setSelectedItem(item);
                        }}
                      />
                    </DraxView>
                  ))
                  .with('document', () => (
                    <DraxView
                      draggable={isAdminView}
                      style={styles.draxView}
                      lockDragXPosition
                      draggingStyle={styles.dragging}
                      dragReleasedStyle={styles.dragging}
                      hoverDraggingStyle={styles.hoverDragging}
                      dragPayload={item}
                      longPressDelay={500}
                    >
                      <DocumentItemRow
                        item={item}
                        actions={getItemActions(item)}
                        isAdminView={isAdminView}
                        onPress={() => onItemPress(item)}
                        onSelect={() => {
                          setSelectedItem(item);
                        }}
                      />
                    </DraxView>
                  ))
                  .exhaustive()}
              </View>
            ))
          ) : (
            <AppText style={styles.emptyText}>{t('no_contributions')}</AppText>
          )}
        </DraxScrollView>
      </DraxProvider>
      {isAdminView && (
        <>
          <SpeedDial
            isOpen={openSpeedDialog}
            icon={{ name: 'add', color: '#fff' }}
            openIcon={{ name: 'close', color: '#fff' }}
            onOpen={() => setOpenSpeedDialog(true)}
            onClose={() => setOpenSpeedDialog(false)}
            placement="left"
            color={PRIMARY_COLOR}
            containerStyle={{ position: 'relative' }}
          >
            <SpeedDial.Action
              icon={{ name: 'note-add', color: '#fff' }}
              title={data?.title ? t('deposit_in', { title: data.title }) : t('deposit')}
              onPress={createDocument}
              labelPressable
              style={{ marginLeft: 20 }}
              color={PRIMARY_COLOR}
            />
            <SpeedDial.Action
              icon={{ name: 'create-new-folder', color: '#fff' }}
              title={data?.title ? t('create_folder_in', { title: data.title }) : t('create_folder')}
              onPress={createFolder}
              labelPressable
              style={{ marginLeft: 20 }}
              color={PRIMARY_COLOR}
            />
          </SpeedDial>
          <BottomSheet modalProps={{}} isVisible={selectedItem !== null} onBackdropPress={deselectItem}>
            {selectedItem &&
              getItemActions(selectedItem).map((e) => (
                <ListItem key={e.label} onPress={e.onPress}>
                  {e.renderIcon(24)}
                  <ListItem.Content>
                    <ListItem.Title>{e.label}</ListItem.Title>
                  </ListItem.Content>
                </ListItem>
              ))}
          </BottomSheet>
          <AppModalOverlay
            isVisible={wishToRemoveItem !== null}
            onBackdropPress={() => setWishToRemoveItem(null)}
            overlayStyle={{
              backgroundColor: 'red',
            }}
          >
            <AppText mode={Mode.BOLD}>{t('wish_to_remove')}</AppText>
            <View style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-evenly' }}>
              <AppButton
                title={t('yes')}
                onPress={() => {
                  wishToRemoveItem && removeItem(wishToRemoveItem);
                  deselectItem();
                }}
                style={{ width: 80, marginRight: 10 }}
                textStyle={{ fontSize: 10 }}
              />
              <AppButton
                title={t('no')}
                onPress={() => {
                  deselectItem();
                }}
                style={{ width: 80 }}
                textStyle={{ fontSize: 10 }}
              />
            </View>
          </AppModalOverlay>
        </>
      )}
      <BrowseFolderDialog
        isVisible={movedItem !== null}
        type="move"
        onFolderSelected={(newFolderId) => {
          if (movedItem) {
            moveItem(movedItem, newFolderId);
            setMovedItem(null);
            setOpenSpeedDialog(false);
          }
        }}
        onClose={() => setMovedItem(null)}
      />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  searchInput: {
    margin: 10,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  scrollView: {
    height: '100%',
  },
  draxView: {
    padding: 10,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: 'transparent',
  },
  receiving: {
    borderColor: PRIMARY_COLOR,
  },
  dragging: {
    opacity: 0.2,
  },
  hoverDragging: {
    borderColor: PRIMARY_COLOR,
  },
});
