import hex_sha1 from 'react-native-uuid/dist/sha1';
import { domainUrl } from '../../configuration';
import { DateTime } from 'luxon';

export function validateTVParameters(salt: string) {
  return salt !== undefined && salt !== '';
}

export function generateKey(siteGeoId: any, companyId: any) {
  return hex_sha1(siteGeoId + ' ' + companyId + ' konecteam-internal-secret-key');
}

export function generateTVInternLink(salt: string) {
  return `${domainUrl}tv-intern?code=${salt}`;
}

export function generateTVPublicLink(salt: string) {
  return `${domainUrl}tv-public?code=${salt}`;
}

function generateUrl(baseUrl: string, parameters: { [key: string]: string }): string {
  return (
    baseUrl +
    '?' +
    Object.entries(parameters)
      .map(([key, value]) => key + '=' + value)
      .join('&')
  );
}

function dateToGoogleString(date: DateTime): string {
  return date.toFormat("yyyyMMdd'T'HHmmss'Z'");
}

export function generateGoogleAgenda(title: string, description: string, date: string, duration: number): string {
  const startDate = DateTime.fromISO(date); // keep as utc
  const endDate = startDate.plus({ seconds: duration });
  const url = generateUrl('https://www.google.com/calendar/render', {
    action: 'TEMPLATE',
    text: encodeURIComponent(title),
    details: encodeURIComponent(description),
    dates: dateToGoogleString(startDate) + '%2F' + dateToGoogleString(endDate),
  });

  return url;
}

export function generateOutlookAgenda(title: string, description: string, date: string, duration: number): string {
  const startDate = DateTime.fromISO(date, { zone: 'utc' });
  const endDate = startDate.plus({ seconds: duration });
  const url = generateUrl('https://outlook.office.com/calendar/0/deeplink/compose', {
    allday: 'false',
    subject: encodeURIComponent(title),
    body: encodeURIComponent(description),
    location: '',
    path: encodeURIComponent('/calendar/action/compose'),
    rru: 'addevent',
    startdt: encodeURIComponent(startDate.toISO()),
    enddt: encodeURIComponent(endDate.toISO()),
  });

  return url;
}

export function generateTeams(email: string): string {
  return `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
}

export function generateSlack(teamId: string, userId: string): string {
  return `https://slack.com/app_redirect?team=${teamId}&channel=${userId}`;
}

export function generateGoogleChat(userId: string): string {
  return `https://chat.google.com/u/0/dm/${userId}`;
}

const YOUTUBE_REGEX =
  /^(?:(?:https?:)?\/\/)?(?:(?:(?:www|m(?:usic)?)\.)?youtu(?:\.be|be\.com)\/(?:shorts\/|live\/|v\/|e(?:mbed)?\/|watch(?:\/|\?(?:\S+=\S+&)*v=)|oembed\?url=https?%3A\/\/(?:www|m(?:usic)?)\.youtube\.com\/watch\?(?:\S+=\S+&)*v%3D|attribution_link\?(?:\S+=\S+&)*u=(?:\/|%2F)watch(?:\?|%3F)v(?:=|%3D))?|www\.youtube-nocookie\.com\/embed\/)([\w-]{11})[\?&#]?\S*$/;

export function isValidYoutubeUrl(url: string) {
  return url.match(YOUTUBE_REGEX);
}
