import React from 'react';
import { StyleSheet, View } from 'react-native';
import AppText from './AppText';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import AppSwitch from './AppSwitch';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';

export function AppListSwitch(props: {
  onValueChange: (value: boolean) => void;
  value: boolean;
  firstOptionText: string;
  secondOptionText: string;
  design?: 'native' | 'ios';
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 22,
      }}
    >
      <AppText style={!props.value ? style.selected : style.notSelected}>{props.firstOptionText}</AppText>
      <AppSwitch
        onValueChange={props.onValueChange}
        value={typeof props.value === 'string' ? props.value === 'true' : props.value}
        style={{ marginLeft: 28, marginRight: 28 }}
        design={props.design}
      />
      <AppText style={props.value ? style.selected : style.notSelected}>{props.secondOptionText}</AppText>
    </View>
  );
}

const style = StyleSheet.create({
  selected: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 15,
    color: PRIMARY_COLOR,
  },
  notSelected: {
    fontSize: 15,
    color: LIGHT_GRAY,
  },
});
