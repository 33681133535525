import React, { createRef, useState } from 'react';
import { FlatList, ListRenderItem, TouchableOpacity, View } from 'react-native';
import { Icon } from '@rneui/base';
import { PRIMARY_COLOR } from '../../styles/appColor';

export interface HorizontalFlatListProps<T> {
  data: T[];
  renderItem: ListRenderItem<T>;
  keyExtractor: (item: T) => string;
}

export default function HorizontalFlatList<T>({ data, renderItem, keyExtractor }: HorizontalFlatListProps<T>) {
  const ref = createRef<FlatList<T>>();
  const [currentX, setCurrentX] = useState(0);
  const [layoutX, setLayoutX] = useState(0);
  const [contentWidth, setContentWidth] = useState<number | undefined>(undefined);

  const onLeft = () => {
    ref.current?.scrollToOffset({ animated: true, offset: currentX - layoutX });
  };

  const onRight = () => {
    ref.current?.scrollToOffset({ animated: true, offset: currentX + layoutX });
  };

  return (
    <View style={{ height: 48 }}>
      <FlatList<T>
        ref={ref}
        data={data}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        horizontal
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={setContentWidth}
        onLayout={(event) => setLayoutX(event.nativeEvent.layout.width)}
        onScroll={(event) => setCurrentX(event.nativeEvent.contentOffset.x)}
      />
      {!!contentWidth && (
        <>
          {currentX > 0 && (
            <TouchableOpacity
              onPress={onLeft}
              style={{ position: 'absolute', top: 4, left: 4, backgroundColor: '#FFFFFFAA', borderRadius: 16 }}
            >
              <Icon name="arrow-back" size={24} color={PRIMARY_COLOR} />
            </TouchableOpacity>
          )}
          {currentX + layoutX < contentWidth && (
            <TouchableOpacity
              onPress={onRight}
              style={{ position: 'absolute', top: 4, right: 4, backgroundColor: '#FFFFFFAA', borderRadius: 16 }}
            >
              <Icon name="arrow-forward" size={24} color={PRIMARY_COLOR} />
            </TouchableOpacity>
          )}
        </>
      )}
    </View>
  );
}
