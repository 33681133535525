import React, { useContext, useState } from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import AppText from '../../components/atomic/AppText';
import { t } from '../../services/translations';
import { FormField, TextFormField } from '../../components/atomic/FormField';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { postCategory } from '../../services/api/category.api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { CategoriesContext } from './CategoriesContext';
import { CategoriesContributions } from '../../utils/Category';
import { ContributionTypesInfos } from '../../types';
import { AppScreen } from '../../components/containers/AppScreen';
import { isMobile } from '../../services/utils';
import useLoggedUser from '../../hooks/useLoggedUser';

export const categoriesColors: string[] = [
  '#03579b',
  '#0488d1',
  '#03a9f4',
  '#4fc3f7',
  '#253137',
  '#455a64',
  '#607d8b',
  '#90a4ae',
  '#19237e',
  '#303f9f',
  '#3f51b5',
  '#7986cb',
  '#4a198c',
  '#7b21a2',
  '#9c27b0',
  '#ba68c8',
  '#88144f',
  '#c21f5b',
  '#e92663',
  '#f06292',
  '#bf360c',
  '#e64a18',
  '#ff5722',
  '#ff8a65',
  '#f67f17',
  '#fbc02c',
  '#33691d',
  '#689f38',
  '#8bc34a',
];

export function CreateCategoryScreen(props: { navigation: NavigationProp<ParamListBase> }) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const { call } = useContext(CategoriesContext);

  const [customColor, setCustomColor] = useState<{ [key: string]: undefined | string }>({});
  const [success, setSuccess] = useState(false);
  const [selections, setSelections] = useState<{ [key: string]: undefined | { name?: string; color?: string } }>({});

  const onCustomColor = (type: string, color: string) => {
    if (!!color && CSS.supports('color', color)) {
      setSelections((prev) => ({ ...prev, [type]: { ...(prev[type] ?? {}), color } }));
      setCustomColor((prev) => ({ ...prev, [type]: color }));
    }
  };

  const onChangeName = (type: string, name: string) =>
    setSelections((prev) => ({ ...prev, [type]: { ...(prev[type] ?? {}), name } }));

  const onSelectColor = (type: string, color: string) => {
    setSelections((prev) => ({ ...prev, [type]: { ...(prev[type] ?? {}), color } }));
  };

  const onCreateCategory = (type: string) => {
    setSuccess(false);
    dispatch(setLoading(true));
    postCategory(
      user.entreprise?.id!,
      { type, name: selections[type]?.name?.trim()!, color: selections[type]?.color! },
      user.token!
    )
      .then(() => {
        setSelections((prev) => ({ ...prev, [type]: undefined }));
        call();
        setSuccess(true);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <AppScreen>
      <ScrollView style={{ padding: 16 }}>
        {success && (
          <AppText style={{ fontWeight: 'bold', color: PRIMARY_COLOR }}>{t('category_creation_successful')}</AppText>
        )}
        {CategoriesContributions.map((categoryContribution) => (
          <View key={categoryContribution}>
            <AppText style={{ fontWeight: 'bold', fontSize: 16, marginVertical: 18 }}>
              {t(ContributionTypesInfos[categoryContribution].key)}
            </AppText>
            <TextFormField
              mandatory
              value={selections[ContributionTypesInfos[categoryContribution].key]?.name ?? ''}
              onChangeText={(name) => onChangeName(ContributionTypesInfos[categoryContribution].key, name)}
              title={t('category_name') + '*'}
            />
            <FormField title={t('category_color') + '*'} style={{ marginBottom: 48 }}>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: !isMobile() ? '70%' : undefined }}>
                {categoriesColors.map((color) => (
                  <TouchableOpacity
                    key={color}
                    style={{
                      backgroundColor: color,
                      width: 64,
                      height: 24,
                      borderColor: 'black',
                      borderWidth:
                        selections[ContributionTypesInfos[categoryContribution].key]?.color === color ? 2 : 0,
                    }}
                    onPress={() => onSelectColor(ContributionTypesInfos[categoryContribution].key, color)}
                  />
                ))}
                {!!customColor[ContributionTypesInfos[categoryContribution].key] && (
                  <View
                    style={{
                      backgroundColor: customColor[ContributionTypesInfos[categoryContribution].key],
                      width: 64,
                      height: 24,
                      borderColor: 'black',
                      borderWidth:
                        selections[ContributionTypesInfos[categoryContribution].key]?.color ===
                        customColor[ContributionTypesInfos[categoryContribution].key]
                          ? 2
                          : 0,
                    }}
                  />
                )}
              </View>
              {!isMobile() && (
                <TextFormField
                  style={{ marginTop: 16 }}
                  title={t('custom_color')}
                  onChangeText={(text) => onCustomColor(ContributionTypesInfos[categoryContribution].key, text)}
                />
              )}
            </FormField>
            <AppButton
              title={t('creating_the_category')}
              type={Type.PRIMARY}
              onPress={() => onCreateCategory(ContributionTypesInfos[categoryContribution].key)}
              enabled={
                !!selections[ContributionTypesInfos[categoryContribution].key]?.name &&
                selections[ContributionTypesInfos[categoryContribution].key]?.name !== '' &&
                !!selections[ContributionTypesInfos[categoryContribution].key]?.color
              }
            />
          </View>
        ))}
      </ScrollView>
    </AppScreen>
  );
}
