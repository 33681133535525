import MapView, { PROVIDER_DEFAULT } from 'react-native-web-maps';
import React from 'react';
import { View } from 'react-native';
import { latitude, longitude } from '../../services/utils';
import { AppMapViewProps } from './AppMapView';

export function AppMapView({ data, markers, fullWidth, setSelectedItem }: AppMapViewProps) {
  function _getColor(index: number) {
    const colors = ['#db67cc', '#18e6d2', '#52e605', '#1248ed', '#766892', '#314c53', '#ccce62'];
    return colors[index % colors.length];
  }

  return (
    <MapView
      style={{ flex: 1, marginBottom: 10, width: fullWidth ? undefined : '500px' }}
      initialRegion={{
        latitude: 46.56961266894209,
        latitudeDelta: 10.823418848981788,
        longitude: 1.845703955223944,
        longitudeDelta: 13.249511999999982,
      }}
      provider={PROVIDER_DEFAULT}
    >
      {data &&
        data.map((marker, index) => {
          if (marker.isEmpty) {
            return undefined;
          }
          return (
            <View key={index}>
              <MapView.Polyline
                path={[
                  { lat: latitude(marker.adresseDepart), lng: longitude(marker.adresseDepart) },
                  { lat: latitude(marker.adresseArrivee), lng: longitude(marker.adresseArrivee) },
                ]}
                geodesic={true}
                options={{
                  strokeColor: _getColor(index),
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
                }}
              />

              <MapView.Marker
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                }}
                coordinate={{
                  latitude: latitude(marker.adresseDepart),
                  longitude: longitude(marker.adresseDepart),
                }}
                onPress={() => setSelectedItem(marker)}
              />

              <MapView.Marker
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                }}
                coordinate={{
                  latitude: latitude(marker.adresseArrivee),
                  longitude: longitude(marker.adresseArrivee),
                }}
                onPress={() => setSelectedItem(marker)}
              />
            </View>
          );
        })}
      {markers &&
        markers.map((marker, index) => {
          if (!marker) {
            return undefined;
          } else {
            return (
              <View key={index + (data?.length || 0)}>
                <MapView.Marker
                  icon={{
                    url: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                  }}
                  coordinate={{
                    latitude: latitude(marker),
                    longitude: longitude(marker),
                  }}
                />
              </View>
            );
          }
        })}
    </MapView>
  );
}
