import React, { createRef, ForwardedRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  FlatList,
  ListRenderItem,
  RefreshControl,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import OutsidePressHandler from 'react-native-outside-press';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { AppTextInput } from './AppTextInput';
import { FormField } from './FormField';
import Hoverable from './Hoverable';

export type AutoCompleteProps<TItem> = {
  title?: string;
  placeholder: string;
  style?: StyleProp<ViewStyle>;
  defaultQuery?: string;
  noModal?: boolean;
  renderItem: ListRenderItem<TItem>;
  onSelect: (item: TItem) => void;
  search: (q: string) => PromiseLike<TItem[]>;
  keyExtractor: (item: TItem) => string;
};

export type AutoCompleteHandle = {
  setQuery: (value: string) => void;
};

export default React.forwardRef(
  <TItem,>(
    {
      title,
      placeholder,
      style,
      defaultQuery,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      noModal,
      renderItem,
      onSelect,
      search,
      keyExtractor,
    }: AutoCompleteProps<TItem>,
    ref: ForwardedRef<AutoCompleteHandle>
  ) => {
    const [items, setItems] = useState<TItem[]>([]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(0);
    const [query, setQuery] = useState('');
    const flatListRef = createRef<FlatList>();

    useImperativeHandle(ref, () => ({
      setQuery,
    }));

    useEffect(() => setQuery(defaultQuery ?? ''), [defaultQuery]);

    const reload = useCallback(async () => {
      setIsLoading(true);

      try {
        setItems(await search(query));
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }, [query, search]);

    useEffect(() => {
      reload();
    }, [reload]);

    const handleQueryChange = useCallback(
      (text: string) => {
        setQuery(text);
        setHoveredIndex(0);
        setShow(true);
      },
      [setQuery, setHoveredIndex]
    );

    const handleSelect = useCallback(
      (item: TItem) => {
        onSelect(item);
        setShow(false);
      },
      [onSelect, setShow]
    );

    const autocompleteList = useMemo(
      () => (
        <FlatList<TItem>
          ref={flatListRef}
          data={items}
          renderItem={({ item, index, separators }) => (
            <Hoverable
              onHoverChange={(value) => {
                if (value) {
                  setHoveredIndex(index);
                }
              }}
            >
              <TouchableOpacity
                onPress={() => handleSelect(item)}
                style={[styles.item, index === hoveredIndex ? styles.hoveredItem : undefined]}
              >
                {renderItem({ item, index, separators })}
              </TouchableOpacity>
            </Hoverable>
          )}
          keyExtractor={keyExtractor}
          refreshControl={<RefreshControl refreshing={isLoading} onRefresh={reload} />}
        />
      ),
      [flatListRef, items, hoveredIndex, isLoading, handleSelect, renderItem, keyExtractor, reload]
    );

    return (
      <View style={[styles.container, style]}>
        <FormField title={title}>
          <OutsidePressHandler onOutsidePress={() => setShow(false)}>
            <AppTextInput
              placeholder={placeholder}
              onChangeText={handleQueryChange}
              value={query}
              onFocus={() => setShow(true)}
            />
            {show && query.length > 0 && items.length > 0 && <View style={styles.list}>{autocompleteList}</View>}
          </OutsidePressHandler>
        </FormField>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: { position: 'relative', borderWidth: 0, zIndex: 10000000 },
  item: {
    padding: 4,
  },
  hoveredItem: {
    backgroundColor: 'lightgray',
  },
  list: {
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 2000,
    top: '100%',
    width: '100%',
    borderWidth: 1,
    borderColor: PRIMARY_COLOR,
    borderRadius: 4,
    elevation: 20,
    maxHeight: 200,
  },
  modal: {
    backgroundColor: 'white',
  },
});
