import React from 'react';
import YouTube from 'react-youtube';
import { getYoutubeIdFromURL } from '../../models/Media';
import { Pressable, StyleSheet, View } from 'react-native';

export type TVScreenYoutubeVideoProps = {
  youtubeUrl: string;
  onVideoEnd?: () => void;
};

export default function TVScreenYoutubeVideo({ youtubeUrl, onVideoEnd }: TVScreenYoutubeVideoProps) {
  return (
    <View style={styles.container}>
      <YouTube
        style={styles.video}
        videoId={getYoutubeIdFromURL(youtubeUrl)}
        opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            autoplay: 1,
            mute: 1,
            controls: 0,
            showinfo: 0,
            rel: 0,
            modestbranding: 1,
            cc_load_policy: 1,
          },
        }}
        onEnd={onVideoEnd}
      />
      <Pressable style={styles.overlay} onPress={() => {}} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0)',
    //@ts-ignore
    cursor: 'normal',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});
