import React from 'react';
import { StyleSheet, View } from 'react-native';
import AppText from '../AppText';
import { t } from '../../../services/translations';
import SwitchField from './SwitchField';
import useLoggedUser from '../../../hooks/useLoggedUser';

export type ContributionAuthorizationsFieldProps = {
  hideEmailActivated?: boolean;
};

export default function ContributionAuthorizationsField({ hideEmailActivated }: ContributionAuthorizationsFieldProps) {
  const user = useLoggedUser();
  const isAdmin = user.lovIdType === 13;

  return (
    <View style={styles.container}>
      <View style={styles.switchGroup}>
        <SwitchField name="commentsActivated" />
        <AppText>{t('authorize_comments')}</AppText>
      </View>
      <View style={styles.switchGroup}>
        <SwitchField name="notifActivated" />
        <AppText>{t('generate_notif')}</AppText>
      </View>
      {isAdmin && !hideEmailActivated && (
        <View style={styles.switchGroup}>
          <SwitchField name="emailActivated" />
          <AppText>{t('generate_email')}</AppText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flexWrap: 'wrap', gap: 40 },
  switchGroup: { flexDirection: 'row', gap: 28 },
});
