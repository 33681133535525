import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { getScreenContributions, getScreenSocialPosts } from '../../services/api/tv.api';
import TVScreenContributionCard from './TVScreenContributionCard';
import { vh } from '../../utils/Utils';
import useInterval from '../../hooks/useInterval';
import { ScreenSocialPostType } from '../../entities/ScreenSocialPost';

export type TVScreenContributionFeedProps = {
  code: string;
  paused?: boolean;
};

export default function TVScreenContributionFeed({ code, paused }: TVScreenContributionFeedProps) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const { data: contributions } = useQuery(['screenContributions', code], () => getScreenContributions(code), {
    refetchInterval: 60 * 1000,
  });
  const { data: socialPosts } = useQuery(['socialPosts', code], () => getScreenSocialPosts(code), {
    refetchInterval: 30 * 60 * 1000,
  });

  const hasSocialPosts = (socialPosts?.length || 0) > 0;
  const currentSocialPostIndex = currentItemIndex % (socialPosts?.length || 1);
  const step = hasSocialPosts ? 1 : 2;

  const item1 = contributions?.[currentItemIndex];
  const item2 =
    hasSocialPosts && socialPosts?.[currentSocialPostIndex]
      ? {
          id: socialPosts[currentSocialPostIndex].url || '',
          type:
            socialPosts[currentSocialPostIndex].type === ScreenSocialPostType.Facebook
              ? 'facebook'
              : ('linkedin' as 'facebook' | 'linkedin'),
          title: null,
          description: socialPosts[currentSocialPostIndex].description,
          imageId: socialPosts[currentSocialPostIndex].imageUrl,
          creationDate: socialPosts[currentSocialPostIndex].dateCreated,
        }
      : contributions?.[(currentItemIndex + 1) % (contributions || []).length];

  // Change every 11 seconds
  useInterval(
    () => {
      setCurrentItemIndex((currentItemIndex) => {
        if (currentItemIndex >= (contributions || []).length - step) {
          return currentItemIndex + step - (contributions || []).length;
        }
        return currentItemIndex + step;
      });
    },
    {
      delay: 12000,
      paused,
      dependencies: [contributions, step],
    }
  );

  return (
    <View style={styles.container}>
      <View style={styles.itemContainer}>
        <TVScreenContributionCard item={item1 || null} style={styles.item} />
      </View>
      <View style={styles.itemContainer}>
        <TVScreenContributionCard item={item2 || null} style={styles.item} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    gap: vh(2),
  },
  itemContainer: {
    flex: 1,
  },
  item: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
});
