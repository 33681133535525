import { useField } from 'formik';
import { AppLanguagePicker, AppLanguagePickerProps } from '../AppLanguagePicker';
import { FormField } from '../FormField';
import { t } from '../../../services/translations';
import AppText, { Mode } from '../AppText';

export type LanguagePickerFieldProps = Omit<Omit<AppLanguagePickerProps, 'defaultValue'>, 'onLanguageChanged'> & {
  name: string;
  title: string;
  required?: boolean;
};

export function LanguagePickerField({ name, title, required, ...rest }: LanguagePickerFieldProps) {
  const [field, meta, helper] = useField(name);

  return (
    <FormField title={required ? `${title}*` : title}>
      <AppLanguagePicker defaultValue={field.value} onLanguageChanged={helper.setValue} />
      {meta.touched && meta.error && <AppText mode={Mode.ERROR}>{meta.error}</AppText>}
    </FormField>
  );
}
