import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import DocumentFormFields, { schema } from './DocumentFormFields';
import { createDocument } from '../../../../services/api/document.contributions.api';
import { DocumentCreate } from '../../../../entities/DocumentCreate';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type CreateDocumentScreenProps = {
  route: {
    params: {
      folderId?: string;
    };
  };
};

export default function CreateDocumentScreen({ route }: CreateDocumentScreenProps) {
  const user = useLoggedUser();
  const initialFolderId = (route.params.folderId && parseInt(route.params.folderId)) || null;
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: Omit<DocumentCreate, 'folder'>) => {
      await createDocument(user, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={
        {
          folderId: initialFolderId,
          commentsActivated: true,
          notifActivated: true,
          emailActivated: false,
        } as any
      }
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <DocumentFormFields />
    </Form>
  );
}
