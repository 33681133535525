import { useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import ScreenSettingsFormFields, { schema } from '../../../../components/molecules/ScreenSettingsFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { setLoading } from '../../../../store/action';
import { createPlaylistScreenSettings } from '../../../../services/api/tv.api';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { WHITE } from '../../../../styles/appColor';

export default function ScreenSettingsCreateScreen() {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      dispatch(setLoading(true));

      try {
        await createPlaylistScreenSettings(user, values);
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    await mutation.mutateAsync(values);
    resetForm();
    navigation.goBack();
  };

  const isLoading = mutation.isLoading;

  const initialValues = {
    title: '',
    backgroundColor: '',
    playlistId: null,
    latitude: null,
    longitude: null,
    rssFeed: '',
    facebookPageUrl: '',
    linkedInPageUrl: '',
  };

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  return (
    <ScrollView style={styles.container}>
      <AppScreen>
        <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnMount>
          <ScreenSettingsFormFields />
        </Form>
      </AppScreen>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
});
