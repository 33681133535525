import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { objectToFormData } from '../formUtil';
import { UserSaved } from '../storage';
import { ContributionSondagesOwnerDetails } from '../../entities/ContributionSondagesOwnerDetails';
import { ContributionSondagesDetails } from '../../entities/ContributionSondagesDetails';
import { CreateSurvey } from '../../entities/CreateSurvey';
import { RespondSurvey } from '../../entities/RespondSurvey';

export function getSurvey(
  user: UserSaved,
  id: number
): Promise<ContributionSondagesOwnerDetails | ContributionSondagesDetails> {
  return axios
    .get(apiUrlBase + 'ContributionSondages/' + id, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export function sendSurvey(user: UserSaved, args: CreateSurvey) {
  return axios.post(apiUrlBase + 'ContributionSondages', objectToFormData(args), {
    headers: headers(user.token, 'multipart/form-data'),
  });
}

export function updateSurvey(user: UserSaved, id: number, args: CreateSurvey) {
  return axios.put(apiUrlBase + 'ContributionSondages/' + id, objectToFormData(args), {
    headers: headers(user.token, 'multipart/form-data'),
  });
}

export async function respondSurvey(user: UserSaved, surveyId: number, args: RespondSurvey) {
  return axios.put(`${apiUrlBase}ContributionSondages/${surveyId}/respond`, args, {
    headers: headers(user.token),
  });
}

export function getSurveyExportPdfUrl(user: UserSaved, id: number) {
  return `${apiUrlBase}ContributionSondages/pdf/${id}?access_token=${user.token}`;
}

export function getSurveyExportCsvUrl(user: UserSaved, id: number) {
  return `${apiUrlBase}ContributionSondages/csv/${id}?access_token=${user.token}`;
}

export function recallSurveyNotification(user: UserSaved, id: number) {
  return axios.post(
    `${apiUrlBase}ContributionSondages/recall-notification/${id}`,
    {},
    {
      headers: headers(user.token, 'multipart/form-data'),
    }
  );
}
