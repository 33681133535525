import React, { ReactNode } from 'react';
import { Modal, StyleProp, ViewStyle } from 'react-native';
import { Overlay } from '@rneui/base';

export function AppModalOverlay(props: {
  onBackdropPress?(): void;
  isVisible: boolean;
  children: ReactNode;
  overlayStyle?: StyleProp<ViewStyle>;
}) {
  return (
    <Overlay
      overlayStyle={Object.assign({}, props.overlayStyle, styles.defaultOverlay)}
      ModalComponent={Modal}
      onBackdropPress={props.onBackdropPress}
      isVisible={props.isVisible}
    >
      {props.children}
    </Overlay>
  );
}

const styles = {
  defaultOverlay: {
    backgroundColor: 'white',
  },
};
