import React from 'react';
import { View } from 'react-native';
import { hasLicenceMessaging } from '../../services/licences';
import { MessagingButton } from './MessagingButton';
import { NotificationButton } from './NotificationButton';
import useLoggedUser from '../../hooks/useLoggedUser';

export function HeaderButtons() {
  const user = useLoggedUser();

  return (
    <View style={{ flexDirection: 'row' }}>
      {hasLicenceMessaging(user) && <MessagingButton />}
      <NotificationButton />
    </View>
  );
}
