import { View } from 'react-native';
import React from 'react';

const Space = (height: number) => {
  return <View style={{ height: height }} />;
};

export const Space1 = () => {
  return Space(15);
};

export const Space2 = () => {
  return Space(30);
};

export const Space3 = () => {
  return Space(45);
};

export const SpaceFiller = () => {
  return <View style={{ flex: 1 }} />;
};
