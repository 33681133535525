import axios from 'axios';
import { Paginated } from '../../entities/Paginated';
import { UserSaved } from '../storage';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { SmsEmergencyTemplate } from '../../entities/SmsEmergencyTemplate';
import { CreateSmsEmergencyTemplate } from '../../entities/CreateSmsEmergencyTemplate';
import { UpdateSmsEmergencyTemplate } from '../../entities/UpdateSmsEmergencyTemplate';
import { SendSmsEmergency } from '../../entities/SendSmsEmergency';
import { SendTemplate } from '../../entities/SendTemplate';
import { SpreadingPostData } from '../../entities/SpreadingPostData';
import { SendTemplateUserCount } from '../../entities/SendTemplateUserCount';

export async function listSmsEmergencyTemplates(
  user: UserSaved,
  cursor: string | null,
  search?: string | null
): Promise<Paginated<SmsEmergencyTemplate, string>> {
  return axios
    .get(`${apiUrlBase}SmsEmergency/template`, {
      headers: headers(user.token),
      params: { cursor, search },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listSmsEmergencyMyTemplates(
  user: UserSaved,
  cursor: string | null,
  search?: string | null
): Promise<Paginated<SmsEmergencyTemplate, string>> {
  return axios
    .get(`${apiUrlBase}SmsEmergency/template/my`, {
      headers: headers(user.token),
      params: { cursor, search },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getSmsEmergencyTemplates(user: UserSaved, id: number): Promise<SmsEmergencyTemplate> {
  return axios
    .get(`${apiUrlBase}SmsEmergency/template/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function createSmsEmergencyTemplate(
  user: UserSaved,
  request: CreateSmsEmergencyTemplate
): Promise<SmsEmergencyTemplate> {
  return axios
    .post(`${apiUrlBase}SmsEmergency/template`, request, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 201) return response.data;
      else return null;
    });
}

export async function updateSmsEmergencyTemplate(
  user: UserSaved,
  id: number,
  request: UpdateSmsEmergencyTemplate
): Promise<SmsEmergencyTemplate> {
  return axios
    .put(`${apiUrlBase}SmsEmergency/template/${id}`, request, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 201) return response.data;
      else return null;
    });
}

export async function deleteSmsEmergencyTemplate(user: UserSaved, id: number): Promise<void> {
  return axios.delete(`${apiUrlBase}SmsEmergency/template/${id}`, {
    headers: headers(user.token),
  });
}

export async function sendSmsEmergency(user: UserSaved, request: SendSmsEmergency): Promise<void> {
  return axios.post(`${apiUrlBase}SmsEmergency/send`, request, {
    headers: headers(user.token),
  });
}

export async function sendSmsEmergencyTemplate(user: UserSaved, id: number, request: SendTemplate): Promise<void> {
  return axios.post(`${apiUrlBase}SmsEmergency/template/${id}/send`, request, {
    headers: headers(user.token),
  });
}

export async function sendUserCount(user: UserSaved, request: SpreadingPostData): Promise<SendTemplateUserCount> {
  return axios
    .post(`${apiUrlBase}SmsEmergency/send/count`, request, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
