import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { HtmlText } from '../../../../components/atomic/HtmlText';
import SpreadingChipList from '../../../../components/atomic/SpreadingChipsList';
import { UserSubRow } from '../../../../components/atomic/UserSubRow';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import { ContentWithImageHeaderScreen } from '../../../../components/containers/ContentWithImageHeaderScreen';
import { CommentList } from '../../../../components/molecules/CommentList';
import { getCustomModuleContributionsDetails } from '../../../../services/api/custom.module.api';
import { t } from '../../../../services/translations';
import { alertInfo, getReadableDate, isMobile } from '../../../../services/utils';
import { setCurrentContribution, setDetailContributionType, setLoading } from '../../../../store/action';
import { ContributionType } from '../../../../types';
import { BigDetailRow, BigDetailRowInline } from '../ContributionItemDetailScreen';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type DetailsCustomModuleContributionScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function DetailsCustomModuleContributionScreen({ route }: DetailsCustomModuleContributionScreenProps) {
  const user = useLoggedUser();
  const id = parseInt(route.params.id);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const { data, isLoading, isError } = useQuery(
    ['customContributionDetails', id],
    async () => {
      let result = await getCustomModuleContributionsDetails(user, id);
      dispatch(setCurrentContribution(result));
      dispatch(setDetailContributionType(ContributionType.CUSTOM_MODULE));

      return result;
    },
    {
      cacheTime: 0,
    }
  );

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <DefaultBackground style={{ flex: 1 }}>
      {data && (
        <ContentWithImageHeaderScreen
          underImageContent={
            <View
              style={{
                flexDirection: 'row',
                paddingTop: 10,
                paddingLeft: 10,
                paddingRight: 10,
                width: isMobile() ? undefined : 540,
              }}
            >
              <UserSubRow user={data.contribution?.userIdCreateurNavigation} withPicture={true} style={{ flex: 1 }} />
              <AppText style={{ width: isMobile() ? undefined : 200, textAlign: 'right' }}>
                {getReadableDate(data.contribution.dateCreation, true)}
              </AppText>
            </View>
          }
          contribution={data}
          contributionType={ContributionType.CUSTOM_MODULE}
          navigation={navigation}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AppText
              mode={Mode.BOLD}
              style={{
                flex: 1,
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              {data.title}
            </AppText>
          </View>
          <BigDetailRow borderBottomWidth={1} content={<HtmlText text={data.description} />} title={t('description')} />
          <BigDetailRowInline
            content={<SpreadingChipList item={data.contribution} monocolor={true} inline={true} />}
            title={t('who_can_see')}
          />
          {!!data.contribution?.commentsActivated && <CommentList contributionId={data.contribution.id} />}
        </ContentWithImageHeaderScreen>
      )}
    </DefaultBackground>
  );
}
