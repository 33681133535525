import React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Icon, Image } from '@rneui/base';
import AppText from './AppText';
import { alertInfo, combineNames, getReadableDate, isMobile } from '../../services/utils';
import { ProfilePicture } from './ProfilePicture';
import { setDirectoryListNeedsRefresh, setLoading } from '../../store/action';
import { t } from '../../services/translations';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { useDispatch } from 'react-redux';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import { removeUser } from '../../services/api/user.api';
import { FONT_FAMILY_DEFAULT, globalStyle } from '../../styles/globalStyle';
import { User } from '../../entities/User';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../ScreenNames';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import { useMutation } from '@tanstack/react-query';
import { resetPasswordSendCode } from '../../services/api/login';

const logoFile = require('../../../assets/adaptive-icon.png');

export function ProfileRow(props: {
  user: User;
  onPress?: () => void;
  allowEdit?: boolean;
  simple?: boolean;
  small?: boolean;
  showAdminTag?: boolean;
  showRecoveryEmail?: boolean;
  style?: StyleProp<ViewStyle>;
}) {
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp<any>>();
  const userSaved = useLoggedUser();
  const _allowEdit = props.allowEdit === undefined ? false : props.allowEdit;

  const { mutateAsync: sendRecoveryEmail } = useMutation({
    mutationFn: async (email: string) => {
      await resetPasswordSendCode(email);
    },
    onSuccess: () => {
      alertInfo(t('recovery_email_sent'));
    },
    onError: () => {
      alertInfo(t('unknown_error'));
    },
  });

  const content = (
    <View style={{ flexDirection: 'column', flex: 1 }}>
      {isMobile() && (
        <View style={{ flexDirection: 'row', marginBottom: 10 }}>
          {/*{props.allowEdit && !props.simple &&*/}
          {props.showAdminTag && props.user.lovIdType == 13 && (
            <View style={{ flexDirection: 'row', marginLeft: 65 }}>
              <Image source={logoFile} style={{ width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30 }} />
              <AppText
                style={{
                  marginLeft: 10,
                  lineHeight: isMobile() ? 20 : 30,
                  fontSize: isMobile() ? 12 : 14,
                  textAlign: 'center',
                  fontFamily: FONT_FAMILY_DEFAULT.bold,
                }}
              >
                Responsable
              </AppText>
            </View>
          )}
          {/*{props.allowEdit && !props.simple && */}
          {props.showAdminTag && props.user.lovIdType != 13 && props.user.userSubAdmins?.length > 0 && (
            <View style={{ flexDirection: 'row', marginLeft: 65 }}>
              <Image source={logoFile} style={{ width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30 }} />
              <AppText
                style={{
                  marginLeft: 10,
                  lineHeight: isMobile() ? 20 : 30,
                  fontSize: isMobile() ? 12 : 14,
                  textAlign: 'center',
                  fontFamily: FONT_FAMILY_DEFAULT.bold,
                }}
              >
                Administrateur
              </AppText>
            </View>
          )}
          <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
            <AppText
              style={{ fontSize: 12, textAlign: 'center', fontFamily: FONT_FAMILY_DEFAULT.bold, marginRight: 60 }}
            >
              {props.allowEdit &&
                !props.simple &&
                getReadableDate(props.user.dateInscription, undefined, undefined, false)}
              {/*{!(props.allowEdit && !props.simple) && props.showAdminTag && props.user.lovIdType == 13 && <View style={{flexDirection: 'row', marginLeft: 65}}>*/}
              {/*    <Image source={logoFile} style={{width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30}} />*/}
              {/*    <AppText style={{ marginLeft: 10, lineHeight: isMobile() ? 20 : 30, fontSize: isMobile() ? 12 : 14, textAlign: 'center', fontFamily: FONT_FAMILY_DEFAULT.bold }}>Responsable</AppText>*/}
              {/*</View>}*/}
              {/*{!(props.allowEdit && !props.simple) && props.showAdminTag && props.user.lovIdType != 13 && props.user.userSubAdmins.length > 0 && <View style={{flexDirection: 'row', marginLeft: 65}}>*/}
              {/*    <Image source={logoFile} style={{width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30}} />*/}
              {/*    <AppText style={{ marginLeft: 10, lineHeight: isMobile() ? 20 : 30, fontSize: isMobile() ? 12 : 14, textAlign: 'center', fontFamily: FONT_FAMILY_DEFAULT.bold }}>Administrateur</AppText>*/}
              {/*</View>}*/}
            </AppText>
          </View>
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <ProfilePicture pictureId={props.user.fichierIdProfil} size={props.small ? 'small' : 'medium'} />
          <View style={{ flexDirection: 'column', justifyContent: 'center', marginLeft: 10 }}>
            <AppText
              style={{
                fontSize: 14,
                fontFamily: FONT_FAMILY_DEFAULT.bold,
                marginBottom: 11,
              }}
            >
              {combineNames(props.user)}
            </AppText>
            {!props.simple && (
              <>
                <AppText style={{ fontSize: 12, marginBottom: 11 }}>{props.user.fonction}</AppText>
                <AppText style={{ fontSize: 12 }}>{props.user.hideEmail ? '' : props.user.aspNetUsers.email}</AppText>
              </>
            )}
          </View>
        </View>
        {!isMobile() && (
          <View style={{ alignItems: 'flex-end', marginRight: 30, flexShrink: 1 }}>
            <View style={{ flexDirection: 'column', marginLeft: 10, width: isMobile() ? 110 : 250 }}>
              <View style={{ flexDirection: 'row', flex: 1, marginLeft: isMobile() ? 30 : 40 }}>
                <AppText
                  style={{ fontSize: isMobile() ? 14 : 14, textAlign: 'center', fontFamily: FONT_FAMILY_DEFAULT.bold }}
                >
                  {props.allowEdit && !props.simple
                    ? getReadableDate(props.user.dateInscription, undefined, undefined, false)
                    : '\n'}
                </AppText>
              </View>
              {props.showAdminTag && props.user.lovIdType == 13 && (
                <View style={{ flexDirection: 'row' }}>
                  <Image source={logoFile} style={{ width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30 }} />
                  <AppText
                    style={{
                      marginLeft: 10,
                      lineHeight: isMobile() ? 20 : 30,
                      fontSize: isMobile() ? 12 : 14,
                      textAlign: 'center',
                      fontFamily: FONT_FAMILY_DEFAULT.bold,
                    }}
                  >
                    Responsable
                  </AppText>
                </View>
              )}
              {props.showAdminTag && props.user.lovIdType != 13 && props.user.userSubAdmins?.length > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <Image source={logoFile} style={{ width: isMobile() ? 20 : 30, height: isMobile() ? 20 : 30 }} />
                  <AppText
                    style={{
                      marginLeft: 10,
                      lineHeight: isMobile() ? 20 : 30,
                      fontSize: isMobile() ? 12 : 14,
                      textAlign: 'center',
                      fontFamily: FONT_FAMILY_DEFAULT.bold,
                    }}
                  >
                    Administrateur
                  </AppText>
                </View>
              )}
            </View>
          </View>
        )}
        {_allowEdit ? (
          <>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate(ScreenNames.EditUser, {
                  id: props.user.id,
                });
              }}
            >
              <Icon
                size={18}
                name="edit"
                type="feather"
                color={PRIMARY_COLOR}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </TouchableOpacity>
            {props.showRecoveryEmail && (
              <TwoActionsGenericPopup
                title={t('wish_to_send_recovery_email')}
                button1Title={t('yes')}
                button2Title={t('no')}
                onPressButton1={() => {
                  sendRecoveryEmail(props.user.aspNetUsers.email);
                }}
                onPressButton2={() => {}}
              >
                <SimpleLineIcons size={18} name="lock" color={PRIMARY_COLOR} style={{ marginHorizontal: 10 }} />
              </TwoActionsGenericPopup>
            )}
            <TwoActionsGenericPopup
              title={t('wish_to_remove')}
              button1Title={t('yes')}
              button2Title={t('no')}
              onPressButton1={() => {
                dispatch(setLoading(true));
                removeUser(userSaved, props.user.aspNetUsers)
                  .then(() => alertInfo(t('removed_ok')))
                  .finally(() => {
                    dispatch(setDirectoryListNeedsRefresh(true));
                    dispatch(setLoading(false));
                  });
              }}
              onPressButton2={() => {}}
            >
              <FontAwesome6
                size={18}
                name="trash-alt"
                color={PRIMARY_COLOR}
                style={{ marginLeft: 10, marginRight: 24 }}
              />
            </TwoActionsGenericPopup>
          </>
        ) : null}
      </View>
    </View>
  );

  return props.onPress ? (
    <View style={[globalStyle.listRow, props.style]}>
      <TouchableOpacity onPress={props.onPress} style={{ flexDirection: 'row' }}>
        {content}
      </TouchableOpacity>
    </View>
  ) : (
    <View style={[globalStyle.listRow, { flexDirection: 'row' }, props.style]}>{content}</View>
  );
}
