import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, Modal, RefreshControl, SafeAreaView, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Header, Icon } from '@rneui/themed';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { t } from '../../services/translations';
import { AppButton, Type } from './AppButton';
import { match } from 'ts-pattern';
import { listFolderAsAdmin } from '../../services/api/document.contributions.api';
import { setLoading } from '../../store/action';
import DocumentItemRow from './DocumentItemRow';
import AppText from './AppText';
import { DocumentsMainScreenItem } from '../../screens/loggedIn/contribution/documents/DocumentsMainScreen';
import useLoggedUser from '../../hooks/useLoggedUser';

export type BrowseFolderDialogProps = {
  isVisible: boolean;
  type: 'add' | 'move';
  onFolderSelected: (folderId: number | null) => void;
  onClose: () => void;
};

export default function BrowseFolderDialog({ isVisible, type, onFolderSelected, onClose }: BrowseFolderDialogProps) {
  const [folderIdStack, setFolderIdStack] = useState<number[]>([]);
  const currentFolderId = folderIdStack.length > 0 ? folderIdStack[folderIdStack.length - 1] : null;

  const user = useLoggedUser();
  const dispatch = useDispatch();
  const queryFunction = useCallback(() => listFolderAsAdmin(user, currentFolderId), [currentFolderId, user]);
  const {
    data,
    isLoading,
    refetch: refresh,
  } = useQuery(['folderListAdmins', currentFolderId], queryFunction, { cacheTime: 0 });

  // Format items from fetched data
  const items = useMemo(() => {
    const documents = data?.documents.map((e) => ({ type: 'document', ...e })) ?? ([] as DocumentsMainScreenItem[]);
    const folders = data?.folders.map((e) => ({ type: 'folder', ...e })) ?? ([] as DocumentsMainScreenItem[]);

    return [...folders, ...documents] as DocumentsMainScreenItem[];
  }, [data]);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const handlePrevious = useCallback(() => {
    setFolderIdStack(folderIdStack.slice(0, -1));
  }, [folderIdStack]);

  return (
    <Modal animationType="slide" visible={isVisible} onRequestClose={onClose} style={styles.modal}>
      <SafeAreaView style={styles.container}>
        <Header
          backgroundColor="white"
          leftComponent={
            folderIdStack.length > 0 ? (
              <TouchableOpacity onPress={handlePrevious}>
                <Icon name="arrow-left" type="feather" color={PRIMARY_COLOR} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity onPress={onClose}>
                <Icon name="close" color={PRIMARY_COLOR} />
              </TouchableOpacity>
            )
          }
          leftContainerStyle={styles.headingLeftContainer}
          centerComponent={{ text: data?.title || t('choose_folder'), style: styles.heading }}
        />
        <View style={styles.foldersContainer}>
          <FlatList
            refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
            data={items}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item }) =>
              match(item.type)
                .with('folder', () => (
                  <DocumentItemRow
                    item={item}
                    actions={[]}
                    isAdminView={false}
                    onPress={() => {
                      setFolderIdStack([...folderIdStack, item.id]);
                    }}
                  />
                ))
                .with('document', () => <DocumentItemRow item={item} actions={[]} isAdminView={false} />)
                .exhaustive()
            }
            ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
          />
          <AppButton
            title={type === 'move' ? t('move_here') : t('add_here')}
            type={Type.PRIMARY}
            onPress={() => onFolderSelected(currentFolderId)}
            enabled
            style={styles.submitButton}
          />
        </View>
      </SafeAreaView>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  headingLeftContainer: {
    justifyContent: 'center',
  },
  heading: {
    paddingTop: 10,
    fontSize: 16,
    marginVertical: 'auto',
  },
  foldersContainer: {
    flex: 1,
    marginHorizontal: 20,
    marginBottom: 20,
    overflow: 'hidden',
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  submitButton: {},
});
