import { DependencyList, useCallback, useEffect, useState } from 'react';
import { Paginated } from '../entities/Paginated';
import { t } from '../services/translations';
import { alertInfo } from '../services/utils';

export type PaginatedListResult<TItem> = {
  data: TItem[];
  isLoading: boolean;
  isRefreshing: boolean;
  totalCount: number;
  fetchNext: () => Promise<void>;
  refresh: () => Promise<void>;
};

export type PaginatedListResultOptions = {
  firstFetchLimit?: number;
};

export default function usePaginatedList<TItem, TCursor>(
  fetchByCursor: (cursor: TCursor | null, limit: number | null) => Promise<Paginated<TItem, TCursor> | TItem[]>,
  deps?: DependencyList,
  { firstFetchLimit }: PaginatedListResultOptions = {}
): PaginatedListResult<TItem> {
  const [data, setData] = useState<TItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [nextCursor, setNextCursor] = useState<TCursor | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const fetchNext = useCallback(async () => {
    if (nextCursor) {
      setIsLoading(true);

      try {
        let result = await fetchByCursor(nextCursor, null);

        if (Array.isArray(result)) {
          setData(result);
          setNextCursor(null);
          setTotalCount(result.length);
        } else {
          setData([...data, ...result.data]);
          setNextCursor(result.nextCursor);
          setTotalCount(result.totalCount);
        }
      } catch (e) {
        alertInfo(t('error_occurred'));
      }

      setIsLoading(false);
    }
  }, [fetchByCursor, data, nextCursor]);

  const refresh = useCallback(async () => {
    setIsLoading(true);
    setIsRefreshing(true);

    try {
      let result = await fetchByCursor(null, firstFetchLimit || null);

      if (Array.isArray(result)) {
        setData(result);
        setNextCursor(null);
        setTotalCount(result.length);
      } else {
        setData(result.data);
        setNextCursor(result.nextCursor);
        setTotalCount(result.totalCount);
      }
    } catch (e) {
      alertInfo(t('error_occurred'));
    }

    setIsLoading(false);
    setIsRefreshing(false);
  }, [fetchByCursor, firstFetchLimit, nextCursor]);

  // Initialize by fetching
  useEffect(() => {
    refresh();
  }, deps || []);

  return {
    data,
    isLoading,
    isRefreshing,
    totalCount,
    fetchNext,
    refresh,
  };
}
