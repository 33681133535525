import { match } from 'ts-pattern';
import { ModuleEnum } from '../entities/ModuleSettings';

export function getModuleDefaultOrder(module: ModuleEnum) {
  return match(module)
    .with(ModuleEnum.News, () => 0)
    .with(ModuleEnum.Admin, () => 1)
    .with(ModuleEnum.ContributionVente, () => 2)
    .with(ModuleEnum.ContributionHobbie, () => 3)
    .with(ModuleEnum.ContributionService, () => 4)
    .with(ModuleEnum.ContributionCovoiturage, () => 5)
    .with(ModuleEnum.ContributionEvenement, () => 6)
    .with(ModuleEnum.ContributionFormation, () => 7)
    .with(ModuleEnum.ContributionOrganigrame, () => 8)
    .with(ModuleEnum.ContributionObjet, () => 9)
    .with(ModuleEnum.ContributionSondage, () => 10)
    .with(ModuleEnum.ContributionBoiteIdee, () => 11)
    .with(ModuleEnum.ContributionKiosque, () => 12)
    .with(ModuleEnum.UserDirectory, () => 13)
    .with(ModuleEnum.EmergencySms, () => 14)
    .otherwise(() => 0);
}
