import React, { useEffect, useMemo, useState } from 'react';
import { AppScreen } from '../../../components/containers/AppScreen';
import { useDispatch } from 'react-redux';
import { dateLocale, t } from '../../../services/translations';
import { RefreshControl, SectionList, StyleSheet, View } from 'react-native';
import { SearchTextInput } from '../../../components/atomic/SearchTextInput';
import AppText from '../../../components/atomic/AppText';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { EventItemRow } from '../../../components/atomic/EventItemRow';
import { ContributionEvenementSummary } from '../../../entities/ContributionEvenementSummary';
import { getContributionsList } from '../../../services/api/contribution.api';
import { groupByAdjacent, removeAccentsAndLower } from '../../../utils/Utils';
import { format } from 'date-fns';
import { FONT_FAMILY_DEFAULT } from '../../../styles/globalStyle';
import Separator from '../../../components/atomic/Separator';
import usePaginatedList from '../../../hooks/usePaginatedList';
import { setLoading } from '../../../store/action';
import useLoggedUser from '../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../hooks/useContributionListRefresh';

export type EventsMainScreenProps = {
  route: {
    params: {
      contributionType: string;
      contributionsMode: string;
      isAdminView: boolean;
    };
  };
};

export function EventsMainScreen({ route }: EventsMainScreenProps) {
  const contributionTypeInt = parseInt(route.params.contributionType);
  const contributionsModeInt = parseInt(route.params.contributionsMode);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const locale = dateLocale();

  const {
    data: items,
    isLoading,
    isRefreshing,
    fetchNext,
    refresh,
  } = usePaginatedList<ContributionEvenementSummary, string>(
    async (cursor: string | null) => {
      return getContributionsList(user, contributionsModeInt, contributionTypeInt, search, cursor);
    },
    [search]
  );

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isRefreshing));
  }, [isRefreshing, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  const data = useMemo(
    () =>
      groupByAdjacent(
        items.filter((ev) => removeAccentsAndLower(ev.titre).includes(removeAccentsAndLower(search))),
        (item) => format(new Date(item.date), 'eeee d MMMM yyyy', { locale })
      ).map((data) => ({
        title: format(new Date(data[0].date), 'eeee d MMMM yyyy', { locale }),
        data,
      })),
    [items, search]
  );

  return (
    <AppScreen>
      <SearchTextInput onChangeText={setSearch} value={search} style={styles.searchInput} />
      <View style={styles.content}>
        <SectionList
          sections={data}
          keyExtractor={(item) => item.id.toString()}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <EventItemRow contributionType={contributionTypeInt} item={item} isAdminView={!!route.params.isAdminView} />
          )}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={({ section: { title } }) => <AppText style={styles.sectionTitle}>{title}</AppText>}
          ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
          data={items}
          refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
          ItemSeparatorComponent={() => <Separator />}
          onEndReached={fetchNext}
        />
      </View>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  searchInput: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 20,
    color: PRIMARY_COLOR,
    paddingVertical: 10,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
});
