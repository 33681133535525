import { DrawerScreenProps } from '@react-navigation/drawer';
import React from 'react';
import { useDispatch } from 'react-redux';
import FormLoading from '../../../../components/containers/FormLoading';
import { CompanyNewsUpload } from '../../../../models/CompanyNews';
import { getCompanyNewsDetail, putCompanyNews } from '../../../../services/api/news.contributions.api';
import { t } from '../../../../services/translations';
import {
  alertInfo,
  hasSubAdminRight,
  isAdmin,
  PickPhotoOrDocumentResult,
  sendSuccessText,
} from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { ContributionType } from '../../../../types';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import NewsFormFields, { schema } from './NewsFormFields';
import Form from '../../../../components/atomic/formik/Form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { MActuPostDetails } from '../../../../entities/MActuPostDetails';
import { getContributionImageSource } from '../../../../services/api/helper.api';

type ScreenProps = DrawerScreenProps<any>;

export function getInitialValuesFromData(
  data: MActuPostDetails
): Omit<CompanyNewsUpload, 'entrepriseId' | 'userId' | 'image'> {
  return {
    titre: data.titre,
    texte: data.texte,
    translations: data.translations.map((translation) => ({
      lng: translation.languageCode,
      titre: translation.titre,
      description: translation.texte,
    })),
    videoUrl: data.videoUrl,
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    featured: data.featured || false,
    imageId: data.imageId,
    documentId: data.documentId,
    spreadingType: data.spreadingType as any as number,
    userIds: data.userTargets.map((e) => e.userId),
    entrepriseServiceIds: data.entrepriseServices.map((e) => e.entrepriseServiceId),
    entrepriseSiteGeoIds: data.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
    entrepriseGroupIds: data.entrepriseGroups.map((e) => e.entrepriseGroupId),
    visibleStartDate: data.visibleStartDate,
    visibleEndDate: data.visibleEndDate,
    categoryId: data.categoryId || undefined,
    publicationMode: !data.visibleStartDate
      ? 'immediate_posting'
      : !data.visibleEndDate
      ? 'planned_with_start_date'
      : 'planned_with_start_and_end_date',
  };
}

export default function UpdateNewsScreen({ route }: ScreenProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const id = route.params?.id;

  const { data } = useQuery(['news-details', id], () => getCompanyNewsDetail(user, id));

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: async (body: CompanyNewsUpload) => {
      dispatch(setLoading(true));
      return putCompanyNews(user, id, body, (body.image as PickPhotoOrDocumentResult | null) || undefined);
    },
    onSuccess: (_, values) => {
      const hasRights =
        isAdmin(user) ||
        hasSubAdminRight(user, values.entrepriseServiceIds, values.entrepriseSiteGeoIds, values.entrepriseGroupIds);

      alertInfo(sendSuccessText(user, ContributionType.COMPANY_NEWS, hasRights));
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
    onError: () => {
      alertInfo(t('error_occurred'));
    },
    onSettled: () => {
      dispatch(setLoading(false));
    },
  });

  if (isLoading || !data) {
    return <FormLoading />;
  }

  return (
    <Form
      initialValues={getInitialValuesFromData(data)}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <NewsFormFields
        defaultPhoto={getContributionImageSource(data.imageId || undefined, ContributionType.COMPANY_NEWS)}
      />
    </Form>
  );
}
