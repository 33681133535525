import React, { createContext, useCallback, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { updateDeviceToken, deleteDeviceToken } from '../../services/api/notification.api';
import { urlBase, vapidKey } from '../../../configuration';
import useLoggedUser from '../../hooks/useLoggedUser';
import { NotificationType } from '../../utils/Notification';

declare global {
  const Notification:
    | {
        requestPermission: () => Promise<any>;
      }
    | undefined;
}

initializeApp({
  apiKey: 'AIzaSyBtoAc81s3IjnuGXcvqXLGV9Xrqn0UWbVM',
  authDomain: 'konecteam-notification.firebaseapp.com',
  projectId: 'konecteam-notification',
  storageBucket: 'konecteam-notification.appspot.com',
  messagingSenderId: '78519534298',
  appId: '1:78519534298:web:711b972ae71a347652dbe3',
  measurementId: 'G-T2JEXKPF12',
});

export type PushNotificationContextType = {
  isNotificationAllowed: boolean;
  registerOnNotificationOpenedApp: (callback: (notification: NotificationType | undefined) => void) => () => void;
  registerOnMessage: (callback: (notification: NotificationType | undefined) => void) => () => void;
};

export const PushNotificationContext = createContext<PushNotificationContextType | null>(null);

export type PushNotificationProviderProps = {
  children: React.ReactNode;
};

export default function PushNotificationProvider({ children }: PushNotificationProviderProps) {
  const loggedUser = useLoggedUser();
  const [isNotificationAllowed, setIsNotificationAllowed] = useState(false);
  const [onMessage, setOnMessage] = useState<(notification: NotificationType | undefined) => void>();
  const accessToken = loggedUser?.token;
  const [lastAccessToken, setLastAccessToken] = useState<string | null>(null);

  useEffect(() => {
    requestPermission(() => setIsNotificationAllowed(true));
  }, []);

  const registerOnNotificationOpenedApp = useCallback(() => {
    return () => {};
  }, []);

  const registerOnMessage = useCallback((callback: (notification: NotificationType | undefined) => void) => {
    setOnMessage(() => callback);

    return () => {
      setOnMessage(undefined);
    };
  }, []);

  useEffect(() => {
    if (!isNotificationAllowed) {
      return;
    }

    if (!onMessage) {
      return;
    }

    // Listen to signalR
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${urlBase}notification?access_token=${accessToken}`)
      .withAutomaticReconnect()
      .build();

    connection.on('message', (message) => {
      return onMessage(message as NotificationType | undefined);
    });

    connection.start().catch((err) => console.error(err));

    return () => {
      connection.stop();
    };
  }, [accessToken, isNotificationAllowed, onMessage]);

  useEffect(() => {
    if (!isNotificationAllowed) {
      return;
    }

    if (!loggedUser) {
      return;
    }

    const asyncEffect = async () => {
      const token = await getToken(getMessaging(), { vapidKey });
      await updateDeviceToken(loggedUser, token);
      setLastAccessToken(loggedUser.token);
    };

    asyncEffect();
  }, [loggedUser, isNotificationAllowed]);

  useEffect(() => {
    if (!isNotificationAllowed) {
      return;
    }

    if (loggedUser || !lastAccessToken) {
      return;
    }

    const asyncEffect = async () => {
      const token = await getToken(getMessaging(), { vapidKey });
      await deleteDeviceToken(lastAccessToken, token);
    };

    asyncEffect();
  }, [loggedUser, lastAccessToken, isNotificationAllowed]);

  return (
    <PushNotificationContext.Provider
      value={{
        isNotificationAllowed,
        registerOnNotificationOpenedApp,
        registerOnMessage,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
}

function requestPermission(callback: () => void): void {
  if (typeof Notification === 'undefined') {
    return;
  }

  Notification.requestPermission().then(() => callback());
}
