import React from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Field, useField, useFormikContext } from 'formik';
import { t } from '../../../services/translations';
import { ALERT_COLOR, DARK_GRAY, LIGHT_GRAY, SMOKE_WHITE } from '../../../styles/appColor';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import Animated, { interpolate, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import AppText, { Mode } from '../AppText';

export type LoginFieldProps = {
  emailName: string;
  passwordName: string;
  passwordVisible?: boolean;
  isLoading?: boolean;
  onNext?: (email: string) => void;
  onEmailChange?: () => void;
};

export function LoginField({
  emailName,
  passwordName,
  passwordVisible,
  isLoading,
  onNext,
  onEmailChange,
}: LoginFieldProps) {
  const [emailField, emailState, emailHelper] = useField(emailName);
  const [passwordField, passwordState, passwordHelper] = useField(passwordName);
  const { submitForm } = useFormikContext();

  let passwordViewOpacity = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    return {
      display: passwordVisible ? 'flex' : 'none',
      height: interpolate(passwordViewOpacity.value, [0, 1], [0, 44]),
      overflow: passwordViewOpacity.value === 1 ? 'visible' : 'hidden',
    };
  });

  const handleSubmit = !isLoading
    ? passwordVisible
      ? submitForm
      : () => onNext && onNext(emailField.value)
    : undefined;

  const isEmailValid = !emailState.error;

  // Password grow when it becomes visible
  React.useEffect(() => {
    passwordViewOpacity.value = withTiming(passwordVisible ? 1 : 0, { duration: 1000 });
  }, [passwordVisible, passwordViewOpacity]);

  return (
    <View style={styles.container}>
      <View style={styles.doubleInput}>
        <Field
          id={emailName}
          value={emailField.value}
          onChangeText={(value: string) => {
            emailHelper.setValue(value.toLowerCase(), true);
            onEmailChange && onEmailChange();
          }}
          onBlur={() => emailHelper.setTouched(true)}
          as={TextInput}
          onSubmitEditing={handleSubmit}
          placeholder={t('email_address')}
          keyboardType="email-address"
          autoCapitalize="none"
          style={[
            styles.input,
            passwordVisible ? styles.inputTop : styles.inputSingle,
            emailState.touched && emailState.error ? styles.inputError : {},
          ]}
        />
        <Animated.View style={animatedStyles}>
          {passwordVisible && (
            <>
              <Field
                id={passwordName}
                value={passwordField.value}
                onChangeText={(value: string) => {
                  passwordHelper.setValue(value, true);
                }}
                onBlur={() => passwordHelper.setTouched(true)}
                placeholder={t('password')}
                as={TextInput}
                onSubmitEditing={handleSubmit}
                secureTextEntry
                style={[
                  styles.input,
                  styles.inputBottom,
                  passwordState.touched && passwordState.error ? styles.inputError : {},
                ]}
                editable={!isLoading}
                autoFocus
              />
            </>
          )}
        </Animated.View>
        <TouchableOpacity style={styles.nextButton} onPress={handleSubmit} disabled={!isEmailValid}>
          {isLoading ? (
            <ActivityIndicator color={DARK_GRAY} size={20} />
          ) : (
            <FontAwesome6 name="circle-arrow-right" color={isEmailValid ? DARK_GRAY : LIGHT_GRAY} size={20} />
          )}
        </TouchableOpacity>
      </View>
      <AppText mode={Mode.ERROR}>{emailState.touched && emailState.error}</AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  doubleInput: {
    width: '100%',
    borderRadius: 16,
    padding: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: DARK_GRAY,
    padding: 12,
    fontSize: 18,
  },
  inputError: {
    borderColor: ALERT_COLOR,
    borderWidth: 2,
  },
  inputSingle: {
    borderRadius: 16,
  },
  inputTop: {
    borderBottomWidth: 0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  inputDisabled: {
    color: LIGHT_GRAY,
    backgroundColor: SMOKE_WHITE,
  },
  inputBottom: {
    borderTopColor: LIGHT_GRAY,
    borderTopWidth: 1,
    borderWidth: 1,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    height: '100%',
    width: '100%',
  },
  nextButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: 44,
    width: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
