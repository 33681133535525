import React from 'react';
import { View } from 'react-native';
import useLoggedUser from '../hooks/useLoggedUser';
import GuestRoute from './GuestRoute';
import { LoggedInRoute } from './LoggedInRoute';

export default function UserStatusRoute() {
  let user = useLoggedUser();

  if (user) {
    return (
      <View style={{ flex: 1 }}>
        <LoggedInRoute />
      </View>
    );
  } else {
    return <GuestRoute />;
  }
}
