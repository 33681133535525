import { Icon } from '@rneui/base';
import React, { useCallback, useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import { isMobile, pickDocument, takePhoto } from '../../services/utils';
import { DARK_GRAY, LIGHT_GRAY, PRIMARY_COLOR, PRIMARY_COLOR_DARKEN } from '../../styles/appColor';
import AppText, { Mode } from '../atomic/AppText';
import AutoExpandingTextInput from '../atomic/AutoExpandingTextInput';
import { FilePreview } from '../atomic/FilePreview';
import { IconButton } from '../atomic/IconButton';

export type NewMessageFormProps = {
  sendNewMessage: (body: { message: string | null; file: Blob | null }) => Promise<void>;
};

export function NewMessageForm({ sendNewMessage }: NewMessageFormProps) {
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<Blob | null>(null);

  const isValid = !!message || !!file;

  const send = useCallback(async () => {
    if (isValid) {
      await sendNewMessage({ message, file });
      setMessage('');
      setFile(null);
    }
  }, [message, isValid, sendNewMessage, setMessage]);

  const handlePickDocumentPressed = useCallback(() => {
    pickDocument().then((result) => {
      if (result) {
        setFile(result);
      }
    });
  }, [setFile]);

  const handleTakePhotoPressed = useCallback(() => {
    takePhoto().then((result) => {
      if (result) {
        setFile(result);
      }
    });
  }, [setFile]);

  return (
    <View style={styles.container}>
      <View
        style={[
          {
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: PRIMARY_COLOR,
            borderRadius: 8,
            flex: 1,
            justifyContent: 'center',
          },
        ]}
      >
        {file && (
          <View style={styles.fileContainer}>
            <FilePreview style={styles.filePreview} source={file} />
            <View style={styles.fileTextContainer}>
              <AppText mode={Mode.SMALL}>{file.name || file.fileName}</AppText>
            </View>
            <Icon name="close" onPress={() => setFile(null)} />
          </View>
        )}
        <View style={styles.inputContainer}>
          <AutoExpandingTextInput value={message} onChangeText={(value) => setMessage(value)} style={styles.input} />
          {!file && (
            <View style={styles.actionContainer}>
              {isMobile() && (
                <Entypo
                  name="camera"
                  size={20}
                  color={DARK_GRAY}
                  style={styles.action}
                  onPress={handleTakePhotoPressed}
                />
              )}
              <Entypo
                name="attachment"
                size={20}
                color={DARK_GRAY}
                style={styles.action}
                onPress={handlePickDocumentPressed}
              />
            </View>
          )}
        </View>
      </View>
      <IconButton icon="send" onPress={send} disabled={!isValid} style={styles.button} reverse />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 10,
    position: 'relative',
  },
  input: {
    flex: 1,
    color: PRIMARY_COLOR_DARKEN,
  },
  button: {
    marginLeft: 10,
    marginVertical: 2,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionContainer: isMobile()
    ? {
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingLeft: 8,
        paddingRight: 8,
        height: '100%',
      }
    : {
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingVertical: 4,
        paddingHorizontal: 8,
        height: '100%',
      },
  action: {
    padding: 5,
  },
  fileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: LIGHT_GRAY,
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    margin: 5,
    marginBottom: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  filePreview: {
    width: 80,
  },
  fileTextContainer: {
    flex: 1,
    marginLeft: 10,
  },
});
