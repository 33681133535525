import React, { useCallback, useMemo } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { formatDistanceStrict } from 'date-fns';
import { getPictureSource } from '../../services/api/helper.api';
import { ContributionEvenementSummary } from '../../entities/ContributionEvenementSummary';
import { dateLocale, t } from '../../services/translations';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { ALERT_COLOR, DARK_GRAY, PRIMARY_COLOR, SECONDARY_COLOR } from '../../styles/appColor';
import { useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../ScreenNames';
import { ContributionTypesInfos } from '../../types';
import { alertInfo, forceUTCToLocal, getReadableTime } from '../../services/utils';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import Feather from 'react-native-vector-icons/Feather';
import { useDispatch } from 'react-redux';
import { setContributionsListNeedsRefresh, setLoading } from '../../store/action';
import { removeContribution } from '../../services/api/contribution.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

const eventImage = require('../../../assets/drawable/event.jpg');

export type EventItemRowProps = {
  item: ContributionEvenementSummary;
  contributionType: number;
  isAdminView: boolean;
};

export function EventItemRow({ item, contributionType, isAdminView }: EventItemRowProps) {
  const locale = dateLocale();
  const navigation = useNavigation();
  const { isSmallMediumDevice } = useDeviceSize();
  const dispatch = useDispatch();
  const user = useLoggedUser();

  const seatsLabel = useMemo(() => {
    return item.availableSeats === null ? (
      <Text style={styles.remainingSealsText}>{t('event_unlimited_seats')}</Text>
    ) : item.availableSeats === item.participationCount ? (
      <Text style={styles.noAvailableSealsText}>{t('event_no_available_seats')}</Text>
    ) : (
      <Text style={styles.remainingSealsText}>
        {t('event_limited_seats', { count: (item.availableSeats || 0) - item.participationCount })}
      </Text>
    );
  }, [item.availableSeats, item.participationCount]);

  const eventTypeLabel = useMemo(() => {
    return item.place !== null && item.url !== null
      ? t('event_type_both_short')
      : item.url
      ? t('event_type_remote')
      : t('event_type_place');
  }, [item.place, item.url]);

  let endDate = useMemo(() => {
    const endDate = new Date(forceUTCToLocal(item.date));
    endDate.setSeconds(endDate.getSeconds() + item.duration);

    return endDate;
  }, [item.date, item.duration]);

  const onPress = useCallback(() => {
    navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ContributionTypesInfos[contributionType].detailScreen ?? ScreenNames.ContributionItemDetailScreen,
      params: {
        id: item.id,
        contributionType: contributionType,
        isNotif: false,
      },
    });
  }, [navigation, item]);

  const onPressEdit = useCallback(() => {
    navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ContributionTypesInfos[contributionType].formScreen ?? ScreenNames.ContributionEditFormScreen,
      params: {
        id: item.id,
        contributionType: contributionType,
      },
    });
  }, [navigation, contributionType, item]);

  const onPressRemove = useCallback(() => {
    dispatch(setLoading(true));
    removeContribution(user, item.contributionId)
      .then(() => alertInfo(t('removed_ok')))
      .finally(() => {
        dispatch(setContributionsListNeedsRefresh(true));
        dispatch(setLoading(false));
      });
  }, [user, dispatch]);

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <Image
          source={item.fichierIdPhoto ? getPictureSource(item.fichierIdPhoto) : eventImage}
          style={styles.picture}
        />
        <View style={styles.contentContainer}>
          <View style={isSmallMediumDevice ? styles.topContainerSmall : styles.topContainer}>
            <Text style={styles.title} numberOfLines={1}>
              {item.titre}
            </Text>
            {!isAdminView && (
              <Text style={isSmallMediumDevice ? styles.seatsTextSmall : styles.seatsText}>{seatsLabel}</Text>
            )}
          </View>
          <View style={styles.bottomContainer}>
            <Text style={isSmallMediumDevice ? styles.textSmall : styles.text}>{getReadableTime(item.date, true)}</Text>
            <Text style={isSmallMediumDevice ? styles.textSmall : styles.text}>
              {formatDistanceStrict(new Date(forceUTCToLocal(item.date)), endDate, { locale, roundingMethod: 'ceil' })}
            </Text>
            {!isAdminView && (
              <Text style={[isSmallMediumDevice ? styles.textSmall : styles.text, styles.textRight]}>
                {eventTypeLabel}
              </Text>
            )}
          </View>
        </View>
        {isAdminView && (
          <View style={styles.actionsContainer}>
            <TouchableOpacity onPress={onPressEdit} style={styles.editButton}>
              <FontAwesome6 size={24} name="edit" color={PRIMARY_COLOR} />
            </TouchableOpacity>
            <TwoActionsGenericPopup
              title={t('wish_to_remove')}
              button1Title={t('yes')}
              button2Title={t('no')}
              onPressButton1={onPressRemove}
              onPressButton2={() => {}}
            >
              <Feather size={24} name="trash" color={ALERT_COLOR} />
            </TwoActionsGenericPopup>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    marginVertical: 10,
  },
  picture: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 20,
  },
  contentContainer: {
    flex: 1,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  topContainerSmall: {
    flexDirection: 'column',
    flex: 1,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingTop: 16,
  },
  title: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  seatsText: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
  },
  seatsTextSmall: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 12,
  },
  text: {
    fontFamily: FONT_FAMILY_DEFAULT.regular,
    fontSize: 16,
    color: DARK_GRAY,
    flex: 1,
  },
  textSmall: {
    fontFamily: FONT_FAMILY_DEFAULT.regular,
    fontSize: 14,
    color: DARK_GRAY,
    flex: 1,
  },
  textRight: {
    textAlign: 'right',
  },
  remainingSealsText: {
    color: SECONDARY_COLOR,
  },
  noAvailableSealsText: {
    color: ALERT_COLOR,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  editButton: {
    paddingRight: 10,
  },
});
