import React, { useState } from 'react';
import AppText, { Mode } from '../atomic/AppText';
import { t } from '../../services/translations';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { AppButton } from '../atomic/AppButton';
import { AppModalOverlay } from '../atomic/AppModalOverlay';

export function TwoActionsButtonPopup(props: {
  initialButtonTitle: string;
  title: string;
  button1Title: string;
  button2Title: string;
  onPressButton1;
  onPressButton2;
}) {
  const [visible, setVisible] = useState(false);

  return (
    <View>
      <AppButton title={props.initialButtonTitle} onPress={() => setVisible(true)} />
      <AppModalOverlay isVisible={visible} onBackdropPress={() => setVisible(false)}>
        <AppText>{props.title}</AppText>
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <AppButton
            title={props.button1Title}
            onPress={() => {
              props.onPressButton1();
              setVisible(false);
            }}
            style={{ width: 80, marginRight: 10 }}
          />
          <AppButton
            title={t('no')}
            onPress={() => {
              props.onPressButton2();
              setVisible(false);
            }}
            style={{ width: 80 }}
          />
        </View>
      </AppModalOverlay>
    </View>
  );
}
export function TwoActionsGenericPopup(props: {
  children;
  title: string;
  description?: string;
  button1Title: string;
  button2Title: string;
  onPressButton1;
  onPressButton2;
  style?: StyleProp<ViewStyle>;
}) {
  const [visible, setVisible] = useState(false);

  return (
    <View>
      <TouchableOpacity style={props.style} onPress={() => setVisible(true)}>
        {props.children}
      </TouchableOpacity>
      <AppModalOverlay isVisible={visible} onBackdropPress={() => setVisible(false)}>
        <AppText mode={Mode.BOLD}>{props.title}</AppText>
        {props.description !== undefined && (
          <AppText style={{ textAlign: 'center', fontSize: 12, marginTop: 10, marginBottom: 10 }}>
            {props.description}
          </AppText>
        )}
        <View style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-evenly' }}>
          <AppButton
            title={props.button1Title}
            onPress={() => {
              props.onPressButton1();
              setVisible(false);
            }}
            style={{ width: 80, marginRight: 10 }}
            textStyle={{ fontSize: 10 }}
          />
          <AppButton
            title={t('no')}
            onPress={() => {
              props.onPressButton2();
              setVisible(false);
            }}
            style={{ width: 80 }}
            textStyle={{ fontSize: 10 }}
          />
        </View>
      </AppModalOverlay>
    </View>
  );
}
