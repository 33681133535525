import { useState } from 'react';
import { User } from '../../entities/User';
import { t } from '../../services/translations';
import { AppButton, Type } from '../atomic/AppButton';
import { UserProfileOverlay } from '../atomic/UserProfileOverlay';

export type ContactUserButtonProps = {
  user: User;
};

export default function ContactUserButton({ user }: ContactUserButtonProps) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <AppButton
        title={t('contact_original_poster')}
        style={{ margin: 29, marginBottom: 19 }}
        textStyle={{ fontSize: 16 }}
        type={Type.PRIMARY}
        onPress={() => setVisible(true)}
      />
      <UserProfileOverlay onBackdropPress={() => setVisible(false)} userProfileData={user} isVisible={visible} />
    </>
  );
}
