import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation, useQuery } from '@tanstack/react-query';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { getSmsEmergencyTemplates, updateSmsEmergencyTemplate } from '../../../services/api/sms.emergency.api';
import { UpdateSmsEmergencyTemplate } from '../../../entities/UpdateSmsEmergencyTemplate';
import { alertInfo } from '../../../services/utils';
import { t } from '../../../services/translations';
import Form from '../../../components/atomic/formik/Form';
import EmergencySmsTemplateFormFields, { schema } from './EmergencySmsTemplateFormFields';
import { setLoading } from '../../../store/action';
import { View } from 'react-native';

export type EmergencySmsUpdateTemplateScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function EmergencySmsUpdateTemplateScreen({ route }: EmergencySmsUpdateTemplateScreenProps) {
  const user = useLoggedUser();
  const id = parseInt(route.params.id);
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingDetails,
    isFetched,
  } = useQuery(['getSmsEmergencyTemplates', id], () => getSmsEmergencyTemplates(user, id), { cacheTime: 0 });

  const {
    mutateAsync,
    isError,
    isLoading: isLoadingMutation,
  } = useMutation({
    mutationFn: async (values: UpdateSmsEmergencyTemplate) => {
      await updateSmsEmergencyTemplate(user, id, values);
      navigation.goBack();
    },
  });

  const isLoading = isLoadingDetails || isLoadingMutation;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  const initialValues = useMemo(
    () => ({
      title: data?.title,
      message: data?.message,
    }),
    [data]
  );

  return isFetched ? (
    <Form initialValues={initialValues} onSubmit={mutateAsync as any} validationSchema={schema} validateOnMount>
      <EmergencySmsTemplateFormFields />
    </Form>
  ) : (
    <View />
  );
}
