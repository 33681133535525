import React, { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { User } from '../../entities/User';
import { searchUsers } from '../../services/api/user.api';
import { t } from '../../services/translations';
import AppAutoComplete from './AppAutoComplete';
import { ProfileRow } from './ProfileRow';
import useLoggedUser from '../../hooks/useLoggedUser';

export type UserAutocompleteProps = {
  title?: string;
  style?: StyleProp<ViewStyle>;
  selectedIds: number[];
  onSelect: (user: User) => void;
  updateNumSelected?: (n: number) => void;
};

export default function UserAutocomplete({ title, style, selectedIds, onSelect }: UserAutocompleteProps) {
  const user = useLoggedUser();

  const handleSearch = useCallback(
    async (query: string) => (await searchUsers(user, query)).filter((e) => !selectedIds.includes(e.id)),
    [user, selectedIds]
  );

  return (
    <AppAutoComplete<User>
      title={title}
      placeholder={t('search_user')}
      style={style}
      renderItem={({ item }) => <ProfileRow user={item} allowEdit={false} simple small />}
      onSelect={onSelect}
      search={handleSearch}
      keyExtractor={(item) => item.id.toString()}
    />
  );
}
