import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { User } from '../../entities/User';
import {
  CompanyGeoSiteReduced,
  CompanyGroupReduced,
  CompanyServiceReduced,
  getCompanyGeoSitesWithCount,
  getCompanyGeoSitesWithServicesWithCount,
  getCompanyServicesWithCount,
  getCompanyUsersCount,
  getLoggedUserCompanyGroupsWithCount,
} from '../../services/api/company.api';
import { getCompanyUsersFromCompanyId, getUsersByIds } from '../../services/api/user.api';
import { t } from '../../services/translations';
import { alertInfo, hasSubAdminRight, isAdmin, isMobile } from '../../services/utils';
import { setLoading } from '../../store/action';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { SpreadingType } from '../../types';
import AppSwitch from './AppSwitch';
import AppText, { Mode } from './AppText';
import { FormFieldPicker } from './FormField';
import { ProfileRow } from './ProfileRow';
import UserAutocomplete from './UserAutocomplete';
import { uniq } from 'lodash';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type PickUsersProps = {
  enabled: boolean;
  filterByUserRights?: boolean;
  subAdminCanShareWithEveryone?: boolean;
  left?: React.ReactNode;
  spreadingType: SpreadingType | undefined;
  pickedUsers: number[];
  pickedServices: number[];
  pickedGeoSites: number[];
  pickedGroups: number[];
  setSpreadingType: (s: SpreadingType | undefined) => void;
  setPickedUsers: (ids: number[]) => void;
  setPickedServices: (ids: number[]) => void;
  setPickedGeoSites: (ids: number[]) => void;
  setPickedGroups: (ids: number[]) => void;
  onNeedsValidationChange?: (hasAdminRight: boolean) => void;
};

export function PickUsers({
  enabled,
  filterByUserRights,
  subAdminCanShareWithEveryone,
  left,
  spreadingType,
  pickedUsers,
  pickedServices,
  pickedGeoSites,
  pickedGroups,
  setSpreadingType,
  setPickedUsers,
  setPickedServices,
  setPickedGeoSites,
  setPickedGroups,
  onNeedsValidationChange,
}: PickUsersProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();

  const [companyServices, setCompanyServices] = useState<CompanyServiceReduced[]>([]);
  const [companyGeoSites, setCompanyGeoSites] = useState<CompanyGeoSiteReduced[]>([]);
  const [companyGeoSitesWithServices, setCompanyGeoSitesWithServices] = useState<{
    [key: number]: { service: number; count: number }[];
  }>({});
  const [companyGroups, setCompanyGroups] = useState<CompanyGroupReduced[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [entrepriseUserCount, setEntrepriseUserCount] = useState(0);
  const [collapsedGeoSites, setCollapsedGeoSites] = useState<number[]>([]);

  const [pickedGeoSiteServices, setPickedGeoSiteServices] = useState<{ siteId: number; serviceId: number }[]>([]);

  // Filter by user rights
  const spreadingTypeOptions = useMemo(() => {
    const isAdmin = !filterByUserRights || user.lovIdType === 13;
    const isServiceSubAdmin = isAdmin || user.userSubAdmins?.find((e) => !!e.entrepriseServiceId) !== undefined;
    const isSiteGeoSubAdmin = isAdmin || user.userSubAdmins?.find((e) => !!e.entrepriseSiteGeoId) !== undefined;
    const isGroupSubAdmin = isAdmin || user.userSubAdmins?.find((e) => !!e.entrepriseGroup) !== undefined;

    return [
      ...(isAdmin || subAdminCanShareWithEveryone
        ? [{ label: t('company_wide') + ' - ' + user.entreprise.nom, value: SpreadingType.ENTREPRISE }]
        : []),
      ...(isServiceSubAdmin ? [{ label: t('by_service'), value: SpreadingType.SERVICES }] : []),
      ...(isSiteGeoSubAdmin ? [{ label: t('by_geo_site'), value: SpreadingType.SITES }] : []),
      ...(isServiceSubAdmin && isSiteGeoSubAdmin
        ? [{ label: t('by_geo_site_and_service'), value: SpreadingType.SITES_AND_SERVICES }]
        : []),
      ...(isGroupSubAdmin && !!companyGroups?.length ? [{ label: t('by_group'), value: SpreadingType.GROUPS }] : []),
      ...(isAdmin ? [{ label: t('manual'), value: SpreadingType.USERS }] : []),
    ];
  }, [filterByUserRights, user, companyGroups, subAdminCanShareWithEveryone]);

  const filteredServices = useMemo(() => {
    const isAdmin = !filterByUserRights || user.lovIdType === 13;

    if (isAdmin) {
      return companyServices;
    } else {
      return companyServices.filter(
        (e) => !!user.userSubAdmins?.find((subAdmin) => subAdmin.entrepriseServiceId === e.id)
      );
    }
  }, [companyServices, filterByUserRights, user]);

  const filteredGeoSites = useMemo(() => {
    const isAdmin = !filterByUserRights || user.lovIdType === 13;

    if (isAdmin) {
      return companyGeoSites;
    } else {
      return companyGeoSites.filter(
        (e) => !!user.userSubAdmins?.find((subAdmin) => subAdmin.entrepriseSiteGeoId === e.id)
      );
    }
  }, [companyGeoSites, filterByUserRights, user]);

  const filteredGroups = useMemo(() => {
    const isAdmin = !filterByUserRights || user.lovIdType === 13;

    if (isAdmin) {
      return companyGroups;
    } else {
      return companyGroups.filter((e) => !!user.userSubAdmins?.find((subAdmin) => subAdmin.entrepriseGroupId === e.id));
    }
  }, [companyGroups, filterByUserRights, user]);

  // Fill users with initial values
  useEffect(() => {
    if (spreadingType === SpreadingType.USERS) {
      getUsersByIds(user, pickedUsers).then((users) => setUsers(users));
    }
  }, []);

  // Fill geo sites and services with initial values
  useEffect(() => {
    const newPickedGeoSiteServices: { siteId: number; serviceId: number }[] = [];

    pickedGeoSites.forEach((siteId) => {
      pickedServices.forEach((serviceId) => {
        newPickedGeoSiteServices.push({ siteId, serviceId });
      });
    });

    setPickedGeoSiteServices(newPickedGeoSiteServices);
  }, []);

  // Update geo sites and services when picking a new geo site/service
  useEffect(() => {
    if (spreadingType === SpreadingType.SITES_AND_SERVICES) {
      let pickedSites = uniq(pickedGeoSiteServices.map((e) => e.siteId));
      let pickedServices = uniq(pickedGeoSiteServices.map((e) => e.serviceId));

      setPickedGeoSites(pickedSites);
      setPickedServices(pickedServices);
    }
  }, [pickedGeoSiteServices]);

  // Get company data about users, services, geo sites and groups
  useEffect(() => {
    setLoading(true);
    getCompanyUsersFromCompanyId(user.entreprise?.id!, user.token!)
      .then(() => getCompanyUsersCount(user.entreprise?.id!, user.token!).then(setEntrepriseUserCount))
      .then(() => getCompanyServicesWithCount(user.entreprise?.id!, user.token!).then(setCompanyServices))
      .then(() => getCompanyGeoSitesWithCount(user.entreprise?.id!, user.token!).then(setCompanyGeoSites))
      .then(() =>
        getCompanyGeoSitesWithServicesWithCount(user.entreprise?.id!, user.token!).then(setCompanyGeoSitesWithServices)
      )
      .then(() => getLoggedUserCompanyGroupsWithCount(user.token!).then(setCompanyGroups))
      .catch((e) => {
        alertInfo(t('unknown_error'));
      })
      .finally(() => dispatch(setLoading(false)));
  }, []);

  function selectUser(user: User) {
    const newUsers = [...users, user];
    setUsers(newUsers);
    setPickedUsers(newUsers.map((e) => e.id));
  }

  function unselectUser(user: User) {
    const newUsers = users.filter((e) => e.id !== user.id);
    setUsers(newUsers);
    setPickedUsers(newUsers.map((e) => e.id));
  }

  function setServiceSelected(id: number, value: boolean) {
    if (value) {
      setPickedServices([...pickedServices, id]);
    } else {
      setPickedServices(pickedServices.filter((e) => e !== id));
    }
  }

  function setGeoSiteSelected(id: number, value: boolean) {
    if (value) {
      setPickedGeoSites([...pickedGeoSites, id]);
    } else {
      setPickedGeoSites(pickedGeoSites.filter((e) => e !== id));
    }
  }

  function setGroupSelected(id: number, value: boolean) {
    if (value) {
      setPickedGroups([...pickedGroups, id]);
    } else {
      setPickedGroups(pickedGroups.filter((e) => e !== id));
    }
  }

  const audienceCount = useMemo(() => {
    switch (spreadingType) {
      case SpreadingType.ENTREPRISE:
        return entrepriseUserCount;
      case SpreadingType.SITES:
        return companyGeoSites != null
          ? pickedGeoSites.map((id) => companyGeoSites.find((s) => s.id === id)?.count || 0).reduce((a, b) => a + b, 0)
          : 0;
      case SpreadingType.SERVICES:
        return companyServices != null
          ? pickedServices.map((id) => companyServices.find((s) => s.id === id)?.count || 0).reduce((a, b) => a + b, 0)
          : 0;
      case SpreadingType.SITES_AND_SERVICES:
        return companyGeoSitesWithServices != null
          ? pickedGeoSiteServices
              .map(({ serviceId, siteId }) => {
                return companyGeoSitesWithServices[siteId]?.find((e) => e.service == serviceId)?.count || 0;
              })
              .reduce((a, b) => a + b, 0)
          : 0;
      case SpreadingType.GROUPS:
        return companyGroups != null
          ? pickedGroups.map((id) => companyGroups.find((s) => s.id === id)?.count || 0).reduce((a, b) => a + b, 0)
          : 0;
      case SpreadingType.USERS:
        return users.length;
    }
    return 0;
  }, [users, pickedGroups, pickedServices, pickedGeoSites, spreadingType]);

  const needsValidation =
    !isAdmin(user) &&
    !hasSubAdminRight(
      user,
      companyServices.map((e) => e.id),
      companyGeoSites.map((e) => e.id),
      companyGroups.map((e) => e.id)
    );

  useEffect(() => {
    if (onNeedsValidationChange) {
      onNeedsValidationChange(needsValidation);
    }
  }, [needsValidation, onNeedsValidationChange]);

  return (
    <View style={{ flexDirection: isMobile() ? 'column' : 'row', zIndex: 100 }}>
      <View style={{ marginTop: 10, zIndex: (!isMobile() ? 'unset' : undefined) as any }}>
        <FormFieldPicker
          style={{ flex: 1 }}
          displayOnly={!enabled}
          defaultValue={spreadingType}
          onChangeText={(itemValue) => {
            if (itemValue != SpreadingType.USERS) {
              setPickedUsers([]);
              setPickedServices([]);
              setPickedGeoSites([]);
              setPickedGroups([]);
            }

            setSpreadingType(parseInt(itemValue as any) as SpreadingType);
          }}
          data={spreadingTypeOptions}
          title={t('spreading') + '*' + (enabled ? '' : ' ' + t('available_soon'))}
        ></FormFieldPicker>
        {spreadingType == SpreadingType.SERVICES
          ? filteredServices.map((item) => {
              return (
                <View key={item.id} style={styles.userPickRow}>
                  <AppSwitch
                    onValueChange={(value) => setServiceSelected(item.id, value)}
                    value={pickedServices.includes(item.id)}
                  />
                  <AppText style={styles.switchText}>{item.libelle}</AppText>
                </View>
              );
            })
          : null}
        {spreadingType == SpreadingType.SITES
          ? filteredGeoSites.map((item) => {
              return (
                <View key={item.id} style={styles.userPickRow}>
                  <AppSwitch
                    onValueChange={(value) => setGeoSiteSelected(item.id, value)}
                    value={pickedGeoSites.includes(item.id)}
                  />
                  <AppText style={styles.switchText}>{item.geoSite}</AppText>
                </View>
              );
            })
          : null}
        {spreadingType == SpreadingType.SITES_AND_SERVICES
          ? filteredGeoSites.map((geoSite) => {
              return (
                <View key={geoSite.id}>
                  <TouchableOpacity
                    onPress={() =>
                      collapsedGeoSites.includes(geoSite.id)
                        ? setCollapsedGeoSites(collapsedGeoSites.filter((e) => e != geoSite.id))
                        : setCollapsedGeoSites([geoSite.id, ...collapsedGeoSites])
                    }
                  >
                    <AppText mode={Mode.BOLD} style={{ marginBottom: 5 }}>
                      {collapsedGeoSites.includes(geoSite.id) ? '▼' : '▲'} {geoSite.geoSite}
                    </AppText>
                  </TouchableOpacity>
                  {collapsedGeoSites.includes(geoSite.id) &&
                    filteredServices
                      .filter(
                        (service) =>
                          companyGeoSitesWithServices[geoSite.id]?.find((e) => e.service == service.id) !== undefined
                      )
                      .map((service) => {
                        return (
                          <View key={service.id} style={styles.userPickRow}>
                            <AppSwitch
                              onValueChange={(value) => {
                                if (value) {
                                  setPickedGeoSiteServices([
                                    ...pickedGeoSiteServices,
                                    { siteId: geoSite.id, serviceId: service.id },
                                  ]);
                                } else {
                                  setPickedGeoSiteServices(
                                    pickedGeoSiteServices.filter(
                                      (e) => e.siteId !== geoSite.id || e.serviceId !== service.id
                                    )
                                  );
                                }
                              }}
                              value={
                                pickedGeoSiteServices.find(
                                  (e) => e.siteId === geoSite.id && e.serviceId === service.id
                                ) !== undefined
                              }
                            />
                            <AppText style={styles.switchText}>{service.libelle}</AppText>
                          </View>
                        );
                      })}
                </View>
              );
            })
          : null}
        {spreadingType == SpreadingType.GROUPS
          ? filteredGroups.map((item) => {
              return (
                <View key={item.id} style={styles.userPickRow}>
                  <AppSwitch
                    onValueChange={(value) => setGroupSelected(item.id, value)}
                    value={pickedGroups.includes(item.id)}
                  />
                  <AppText style={styles.switchText}>{item.label}</AppText>
                </View>
              );
            })
          : null}
        {spreadingType == SpreadingType.USERS && (
          <>
            <UserAutocomplete onSelect={(user) => selectUser(user)} selectedIds={users.map((e) => e.id)} />
            {users.map((item) => (
              <View key={item.id} style={styles.profileRow}>
                <View style={styles.profileContainer}>
                  <ProfileRow user={item} allowEdit={false} />
                </View>
                <TouchableOpacity onPress={() => unselectUser(item)}>
                  <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} style={styles.trashIcon} />
                </TouchableOpacity>
              </View>
            ))}
          </>
        )}
      </View>
      {left ? (
        left
      ) : (
        <View
          style={{ flexDirection: 'column', ...(isMobile() ? { marginTop: 20 } : { marginLeft: 50, marginTop: 10 }) }}
        >
          <AppText style={{ fontSize: 12, marginBottom: 10, color: PRIMARY_COLOR }} mode={Mode.BOLD}>
            {t('chosen_audience')}
          </AppText>
          <AppText style={{ fontSize: 12, marginBottom: 10 }} mode={Mode.BOLD}>
            {audienceCount} contributeur{audienceCount > 1 ? 's' : ''}
          </AppText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  userPickRow: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
  },
  switchText: {
    marginLeft: 10,
  },
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileContainer: {
    flex: 1,
  },
  trashIcon: {
    marginLeft: 10,
    marginRight: 24,
  },
});
