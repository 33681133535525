import { Dispatch } from 'redux';
import { match } from 'ts-pattern';
import { RootState, UserSaved } from '../services/storage';
import { ActionType } from './action';

export type UserState = {
  user: UserSaved | null;
};

export const defaultUserState = {
  user: null as UserSaved | null,
};

export type UserActionType = {
  type: 'SET_USER';
  payload: UserSaved | null;
};

export function userReducer(state: RootState, action: ActionType): RootState {
  return match(action)
    .with({ type: 'SET_USER' }, (action) => ({
      ...state,
      user: {
        ...state.user,
        user: action.payload,
      },
    }))
    .otherwise(() => state);
}

export function setUser(user: UserSaved | null, dispatch: Dispatch<ActionType>) {
  dispatch({ type: 'SET_USER', payload: user });
}
