import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { CategoryChip } from '../../../../components/atomic/CategoryChip';
import { FeaturedLabel } from '../../../../components/atomic/FeaturedLabel';
import { HtmlText } from '../../../../components/atomic/HtmlText';
import SpreadingChipList from '../../../../components/atomic/SpreadingChipsList';
import { UserProfileOverlay } from '../../../../components/atomic/UserProfileOverlay';
import { UserSubRow } from '../../../../components/atomic/UserSubRow';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import { CommentList } from '../../../../components/molecules/CommentList';
import {
  getCompanyNewsDetail,
  setFavoriteNews,
  setInterestedNews,
} from '../../../../services/api/news.contributions.api';
import { t } from '../../../../services/translations';
import { alertInfo, getReadableDate, isMobile } from '../../../../services/utils';
import { ContributionType } from '../../../../types';
import { BigDetailRow, ViewDetailRow, BigDetailRowInline } from '../ContributionItemDetailScreen';
import { ContentWithImageHeaderScreen } from '../../../../components/containers/ContentWithImageHeaderScreen';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import FormLoading from '../../../../components/containers/FormLoading';
import NewsInterestedUserList from '../../../../components/molecules/NewsInterestedUserList';
import { setCompanyNewsView } from '../../../../services/api/interest.contributions.api';
import { useDispatch } from 'react-redux';
import { setCurrentContribution, setDetailContributionType, setForceBack, setIsNotif } from '../../../../store/action';

type NewsDetailScreenParams = { id: number; isNotif: boolean | undefined; isAdminView: boolean | undefined };
type NewsDetailScreenProps = DrawerScreenProps<any>;

export default function NewsDetailScreen({ route }: NewsDetailScreenProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const params = route.params as NewsDetailScreenParams;
  const navigation = useNavigation<NavigationProp<any>>();
  const [authorDetailsVisible, setAuthorDetailsVisible] = useState(false);
  const [showInterestedUserList, setShowInterestedUserList] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['news-details', params.id],
    () => getCompanyNewsDetail(user, params.id),
    { enabled: !!user }
  );

  const { mutateAsync: toggleInterest, isLoading: isLoadingToggleInterest } = useMutation({
    mutationFn: async () => {
      if (!data) {
        return;
      }

      return await setInterestedNews(user, data.id, { interested: !data.isInterested });
    },
    onError: () => {
      alertInfo(t('error_occurred'));
    },
    onSuccess: () => {
      refetch();
    },
  });

  const { mutateAsync: toggleFavorite, isLoading: isLoadingToggleFavorite } = useMutation({
    mutationFn: async () => {
      if (!data) {
        return;
      }

      return await setFavoriteNews(user, data.id, { favorite: !data.isFavorite });
    },
    onError: () => {
      alertInfo(t('error_occurred'));
    },
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (data) {
      setCompanyNewsView(user, data.mActuId).then(() => refetch());
    }
  }, [data, user, refetch]);

  useEffect(() => {
    if (!data) {
      return;
    }

    dispatch(setCurrentContribution(data));
    dispatch(setDetailContributionType(ContributionType.COMPANY_NEWS));

    if (route.params?.isNotif) {
      dispatch(setIsNotif(true));
    }

    if (route.params?.isAdminView) {
      dispatch(setForceBack(true));
    }
  }, [dispatch, data, route.params?.isNotif, route.params?.isAdminView]);

  if (isLoading || !data) {
    return <FormLoading />;
  }

  return (
    <DefaultBackground>
      <ContentWithImageHeaderScreen
        contribution={data}
        contributionType={ContributionType.COMPANY_NEWS}
        underImageContent={
          <View style={styles.underImageContent}>
            <UserSubRow user={data.author} withPicture={true} style={styles.userSubRow} />
            <AppText style={styles.underImageContentText}>
              {getReadableDate(data.visibleStartDate ? data.visibleStartDate : data.dateCreation, true)}
            </AppText>
          </View>
        }
        navigation={navigation}
      >
        <View style={styles.header}>
          <View style={styles.titleContainer}>
            <AppText mode={Mode.BOLD} style={styles.title}>
              {data.titre}
            </AppText>
            {data.featured && <FeaturedLabel />}
          </View>
          {!!data.category && (
            <View style={styles.categoryContainer}>
              <CategoryChip style={styles.category} category={data.category} />
            </View>
          )}
        </View>
        <BigDetailRow borderBottomWidth={0} content={<HtmlText text={data.texte} />} />
        <ViewDetailRow first title={t('view_count')} content={data.userSummaryViewCount} />
        <ViewDetailRow title={t('click_count')} content={data.userDetailViewCount} />
        <TouchableOpacity onPress={() => setShowInterestedUserList(!showInterestedUserList)}>
          <ViewDetailRow content={data.interestedUserCount} title={t('interested_people')} />
        </TouchableOpacity>
        {showInterestedUserList && <NewsInterestedUserList id={data.id} />}
        <BigDetailRowInline
          content={<SpreadingChipList item={data} monocolor={true} inline={true} />}
          title={t('who_can_see')}
        />
        <View style={styles.buttonsSection}>
          {!!data?.author && data?.author.aspNetUsers?.deletedAt === null && (
            <AppButton
              title={t('contact_original_poster')}
              textStyle={styles.infoText}
              type={Type.PRIMARY}
              onPress={() => setAuthorDetailsVisible(true)}
            />
          )}
          <AppButton
            title={t('i_am_interested')}
            icon="heart"
            textStyle={styles.infoText}
            type={data.isInterested ? Type.PRIMARY : Type.LIGHT}
            onPress={toggleInterest}
            enabled={!isLoadingToggleInterest}
          />
          <AppButton
            title={t('favorites')}
            icon="star"
            textStyle={styles.infoText}
            type={data.isFavorite ? Type.PRIMARY : Type.LIGHT}
            onPress={toggleFavorite}
            enabled={!isLoadingToggleFavorite}
          />
        </View>
        <UserProfileOverlay
          onBackdropPress={() => setAuthorDetailsVisible(false)}
          userProfileData={data.author}
          isVisible={authorDetailsVisible}
        />
        {!!data.commentsActivated && <CommentList newsId={data.mActuId} />}
      </ContentWithImageHeaderScreen>
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  underImageContent: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: isMobile() ? undefined : 540,
  },
  underImageContentText: { width: isMobile() ? undefined : 200, textAlign: 'right' },
  userSubRow: { flex: 1 },
  header: { padding: 18 },
  buttonsSection: {
    paddingVertical: 20,
    gap: 20,
  },
  infoText: {
    fontSize: 16,
  },
  titleContainer: { flexDirection: 'row', alignItems: 'center' },
  title: {
    flex: 1,
    fontSize: 20,
    textAlign: 'center',
  },
  categoryContainer: { flexDirection: 'row', justifyContent: 'center' },
  category: { marginLeft: 8, marginVertical: 16, height: 24 },
});
