import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { User } from '../../entities/User';
import { UserSubAdmin } from '../../entities/UserSubAdmin';
import { UserSubAdminMap } from '../../entities/UserSubAdminMap';
import { UserSaved } from '../storage';

export async function getSingleUserSubAdmins(token: string): Promise<UserSubAdmin[]> {
  return axios
    .get(`${apiUrlBase}UserSubAdmins/getSingle`, {
      headers: headers(token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getUserSubAdmins(user: UserSaved): Promise<UserSubAdminMap> {
  return axios
    .get(`${apiUrlBase}UserSubAdmins`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getUsersByUserSubAdmin(user: UserSaved, id: number, type: string): Promise<User[]> {
  return axios
    .get(`${apiUrlBase}UserSubAdmins/getUsers/${type}/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateValidationMap(user: UserSaved, id: number, type: string, userIds: number[]): Promise<any> {
  return axios
    .put(`${apiUrlBase}UserSubAdmins/${type}/${id}`, userIds, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
