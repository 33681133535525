import React from 'react';
import { Formik, FormikConfig, FormikValues } from 'formik';

export type FormProps<Values> = Omit<FormikConfig<Values>, 'children'> & {
  children: React.ReactNode;
};

export default function Form<Values extends FormikValues>({ children, ...rest }: FormProps<Values>) {
  return (
    <Formik<Values> {...rest}>
      <>{children}</>
    </Formik>
  );
}
