import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';
import { isMobile } from '../../services/utils';

export type HoverableProps = {
  children: ReactNode;
  onHoverChange: (value: boolean) => void;
};

export default function Hoverable({ children, onHoverChange }: HoverableProps) {
  if (isMobile()) {
    return <>{children}</>;
  } else {
    return (
      <div onMouseEnter={() => onHoverChange(true)} onMouseLeave={() => onHoverChange(false)}>
        {children}
      </div>
    );
  }
}
