export enum ContributionsCountByIntervalStatisticsType {
  MONTH,
  YEAR,
}

export interface ContributionsCountByIntervalStatisticsItem {
  date: string;
  count: number;
}

export interface ContributionsCountByIntervalStatistics {
  type: ContributionsCountByIntervalStatisticsType;
  items: ContributionsCountByIntervalStatisticsItem[];
}
