import React from 'react';
import { NEWS_PUBLICATION_MODE_ENABLED } from '../../services/features';
import { t } from '../../services/translations';
import { DateField, FormFieldPicker } from './FormField';

export interface PublicationModePickerProps {
  mode: string | null;
  visibleStartDate: string | undefined;
  visibleEndDate: string | undefined;
  onChangeMode: (mode: string | null) => void;
  onChangeStartDate: (visibleStartDate: string | undefined) => void;
  onChangeEndDate: (visibleEndDate: string | undefined) => void;
}

const PublicationModePicker = ({
  onChangeMode,
  onChangeEndDate,
  onChangeStartDate,
  mode,
  visibleEndDate,
  visibleStartDate,
}: PublicationModePickerProps) => {
  return (
    <>
      <FormFieldPicker
        title={t('publication_mode') + '*'}
        displayOnly={NEWS_PUBLICATION_MODE_ENABLED}
        defaultValue={mode}
        onChangeText={(mode) => {
          onChangeMode(mode);
          onChangeStartDate(
            mode === 'planned_with_start_date' || mode === 'planned_with_start_and_end_date'
              ? new Date().toISOString()
              : undefined
          );
          onChangeEndDate(mode === 'planned_with_start_and_end_date' ? new Date().toISOString() : undefined);
        }}
        data={[
          { label: t('immediate_posting'), value: 'immediate_posting' },
          { label: t('planned_with_start_date'), value: 'planned_with_start_date' },
          { label: t('planned_with_start_and_end_date'), value: 'planned_with_start_and_end_date' },
        ]}
      />
      {(mode === 'planned_with_start_date' || mode === 'planned_with_start_and_end_date') && (
        <DateField
          withTime={true}
          onChangeText={onChangeStartDate}
          defaultValue={visibleStartDate}
          title={t('visible_start_time')}
        />
      )}
      {mode === 'planned_with_start_and_end_date' && (
        <DateField
          withTime={true}
          onChangeText={onChangeEndDate}
          defaultValue={visibleEndDate}
          title={t('visible_end_time')}
        />
      )}
    </>
  );
};

export default PublicationModePicker;
