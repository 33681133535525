import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { createUserProfile } from '../../services/api/user.api';
import { setLoading } from '../../store/action';
import { _compressImage, alertInfo, isMobile } from '../../services/utils';
import { t } from '../../services/translations';
import Form from '../atomic/formik/Form';
import UserFormFields, { UserFormFieldsProps, schema } from './UserFormFields';
import { avatars } from '../atomic/formik/AvatarField';
import { Asset } from 'expo-asset';
import { Platform } from 'react-native';
import { ErrorReason } from '../../services/api/login';
import useLoggedUser from '../../hooks/useLoggedUser';

export type CreateUserFormProps = {
  onSubmitEnd?: (values: any) => Promise<void>;
} & UserFormFieldsProps;

export function CreateUserForm({ onSubmitEnd, ...rest }: CreateUserFormProps) {
  const user = useLoggedUser();
  const token = user?.token;
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      dispatch(setLoading(true));

      try {
        const avatarData = await getAvatarData(values.gender);
        await createUserProfile(
          token,
          {
            ...values,
            visibleZone: JSON.stringify(values.visibleZone),
          },
          avatarData
        );

        if (onSubmitEnd) {
          await onSubmitEnd(values);
        }
      } catch (err: any) {
        console.error(err);

        if (err.message === ErrorReason.EMAIL_ALREADY_REGISTERED) {
          alertInfo(t('email_already_registered'));
        } else {
          alertInfo(t('error_occurred'));
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    await mutation.mutateAsync(values);
    resetForm();
  };

  const isLoading = mutation.isLoading;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const initialValues = {
    email: '',
    firstName: '',
    lastName: '',
    service: undefined,
    geoSite: undefined,
    group: undefined,
    jobTitle: '',
    landlineNumber: '',
    mobileNumber: '',
    visibleZone: false,
    languageCode: undefined,
    photo: null,
    hideEmail: false,
    hidePhone: false,
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnMount>
      <UserFormFields {...rest} />
    </Form>
  );
}

async function getAvatarData(gender: 'F' | 'H') {
  const avatar = gender === 'F' ? avatars[0] : avatars[2];

  const [result] = await Asset.loadAsync(Platform.OS === 'web' ? avatar.file : (avatar.uri as any));
  const compressedResult = await _compressImage({ assets: [result] });

  if (Platform.OS === 'web') {
    let blob = await fetch(compressedResult.uri).then((r) => r.blob());
    let metadata = {
      type: 'image/png',
    };

    let file = new File([blob], 'mypicture.png', metadata);
    file.uri = result.uri;

    return file;
  } else {
    return {
      name: 'mypicture.jpg',
      fileName: 'mypicture.jpg',
      type: (compressedResult as any).type || 'image/png',
      height: compressedResult.height,
      width: compressedResult.width,
      uri: compressedResult.uri,
    };
  }
}
