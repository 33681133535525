import React, { useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { urlBase } from '../../../configuration';
import useLoggedUser from '../../hooks/useLoggedUser';
import { acceptCGU } from '../../services/api/user.api';
import { t } from '../../services/translations';
import { openURL } from '../../services/utils';
import { Space1, Space2 } from '../../styles/spaces';
import { FormButtons } from '../containers/FormButtons';
import { AppButton, Type } from './AppButton';
import { AppCheckbox } from './AppCheckbox';
import { AppModalOverlay } from './AppModalOverlay';
import { HypertextLink } from './HypertextLink';
import { setUser } from '../../store/user';

export default () => {
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(true);
  const [hasCGUBeenApproved, setCGUApproved] = useState(false);
  const [hasRGDPConsent, setRGPDConsent] = useState(false);

  const accept = () => {
    acceptCGU(user).then(() => {
      setVisible(false);
      user.hasAcceptedCGU = true;
      setUser(user, dispatch);
    });
  };

  return (
    <AppModalOverlay
      isVisible={visible}
      overlayStyle={{
        flexDirection: 'column',
        width: 350,
        padding: 0,
        borderRadius: 5,
        overflow: 'hidden',
      }}
    >
      <ScrollView
        contentContainerStyle={{ alignItems: 'center', justifyContent: 'flex-start', padding: 0, flexGrow: 1 }}
        bounces={false}
      >
        <View style={Platform.OS == 'web' ? { maxWidth: 500, margin: 20 } : { margin: 20 }}>
          <AppCheckbox
            value={hasCGUBeenApproved}
            onValueChange={setCGUApproved}
            title={
              <>
                {t('read_and_accept') + ' '}
                <HypertextLink
                  onPress={() => {
                    openURL(`${urlBase}assets/documents/CGU-KonecTeam_2022.pdf`);
                  }}
                  text={t('cgu')}
                />
              </>
            }
          />
          <Space1 />
          <AppCheckbox
            value={hasRGDPConsent}
            onValueChange={setRGPDConsent}
            title={
              <>
                {t('rgpd_text_1') + ' '}
                <HypertextLink
                  onPress={() => {
                    openURL(`${urlBase}assets/documents/Politique-de-confidentialité-2022.pdf`);
                  }}
                  text={t('privacy')}
                />
                .
              </>
            }
            subtitle={t('rgpd_text_2')}
          />
          <FormButtons>
            <Space2 />
            <AppButton
              title={"J'accepte"}
              type={Type.PRIMARY}
              enabled={hasCGUBeenApproved && hasRGDPConsent}
              onPress={accept}
            />
          </FormButtons>
        </View>
      </ScrollView>
    </AppModalOverlay>
  );
};
