import React, { useEffect, useState } from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import useUnsplash from '../../hooks/useUnsplash';
import { t } from '../../services/translations';
import { PickPhotoOrDocumentResult, PickPhotoResult } from '../../services/utils';
import { ALERT_COLOR, DARK_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { MediasInfos } from '../../utils/Medias';
import { AppModalOverlay } from '../atomic/AppModalOverlay';
import AppText, { Mode } from '../atomic/AppText';
import { TextFormField } from '../atomic/FormField';
import { PhotoPicker } from '../atomic/PhotoPicker';

export interface PhotoFormFieldProps {
  defaultPhoto?: ImageSourcePropType;
  photo: PickPhotoOrDocumentResult | null | undefined;
  videoUrl: string | null | undefined;
  allowPdf?: boolean;
  onPhotoChanged: (photo: PickPhotoOrDocumentResult | null) => void;
  onVideoUrlChanged: (videoUrl: string | null) => void;
}

export default function PhotoFormField({
  defaultPhoto,
  photo,
  videoUrl,
  allowPdf,
  onPhotoChanged,
  onVideoUrlChanged,
}: PhotoFormFieldProps) {
  const { images, isLoading, notifyImageDownload, searchImages, fetchNext } = useUnsplash();
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [invalidVideoUrl, setInvalidVideoUrl] = useState(false);

  useEffect(() => {
    setInvalidVideoUrl(false);

    if (videoUrl) {
      for (const mediaName in MediasInfos) {
        const mediaInfo = MediasInfos[mediaName];
        if (mediaInfo.isMedia(videoUrl)) {
          setShowVideoPopup(false);
          onPhotoChanged({ uri: mediaInfo.imagePreview(videoUrl) } as PickPhotoResult);
        } else {
          setInvalidVideoUrl(true);
        }
      }
    }
  }, [videoUrl, onPhotoChanged]);

  return (
    <View style={styles.container}>
      <AppText style={styles.acceptedFormatsText}>{t('accepted_formats')}</AppText>
      <AppText style={styles.idealFormatText}>{`${t('ideal_format')} : 540 x 540 px`}</AppText>
      <PhotoPicker
        defaultPhoto={defaultPhoto}
        photo={photo}
        setPhoto={(value) => {
          onPhotoChanged(value);
          onVideoUrlChanged(null);
        }}
        images={images}
        isLoading={isLoading}
        extraOption={[
          {
            label: t('choose_a_video'),
            icon: 'video',
            onPress: () => setShowVideoPopup(true),
          },
        ]}
        allowPdf={allowPdf}
        onPress={notifyImageDownload}
        searchImages={searchImages}
        fetchNext={fetchNext}
      />
      <AppModalOverlay isVisible={showVideoPopup} onBackdropPress={() => setShowVideoPopup(false)}>
        <AppText mode={Mode.BOLD} style={styles.videoModalTitle}>
          {t('insert_video_link')}
        </AppText>
        <TextFormField
          placeholder={t('link_to_youtube')}
          style={styles.videoModalTextField}
          value={videoUrl || undefined}
          onChangeText={onVideoUrlChanged}
          variant="contained"
        />
        <AppText style={styles.videoModalErrorLabel}>{invalidVideoUrl ? t('invalid_link') : ' '}</AppText>
      </AppModalOverlay>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 20,
  },
  acceptedFormatsText: {
    fontFamily: FONT_FAMILY_DEFAULT.light,
    fontSize: 11,
    color: DARK_GRAY,
    paddingBottom: 10,
  },
  idealFormatText: {
    paddingBottom: 30,
    fontFamily: FONT_FAMILY_DEFAULT.light,
    fontSize: 11,
    color: DARK_GRAY,
  },
  videoModalTitle: {
    textAlign: 'center',
    color: PRIMARY_COLOR,
  },
  videoModalTextField: {
    width: 280,
    paddingTop: 16,
  },
  videoModalErrorLabel: {
    color: ALERT_COLOR,
    fontSize: 12,
    paddingLeft: 8,
  },
});
