import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';

export function useDeviceSize() {
  let screenWidth = useWindowDimensions().width;

  const deviceSize = useMemo(() => {
    if (screenWidth < 600) {
      return 'extra-small';
    } else if (screenWidth < 768) {
      return 'small';
    } else if (screenWidth < 992) {
      return 'medium';
    } else if (screenWidth < 1200) {
      return 'large';
    } else {
      return 'extra-large';
    }
  }, [screenWidth]);

  const isSmallDevice = deviceSize == 'extra-small' || deviceSize == 'small';
  const isMediumDevice = deviceSize == 'medium';
  const isLargeDevice = deviceSize == 'extra-large' || deviceSize == 'large';
  const isSmallMediumDevice = deviceSize == 'extra-small' || deviceSize == 'small' || deviceSize == 'medium';

  return {
    deviceSize,
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isSmallMediumDevice,
  };
}
