import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { EntrepriseGroup } from '../../entities/EntrepriseGroup';
import { UserSaved } from '../storage';
import { EntrepriseGroupCreate } from '../../entities/EntrepriseGroupCreate';
import { EntrepriseGroupUpdate } from '../../entities/EntrepriseGroupUpdate';

export async function getCompanyGroups(user: UserSaved): Promise<EntrepriseGroup[]> {
  return axios
    .get(`${apiUrlBase}EntrepriseGroup`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getCompanyGroupDetails(user: UserSaved, id: number): Promise<EntrepriseGroup> {
  return axios
    .get(`${apiUrlBase}EntrepriseGroup/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function createCompanyGroup(user: UserSaved, body: EntrepriseGroupCreate): Promise<EntrepriseGroup> {
  return axios
    .post(`${apiUrlBase}EntrepriseGroup`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function updateCompanyGroup(
  user: UserSaved,
  id: number,
  body: EntrepriseGroupUpdate
): Promise<EntrepriseGroup> {
  return axios
    .put(`${apiUrlBase}EntrepriseGroup/${id}`, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function deleteCompanyGroup(user: UserSaved, id: number): Promise<EntrepriseGroup> {
  return axios
    .delete(`${apiUrlBase}EntrepriseGroup/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
