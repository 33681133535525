import { useQuery } from '@tanstack/react-query';
import { listCustomModules } from '../services/api/custom.module.api';
import useLoggedUser from './useLoggedUser';

export default function useCustomModules() {
  let loggedUser = useLoggedUser();

  const {
    data: customModules,
    isFetched: isCustomModulesFetched,
    isError: isCustomModulesError,
  } = useQuery(['customModules'], () => listCustomModules(loggedUser), {
    // Refetch every minute
    refetchInterval: 60000,
  });

  return { customModules, isCustomModulesFetched, isCustomModulesError };
}
