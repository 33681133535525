import React, { useState } from 'react';
import { FlatList, FlatListProps, View } from 'react-native';

export type ResponsiveFlatListProps<TItem> = FlatListProps<TItem>;

export function ResponsiveFlatList<TItem>({
  renderItem,
  columnWrapperStyle,
  numColumns,
  ...rest
}: FlatListProps<TItem>) {
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const internNumColumns = numColumns || (containerWidth && Math.max(1, Math.floor(containerWidth / 300)));

  return (
    <View
      onLayout={(event) => {
        setContainerWidth(event.nativeEvent.layout.width);
      }}
      style={{ flexDirection: 'row', flex: 1 }}
    >
      {internNumColumns && (
        <FlatList<TItem>
          key={internNumColumns || 0}
          columnWrapperStyle={internNumColumns && internNumColumns > 1 ? columnWrapperStyle : undefined}
          numColumns={internNumColumns}
          renderItem={(item) => {
            return (
              <View
                style={{
                  flex: 1 / internNumColumns,
                }}
              >
                {renderItem && renderItem(item)}
              </View>
            );
          }}
          {...rest}
        />
      )}
    </View>
  );
}
