import React from 'react';
import { useField } from 'formik';
import AppRadioGroup, { AppRadioGroupProps } from '../AppRadioGroup';

export type RadioGroupFieldProps<T> = Omit<Omit<AppRadioGroupProps<T>, 'value'>, 'onChange'> & {
  name: string;
};

export default function RadioGroupField<T>({ name, ...rest }: RadioGroupFieldProps<T>) {
  const [field, , helper] = useField(name);

  return <AppRadioGroup {...rest} value={field.value} onChange={helper.setValue} />;
}
