import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessagingThread } from '../entities/MessagingThread';
import { RootState } from '../services/storage';
import { MessagingState, fetchNextMessagingThreads, resetMessagingThreads } from '../store/messaging';
import useLoggedUser from './useLoggedUser';

export type MessagingThreadResult = {
  threads: MessagingThread[];
  nextCursor: string | null;
  isLoading: boolean;
  fetchNext: () => Promise<void>;
  refresh: () => Promise<void>;
};

export default function useMessagingThreads(searchFilter?: string | null): MessagingThreadResult {
  const user = useLoggedUser();
  const state = useSelector<RootState, MessagingState>((state) => state.messaging);
  const dispatch = useDispatch();

  const fetchNext = useCallback(async () => {
    if (!state.nextCursor) {
      return;
    }

    fetchNextMessagingThreads(dispatch, user, state.nextCursor, searchFilter);
  }, [dispatch, user, state.nextCursor, searchFilter]);

  const refresh = useCallback(async () => {
    resetMessagingThreads(dispatch, user, searchFilter);
  }, [dispatch, user, searchFilter]);

  // Initialize by fetching first
  useEffect(() => {
    refresh();
  }, [searchFilter]);

  return {
    ...state,
    fetchNext,
    refresh,
  };
}
