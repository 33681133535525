import React from 'react';
import { Login } from '../screens/guest/Login';
import { t } from '../services/translations';
import { ValidationCode } from '../screens/guest/ValidationCode';
import { Registration } from '../screens/guest/Registration';
import { createStackNavigator } from '@react-navigation/stack';
import { NoProfessionalAddress } from '../screens/guest/NoProfessionalAddress';
import { ScreenNames } from '../ScreenNames';
import BackIcon from '../components/atomic/BackIcon';
import { ResetPasswordSendCode } from '../screens/guest/ResetPasswordSendCode';
import { ResetPassword } from '../screens/guest/ResetPassword';
import { ResetPasswordSendCodeSuccess } from '../screens/guest/ResetPasswordSendCodeSuccess';

const Stack = createStackNavigator();

export default function GuestRoute() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false, headerBackImage: BackIcon }}>
      <Stack.Screen name={ScreenNames.Login} component={Login} options={{ title: t('login') }} />
      <Stack.Screen name={ScreenNames.Registration} component={Registration} options={{ title: t('registration') }} />
      <Stack.Screen
        name={ScreenNames.ResetPasswordSendCode}
        component={ResetPasswordSendCode}
        options={{ title: t('forgotten_password') }}
      />
      <Stack.Screen
        name={ScreenNames.ResetPasswordSendCodeSuccess}
        component={ResetPasswordSendCodeSuccess}
        options={{ title: t('forgotten_password') }}
      />
      <Stack.Screen
        name={ScreenNames.ResetPassword}
        component={ResetPassword}
        options={{ title: t('forgotten_password') }}
      />
      <Stack.Screen
        name={ScreenNames.NoProfessionalAddress}
        component={NoProfessionalAddress}
        options={{ title: t('no_professional_address') }}
      />
      <Stack.Screen
        name={ScreenNames.ValidationCode}
        component={ValidationCode}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}
