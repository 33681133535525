const isDev = false;
const dev = isDev ? '-dev' : '';

const localUrl = undefined; // 'clean-islands-dig-90-26-7-161.loca.lt'
// const localUrl = '192.168.45.74:5000';
// const localUrl = 'localhost:5050';

export const apiUrlBase = localUrl !== undefined ? `http://${localUrl}/api/` : `https://api${dev}.konecteam.fr/api/`;
export const urlBase = localUrl !== undefined ? `http://${localUrl}/` : `https://api${dev}.konecteam.fr/`;
export const domainUrl = isDev ? 'https://preprod.konecteam.fr/' : 'https://myapp.konecteam.fr/';
export const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.konecteam_app';
export const appleStoreUrl = 'https://apps.apple.com/fr/app/konecteam/id1551801133';
export const apiTimeZone = 'Europe/Paris';
export const unsplashAccessKey = 'U1ApE3vhb7jJ4NAZxUQVtGSzZulMXndAZzDW1n02JAg';
export const apiVersion = '1.4';
export const vapidKey = 'BJUHq-QEq-iPiLU60DNG4BL3UktLsETUHLZtnGUkP5x7vseVLZ3OROVC7NF7nlkT3tslkMjckajfxGqvAeKN3dI';
export const linkingPrefixes = [
  'https://preprod.konecteam.fr',
  'http://preprod.konecteam.fr',
  'https://myapp.konecteam.fr',
  'http://myapp.konecteam.fr',
  'https://myapp.konecteam.com',
  'http://myapp.konecteam.com',
  'https://app.konecteam.fr',
  'http://app.konecteam.fr',
];
