import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { getPictureSource, isAnonymous } from '../../services/api/helper.api';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { DARK_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { ContributionType } from '../../types';
import { getReadableDate } from '../../services/utils';
import AppText from './AppText';
import { UserSubRow } from './UserSubRow';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { match } from 'ts-pattern';
import { DocumentsMainScreenItem } from '../../screens/loggedIn/contribution/documents/DocumentsMainScreen';
import ListItemRow from './ListItemRow';

export type DocumentItemRowProps = {
  item: DocumentsMainScreenItem;
  actions: {
    label: string;
    renderIcon: (size: number) => JSX.Element;
    onPress: () => void | 0 | null;
  }[];
  isAdminView: boolean;
  onPress?: () => void;
  onSelect?: () => void;
};

export default function DocumentItemRow({ item, actions, onPress, onSelect }: DocumentItemRowProps) {
  let imageId = (item as any).imageId;

  return (
    <ListItemRow
      imageId={imageId}
      defaultImage={
        item.type === 'document' ? (
          <FontAwesome name="file-o" size={80} color={DARK_GRAY} style={styles.pictureContainer} />
        ) : (
          <FontAwesome name="folder-o" size={80} color={DARK_GRAY} style={styles.pictureContainer} />
        )
      }
      title={item.title}
      date={item.dateCreated}
      user={item.user}
      left={match(actions.length)
        .with(0, () => <></>)
        .with(1, () => (
          <TouchableOpacity style={styles.downloadIcon} onPress={actions[0].onPress}>
            {actions[0].renderIcon(30)}
          </TouchableOpacity>
        ))
        .otherwise(() => (
          <TouchableOpacity style={styles.downloadIcon} onPress={onSelect}>
            <Feather size={30} name="more-horizontal" color={PRIMARY_COLOR} />
          </TouchableOpacity>
        ))}
      onPress={onPress}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    marginVertical: 10,
  },
  pictureContainer: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 20,
  },
  contentContainer: {
    flex: 1,
  },
  topContainer: {
    justifyContent: 'space-between',
    flex: 1,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    paddingTop: 16,
  },
  title: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  dateText: {
    flex: 1,
    fontSize: 12,
    fontStyle: 'italic',
    color: 'black',
    paddingRight: 7,
  },
  downloadIcon: {
    paddingHorizontal: 5,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
