import React, { useCallback } from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useField } from 'formik';
import UserAutocomplete from '../UserAutocomplete';
import { FormField } from '../FormField';
import { t } from '../../../services/translations';
import { User } from '../../../entities/User';
import { ProfileRow } from '../ProfileRow';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type UserAutocompleteFieldProps = {
  name: string;
  lockedUsers?: number[];
};

export function UserAutocompleteField({ name, lockedUsers }: UserAutocompleteFieldProps) {
  const [field, , helper] = useField(name);
  const users: User[] = field.value || [];

  const removeUser = useCallback((user: User) => helper.setValue(users.filter((e) => e.id !== user.id)), [users]);

  return (
    <>
      <UserAutocomplete
        title={t('add_group_member')}
        onSelect={(user) => helper.setValue([...users, user])}
        selectedIds={Array.from(users.map((e) => e.id))}
      />
      <FormField title={t('group_members')} children={undefined} />
      <FlatList
        data={users}
        renderItem={(u) => (
          <View style={styles.profileRow}>
            <View style={styles.profileContainer}>
              <ProfileRow user={u.item} allowEdit={false} />
            </View>

            {(!lockedUsers || !lockedUsers.includes(u.item.id)) && (
              <TouchableOpacity onPress={() => removeUser(u.item)}>
                <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} style={styles.trashIcon} />
              </TouchableOpacity>
            )}
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    </>
  );
}

const styles = StyleSheet.create({
  profileRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  profileContainer: {
    flex: 1,
  },
  trashIcon: {
    marginLeft: 10,
    marginRight: 24,
  },
});
