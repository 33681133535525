import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { ContributionType, User } from '../../types';
import { objectToFormData } from '../formUtil';
import { UserSaved } from '../storage';
import { alertInfo } from '../utils';
import { sendEvent, updateEvent } from './event.contributions.api';
import { sendTraining, updateTraining } from './formation.contributions.api';
import { addPhotoToFormData, getFormDataByContributionType } from './formData.api';
import { GetContributionsMode } from './getContributionsMode';
import { _getPrefixByContributionType, _postPrefixByContributionType } from './helper.api';
import { publishNews, updateNews } from './news.contributions.api';
import { Paginated } from '../../entities/Paginated';

if (__DEV__) {
  axios.interceptors.response.use((response) => {
    return response;
  });
}

export async function report(user: UserSaved, contributionId: number, title: string) {
  return axios
    .post(
      apiUrlBase + 'ContributionSignalements',
      {
        contributionId,
        userIdSignaler: user.id,
        title,
      },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      return response.data;
    });
}

export async function unreport(user: UserSaved, contributionId: number) {
  return axios
    .delete(apiUrlBase + 'ContributionSignalements/' + contributionId, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response.data;
    });
}

export type GetContributionsListOptions = {
  favorites?: boolean;
  filterByService?: boolean;
  filterByGeoSite?: boolean;
  categoryId?: number;
};

export async function getContributionsList(
  user: UserSaved,
  mode: GetContributionsMode,
  contributionType: ContributionType,
  search: string,
  cursor: string | null,
  limit?: number,
  options: GetContributionsListOptions = {}
) {
  let url = '';

  if (contributionType === ContributionType.ALL && mode != GetContributionsMode.admin_all) {
    url = apiUrlBase + 'Contributions/view/user/' + user.id + '/paginated';
  } else if (contributionType === ContributionType.COMPANY_NEWS) {
    switch (mode) {
      case GetContributionsMode.all:
        url = apiUrlBase + 'MActu/user/' + user.id + '/paginated';
        break;
      case GetContributionsMode.toApprove:
        url = apiUrlBase + 'MActu/avalider/' + user.entrepriseId + '/paginated';
        break;
      default:
      case GetContributionsMode.approved:
        url = apiUrlBase + 'MActu/valider/' + user.entrepriseId + '/paginated';
        break;
    }
  } else {
    let urlPrefixType = GetContributionsMode.admin_all == mode ? '' : _getPrefixByContributionType(contributionType);
    switch (mode) {
      case GetContributionsMode.admin_all:
        url = apiUrlBase + 'Contributions/view/entreprise/' + user.id + '/paginated';
        break;
      case GetContributionsMode.all:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/view/user/' + user.id + '/paginated';
        break;
      case GetContributionsMode.mine:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/user/' + user.id + '/paginated';
        break;
      case GetContributionsMode.interested:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/interest/user/' + user.id + '/paginated';
        break;
      case GetContributionsMode.requests:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/view/demande/' + user.id + '/paginated';
        break;
      case GetContributionsMode.offers:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/view/offre/' + user.id + '/paginated';
        break;
      case GetContributionsMode.participation:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/view/participation/' + user.id + '/paginated';
        break;
      case GetContributionsMode.toApprove:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/avalider/entreprise/' + user.entrepriseId + '/paginated';
        break;
      case GetContributionsMode.reviewed:
      case GetContributionsMode.approved:
        url = apiUrlBase + 'Contribution' + urlPrefixType + '/valide/entreprise/' + user.entrepriseId + '/paginated';
        break;
    }
  }

  return axios
    .get(url, {
      headers: headers(user.token),
      params: { search: search && search.length > 0 ? search : undefined, cursor, limit, ...options },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    });
}

export async function getContributionItem(user: UserSaved, itemId: number, contributionType: ContributionType) {
  let urlPrefixType = _getPrefixByContributionType(contributionType);
  let url = apiUrlBase + 'Contribution' + urlPrefixType + '/detail/' + itemId + '/user/' + user.id;
  if (contributionType === ContributionType.SUGGESTION) {
    url = apiUrlBase + 'Contribution' + urlPrefixType + '/' + itemId;
  }

  if (contributionType === ContributionType.DOCUMENT) {
    url = apiUrlBase + 'Contribution' + urlPrefixType + '/document/' + itemId;
  }

  if (contributionType === ContributionType.COMPANY_NEWS) {
    url = apiUrlBase + 'MActu/detail/' + itemId;
  }

  if (contributionType === ContributionType.EVENT || contributionType === ContributionType.FORMATION) {
    url = apiUrlBase + 'Contribution' + urlPrefixType + '/' + itemId;
  }

  return axios
    .get(url, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => alertInfo(e));
}

async function postWithPhoto(url: string, formData, photo, token: string) {
  if (photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, formData);
  }

  return axios
    .post(url, formData, {
      headers: headers(token, 'multipart/form-data'),
    })
    .then((response) => response.data.json);
}

async function post(url: string, body: { [key: string]: any }, token: string) {
  return axios.post(url, body, {
    headers: headers(token),
  });
}

async function put(url: string, body: { [key: string]: any }, token: string) {
  return axios.put(url, body, {
    headers: headers(token),
  });
}

async function putWithPhoto(url, formData, photo, token) {
  if (photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, formData);
  }

  return axios
    .put(url, formData, {
      headers: photo === null ? headers(token) : headers(token, 'multipart/form-data'),
    })
    .then((response) => response.data.json);
}

export async function sendContribution(item, token, users, photo, contributionType: ContributionType) {
  if (contributionType === ContributionType.EVENT) {
    return sendEvent(item, token, users, photo);
  }
  if (contributionType === ContributionType.FORMATION) {
    return sendTraining(item, token, users, photo);
  }
  if (contributionType === ContributionType.COMPANY_NEWS) {
    return publishNews({ ...item }, photo);
  }

  let newUserData = getFormDataByContributionType(item, contributionType);

  const newUserFormData = objectToFormData(newUserData);

  if (contributionType === ContributionType.MUTUAL_AID) {
    return postWithPhoto(
      apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType),
      newUserFormData,
      photo,
      token
    );
  }
  if (contributionType === ContributionType.CARPOOL) {
    return post(apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType), newUserData, token);
  } else {
    return postWithPhoto(
      apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType),
      newUserFormData,
      photo,
      token
    );
  }
}

export async function sendUpdateContribution(
  id: number,
  item,
  token: string,
  users,
  photo,
  contributionType: ContributionType
) {
  if (contributionType === ContributionType.EVENT) {
    return updateEvent(id, item, token, users, photo);
  }
  if (contributionType === ContributionType.FORMATION) {
    return updateTraining(id, item, token, users, photo);
  }
  if (contributionType === ContributionType.COMPANY_NEWS) {
    return updateNews(id, item, photo);
  }

  let newUserData = getFormDataByContributionType(item, contributionType);
  const newUserFormData = objectToFormData(newUserData);

  if (contributionType === ContributionType.MUTUAL_AID) {
    return putWithPhoto(
      apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType) + '/update/' + id,
      newUserFormData,
      photo,
      token
    );
  }

  if (contributionType === ContributionType.CARPOOL) {
    return put(
      apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType) + '/update/' + id,
      newUserData,
      token
    );
  } else {
    let formData = newUserFormData;

    return putWithPhoto(
      apiUrlBase + 'Contributions/' + _postPrefixByContributionType(contributionType) + '/update/' + id,
      formData,
      photo,
      token
    );
  }
}

export async function removeContribution(user: UserSaved, contributionId: number) {
  return axios
    .delete(apiUrlBase + 'Contributions/' + contributionId, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}

export async function setApproveContribution(
  user: UserSaved,
  id: number,
  approved: boolean,
  contributionType: ContributionType
) {
  let urlPrefixType = _getPrefixByContributionType(contributionType);
  if (approved) {
    return axios
      .put(
        apiUrlBase + 'Contribution' + urlPrefixType + '/valider/' + id,
        {},
        {
          headers: headers(user.token),
        }
      )
      .then((response) => {
        return response;
      });
  } else {
    const refuseSegment = contributionType === ContributionType.SURVEY ? 'decline' : 'refuser';

    return axios
      .put(
        `${apiUrlBase}Contribution${urlPrefixType}/${refuseSegment}/${id}`,
        {},
        {
          headers: headers(user.token),
        }
      )
      .then((response) => {
        return response;
      });
  }
}

export async function listContributionInterestedUsers(
  user: UserSaved,
  contributionId: number,
  cursor: string | null
): Promise<Paginated<User, string>> {
  return axios
    .get(`${apiUrlBase}Contributions/${contributionId}/interested`, {
      headers: headers(user.token),
      params: { cursor },
    })
    .then((d) => d.data)
    .catch(() => []);
}
