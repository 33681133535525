import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ContributionPreviewItem, ContributionPreviewItemProps } from './ContributionItemSquare';
import { t } from '../../services/translations';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type ContributionValidatedItemProps = ContributionPreviewItemProps & {
  onEdit: () => void;
  onRemove: () => void;
};

export default function ContributionValidatedItem({ onEdit, onRemove, ...rest }: ContributionValidatedItemProps) {
  return (
    <View>
      <ContributionPreviewItem {...rest} />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1, marginTop: 5, marginBottom: 5 }}>
          <TouchableOpacity onPress={onEdit}>
            <FontAwesome6 size={18} name="edit" color={PRIMARY_COLOR} />
          </TouchableOpacity>
          <TwoActionsGenericPopup
            title={t('wish_to_remove')}
            button1Title={t('yes')}
            button2Title={t('no')}
            onPressButton1={onRemove}
            onPressButton2={() => {}}
          >
            <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
          </TwoActionsGenericPopup>
        </View>
      </View>
    </View>
  );
}
