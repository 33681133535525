import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { View } from 'react-native';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import {
  getCustomModuleContributionsDetails,
  updateCustomModuleContributions,
} from '../../../../services/api/custom.module.api';
import { ContributionCustomModuleCreate } from '../../../../entities/ContributionCustomModuleCreate';
import CustomModuleContributionFormFields, { schema } from './CustomModuleContributionFormFields';
import { getPictureURI } from '../../../../services/api/helper.api';
import { newsImage } from '../../../../services/assets';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export type UpdateCustomModuleContributionScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function UpdateCustomModuleContributionScreen({ route }: UpdateCustomModuleContributionScreenProps) {
  const user = useLoggedUser();
  const id = parseInt(route.params.id);
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingList,
    isFetched,
    isError,
  } = useQuery(['customContributionDetails', id], () => getCustomModuleContributionsDetails(user, id), {
    cacheTime: 0,
  });

  const mutation = useMutation({
    mutationFn: async (values: ContributionCustomModuleCreate) => {
      await updateCustomModuleContributions(user, id, { ...values });
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  const isLoading = isLoadingList || mutation.isLoading;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  const initialValues = useMemo(
    () => ({
      title: data?.title,
      description: data?.description,
      commentsActivated: data?.contribution?.commentsActivated,
      notifActivated: data?.contribution?.notifActivated,
      emailActivated: data?.contribution?.emailActivated,
      spreadingType: data?.contribution?.spreadingType,
      userIds: data?.contribution?.userTargets.map((e) => e.userId),
      entrepriseServiceIds: data?.contribution?.entrepriseServices.map((e) => e.entrepriseServiceId),
      entrepriseSiteGeoIds: data?.contribution?.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
      entrepriseGroupIds: data?.contribution?.entrepriseGroups.map((e) => e.entrepriseGroupId),
    }),
    [data]
  );

  return isFetched && data ? (
    <Form
      initialValues={initialValues}
      onSubmit={mutation.mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <CustomModuleContributionFormFields defaultPhoto={data.imageId ? getPictureURI(data.imageId) : newsImage} />
    </Form>
  ) : (
    <View />
  );
}
