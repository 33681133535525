import { ScreenPlaylist } from './ScreenPlaylist';
import { ScreenPlaylistMedia } from './ScreenPlaylistMedia';

export enum ScreenPlaylistScreenPlaylistItemType {
  Media,
  Meteo,
  Facebook,
  LinkedIn,
}

export interface ScreenPlaylistScreenPlaylistItem {
  id: number;
  order: number;
  delay: number;
  type: ScreenPlaylistScreenPlaylistItemType;
  screenPlaylistId: number;
  screenPlaylistMediaId?: number;
  screenPlaylist?: ScreenPlaylist;
  screenPlaylistMedia?: ScreenPlaylistMedia;
}
