import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { addAlpha, lightenDarkenColor } from '../../utils/Color';
import { vh } from '../../utils/Utils';

export type ColoredCardProps = {
  children: React.ReactNode;
  color: string;
  style?: StyleProp<ViewStyle>;
};

export default function ColoredCard({ children, color, style }: ColoredCardProps) {
  // Calculate the color 30% darker
  const baseColor = addAlpha(color, 0.8);
  const darkerColor = addAlpha(lightenDarkenColor(color, 30), 0.8);

  return (
    <LinearGradient
      colors={[baseColor, darkerColor]}
      start={{ x: 0, y: 0 }}
      end={{ x: 1, y: 1 }}
      style={[styles.container, style]}
    >
      {children}
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: vh(1),
    padding: vh(2),
    elevation: 2,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 8,
    overflow: 'hidden',
  },
});
