import React, { ReactNode } from 'react';
import { SafeAreaView, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { WHITE } from '../../styles/appColor';

export type MaxWidthScreenProps = {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
};

export function MaxWidthScreen({ style, children }: MaxWidthScreenProps) {
  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.content, style]}>{children}</View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: WHITE,
  },
  content: {
    width: '100%',
    flex: 1,
    maxWidth: 480,
    padding: 20,
  },
});
