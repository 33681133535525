import React from 'react';
import { Field, useField } from 'formik';
import { AppTextInput, AppTextInputProps } from '../AppTextInput';
import { FormField } from '../FormField';
import AppText, { Mode } from '../AppText';

export type TextFieldProps = Omit<Omit<Omit<AppTextInputProps, 'value'>, 'onChangeText'>, 'onBlur'> & {
  name: string;
  title: string;
  required?: boolean;
};

export default function TextField({ name, title, required, style, ...rest }: TextFieldProps) {
  const [field, meta, helper] = useField(name);

  return (
    <FormField style={style} title={required ? `${title}*` : title}>
      <Field
        {...rest}
        value={field.value}
        onChangeText={helper.setValue}
        onBlur={() => helper.setTouched(true)}
        as={AppTextInput}
      />
      {meta.touched && meta.error && <AppText mode={Mode.ERROR}>{meta.error}</AppText>}
    </FormField>
  );
}
