import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { UserSaved } from '../storage';

export async function getMinimumVersion(): Promise<string> {
  return axios
    .get(`${apiUrlBase}version/minimum`, {
      headers: headers(),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
