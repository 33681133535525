import { Platform } from 'react-native';
import { ContributionType } from '../../types';
import {
  SellPostData,
  SpreadingData,
  ActivityPostData,
  MutualAidPostData,
  CarPoolPostData,
  LostPropertyPostData,
  EventPostData,
} from '../../models/Contribution';

export function addPhotoToFormData(photo, formData) {
  if (Platform.OS === 'android' && !photo.type) {
    return;
  }

  formData.append('files', photo);
}

export function getSpreadingFormData(data: SpreadingData) {
  return {
    userIds: Array.from(data.users),
    users: Array.from(data.users),
    entrepriseServiceIds: Array.from(data.services),
    entrepriseSiteGeoIds: Array.from(data.siteGeos),
    entrepriseGroupIds: Array.from(data.groups),
    spreadingType: data.spreadingType,
    categoryId: data.categoryId,
  };
}

export function getFormDataSell(data: SellPostData) {
  return {
    userId: data.userId,
    entrepriseId: data.companyId,
    type: 'Vente',
    description: data.description,
    titre: data.title,
    prix: data.price == undefined || data.price == null ? '' : data.price.toString(),
    marque: data.brand == undefined ? '' : data.brand,
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    videoUrl: data.videoUrl,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataActivity(data: ActivityPostData) {
  return {
    userId: data.userId,
    entrepriseId: data.companyId,
    type: 'Hobbies',
    activite: data.title,
    description: data.description,
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    videoUrl: data.videoUrl,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataMutualAid(data: MutualAidPostData) {
  return {
    contribution: {
      entrepriseId: data.companyId,
      userIdCreateur: data.userId,
      visibleZone: false,
    },
    entrepriseId: data.companyId,
    service: {
      description: data.description,
      theme: data.title,
    },
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    videoUrl: data.videoUrl,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataCarPool(data: CarPoolPostData) {
  return {
    contribution: {
      entrepriseId: data.companyId,
      userIdCreateur: data.userId,
      visibleZone: false,
    },
    covoit: {
      description: data.description,
      libelleDepart: data.start,
      adresseDepart: data.startCoordinates,
      libelleArrivee: data.end,
      adresseArrivee: data.endCoordinates,
      lovIdOffre: 25,
      lovIdType: data.permanent ? 22 : 23,
    },
    entrepriseId: data.companyId,
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataEvent(data: EventPostData) {
  const users = data.users !== undefined && data.users !== null ? data.users : [];
  return {
    userId: data.userId,
    entrepriseId: data.companyId,
    type: 'Evenement',
    titre: data.title,
    description: data.description,
    date: data.date,
    cagnotte: data.moneyPool,
    place: data.place,
    url: data.url,
    availableSeats: data.availableSeats,
    lovIdType: users.length == 0 ? 20 : 21,
    visibleZone: false,
    nomCreateur: 'Prenom Nom',
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    videoUrl: data.videoUrl,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataLostProperty(data: LostPropertyPostData) {
  return {
    userId: data.userId,
    entrepriseId: data.companyId,
    type: 'Objet',
    lieu: data.location,
    description: data.title,
    date: data.date,
    lovIdType: data.lost ? 19 : 18,
    commentsActivated: data.commentsActivated,
    notifActivated: data.notifActivated,
    emailActivated: data.emailActivated,
    videoUrl: data.videoUrl,
    ...getSpreadingFormData(data),
  };
}

export function getFormDataByContributionType(item, contributionType: ContributionType) {
  let newUserData;
  switch (contributionType) {
    case ContributionType.SELL:
      newUserData = getFormDataSell(item);
      break;
    case ContributionType.ACTIVITY:
      newUserData = getFormDataActivity(item);
      break;
    case ContributionType.MUTUAL_AID:
      newUserData = getFormDataMutualAid(item);
      break;
    case ContributionType.CARPOOL:
      newUserData = getFormDataCarPool(item);
      break;
    case ContributionType.EVENT:
      newUserData = getFormDataEvent(item);
      break;
    case ContributionType.FORMATION:
      newUserData = getFormDataEvent(item);
      break;
    case ContributionType.LOST_PROPERTY:
      newUserData = getFormDataLostProperty(item);
      break;
  }
  return newUserData;
}
