import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Icon } from '@rneui/base';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../../components/atomic/AppButton';
import AppText from '../../components/atomic/AppText';
import { FormField, TextFormField } from '../../components/atomic/FormField';
import { Category } from '../../entities/Category';
import { putCategory } from '../../services/api/category.api';
import { t } from '../../services/translations';
import { isMobile } from '../../services/utils';
import { setLoading } from '../../store/action';
import { categoriesColors } from './CreateCategoryScreen';
import useLoggedUser from '../../hooks/useLoggedUser';

export const EditCategory = ({ category, close }: { category: Category; close(): void }) => {
  const dispatch = useDispatch();
  const user = useLoggedUser();

  const [name, setName] = useState(category.name);
  const [color, setColor] = useState(category.color);
  const [customColor, setCustomColor] = useState<string | undefined>();

  const onCustomColor = (customColor: string) => {
    if (!!customColor && CSS.supports('color', customColor)) {
      setCustomColor(customColor);
      setColor(customColor);
    }
  };

  const updateCategory = () => {
    dispatch(setLoading(true));
    putCategory(category.id, { color, name: name.trim() }, user.token!)
      .then(close)
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <View style={{ padding: 16 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Icon onPress={close} name="arrow-back" size={18} style={{ marginRight: 8 }} />
        <AppText style={{ fontWeight: 'bold', fontSize: 16, marginVertical: 18 }}>{t(category.type)}</AppText>
      </View>
      <TextFormField mandatory value={name ?? ''} onChangeText={setName} title={t('category_name') + '*'} />
      <FormField title={t('category_color') + '*'} style={{ marginBottom: 48 }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: '70%' }}>
          {categoriesColors.map((col) => (
            <TouchableOpacity
              key={col}
              style={{
                backgroundColor: col,
                width: 64,
                height: 24,
                borderColor: 'black',
                borderWidth: col === color ? 2 : 0,
              }}
              onPress={() => setColor(col)}
            />
          ))}
          {!!customColor && (
            <TouchableOpacity
              style={{
                backgroundColor: customColor,
                width: 64,
                height: 24,
                borderColor: 'black',
                borderWidth: customColor === color ? 2 : 0,
              }}
              onPress={() => setColor(customColor)}
            />
          )}
        </View>
      </FormField>
      {!isMobile() && (
        <TextFormField style={{ marginTop: 16 }} title={t('custom_color')} onChangeText={onCustomColor} />
      )}
      <AppButton
        title={t('updating_the_category')}
        type={Type.PRIMARY}
        onPress={updateCategory}
        enabled={!!name && name !== '' && !!color}
      />
    </View>
  );
};
