import { Category } from './entities/Category';
import { ContributionCovoiturage } from './entities/ContributionCovoiturage';
import { ContributionEvenement } from './entities/ContributionEvenement';
import { ContributionHobbie } from './entities/ContributionHobbie';
import { ContributionKiosque } from './entities/ContributionKiosque';
import { ContributionObjet } from './entities/ContributionObjet';
import { ContributionService } from './entities/ContributionService';
import { ContributionVente } from './entities/ContributionVente';
import { CSondage } from './entities/CSondage';
import { MActuPost } from './entities/MActuPost';
import { ScreenNames } from './ScreenNames';

export enum SelectMode {
  MANUAL,
  EVERYONE,
  BY_SERVICE,
  BY_GEOSITE,
  BY_GEOSITE_AND_SERVICE,
  BY_GROUP,
}

export type UserProfileData = {
  nom: string;
  prenom: string;
  telephone: string;
  portable: string;
  aspNetUsers: { email: string };
  entrepriseSiteGeo: { siteGeo: string };
  entrepriseService: { libelle: string };
  fonction: any;
  entreprise: { fichierIdLogo: string };
  fichierIdProfil: string;
};

export enum SpreadingType {
  ENTREPRISE,
  USERS,
  SERVICES,
  SITES,
  SITES_AND_SERVICES,
  GROUPS,
}

export function SelectModeToSpreadingType(selectMode: SelectMode | undefined): SpreadingType | undefined {
  switch (selectMode) {
    case SelectMode.EVERYONE:
      return SpreadingType.ENTREPRISE;
    case SelectMode.MANUAL:
      return SpreadingType.USERS;
    case SelectMode.BY_SERVICE:
      return SpreadingType.SERVICES;
    case SelectMode.BY_GEOSITE:
      return SpreadingType.SITES;
    case SelectMode.BY_GEOSITE_AND_SERVICE:
      return SpreadingType.SITES_AND_SERVICES;
    case SelectMode.BY_GROUP:
      return SpreadingType.GROUPS;
    default:
      return undefined;
  }
}

export function spreadingTypeToSelectMode(selectMode: SpreadingType | undefined): SelectMode | undefined {
  switch (selectMode) {
    case SpreadingType.ENTREPRISE:
      return SelectMode.EVERYONE;
    case SpreadingType.USERS:
      return SelectMode.MANUAL;
    case SpreadingType.SERVICES:
      return SelectMode.BY_SERVICE;
    case SpreadingType.SITES:
      return SelectMode.BY_GEOSITE;
    case SpreadingType.SITES_AND_SERVICES:
      return SelectMode.BY_GEOSITE_AND_SERVICE;
    case SpreadingType.GROUPS:
      return SelectMode.BY_GROUP;
    default:
      return undefined;
  }
}

export enum ContributionType {
  UNDEFINED,
  SUGGESTION,
  CARPOOL,
  LOCAL_ECONOMY,
  EVENT,
  ACTIVITY,
  LOST_PROPERTY,
  MUTUAL_AID,
  SURVEY,
  SELL,
  DOCUMENT,
  COMPANY_NEWS,
  ALL,
  UNKNOWN,
  FORMATION,
  CUSTOM_MODULE,
}

export interface ContribTypeInfo {
  key: string;
  screenName: string;
  contributionIdSelector?: (item: any) => number;
  categoryIdSelector?: (item: any) => number;
  categorySelector?: (item: any) => Category;
  titleSelector?: (item: any) => string;
  formScreen?: string;
  detailScreen?: string;
}

export const GetContributionId = (type: ContributionType, data: any) =>
  ContributionTypesInfos[type].contributionIdSelector?.(data) ?? data.contributionId;

export const ContributionTypesInfos: {
  [key: number]: ContribTypeInfo;
} = {
  [ContributionType.ALL]: {
    key: 'contribution_all',
    screenName: ScreenNames.LoggedInHome,
  },
  [ContributionType.CARPOOL]: {
    key: 'contribution_carpool',
    screenName: ScreenNames.CarPoolMainRouter,
    titleSelector: (item: ContributionCovoiturage) => item.description,
  },
  [ContributionType.COMPANY_NEWS]: {
    key: 'company_news',
    screenName: ScreenNames.LoggedInHome,
    contributionIdSelector: (item) => item.mActuId,
    categoryIdSelector: (item) => item.mActu?.categoryId,
    categorySelector: (item) => item.mActu?.category,
    titleSelector: (item: MActuPost) => item.titre,
    formScreen: ScreenNames.EditCompanyNews,
    detailScreen: ScreenNames.DetailCompanyNews,
  },
  [ContributionType.DOCUMENT]: {
    key: 'contribution_document',
    screenName: ScreenNames.DocumentMainRouter,
    titleSelector: (item: ContributionKiosque) => item.title,
  },
  [ContributionType.SELL]: {
    key: 'contribution_sell',
    screenName: ScreenNames.SellMainRouter,
    categoryIdSelector: (item) => item.contribution?.categoryId,
    categorySelector: (item) => item.contribution?.category,
    titleSelector: (item: ContributionVente) => item.titre,
  },
  [ContributionType.EVENT]: {
    key: 'contribution_event',
    screenName: ScreenNames.EventMainRouter,
    titleSelector: (item: ContributionEvenement) => item.titre,
  },
  [ContributionType.FORMATION]: {
    key: 'contribution_training',
    screenName: ScreenNames.TrainingMainRouter,
    titleSelector: (item: ContributionEvenement) => item.titre,
  },
  [ContributionType.ACTIVITY]: {
    key: 'contribution_activity',
    screenName: ScreenNames.ActivityMainRouter,
    categoryIdSelector: (item) => item.contribution?.categoryId,
    categorySelector: (item) => item.contribution?.category,
    titleSelector: (item: ContributionHobbie) => item.activite,
  },
  [ContributionType.MUTUAL_AID]: {
    key: 'contribution_mutual_aid',
    screenName: ScreenNames.MutualAidMainRouter,
    categoryIdSelector: (item) => item.contribution?.categoryId,
    categorySelector: (item) => item.contribution?.category,
    titleSelector: (item: ContributionService) => item.theme,
  },
  [ContributionType.SUGGESTION]: {
    key: 'contribution_suggestion',
    screenName: ScreenNames.SuggestionsToApproveList,
  },
  [ContributionType.SURVEY]: {
    key: 'contribution_survey',
    screenName: ScreenNames.SurveyMainRouter,
    titleSelector: (item: CSondage) => item.titre,
  },
  [ContributionType.LOST_PROPERTY]: {
    key: 'contribution_lost_property',
    screenName: ScreenNames.LostPropertyMainRouter,
    titleSelector: (item: ContributionObjet) => item.lieu,
  },
};

export function IntToContributionType(value?: string): ContributionType {
  for (const ct in ContributionType) {
    if (ContributionType[ct] == value || ContributionType[ct] == value)
      return ContributionType[ct] as unknown as ContributionType;
  }
  return ContributionType.UNKNOWN;
}

export type User = {
  id: string;
  token: string;
  entrepriseId: string;
};
