import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { GetUsersFilters } from '../../entities/GetUsersFilters';
import { SearchTextInput } from '../atomic/SearchTextInput';
import HorizontalFlatList from '../containers/HorizontalList';
import useLoggedUser from '../../hooks/useLoggedUser';
import { getCompanyGeoSites, getCompanyServices } from '../../services/api/company.api';
import { Chip } from '../atomic/Chip';
import { contributionColors } from '../../services/api/contributions';

export type UserFiltersProps = {
  right?: React.ReactNode;
  filters: GetUsersFilters;
  onChange(filters: GetUsersFilters): void;
};

const colorList = Object.values(contributionColors);

export default function UserFilters({ right, filters, onChange }: UserFiltersProps) {
  const loggedUser = useLoggedUser();
  const { data: companyServices } = useQuery(['company-service', loggedUser.entrepriseId], () =>
    getCompanyServices(loggedUser.entrepriseId, loggedUser.token)
  );
  const { data: companySiteGeos } = useQuery(['company-site-geos', loggedUser.entrepriseId], () =>
    getCompanyGeoSites(loggedUser.entrepriseId, loggedUser.token)
  );

  return (
    <>
      <View style={styles.headerContainer}>
        <SearchTextInput
          style={styles.searchInput}
          onChangeText={(text) => {
            onChange({ ...filters, search: text });
          }}
          defaultValue={filters.search || ''}
        />
        {right}
      </View>
      <HorizontalFlatList
        data={companyServices || []}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item, index }) => (
          <Chip
            key={item.id}
            text={item.libelle}
            color={colorList[index % colorList.length]}
            style={styles.chip}
            onPress={() => {
              if (filters.services?.includes(item.id)) {
                onChange({ ...filters, services: filters.services?.filter((id) => id !== item.id) });
              } else {
                onChange({ ...filters, services: [...(filters.services || []), item.id] });
              }
            }}
            selected={filters.services?.includes(item.id)}
          />
        )}
      />
      <HorizontalFlatList
        data={companySiteGeos || []}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item, index }) => (
          <Chip
            key={item.id}
            text={item.geoSite}
            color={colorList[index % colorList.length]}
            style={styles.chip}
            onPress={() => {
              if (filters.siteGeos?.includes(item.id)) {
                onChange({ ...filters, siteGeos: filters.siteGeos?.filter((id) => id !== item.id) });
              } else {
                onChange({ ...filters, siteGeos: [...(filters.siteGeos || []), item.id] });
              }
            }}
            selected={filters.siteGeos?.includes(item.id)}
          />
        )}
      />
    </>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchInput: { flex: 1, height: 60 },
  filterInput: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    marginTop: 0,
  },
  chip: { marginLeft: 8 },
});
