import { useEffect, useState } from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { FAB } from '@rneui/base';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import Feather from 'react-native-vector-icons/Feather';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { setLoading } from '../../../store/action';
import usePaginatedList from '../../../hooks/usePaginatedList';
import { deleteSmsEmergencyTemplate, listSmsEmergencyMyTemplates } from '../../../services/api/sms.emergency.api';
import { AppScreen } from '../../../components/containers/AppScreen';
import { SearchTextInput } from '../../../components/atomic/SearchTextInput';
import AppText from '../../../components/atomic/AppText';
import { t } from '../../../services/translations';
import ListItemRow from '../../../components/atomic/ListItemRow';
import { ALERT_COLOR, DARK_GRAY, LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { ScreenNames } from '../../../ScreenNames';
import Separator from '../../../components/atomic/Separator';
import { TwoActionsGenericPopup } from '../../../components/containers/TwoActionsButtonPopup';

export default function EmergencySmsListMyTemplatesScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const {
    data: templates,
    isLoading,
    fetchNext,
    refresh,
  } = usePaginatedList((cursor: string | null) => listSmsEmergencyMyTemplates(user, cursor, search), [search, user]);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh on page focus
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refresh();
    });

    return unsubscribe;
  }, [navigation, refresh]);

  return (
    <AppScreen>
      <SearchTextInput
        onPressIcon={() => setSearch('')}
        leftIcon="arrow-back"
        onChangeText={setSearch}
        value={search}
        style={{ marginBottom: 10 }}
      />
      <FlatList
        data={templates}
        renderItem={({ item }) => {
          return (
            <ListItemRow
              defaultImage={
                <FontAwesome6 name="comment-sms" size={80} color={DARK_GRAY} style={styles.pictureContainer} />
              }
              title={item.title}
              date={item.dateCreated}
              user={
                item.author && {
                  profileFileId: item.author.fichierIdProfil,
                  lastName: item.author.nom,
                  firstName: item.author.prenom,
                }
              }
              left={
                <View style={styles.actionContainer}>
                  <TouchableOpacity
                    style={styles.actionIcon}
                    onPress={() => {
                      navigation.navigate(ScreenNames.EmergencySmsUpdateTemplate, { id: item.id });
                    }}
                  >
                    <FontAwesome6 size={24} name="edit" color={PRIMARY_COLOR} />
                  </TouchableOpacity>
                  <TwoActionsGenericPopup
                    title={t('wish_to_remove')}
                    button1Title={t('yes')}
                    button2Title={t('no')}
                    onPressButton1={async () => {
                      await deleteSmsEmergencyTemplate(user, item.id);
                      await refresh();
                    }}
                    onPressButton2={() => {}}
                  >
                    <Feather size={24} name="trash" color={ALERT_COLOR} />
                  </TwoActionsGenericPopup>
                </View>
              }
            />
          );
        }}
        ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
        ItemSeparatorComponent={() => <Separator />}
        onEndReached={fetchNext}
      />
      <FAB
        color={PRIMARY_COLOR}
        icon={<FontAwesome6 size={15} name="plus" color="#fff" />}
        onPress={() => {
          navigation.navigate(ScreenNames.EmergencySmsCreateTemplate);
        }}
        placement="left"
      />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  pictureContainer: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 20,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    paddingHorizontal: 10,
  },
});
