import React, { useEffect } from 'react';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../services/storage';
import { navigateToMessaging } from '../../utils/Messaging';
import { Badge } from './Badge';
import { fetchUnseenMessagingMessageCount } from '../../store/messaging';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import useLoggedUser from '../../hooks/useLoggedUser';
import { NavigationProp, useNavigation } from '@react-navigation/native';

export function MessagingButton() {
  const unseenMessageCount = useSelector<RootState, number>((state) => state.messaging.unseenMessageCount);
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp<any>>();

  // Update unseen message count
  useEffect(() => {
    fetchUnseenMessagingMessageCount(dispatch, user);
  }, [dispatch, user]);

  return (
    <TouchableOpacity style={styles.touchable} onPress={() => navigateToMessaging(navigation)}>
      <Badge value={unseenMessageCount} />
      <FontAwesome6 size={24} name="comment-dots" color={PRIMARY_COLOR} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  touchable: { paddingHorizontal: 12, flexDirection: 'row' },
});
