import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Image, ImageStyle, View } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import useLoggedUser from '../../hooks/useLoggedUser';
import { match, P } from 'ts-pattern';
import { getFileDetails } from '../../services/api/file.api';
import { getDocumentURI } from '../../services/api/helper.api';
import { PickPhotoOrDocumentResult } from '../../services/utils';
import { Pdf } from './Pdf';

export type FilePreviewProps = {
  source: Blob | { id: number } | PickPhotoOrDocumentResult | undefined | null;
  style: ImageStyle;
};

export function FilePreview({ source, style }: FilePreviewProps) {
  const user = useLoggedUser();
  const [uri, setUri] = useState<string | null>(null);

  useEffect(() => {
    if (!!source?.uri) {
      setUri(source?.uri);
    } else if (!!source?.id) {
      setUri(getDocumentURI(source?.id));
    } else if (source instanceof Blob) {
      fileToBase64(source).then(setUri);
    }
  }, [source]);

  // Get document informations if needed
  const { data: documentDetails } = useQuery(
    ['document', source?.id],
    async () => {
      if (source?.id) {
        return await getFileDetails(user, source?.id);
      }
    },
    {
      enabled: !!source?.id,
    }
  );

  const mime = documentDetails?.format.toLowerCase() || source?.type?.toLowerCase() || null;

  return uri ? (
    match(mime)
      .with(P.union('image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/svg+xml'), () => (
        <Image resizeMode="contain" source={{ uri }} style={[style, { height: 80 }]} />
      ))
      .with('application/pdf', () => <Pdf source={uri} style={style} />)
      .otherwise(() => (
        <View style={style}>
          <Feather
            name="file"
            style={{
              fontSize: 50,
              color: 'black',
              alignSelf: 'center',
              textAlign: 'center',
              textAlignVertical: 'center',
              flex: 1,
            }}
          />
        </View>
      ))
  ) : (
    <></>
  );
}

function fileToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });
}
