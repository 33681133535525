import React from 'react';
import { Image } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { isMobile, openURL } from '../../services/utils';
import { generateSlack } from '../../services/url.utils';
import { slack } from '../../services/assets';

export type SlackButtonProps = {
  teamId: string;
  userId: string;
  visible: boolean;
};

export function SlackButton({ teamId, userId, visible }: SlackButtonProps) {
  if (!visible) {
    return null;
  } else {
    return (
      <TouchableOpacity
        style={{ width: isMobile() ? 250 : undefined }}
        onPress={() => openURL(generateSlack(teamId, userId))}
      >
        <Image source={slack} resizeMode="contain" style={{ width: isMobile() ? 250 : undefined, height: 56 }} />
      </TouchableOpacity>
    );
  }
}
