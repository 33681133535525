import { NavigationProp } from '@react-navigation/native';
import { User } from '../entities/User';
import { ScreenNames } from '../ScreenNames';

export const navigateToMessaging = (navigation: NavigationProp<any>) => {
  navigation.navigate(ScreenNames.Messaging);
};

export const navigateToMessagingCreateThread = (navigation: NavigationProp<any>) => {
  navigation.navigate(ScreenNames.Messaging, { screen: ScreenNames.MessagingCreateThread });
};

export const navigateToMessagingNewThread = (navigation: NavigationProp<any>, user: User) => {
  navigateToMessaging(navigation);
  navigation.navigate(ScreenNames.Messaging, {
    screen: ScreenNames.MessagingThread,
    params: {
      contactId: user.id,
      threadName: `${user.prenom} ${user.nom}`,
    },
  });
};

export const navigateToMessagingThread = (
  navigation: NavigationProp<any>,
  thread: {
    id: number;
    contactLastName: string;
    contactFirstName: string;
  }
) => {
  navigation.navigate(ScreenNames.Messaging, {
    screen: ScreenNames.MessagingThread,
    params: {
      threadId: thread.id,
      threadName: `${thread.contactFirstName} ${thread.contactLastName}`,
    },
  });
};
