import { Platform, View } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AppText from '../../../components/atomic/AppText';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import { isMobile } from '../../../services/utils';
import React from 'react';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export function FloatingButtons(props: { onPress }) {
  const actions = [
    {
      text: 'Accessibility',
      icon: require('../../../../assets/plus.png'),
      name: 'bt_plus',
      position: 1,
    },
  ];
  return (
    <FloatingAction
      actions={actions}
      overrideWithAction={true}
      color={PRIMARY_COLOR}
      position="left"
      onPressItem={props.onPress}
    />
  );
}

export function FloatingButtonsNews(props: { onPress }) {
  const actions = [
    {
      text: 'Accessibility',
      icon: require('../../../../assets/plus.png'),
      name: 'bt_plus',
      position: 1,
    },
  ];
  return (
    <View
      style={[
        {
          borderColor: 'rgba(0,0,0,0.2)',
          alignItems: 'center',
          justifyContent: 'center',
          height: 55,
          left: 10,
          backgroundColor: PRIMARY_COLOR,
          borderRadius: 100,
          boxShadow: 'rgb(0 0 0 / 35%) 0px 5px 3px',
          display: 'flex',
          flexDirection: 'row',
          padding: 20,
          position: 'absolute',
          bottom: 10,
        },
        Platform.OS === 'web' ? { cursor: 'pointer', width: 250 } : {},
      ]}
    >
      <TouchableOpacity onPress={props.onPress} style={{ display: 'flex', flexDirection: 'row' }}>
        <FontAwesome6 size={15} style={{ overflow: 'hidden', fontWeight: '200', color: 'white' }} name="pen" />
        <AppText style={{ color: 'white', fontWeight: 'bold', lineHeight: 16, fontSize: 15, paddingLeft: 10 }}>
          Rédiger une actualité
        </AppText>
      </TouchableOpacity>
    </View>
  );
}
