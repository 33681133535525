import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { _compressImage, alertInfo } from '../../services/utils';
import { saveLanguage } from '../../services/storage';
import { AppSafeAreaView } from '../../components/containers/AppSafeAreaView';
import { AppScreen } from '../../components/containers/AppScreen';
import { EditUserForm } from '../../components/molecules/EditUserForm';
import AcceptCGUModal from '../../components/atomic/AcceptCGUModal';
import { t } from '../../services/translations';
import { getUserProfile } from '../../services/api/user.api';
import { ScrollView } from 'react-native-gesture-handler';
import useLoggedUser from '../../hooks/useLoggedUser';
import { setUser } from '../../store/user';
import { getSingleUserSubAdmins } from '../../services/api/usersubadmin';

export function LoginEditProfileScreen() {
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const handleSubmitEnd = useCallback(
    async (values: any) => {
      saveLanguage(values.languageCode);

      // This trigger first login
      await getSingleUserSubAdmins(user.token);

      const updatedUser = await getUserProfile(user.id, user.token);
      await setUser({ ...user, ...updatedUser }, dispatch);
      alertInfo(t('profile_updated'));
    },
    [user, dispatch]
  );

  return (
    <AppScreen>
      <AppSafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          {!user.hasAcceptedCGU && <AcceptCGUModal />}
          <EditUserForm
            userId={user.id}
            onSubmitEnd={handleSubmitEnd}
            title={t('update_my_profile')}
            allowAvatarEdit
            displayHideEmail
          />
        </ScrollView>
      </AppSafeAreaView>
    </AppScreen>
  );
}
