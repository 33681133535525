import { ImageBackground, View, Dimensions, StyleProp, ViewStyle, ImageSourcePropType } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { UserSubRow } from './UserSubRow';
import AppText from './AppText';
import { getReadableDate, isMobile } from '../../services/utils';
import React, { ReactNode } from 'react';
import { User } from '../../entities/User';

function ImageWithFooterTextUnmemo(props: {
  imageSource: ImageSourcePropType;
  author: User;
  date: string;
  height?: number;
  header?: ReactNode;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <ImageBackground
      style={[
        {
          width: '100%',
          height: isMobile() ? Dimensions.get('window').width - 50 : 290,
          overflow: 'hidden',
          borderRadius: 20,
          backgroundColor: '#161616',
        },
        props.style,
      ]}
      imageStyle={{ borderRadius: 20, flex: 1, resizeMode: 'cover' }}
      source={props.imageSource}
    >
      {props.header}
      <View style={{ flex: 1 }}></View>
      <LinearGradient
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          //flex: 0,
          padding: 5,
          height: 35,
        }}
        start={{ x: 0.0, y: 2 }}
        end={{ x: 0, y: 0 }}
        colors={['rgb(0,0,0)', '#00000000']}
      >
        <UserSubRow user={props.author} withPicture={true} style={{ flex: 1 }} textColor="white" />
        <AppText
          style={{
            fontSize: 12,
            fontStyle: 'italic',
            color: 'white',
            paddingRight: 7,
          }}
        >
          {getReadableDate(props.date, true)}
        </AppText>
      </LinearGradient>
    </ImageBackground>
  );
}

export const ImageWithFooterText = React.memo(ImageWithFooterTextUnmemo);

ImageWithFooterTextUnmemo.defaultProps = {
  height: 200,
};

export type TextSize = undefined | 'small' | 'medium' | 'large';

const getSize = (textSize: TextSize) => {
  switch (textSize) {
    case 'small':
      return 12;
    case 'medium':
      return 18;
    case 'large':
      return 24;
    default:
      return 12;
  }
};

export function ImageWithFooterTextUnder(props: {
  imageSource;
  author;
  date;
  height?;
  style;
  textSize: TextSize;
  radius?: number;
}) {
  return (
    <View style={{ flex: 1 }}>
      <ImageBackground
        style={[{ width: '100%', height: 200, overflow: 'hidden', borderRadius: 20 }, props.style]}
        imageStyle={{ borderRadius: props.radius ? props.radius : 20, flex: 1 }}
        source={props.imageSource}
      ></ImageBackground>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 10,
          //flex: 0,
          padding: 5,
          height: 35,
        }}
      >
        <UserSubRow
          user={props.author}
          withPicture={true}
          style={{ flex: 1 }}
          textColor="#272727"
          textSize={getSize(props.textSize)}
        />
        <AppText
          style={{
            fontSize: getSize(props.textSize),
            color: '#272727',
            paddingRight: 7,
          }}
        >
          {getReadableDate(props.date, true)}
        </AppText>
      </View>
    </View>
  );
}
