import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { DefaultBackground } from './AppScreen';

const FormLoading = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));

    return () => {
      dispatch(setLoading(false));
    }; 
  }, []);

  return (
    <DefaultBackground>
    </DefaultBackground>
  );
};

export default FormLoading;
