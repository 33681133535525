import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import React, { useState } from 'react';
import { RouteProp } from '@react-navigation/native';
import { UsersList } from '../../components/molecules/UsersList';
import { UserProfileOverlay } from '../../components/atomic/UserProfileOverlay';
import { User } from '../../entities/User';
import { ScreenNames } from '../../ScreenNames';
import { t } from '../../services/translations';
import { isMobile } from '../../services/utils';
import CreateUserScreen from './admin/CreateUserScreen';
import useLoggedUser from '../../hooks/useLoggedUser';

export type DirectoryScreenProps = {
  route: RouteProp<any, any>;
};

export function DirectoryScreen(props: DirectoryScreenProps) {
  const Tab = createMaterialTopTabNavigator();
  const user = useLoggedUser();

  return user.lovIdType !== 13 && (user.userSubAdmins?.length ?? 0) === 0 ? (
    <DirectoryList route={props.route} />
  ) : (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.ContributeurList}
        component={DirectoryList}
        options={{ title: t('manage_users') }}
        initialParams={{
          allowEdit: true,
        }}
      />
      <Tab.Screen
        name={ScreenNames.ContributeurCreate}
        component={CreateUserScreen}
        options={{ title: t('create_user') }}
      />
    </Tab.Navigator>
  );
}

export function DirectoryList({ route }: DirectoryScreenProps) {
  const user = useLoggedUser();
  const allowEdit = route.params?.allowEdit === undefined ? false : route.params?.allowEdit;
  const [showProfileUser, setShowProfileUser] = useState<null | User>(null);

  return (
    <>
      <UsersList
        showAdminTag={true}
        allowEdit={allowEdit && (user.lovIdType === 13 || (user.userSubAdmins?.length ?? 0) > 0)}
        onPress={setShowProfileUser}
      />
      {showProfileUser && (
        <UserProfileOverlay
          showAdminTag={true}
          onBackdropPress={() => setShowProfileUser(null)}
          userProfileData={showProfileUser}
          isVisible={!!showProfileUser}
        />
      )}
    </>
  );
}
