import React, { useCallback, useState } from 'react';
import { View, StyleSheet, RefreshControl } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { CommentBubble } from '../atomic/CommentBubble';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { isMobile } from '../../services/utils';
import usePaginatedList from '../../hooks/usePaginatedList';
import { getNewsComments, getContributionComments, deleteComment } from '../../services/api/comment.api';
import { NewCommentForm } from './NewCommentForm';
import AppText, { Mode } from '../atomic/AppText';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { t } from '../../services/translations';
import { AppButton } from '../atomic/AppButton';
import useLoggedUser from '../../hooks/useLoggedUser';

export type CommentListProps = {
  newsId?: number;
  contributionId?: number;
};

export function CommentList({ newsId, contributionId }: CommentListProps) {
  const user = useLoggedUser();
  const [displayAll, setDisplayAll] = useState(false);

  const { data, isLoading, totalCount, fetchNext, refresh } = usePaginatedList(
    (cursor: string | null, limit: number | null) => {
      if (newsId) {
        return getNewsComments(user, newsId, cursor, limit);
      } else if (contributionId) {
        return getContributionComments(user, contributionId, cursor, limit);
      } else {
        return Promise.resolve({
          data: [],
          nextCursor: null,
          totalCount: 0,
        });
      }
    },
    [],
    {
      firstFetchLimit: displayAll ? undefined : 3,
    }
  );

  const displayAllComment = useCallback(() => {
    setDisplayAll(true);
    fetchNext();
  }, [fetchNext]);

  const removeComment = useCallback(
    async (id: number) => {
      await deleteComment(user, id);
      refresh();
    },
    [user, refresh]
  );

  return (
    <View style={{ flex: 1, justifyContent: 'space-around', margin: 20 }}>
      <AppText mode={Mode.BOLD} style={styles.commentsTitle}>
        {t('comments')}
      </AppText>
      <NewCommentForm newsId={newsId} contributionId={contributionId} onSent={refresh} />
      <FlatList
        refreshControl={isMobile() ? <RefreshControl refreshing={isLoading} onRefresh={refresh} /> : undefined}
        onEndReached={displayAll ? fetchNext : undefined}
        data={data}
        keyExtractor={(item) => item.id.toString()}
        style={{ overflow: 'visible' }}
        renderItem={({ item }) => (
          <CommentBubble
            content={item.text}
            user={item.user}
            date={new Date(item.creationDate)}
            remove={() => removeComment(item.id)}
          />
        )}
      />
      {!displayAll && totalCount > 3 && (
        <AppButton title={t('show_comments', { count: totalCount })} onPress={displayAllComment} fontSize={14} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  commonSection: {
    paddingVertical: 8,
  },
  sectionMe: {
    flexDirection: 'row',
  },
  sectionNotMe: {
    flexDirection: 'row-reverse',
  },
  sectionTextArea: {
    justifyContent: 'space-around',
  },
  sectionMeTextArea: {
    paddingLeft: 8,
  },
  sectionNotMeTextArea: {
    paddingRight: 8,
    alignItems: 'flex-end',
  },
  sectionText: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 12,
  },
  sectionFooter: {
    paddingBottom: 8,
  },
  commentsContainer: {
    margin: 20,
  },
  commentsTitle: {
    color: PRIMARY_COLOR,
    fontSize: 20,
    marginBottom: 10,
  },
});
