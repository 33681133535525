import { useEffect, useState } from 'react';
import { Category } from '../entities/Category';
import { getCategoriesByType } from '../services/api/category.api';
import { ContributionType } from '../types';
import useLoggedUser from './useLoggedUser';

const useCategories = (type: ContributionType) => {
  const user = useLoggedUser();
  const [categories, setCategories] = useState<Category[] | undefined>(undefined);

  useEffect(() => {
    getCategoriesByType(user.entrepriseId, type, user).then(setCategories);
  }, [user.entrepriseId, user, type, setCategories]);

  return { categories: !!categories ? categories.filter((category) => category.id >= 0) : [], loading: !categories };
};

export default useCategories;
