import { useMemo } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import ReactAnimatedWeather from 'react-animated-weather';

export type WeatherIconProps = {
  rain: boolean;
  showers: boolean;
  snowfall: boolean;
  cloudCover: number;
  isDay: boolean;
  color: string;
  style?: StyleProp<ViewStyle>;
  hq?: boolean;
};

const icons = {
  day: {
    clear: 'CLEAR_DAY',
    cloud: 'PARTLY_CLOUDY_DAY',
    rain: 'RAIN',
    sleet: 'SLEET',
    snow: 'SNOW',
  },
  night: {
    clear: 'CLEAR_NIGHT',
    cloud: 'PARTLY_CLOUDY_NIGHT',
    rain: 'RAIN',
    sleet: 'SLEET',
    snow: 'SNOW',
  },
};

export default function WeatherIcon({
  rain,
  showers,
  snowfall,
  cloudCover,
  isDay,
  color,
  style,
  hq,
}: WeatherIconProps) {
  const icon = useMemo(() => {
    const iconsForDay = isDay ? icons.day : icons.night;

    if (cloudCover > 80) {
      return iconsForDay.cloud;
    }

    if (rain) {
      return iconsForDay.rain;
    }

    if (showers) {
      return iconsForDay.sleet;
    }

    if (snowfall) {
      return iconsForDay.snow;
    }

    return iconsForDay.clear;
  }, [isDay, cloudCover, rain, showers, snowfall]);

  return (
    <View style={style}>
      <ReactAnimatedWeather style={style} icon={icon} color={color} size={hq ? 500 : 50} animate />
    </View>
  );
}
