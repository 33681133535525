import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { OrganizationalChartForm } from '../../../../components/molecules/OrganizationalChartForm';
import { createOrganizationalChart } from '../../../../services/api/organizational.chart.api';
import { t } from '../../../../services/translations';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export default function CreateOrganizationalChartScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const user = useLoggedUser();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  // Submit form
  const handleSubmit = useCallback(
    async ({ label, file }: { label: string; file: any }) => {
      setIsLoading(true);

      try {
        await createOrganizationalChart(user, label, file);
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      } catch (e) {
        alertInfo(t('unknown_error'));
      } finally {
        setIsLoading(false);
      }
    },
    [user]
  );

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  return (
    <AppScreen>
      <OrganizationalChartForm onSubmit={handleSubmit} />
    </AppScreen>
  );
}
