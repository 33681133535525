import { useEffect, useMemo } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import ScreenSettingsFormFields, { schema } from '../../../../components/molecules/ScreenSettingsFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { setLoading } from '../../../../store/action';
import { getPlaylistScreenSettings, updatePlaylistScreenSettings } from '../../../../services/api/tv.api';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { PRIMARY_COLOR, WHITE } from '../../../../styles/appColor';

export type ScreenPlaylistEditScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function ScreenSettingsUpdateScreen({ route }: ScreenPlaylistEditScreenProps) {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const id = parseInt(route.params.id);

  const {
    data,
    isLoading: isLoadingDetails,
    isFetched,
    isError,
  } = useQuery(['screenPlaylist', id], () => getPlaylistScreenSettings(user, id), { cacheTime: 0 });

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      dispatch(setLoading(true));

      try {
        await updatePlaylistScreenSettings(user, id, values);
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    await mutation.mutateAsync(values);
    resetForm();
    navigation.goBack();
  };

  const isLoading = mutation.isLoading;

  const initialValues = useMemo(
    () => ({
      title: data?.title || '',
      backgroundColor: data?.backgroundColor || '',
      playlistId: data?.playlistId || null,
      latitude: data?.latitude || null,
      longitude: data?.longitude || null,
      rssFeed: data?.rssFeed || '',
      facebookPageUrl: data?.facebookPageUrl || '',
      linkedInPageUrl: data?.linkedInPageUrl || '',
    }),
    [data]
  );

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Set navigator title
  useEffect(() => {
    if (data) {
      navigation.setOptions({
        title: `${t('edit')} ${data.title}`,
      });
    }
  }, [navigation, data]);

  return (
    <ScrollView style={styles.container}>
      <AppScreen>
        {isLoadingDetails ? (
          <ActivityIndicator color={PRIMARY_COLOR} size="large" />
        ) : isFetched ? (
          <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnMount>
            <ScreenSettingsFormFields />
          </Form>
        ) : (
          <View />
        )}
      </AppScreen>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
});
