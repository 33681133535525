import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { addPhotoToFormData, getSpreadingFormData } from './formData.api';
import { EventPostData } from '../../models/Contribution';
import { objectToFormData } from '../formUtil';
import { UserSaved } from '../storage';

function CreateFormDataTraining(photo, users, body) {
  const data = objectToFormData(body);

  if (photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, data);
  }
  return data;
}

const getDuration = (startDate: string | undefined, endDate: string | undefined) => {
  if (!!startDate && !!endDate) {
    const start = new Date(startDate).getTime() / 1000;
    const end = new Date(endDate).getTime() / 1000;
    if (end >= start) {
      return Math.floor(end - start);
    }
  }

  return 3600; // 1 hour fallback
};

export function sendTraining(item: EventPostData, token: string, users: any, photo: any) {
  let _users = users !== undefined && users !== null ? [...users] : [];

  return axios.post(
    apiUrlBase + 'Contributions/training',
    CreateFormDataTraining(photo, _users, {
      userId: item.userId,
      entrepriseId: item.companyId,
      type: 'Evenement',
      titre: item.title,
      description: item.description,
      date: item.date,
      videoUrl: item.videoUrl,
      duration: getDuration(item.date, item.endDate),
      cagnotte: item.moneyPool,
      place: item.place,
      url: item.url,
      availableSeats: item.availableSeats,
      lovIdType: _users.length != 0 ? 21 : 20,
      visibleZone: false,
      nomCreateur: item.user.prenom + ' ' + item.user.nom,
      notifActivated: item.notifActivated,
      emailActivated: item.emailActivated,
      ...getSpreadingFormData(item),
    }),
    {
      headers: headers(token, 'multipart/form-data'),
    }
  );
}

export function updateTraining(id, item: EventPostData, token, users, photo) {
  let _users = users !== undefined && users !== null ? [...users] : [];

  return axios.put(
    apiUrlBase + 'Contributions/training/update/' + id,
    CreateFormDataTraining(photo, _users, {
      userId: item.userId,
      entrepriseId: item.companyId,
      type: 'Formation',
      titre: item.title,
      description: item.description,
      date: item.date,
      videoUrl: item.videoUrl,
      duration: getDuration(item.date, item.endDate),
      cagnotte: item.moneyPool,
      place: item.place,
      url: item.url,
      availableSeats: item.availableSeats,
      lovIdType: _users.length != 0 ? 21 : 20,
      visibleZone: false,
      nomCreateur: item.user.prenom + ' ' + item.user.nom,
      notifActivated: item.notifActivated,
      emailActivated: item.emailActivated,
      ...getSpreadingFormData(item),
    }),
    {
      headers: headers(token, 'multipart/form-data'),
    }
  );
}

export async function setAttendFormation(user: UserSaved, eventId: number, attend: boolean) {
  return axios
    .put(
      apiUrlBase + 'ContributionEvenementsUsers/user/' + user.id + '/contributionEvent/' + eventId,
      {
        contributionEvenementId: eventId,
        userId: user.id,
        reponse: attend,
      },
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      return response;
    });
}

export function recallFormationNotification(user: UserSaved, id: number) {
  return axios
    .post(
      `${apiUrlBase}ContributionFormations/recall-notification/${id}`,
      {},
      {
        headers: headers(user.token, 'multipart/form-data'),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
