import React, { useState } from 'react';
import { View, LayoutChangeEvent, StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export type HideIfGreaterViewProps = {
  children: React.ReactNode;
};

export default function HideIfGreaterView({ children }: HideIfGreaterViewProps) {
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });
  const [contentDimensions, setContentDimensions] = useState({ width: 0, height: 0 });

  const onContainerLayout = (e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout;
    setContainerDimensions({ width, height });
  };

  const onContentLayout = (e: LayoutChangeEvent) => {
    const { width, height } = e.nativeEvent.layout;
    setContentDimensions({ width, height });
  };

  const isContentGreaterThanContainer =
    contentDimensions.width > containerDimensions.width || contentDimensions.height > containerDimensions.height;

  return (
    <ScrollView style={styles.container} onLayout={onContainerLayout} horizontal>
      {!isContentGreaterThanContainer && <View onLayout={onContentLayout}>{children}</View>}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
});
