import { useEffect, useRef, useState } from 'react';
import { Animated, ScrollView, StyleProp, View, ViewStyle } from 'react-native';

export type AutoScrollViewProps = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  beforeDelay?: number;
  afterDelay?: number;
  speed?: number;
  paused?: boolean;
  onReachEnd?: () => void;
};

export default function AutoScrollView({
  children,
  style,
  speed = 20,
  beforeDelay = 0,
  afterDelay = 0,
  paused = false,
  onReachEnd,
}: AutoScrollViewProps) {
  const anim = useRef(new Animated.Value(0)).current;
  const [fullHeight, setFullHeight] = useState<number | undefined>(undefined);
  const [viewportHeight, setViewportHeight] = useState<number | undefined>(undefined);
  const animDuration = fullHeight && viewportHeight && Math.max(((fullHeight - viewportHeight) / speed) * 1000, 0);

  const scroll = () => {
    if (fullHeight && viewportHeight) {
      if (animDuration === 0) {
        setTimeout(() => {
          onReachEnd?.();
        }, afterDelay);
      } else {
        Animated.timing(anim, {
          toValue: -(fullHeight - viewportHeight),
          duration: animDuration,
          useNativeDriver: false,
        }).start(() => {
          setTimeout(() => {
            onReachEnd?.();
          }, afterDelay);
        });
      }
    }
  };

  // Wait and then start scrolling slowly
  useEffect(() => {
    if (!paused && !!fullHeight && !!viewportHeight) {
      setTimeout(() => {
        scroll();
      }, beforeDelay);
    }
  }, [!paused && !!fullHeight && !!viewportHeight]);

  return (
    <ScrollView
      style={style}
      onLayout={(value) => {
        setViewportHeight(value.nativeEvent.layout.height);
      }}
      showsVerticalScrollIndicator={false}
    >
      <Animated.View
        style={{
          transform: [
            {
              translateY: anim,
            },
          ],
        }}
        onLayout={(value) => {
          setFullHeight(value.nativeEvent.layout.height);
        }}
      >
        {children}
      </Animated.View>
    </ScrollView>
  );
}
