import React, { createElement } from 'react';
import { globalStyle } from '../../styles/globalStyle';
import { t } from '../../services/translations';

function digits2(digit: number) {
  return (digit < 10 ? '0' : '') + digit;
}

export function AppDateTimePicker(props: {
  withTime?: boolean;
  formattedDate: Date;
  onChangeText(date: string): void;
}) {
  const day = props.formattedDate && props.formattedDate.getDate();
  const month = props.formattedDate && props.formattedDate.getMonth() + 1;
  const year = props.formattedDate && props.formattedDate.getFullYear();
  const dateValue = props.formattedDate ? year + '-' + digits2(month) + '-' + digits2(day) : t('date_placeholder');
  const timeValue = props.formattedDate
    ? digits2(props.formattedDate.getHours()) + ':' + digits2(props.formattedDate.getMinutes())
    : '';

  const dateInput = createElement('input', {
    type: 'date',
    value: dateValue,
    style: globalStyle.textInput,
    onChange: function (syntheticEvent) {
      const dateText = syntheticEvent.target.value.split('-');

      let newDate = props.formattedDate ? new Date(props.formattedDate) : new Date();
      newDate.setDate(dateText[2] as unknown as number);
      newDate.setMonth((dateText[1] as unknown as number) - 1);
      newDate.setFullYear(dateText[0] as unknown as number);

      props.onChangeText(newDate.toISOString());
    },
  });

  const timeInput = createElement('input', {
    type: 'time',
    value: timeValue,
    style: globalStyle.textInput,
    onChange: function (syntheticEvent) {
      const timeText = syntheticEvent.target.value.split(':');

      let newDate = props.formattedDate ? new Date(props.formattedDate) : new Date();
      newDate.setHours(timeText[0], timeText[1]);
      props.onChangeText(newDate.toISOString());
    },
  });

  return (
    <>
      {dateInput}
      {props.withTime && timeInput}
    </>
  );
}
