import { useEffect, useMemo } from 'react';
import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigation } from '@react-navigation/native';
import Form from '../../../../components/atomic/formik/Form';
import ScreenPlaylistFormFields, { schema } from '../../../../components/molecules/ScreenPlaylistFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { setLoading } from '../../../../store/action';
import { getScreenPlaylist, updateScreenPlaylist } from '../../../../services/api/tv.api';
import { alertInfo } from '../../../../services/utils';
import { t } from '../../../../services/translations';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { PRIMARY_COLOR, WHITE } from '../../../../styles/appColor';

export type ScreenPlaylistEditScreenProps = {
  route: {
    params: {
      id: string;
    };
  };
};

export default function ScreenPlaylistEditScreen({ route }: ScreenPlaylistEditScreenProps) {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const id = parseInt(route.params.id);

  const {
    data,
    isFetching: isLoadingDetails,
    isFetched,
    refetch,
  } = useQuery(['screenPlaylist', id], () => getScreenPlaylist(user, id), { cacheTime: 0 });

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      dispatch(setLoading(true));

      try {
        await updateScreenPlaylist(user, id, values);
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    await mutation.mutateAsync(values);
    await refetch();
    resetForm();
    navigation.goBack();
  };

  const isLoading = mutation.isLoading;

  const initialValues = useMemo(
    () => ({
      name: data?.name || '',
      items:
        data?.items.map((e) => ({
          type: e.type,
          screenPlaylistMediaId: e.screenPlaylistMediaId,
          screenPlaylistMediaIdString: e.screenPlaylistMediaId ? `id${e.screenPlaylistMediaId}` : undefined,
          delay: e.delay,
        })) || [],
    }),
    [data]
  );

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Set navigator title
  useEffect(() => {
    if (data) {
      navigation.setOptions({
        title: `${t('edit')} ${data.name}`,
      });
    }
  }, [navigation, data]);

  return (
    <ScrollView style={styles.container}>
      <AppScreen>
        {isLoadingDetails ? (
          <ActivityIndicator color={PRIMARY_COLOR} size="large" />
        ) : isFetched ? (
          <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnMount>
            <ScreenPlaylistFormFields />
          </Form>
        ) : (
          <View />
        )}
      </AppScreen>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
});
