export enum GetContributionsMode {
  all,
  mine,
  interested,
  requests,
  offers,
  participation,
  toApprove,
  reviewed,
  approved,
  reported,
  ADMIN_VIEW,
  not_set,
  admin_all,
}

export function IntToGetContributionsMode(value: string) {
  for (let gcm in GetContributionsMode) {
    if (GetContributionsMode[gcm] == value) return GetContributionsMode[gcm] as any as GetContributionsMode;
  }

  return GetContributionsMode.not_set;
}
