import React, { useEffect, useState } from 'react';
import { Dimensions, Platform, ScaledSize, View } from 'react-native';
import { globalStyle } from './styles/globalStyle';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPhoneMode } from './store/action';
import { loadAppState, RootState, saveToken } from './services/storage';
import UserStatusRoute from './routes/UserStatusRoute';
import { AppLoader } from './components/atomic/AppLoader';
import { OfferAppDownloadBanner } from './OfferAppDownloadBanner';
import { apiVersion } from '../configuration';
import { compareVersionStrings } from './utils/Utils';
import { UnsupportedVersionScreen } from './screens/UnsupportedVersionScreen';

const window = Dimensions.get('window');

export type MainProps = {
  route: {
    params: {
      accessToken?: string | null;
    };
  };
};

export default function Main({ route: { params } }: MainProps) {
  const [dataLoaded, setDataLoaded] = useState(false);

  const dispatch = useDispatch();

  const onChange = ({ window }: { window: ScaledSize }) => {
    dispatch(setPhoneMode(window.width < 640));
  };

  useEffect(() => {
    async function runAsync() {
      dispatch(setLoading(true));
      try {
        if (Platform.OS !== 'android' && params?.accessToken) {
          await saveToken(params.accessToken);
        }

        await loadAppState(dispatch);
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setLoading(false));
        setDataLoaded(true);
      }
    }

    runAsync();
  }, [dispatch, params?.accessToken]);

  useEffect(() => {
    dispatch(setPhoneMode(window.width < 640));
  }, [dispatch]);

  useEffect(() => {
    const handler = Dimensions.addEventListener('change', onChange);
    return () => {
      handler.remove();
    };
  });

  const isLoading = useSelector<RootState, boolean>((state) => state.isLoading || false);
  const minimumVersion = useSelector<RootState, string>((state) => state.version.minimumVersion);

  if (!dataLoaded) {
    return null;
  }

  const shouldBeUpdated = compareVersionStrings(minimumVersion, apiVersion) > 0;

  return (
    <View style={globalStyle.screen}>
      <OfferAppDownloadBanner />
      {shouldBeUpdated ? <UnsupportedVersionScreen /> : <UserStatusRoute />}
      {isLoading && <AppLoader isVisible={isLoading} />}
    </View>
  );
}
