import { FONT_FAMILY_DEFAULT, globalStyle } from '../../styles/globalStyle';
import { StyleProp, Text, TextStyle } from 'react-native';
import React from 'react';

export enum Mode {
  NORMAL,
  ERROR,
  BOLD,
  HEADER1,
  HEADER2,
  SMALL,
}

export type AppTextProps = {
  numberOfLines?: number;
  children: any;
  onPress?: any;
  style?: StyleProp<TextStyle>;
  mode?: Mode;
};

export default function AppText(props: AppTextProps) {
  let style = {};
  switch (props.mode) {
    case Mode.ERROR:
      style = { color: 'red' };
      break;
    case Mode.BOLD:
      style = {
        fontFamily: FONT_FAMILY_DEFAULT.bold,
        fontWeight: 'bold',
      };
      break;
    case Mode.HEADER1:
      style = {
        fontFamily: FONT_FAMILY_DEFAULT.bold,
        fontSize: 30,
        marginBottom: 20,
      };
      break;
    case Mode.HEADER2:
      style = {
        fontFamily: FONT_FAMILY_DEFAULT.bold,
        fontWeight: 'bold',
        fontSize: 20,
        marginBottom: 30,
      };
      break;
    case Mode.SMALL:
      style = {
        fontFamily: FONT_FAMILY_DEFAULT.light,
        fontSize: 11,
      };
      break;
  }
  return (
    <Text numberOfLines={props.numberOfLines} style={[globalStyle.appText, style, props.style]} onPress={props.onPress}>
      {props.children}
    </Text>
  );
}
