import React, { ReactNode } from 'react';
import { ResponsiveView } from './ResponsiveView';

export function ResponsiveRow(props: { children: ReactNode; breakpoint?: any }) {
  return (
    <ResponsiveView bigScreenStyle={{ flexDirection: 'row' }} breakpoint={props.breakpoint}>
      {props.children}
    </ResponsiveView>
  );
}
