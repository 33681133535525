import React, { useState } from 'react';
import { ImageSourcePropType, StyleSheet, TouchableOpacity, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import ContributionSpreadingField, {
  contributionSpreadingFieldValidators,
} from '../../../../components/atomic/formik/ContributionSpreadingField';
import { t } from '../../../../services/translations';
import { AppScreen } from '../../../../components/containers/AppScreen';
import PhotoField from '../../../../components/atomic/formik/PhotoField';
import TextField from '../../../../components/atomic/formik/TextField';
import RichTextField from '../../../../components/atomic/formik/RichTextField';
import ContributionAuthorizationsField from '../../../../components/atomic/formik/ContributionAuthorizationsField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import SwitchField from '../../../../components/atomic/formik/SwitchField';
import DateField from '../../../../components/atomic/formik/DateField';
import { FormField } from '../../../../components/atomic/FormField';
import { useField } from 'formik';
import { Card } from '@rneui/base';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { CreateSurveyQuestion } from '../../../../entities/CreateSurvey';
import { PRIMARY_COLOR } from '../../../../styles/appColor';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import { CSondageQuestionType } from '../../../../entities/CSondageQuestion';
import RadioGroupField from '../../../../components/atomic/formik/RadioGroupField';
import { isMobile } from '../../../../services/utils';

export const schema = Yup.object({
  title: Yup.string().min(1).required(t('field_required')),
  description: Yup.string().min(1).required(t('field_required')),
  image: Yup.mixed(),
  endDate: Yup.date().required(t('field_required')),
  isAnonym: Yup.boolean().required(t('field_required')),
  questions: Yup.array()
    .of(
      Yup.object({
        text: Yup.string().min(1).required(t('field_required')),
        answers: Yup.mixed().when('type', {
          is: (type: CSondageQuestionType) => type !== CSondageQuestionType.TEXT,
          then: Yup.array().of(Yup.string().min(1).required(t('field_required'))),
        }),
        type: Yup.number().required(t('field_required')),
      })
    )
    .min(1)
    .required(t('field_required')),
  commentsActivated: Yup.boolean().required(t('field_required')),
  notifActivated: Yup.boolean().required(t('field_required')),
  emailActivated: Yup.boolean().required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export type SurveyFormFieldsProps = {
  isEditing?: boolean | undefined;
  defaultPhoto?: ImageSourcePropType | undefined;
};

export default function SurveyFormFields({ isEditing, defaultPhoto }: SurveyFormFieldsProps) {
  const [field, , helper] = useField('questions');
  const [needsValidation, setNeedsValidation] = useState(false);

  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={styles.container}
        keyboardDismissMode="on-drag"
      >
        <PhotoField name="image" defaultPhoto={defaultPhoto} allowPdf />
        <TextField name="title" mandatory required title={t('title')} />
        <RichTextField name="description" title={t('description')} required />
        <FormField title={t('allow_anonymous')}>
          <SwitchField name="isAnonym" />
        </FormField>
        <DateField name="endDate" title={t('due_date')} />
        {field.value.map((question: CreateSurveyQuestion, index: number) => {
          return (
            <Card>
              <Card.Title>
                {t('question') + ' ' + (index + 1)}
                {field.value.length > 1 && (
                  <TouchableOpacity
                    style={styles.questionTrashButton}
                    onPress={() => {
                      helper.setValue(field.value.filter((_: any, i: number) => i !== index));
                    }}
                  >
                    <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
                  </TouchableOpacity>
                )}
              </Card.Title>
              <Card.Divider />
              <TextField name={`questions[${index}].text`} title={t('question')} required />
              <RadioGroupField
                name={`questions[${index}].type`}
                title={t('type')}
                options={[
                  { label: t('single_choice'), value: CSondageQuestionType.SINGLE },
                  { label: t('multiple_choice'), value: CSondageQuestionType.MULTIPLE },
                  { label: t('free_text'), value: CSondageQuestionType.TEXT },
                ]}
                vertical={!isMobile()}
              />
              {question.type !== CSondageQuestionType.TEXT && (
                <>
                  {question.answers.map((answer: string, answerIndex: number) => {
                    return (
                      <View key={answerIndex} style={styles.answerView}>
                        <TextField
                          name={`questions[${index}].answers[${answerIndex}]`}
                          title={t('answer') + ' ' + (answerIndex + 1)}
                          required
                          style={styles.answerTextField}
                        />
                        {question.answers.length > 1 && (
                          <TouchableOpacity
                            style={styles.answerTrashButton}
                            onPress={() => {
                              const newAnswers = question.answers.filter((_, i) => i !== answerIndex);
                              helper.setValue(
                                field.value.map((v: CreateSurveyQuestion, k: number) => {
                                  if (k === index) {
                                    return {
                                      ...v,
                                      answers: newAnswers,
                                    };
                                  }
                                  return v;
                                })
                              );
                            }}
                          >
                            <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
                          </TouchableOpacity>
                        )}
                      </View>
                    );
                  })}
                  <AppButton
                    icon="plus"
                    title={t('new_answer')}
                    type={Type.LIGHT}
                    onPress={() => {
                      helper.setValue(
                        field.value.map((v: CreateSurveyQuestion, k: number) => {
                          if (k === index) {
                            return {
                              ...v,
                              answers: [...v.answers, ''],
                            };
                          }
                          return v;
                        })
                      );
                    }}
                  />
                </>
              )}
            </Card>
          );
        })}
        <AppButton
          icon="plus"
          title={t('new_question')}
          type={Type.LIGHT}
          style={styles.plusButton}
          onPress={() => {
            helper.setValue([
              ...field.value,
              isEditing
                ? {
                    id: null,
                    text: '',
                    answers: ['', '', ''],
                    type: CSondageQuestionType.SINGLE,
                  }
                : {
                    text: '',
                    answers: ['', '', ''],
                    type: CSondageQuestionType.SINGLE,
                  },
            ]);
          }}
        />
        <ContributionSpreadingField onNeedsValidationChange={setNeedsValidation} />
        <ContributionAuthorizationsField />
        <SubmitButton
          text={!isEditing ? (needsValidation ? t('send_for_validation') : t('publish_button')) : t('update_button')}
        />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  questionTrashButton: { marginLeft: 10, position: 'absolute', right: 10, top: 0 },
  answerTrashButton: {
    marginLeft: 10,
  },
  plusButton: {
    marginVertical: 10,
  },
  answerView: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  answerTextField: {
    flex: 1,
  },
});
