import { AppButton } from './AppButton';
import { t } from '../../services/translations';
import React, { useState } from 'react';
import * as DocumentPicker from 'expo-document-picker';
import { Platform, View } from 'react-native';
import AppText from './AppText';
import { match } from 'ts-pattern';

export const megabyte = 1048576;
export type FileErrorMessage = 'file_too_large' | 'file_type_incorrect';

export type FilePickerProps = {
  setFile: (value: ((prevState: undefined) => undefined) | undefined) => void;
  file: any;
  defaultFilename: any;
  maxSizeMB?: number;
  error?: string;
  setError?: (value: FileErrorMessage | undefined) => void;
  allowedExtensions?: string[];
};

export function FilePicker(props: FilePickerProps) {
  const [error, setError] = useState<FileErrorMessage | undefined>(undefined);

  async function _pickFile() {
    DocumentPicker.getDocumentAsync().then((result) => {
      if (props.setError !== undefined) {
        props.setError(undefined);
      }

      setError(undefined);

      if (result.canceled) {
        throw new Error('File must be defined');
      }

      const fileResult = result.assets[0];
      if (!fileResult) {
        throw new Error('File must be defined');
      }

      if (props.maxSizeMB !== undefined && (fileResult.size || 0) > props.maxSizeMB * megabyte) {
        if (props.setError !== undefined) {
          props.setError('file_too_large');
        }

        setError('file_too_large');
        return;
      }

      let { name, size, uri } = fileResult;
      let nameParts = name.split('.');
      let fileType = nameParts[nameParts.length - 1];

      if (
        props.allowedExtensions &&
        props.allowedExtensions.find((e) => e.toLowerCase() === fileType.toLowerCase()) === undefined
      ) {
        setError('file_type_incorrect');
        props.setError && props.setError('file_type_incorrect');
      } else {
        if (Platform.OS === 'web' && fileResult.file !== undefined && fileResult.file !== null) {
          props.setFile(fileResult.file);
        } else {
          if (Platform.OS === 'android' && uri[0] === '/') {
            uri = `file://${uri}`;
            uri = uri.replace(/%/g, '%25');
          }

          const fileToUpload = {
            name: name,
            size: size,
            uri: uri,
            type: 'application/' + fileType,
          };
          props.setFile(fileToUpload);
        }
      }
    });
  }

  return (
    <View style={{ flexDirection: 'column', alignItems: 'center' }}>
      <AppButton
        title={
          props.defaultFilename !== undefined
            ? props.defaultFilename
            : props.file !== undefined && props.file != null
            ? props.file.name
            : t('choose_a_file')
        }
        onPress={_pickFile}
      />
      {props.maxSizeMB && (
        <>
          {(error || props.error) && (
            <AppText style={{ color: 'red', textAlign: 'center' }}>
              {error &&
                match(error)
                  .with('file_too_large', () => t('file_too_large'))
                  .with('file_type_incorrect', () =>
                    t('file_type_incorrect', { types: props.allowedExtensions?.join(', ') })
                  )
                  .exhaustive()}
              {!error && props.error}
            </AppText>
          )}
          <AppText style={{ textAlign: 'right', marginTop: 7 }}>
            {t('max_size')} {props.maxSizeMB} {t('mb')}
          </AppText>
          {props.allowedExtensions?.length && error !== 'file_type_incorrect' && (
            <AppText style={{ textAlign: 'center', marginTop: 7 }}>
              {t('accepted_file_types', { types: props.allowedExtensions?.join(', ') })}
            </AppText>
          )}
        </>
      )}
    </View>
  );
}
