import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Dispatch } from 'redux';
import { match } from 'ts-pattern';
import * as Localization from 'expo-localization';
import { MessagingMessage } from '../entities/MessagingMessage';
import { MessagingThread } from '../entities/MessagingThread';
import { MessagingThreadMember } from '../entities/MessagingThreadMember';
import {
  getMessagingThreadMessages,
  getMessagingThreads,
  getThreadMembers,
  getUnseenMessageCount,
  markThreadAsSeen,
  postThreadMessage,
  deleteMessagingThread as apiDeleteMessagingThread,
} from '../services/api/messaging.api';
import { RootState, UserSaved } from '../services/storage';
import { ActionType } from './action';
import { apiTimeZone } from '../../configuration';

export type NotificationMessage = {
  id: number;
  threadId: number;
  content: string | null;
  fileId: number | null;
  dateCreated: string;
  userId: number;
  profileFileId: number;
  firstName: string;
  lastName: string;
};

export type ThreadMessages = {
  [key: number]: {
    messages: (MessagingMessage & {
      isLoading: boolean;
    })[];
    members: MessagingThreadMember[];
    nextCursor: string | null;
    isLoading: boolean;
  };
};

export type MessagingState = {
  unseenMessageCount: number;
  threads: MessagingThread[];
  nextCursor: string | null;
  isLoading: boolean;
  threadMessages: ThreadMessages;
};

export const defaultThreadMessageState = {
  messages: [],
  nextCursor: null,
  isLoading: false,
  members: [],
};

export const defaultMessagingState = {
  unseenMessageCount: 0,
  threads: [],
  nextCursor: null,
  isLoading: false,
  threadMessages: {},
};

export type MessagingActionType =
  | {
      type: 'MESSAGING_NEW_MESSAGE_RECEIVED';
      payload: NotificationMessage;
    }
  | {
      type: 'MESSAGING_THREAD_SEEN_RECEIVED';
      threadId: number;
      userId: number;
      date: Date;
    }
  | {
      type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_START';
    }
  | {
      type: 'MARK_THREAD_AS_SEEN_START';
      threadId: number;
    }
  | {
      type: 'MARK_THREAD_AS_SEEN_END';
      threadId: number;
      unseenMessageCount: number;
    }
  | {
      type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_END';
      payload: number;
    }
  | {
      type: 'MESSAGING_THREADS_FETCH_START';
    }
  | {
      type: 'MESSAGING_THREADS_FETCH_END';
      threads: MessagingThread[];
      nextCursor: string | null;
    }
  | {
      type: 'MESSAGING_THREADS_RESET_START';
    }
  | {
      type: 'MESSAGING_THREADS_RESET_END';
      threads: MessagingThread[];
      nextCursor: string | null;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_FETCH_START';
      threadId: number;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_FETCH_END';
      threadId: number;
      messages: MessagingMessage[];
      nextCursor: string | null;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_RESET_START';
      threadId: number;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_RESET_END';
      threadId: number;
      messages: MessagingMessage[];
      nextCursor: string | null;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_START';
      threadId: number;
      identifier: number;
      message: string | null;
      file: Blob | null;
      user: UserSaved;
    }
  | {
      type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_END';
      threadId: number;
      identifier: number;
      message: MessagingMessage;
    }
  | {
      type: 'MESSAGING_THREAD_MEMBERS_FETCH_START';
      threadId: number;
    }
  | {
      type: 'MESSAGING_THREAD_MEMBERS_FETCH_END';
      threadId: number;
      members: MessagingThreadMember[];
    }
  | {
      type: 'MESSAGING_THREAD_DELETE_START';
      threadId: number;
    }
  | {
      type: 'MESSAGING_THREAD_DELETE_END';
      threadId: number;
    };

export function messagingReducer(state: RootState, action: ActionType): RootState {
  return match(action)
    .with(
      { type: 'MESSAGING_NEW_MESSAGE_RECEIVED' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            unseenMessageCount: state.messaging.unseenMessageCount + 1,
            threads: state.messaging.threads.map((e) =>
              e.id === action.payload.threadId
                ? {
                    ...e,
                    lastMessage: action.payload.content,
                    seen: false,
                    unseenCount: e.unseenCount + 1,
                  }
                : e
            ),
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.payload.threadId]: {
                ...(state.messaging.threadMessages[action.payload.threadId] || defaultThreadMessageState),
                messages: state.messaging.threadMessages[action.payload.threadId]?.messages?.length
                  ? [
                      {
                        user: {
                          id: action.payload.userId,
                          profileFileId: action.payload.profileFileId,
                          firstName: action.payload.firstName,
                          lastName: action.payload.lastName,
                        },
                        id: action.payload.id,
                        content: action.payload.content,
                        fileId: null,
                        dateCreated: action.payload.dateCreated,
                        isLoading: false,
                      },
                      ...(state.messaging.threadMessages[action.payload.threadId]?.messages.filter(
                        (e) => e.id !== action.payload.id
                      ) || []),
                    ]
                  : [],
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_SEEN_RECEIVED' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                members: state.messaging.threadMessages[action.threadId]?.members?.map((e) =>
                  e.userId === action.userId ? { ...e, lastSeen: action.date.toISOString() } : e
                ),
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MARK_THREAD_AS_SEEN_START' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            unseenMessageCount: state.messaging.unseenMessageCount + 1,
            threads: state.messaging.threads.map((e) =>
              e.id == action.threadId
                ? {
                    ...e,
                    seen: true,
                    unseenCount: 0,
                  }
                : e
            ),
          },
        } as RootState)
    )
    .with(
      { type: 'MARK_THREAD_AS_SEEN_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            unseenMessageCount: action.unseenMessageCount,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            unseenMessageCount: action.payload,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREADS_FETCH_START' },
      () =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            isLoading: true,
            nextCursor: null,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREADS_FETCH_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threads: [
              ...state.messaging.threads,
              ...action.threads.filter(
                (thread) => state.messaging.threads.find((other) => thread.id === other.id) !== null
              ),
            ],
            isLoading: false,
            nextCursor: action.nextCursor,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREADS_RESET_START' },
      () =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            isLoading: true,
            nextCursor: null,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREADS_RESET_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threads: action.threads,
            isLoading: false,
            nextCursor: action.nextCursor,
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_FETCH_START' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                isLoading: true,
                nextCursor: null,
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_FETCH_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                messages: [
                  ...(state.messaging.threadMessages[action.threadId]?.messages || []),
                  ...action.messages.map((data) => ({
                    ...data,
                    isLoading: false,
                  })),
                ],
                nextCursor: action.nextCursor,
                isLoading: false,
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_RESET_START' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                isLoading: true,
                nextCursor: null,
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_RESET_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                messages: action.messages.map((data) => ({
                  ...data,
                  isLoading: false,
                })),
                isLoading: false,
                nextCursor: action.nextCursor,
              },
              threads: state.messaging.threads.map((e) =>
                e.id == action.threadId
                  ? {
                      ...e,
                      lastMessage: action.messages[0]?.content,
                    }
                  : e
              ),
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_START' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                messages: [
                  {
                    user: {
                      id: action.user.id,
                      profileFileId: action.user.fichierIdProfil,
                      firstName: action.user.prenom,
                      lastName: action.user.nom,
                    },
                    id: -action.identifier,
                    content: action.message,
                    fileId: null,
                    dateCreated: !isNaN(
                      utcToZonedTime(zonedTimeToUtc(new Date(), Localization.timezone), apiTimeZone).getTime()
                    )
                      ? utcToZonedTime(zonedTimeToUtc(new Date(), Localization.timezone), apiTimeZone).toISOString()
                      : null,
                    isLoading: true,
                  },
                  ...(state.messaging.threadMessages[action.threadId]?.messages || []),
                ],
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...state.messaging.threadMessages[action.threadId],
                messages: state.messaging.threadMessages[action.threadId].messages.map((message) =>
                  message.id === -action.identifier
                    ? {
                        ...action.message,
                        isLoading: false,
                      }
                    : message
                ),
              },
            },
            threads: state.messaging.threads.map((e) =>
              e.id == action.threadId
                ? {
                    ...e,
                    lastMessage: action.message.content,
                  }
                : e
            ),
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_MEMBERS_FETCH_END' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threadMessages: {
              ...state.messaging.threadMessages,
              [action.threadId]: {
                ...(state.messaging.threadMessages[action.threadId] || defaultThreadMessageState),
                members: action.members,
              },
            },
          },
        } as RootState)
    )
    .with(
      { type: 'MESSAGING_THREAD_DELETE_START' },
      (action) =>
        ({
          ...state,
          messaging: {
            ...state.messaging,
            threads: state.messaging.threads.filter((e) => e.id !== action.threadId),
          },
        } as RootState)
    )
    .otherwise(() => state);
}

export async function messagingMessageReceived(dispatch: Dispatch<MessagingActionType>, message: NotificationMessage) {
  dispatch({ type: 'MESSAGING_NEW_MESSAGE_RECEIVED', payload: message });
}

export async function messagingThreadSeenReceived(
  dispatch: Dispatch<MessagingActionType>,
  threadId: number,
  userId: number,
  date: Date
) {
  dispatch({ type: 'MESSAGING_THREAD_SEEN_RECEIVED', threadId, userId, date });
}

export async function fetchUnseenMessagingMessageCount(dispatch: Dispatch<MessagingActionType>, user: UserSaved) {
  dispatch({ type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_START' });

  const result = await getUnseenMessageCount(user);

  dispatch({ type: 'MESSAGING_UNSEEN_MESSAGE_COUNT_FETCH_END', payload: result });
}

export async function markMessagingThreadAsSeen(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  threadId: number
) {
  dispatch({ type: 'MARK_THREAD_AS_SEEN_START', threadId });

  const unseenMessageCount = await markThreadAsSeen(user, threadId);

  dispatch({ type: 'MARK_THREAD_AS_SEEN_END', threadId, unseenMessageCount });
}

export async function fetchNextMessagingThreads(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  nextCursor: string,
  searchFilter?: string | null
) {
  dispatch({ type: 'MESSAGING_THREADS_FETCH_START' });

  const result = await getMessagingThreads(user, nextCursor, searchFilter);

  dispatch({ type: 'MESSAGING_THREADS_FETCH_END', threads: result.data, nextCursor: result.nextCursor });
}

export async function resetMessagingThreads(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  searchFilter?: string | null
) {
  dispatch({ type: 'MESSAGING_THREADS_RESET_START' });

  const result = await getMessagingThreads(user, null, searchFilter);

  dispatch({ type: 'MESSAGING_THREADS_RESET_END', threads: result.data, nextCursor: result.nextCursor });
}

export async function fetchNextMessagingThreadMessages(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  threadId: number,
  nextCursor: string
) {
  dispatch({ type: 'MESSAGING_THREAD_MESSAGES_FETCH_START', threadId });

  const result = await getMessagingThreadMessages(user, threadId, nextCursor);

  dispatch({
    type: 'MESSAGING_THREAD_MESSAGES_FETCH_END',
    threadId,
    messages: result.data,
    nextCursor: result.nextCursor,
  });
}

export async function resetMessagingThreadMessages(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  threadId: number
) {
  dispatch({ type: 'MESSAGING_THREAD_MESSAGES_RESET_START', threadId });

  const result = await getMessagingThreadMessages(user, threadId, null);

  dispatch({
    type: 'MESSAGING_THREAD_MESSAGES_RESET_END',
    threadId,
    messages: result.data,
    nextCursor: result.nextCursor,
  });
}

export async function sendNewMessagingMessage(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  identifier: number,
  threadId: number,
  body: { message: string | null; file: Blob | null }
) {
  dispatch({ type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_START', threadId, identifier, ...body, user });

  const result = await postThreadMessage(user, threadId, body);

  dispatch({ type: 'MESSAGING_THREAD_MESSAGES_SEND_NEW_MESSAGE_END', threadId, identifier, message: result });
}

export async function fetchMessagingThreadMembers(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  threadId: number
) {
  dispatch({ type: 'MESSAGING_THREAD_MEMBERS_FETCH_START', threadId });

  const members = await getThreadMembers(user, threadId);

  dispatch({ type: 'MESSAGING_THREAD_MEMBERS_FETCH_END', threadId, members });
}

export async function deleteMessagingThread(
  dispatch: Dispatch<MessagingActionType>,
  user: UserSaved,
  threadId: number
) {
  dispatch({ type: 'MESSAGING_THREAD_DELETE_START', threadId });

  await apiDeleteMessagingThread(user, threadId);

  dispatch({ type: 'MESSAGING_THREAD_DELETE_END', threadId });
}
