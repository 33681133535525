import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { getPlaylistScreenFacebookPosts } from '../../services/api/tv.api';
import TVScreenContributionCard from './TVScreenContributionCard';
import { vh } from '../../utils/Utils';
import { ScreenSocialPostType } from '../../entities/ScreenSocialPost';
import { DARK_GRAY } from '../../styles/appColor';
import AppText from '../atomic/AppText';
import useTodayDateTime from '../../hooks/useTodayDateTime';
import { useScreenOrientation } from '../../hooks/useScreenOrientation';

export type FacebookPostsWidgetProps = {
  code: string;
};

export default function FacebookPostsWidget({ code }: FacebookPostsWidgetProps) {
  const orientation = useScreenOrientation();
  const todayDateTime = useTodayDateTime();
  const { data: socialPosts } = useQuery(
    ['playlistScreenFacebookPosts', code],
    () => getPlaylistScreenFacebookPosts(code),
    {
      refetchInterval: 30 * 60 * 1000,
    }
  );

  const mappedSocialPosts =
    socialPosts?.map((e) => ({
      id: e.url || '',
      type: e.type === ScreenSocialPostType.Facebook ? 'facebook' : ('linkedin' as 'facebook' | 'linkedin'),
      title: null,
      description: e.description,
      imageId: e.imageUrl,
      creationDate: e.dateCreated,
    })) || [];

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.dateTimeContainer}>
          <AppText style={styles.timeText}>{todayDateTime.time}</AppText>
          <View style={styles.dateContainer}>
            <AppText style={styles.dateText}>{todayDateTime.shortDate}</AppText>
            <AppText style={styles.dateText}>{todayDateTime.ephemerisOfTheDay}</AppText>
          </View>
        </View>
      </View>
      <View style={orientation === 'landscape' ? styles.columnContainer : styles.verticalColumnContainer}>
        <View style={styles.column}>
          <View style={styles.itemContainer}>
            <TVScreenContributionCard item={mappedSocialPosts[0] || null} style={styles.item} />
          </View>
          <View style={styles.itemContainer}>
            <TVScreenContributionCard item={mappedSocialPosts[2] || null} style={styles.item} />
          </View>
        </View>
        <View style={styles.column}>
          <View style={styles.itemContainer}>
            <TVScreenContributionCard item={mappedSocialPosts[1] || null} style={styles.item} />
          </View>
          <View style={styles.itemContainer}>
            <TVScreenContributionCard item={mappedSocialPosts[3] || null} style={styles.item} />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: vh(8),
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: vh(1),
    flexDirection: 'row',
    marginTop: vh(1),
    marginBottom: vh(1),
  },
  dateTimeContainer: {
    flexDirection: 'row',
    paddingTop: vh(0.25),
  },
  timeText: {
    fontSize: vh(6),
    color: DARK_GRAY,
  },
  dateText: {
    fontSize: vh(2.5),
    color: DARK_GRAY,
  },
  dateContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginBottom: vh(2),
    marginLeft: vh(2),
  },
  columnContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  verticalColumnContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  column: {
    flexDirection: 'column',
    flex: 1,
  },
  itemContainer: {
    marginVertical: vh(1),
    marginHorizontal: vh(2),
    flex: 1,
  },
  item: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
});
