import React, { useEffect, useRef, useState } from 'react';
import { Modal, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Worker, Viewer, SpecialZoomLevel, Plugin, RenderViewer, Icon, MinimalButton } from '@react-pdf-viewer/core';
import { pageNavigationPlugin, RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';
import { PickPhotoOrDocumentResult } from '../../services/utils';
import { isString } from 'lodash';
import { CloseButton } from './CloseButton';
import { match } from 'ts-pattern';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

export type PdfProps = {
  source: PickPhotoOrDocumentResult;
  style: ViewStyle;
  // The following props works only on Web
  fit?: 'width' | 'page' | 'actual_size';
  darkBackground?: boolean;
  autoPlayDelay?: number;
  noModal?: boolean;
  onAutoPlayEnd?: () => void;
};

export function Pdf({ source, style, fit, darkBackground, autoPlayDelay, noModal, onAutoPlayEnd }: PdfProps) {
  const viewerRef = useRef<Viewer>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [base64, setBase64] = useState<string | null>(null);
  const disableScrollPluginInstance = disableScrollPlugin();
  const previewPageNavigationPluginInstance = pageNavigationPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToNextPage: previewJumpToNextPage } = previewPageNavigationPluginInstance;
  const [isPreviewOnLastPage, setIsPreviewOnLastPage] = useState(false);
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    if (isString(source)) {
      setBase64(source);
    } else {
      fileToBase64(source).then(setBase64);
    }
  }, [source]);

  useEffect(() => {
    if (autoPlayDelay && isPreviewOnLastPage) {
      const timeout = setTimeout(() => {
        onAutoPlayEnd?.();
        setModalVisible(false);
      }, autoPlayDelay);

      return () => {
        clearTimeout(timeout);
      };
    } else if (autoPlayDelay) {
      const interval = setInterval(() => {
        previewJumpToNextPage();
      }, autoPlayDelay);

      return () => {
        clearInterval(interval);
      };
    }
  }, [autoPlayDelay, onAutoPlayEnd, isPreviewOnLastPage, previewJumpToNextPage]);

  return base64 ? (
    <>
      <TouchableOpacity disabled={!!noModal} style={style} onPress={() => setModalVisible(true)}>
        <Viewer
          ref={viewerRef}
          fileUrl={base64}
          plugins={[previewPageNavigationPluginInstance, disableScrollPluginInstance]}
          theme={darkBackground ? 'dark' : undefined}
          onDocumentLoad={(props) => {
            setTotalPage(props.doc.numPages);
          }}
          defaultScale={match(fit)
            .with('actual_size', () => SpecialZoomLevel.ActualSize)
            .with('page', () => SpecialZoomLevel.PageFit)
            .otherwise(() => SpecialZoomLevel.PageWidth)}
          onPageChange={(props) => {
            setIsPreviewOnLastPage(props.currentPage === props.doc.numPages - 1);
          }}
        />
      </TouchableOpacity>
      <Modal visible={!noModal && modalVisible} transparent={true} onRequestClose={() => setModalVisible(false)}>
        <View style={styles.modal}>
          <View style={styles.pdfContainer}>
            <View style={styles.horizontalContainer}>
              <GoToPreviousPage>
                {(props: RenderGoToPageProps) => (
                  <MinimalButton onClick={props.onClick}>
                    <Icon size={16}>
                      <path d="M18.4.5,5.825,11.626a.5.5,0,0,0,0,.748L18.4,23.5" />
                    </Icon>
                  </MinimalButton>
                )}
              </GoToPreviousPage>
              <Viewer
                fileUrl={base64}
                plugins={[pageNavigationPluginInstance, disableScrollPluginInstance]}
                defaultScale={SpecialZoomLevel.PageWidth}
                onPageChange={(props) => {
                  setPage(props.currentPage + 1);
                }}
                onDocumentLoad={(props) => {
                  setTotalPage(props.doc.numPages);
                }}
              />
              <GoToNextPage>
                {(props: RenderGoToPageProps) => (
                  <MinimalButton onClick={props.onClick}>
                    <Icon size={16}>
                      <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
                    </Icon>
                  </MinimalButton>
                )}
              </GoToNextPage>
            </View>
            <Text style={styles.pageCounter}>
              {page} / {totalPage}
            </Text>
          </View>
          <CloseButton onPress={() => setModalVisible(false)} />
        </View>
      </Modal>
    </>
  ) : (
    <View style={style} />
  );
}

const styles = StyleSheet.create({
  pdfContainer: {
    backgroundColor: '#fff',
    maxWidth: 600,
    width: '100%',
    paddingTop: 10,
  },
  horizontalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '100%',
  },
  modal: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pageCounter: {
    width: '100%',
    textAlign: 'center',
  },
});

function fileToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });
}

export type PdfProviderProps = {
  children: React.ReactNode;
};

export function PdfProvider({ children }: PdfProviderProps) {
  return <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">{children}</Worker>;
}

const disableScrollPlugin = (): Plugin => {
  const renderViewer = (props: RenderViewer) => {
    const { slot } = props;

    const pageWidth = props.pageSizes[0]?.pageWidth || undefined;
    const pageHeight = props.pageSizes[0]?.pageHeight || undefined;
    const pageRatio = pageWidth && pageHeight && pageHeight / pageWidth;
    const containerWidth = props.containerRef?.current?.clientWidth;
    const height = pageRatio && containerWidth && containerWidth * pageRatio;

    if (slot.attrs && slot.attrs.style) {
      slot.attrs.style = Object.assign({}, slot.attrs.style, {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      });
    }

    if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
      slot.subSlot.attrs.style = Object.assign({}, slot.subSlot.attrs.style, {
        // Disable scrolling in the pages container
        overflow: 'hidden',
        maxHeight: height,
        flex: 1,
      });
    }

    return slot;
  };

  return {
    renderViewer,
  };
};
