import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import { match } from 'ts-pattern';
import { ContributionSondagesDetails } from '../../entities/ContributionSondagesDetails';
import Form from '../atomic/formik/Form';
import { CSondageQuestionType } from '../../entities/CSondageQuestion';
import useLoggedUser from '../../hooks/useLoggedUser';
import { setLoading } from '../../store/action';
import { respondSurvey } from '../../services/api/survey.contributions.api';
import { RespondSurvey } from '../../entities/RespondSurvey';
import { alertInfo } from '../../services/utils';
import { t } from '../../services/translations';
import TextField from '../atomic/formik/TextField';
import RadioButtonGroupField from '../atomic/formik/RadioButtonGroupField';
import SubmitButton from '../atomic/formik/SubmitButton';
import AppText, { Mode } from '../atomic/AppText';
import { DARK_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { globalStyle } from '../../styles/globalStyle';

/*Yup.addMethod(Yup.MixedSchema, 'oneOfSchemas', function (schemas) {
  return this.test('one-of-schemas', 'Not all items in ${path} match one of the allowed schemas', (item) =>
    schemas.some((schema: any) => schema.isValidSync(item, { strict: true }))
  );
});*/

export type SurveyRespondFormProps = {
  item: ContributionSondagesDetails;
  onSubmit: () => void;
};

export default function SurveyRespondForm({ item, onSubmit }: SurveyRespondFormProps) {
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (values: RespondSurvey) => {
      dispatch(setLoading(true));

      try {
        await respondSurvey(user, item.id, values);
        onSubmit && onSubmit();
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const schema = useMemo(() => {
    return Yup.object().shape({
      questions: Yup.array().test('all-questions-answered', t('all_questions_answered'), (questions: any) => {
        return questions.every((question: any) => {
          const q = item.questions.find((e) => e.id === question.questionId);

          if (q == null) {
            return false;
          }

          if (q.type === CSondageQuestionType.TEXT) {
            return question.text;
          }

          if (q.type === CSondageQuestionType.SINGLE) {
            return question.answerId;
          }

          if (q.type === CSondageQuestionType.MULTIPLE) {
            return question.answerIds.length > 0;
          }
        });
      }),
    });
  }, [item]);

  const initialValues = useMemo(() => {
    return {
      questions: item.questions.map((question) => {
        if (question.type === CSondageQuestionType.TEXT) {
          return {
            questionId: question.id,
            text: question.freeText || '',
          };
        }
        if (question.type === CSondageQuestionType.SINGLE) {
          return {
            questionId: question.id,
            answerId: question.answers.find((e) => e.value)?.id,
          };
        }
        if (question.type === CSondageQuestionType.MULTIPLE) {
          return {
            questionId: question.id,
            answerIds: question.answers.filter((e) => e.value).map((e) => e.id),
          };
        }
      }),
    };
  }, [item]);

  const hasAlreadyResponded = item.questions.every(
    (question) => !question.answers.every((answer) => !answer.value) || question.freeText
  );

  return (
    <Form
      onSubmit={mutation.mutateAsync as any}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
    >
      <View style={styles.container}>
        {item.questions.map((question, index) => (
          <View>
            <AppText key={question.id} mode={Mode.BOLD} style={styles.questionText}>
              {question.text}
            </AppText>
            {match(question.type)
              .with(CSondageQuestionType.TEXT, () => (
                <TextField
                  key={question.id}
                  name={`questions[${index}].text`}
                  title={t('your_answer')}
                  displayOnly={hasAlreadyResponded}
                  style={[globalStyle.button, styles.textField]}
                  multiline
                />
              ))
              .with(CSondageQuestionType.SINGLE, () => (
                <>
                  <AppText style={styles.questionSubtext}>* {t('single_choice')}</AppText>
                  <RadioButtonGroupField
                    key={question.id}
                    name={`questions[${index}].answerId`}
                    options={question.answers.map((e) => ({ label: e.text, value: e.id }))}
                    disabled={hasAlreadyResponded}
                  />
                </>
              ))
              .with(CSondageQuestionType.MULTIPLE, () => (
                <>
                  <AppText style={styles.questionSubtext}>* {t('multiple_choice')}</AppText>
                  <RadioButtonGroupField
                    key={question.id}
                    name={`questions[${index}].answerIds`}
                    options={question.answers.map((e) => ({ label: e.text, value: e.id }))}
                    disabled={hasAlreadyResponded}
                    multiple
                  />
                </>
              ))
              .otherwise(() => (
                <></>
              ))}
          </View>
        ))}
        {!hasAlreadyResponded && <SubmitButton text={t('send')} />}
      </View>
    </Form>
  );
}

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
  },
  questionText: {
    alignContent: 'center',
    textAlign: 'center',
    color: PRIMARY_COLOR,
    fontSize: 19,
    marginVertical: 10,
  },
  questionSubtext: {
    alignContent: 'center',
    textAlign: 'center',
    color: DARK_GRAY,
    fontSize: 12,
    marginBottom: 5,
  },
  textField: {},
});
