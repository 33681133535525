import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../services/storage';
import { setContributionsListNeedsRefresh } from '../store/action';

export default function useContributionListRefresh(refreshCallback: () => Promise<any>) {
  const dispatch = useDispatch();
  const contributionsListNeedsRefresh = useSelector<RootState, boolean | undefined>(
    (state) => state.contributionsListNeedsRefresh
  );

  useEffect(() => {
    if (contributionsListNeedsRefresh) {
      refreshCallback().finally(() => {
        dispatch(setContributionsListNeedsRefresh(false));
      });
    }
  }, [contributionsListNeedsRefresh]);
}
