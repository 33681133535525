import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import useRssFeed from '../../hooks/useRssFeed';
import AppText from '../atomic/AppText';
import { WHITE } from '../../styles/appColor';
import { vh } from '../../utils/Utils';
import useInterval from '../../hooks/useInterval';

export type RssFeedProps = {
  paused?: boolean;
  rssUrl: string;
};

export default function RssFeed({ rssUrl, paused }: RssFeedProps) {
  const { currentItem, error, next } = useRssFeed(rssUrl);
  const nodeRef = React.useRef<HTMLDivElement>(null);

  // Change every 10 seconds
  useInterval(
    () => {
      next();
    },
    {
      delay: 10000,
      paused,
    }
  );

  if (error) {
    return <AppText>{error.message}</AppText>;
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition<HTMLDivElement>
        key={currentItem?.title}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          (nodeRef.current as any)?.addEventListener('transitionend', done, false);
        }}
        classNames="fade-slide-right"
      >
        <div ref={nodeRef} style={styles.container}>
          <AppText style={styles.title} numberOfLines={1}>
            {currentItem?.title}
          </AppText>
          <AppText style={styles.description} numberOfLines={2}>
            {currentItem?.description}
          </AppText>
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  title: {
    fontSize: vh(2.5),
    color: WHITE,
    width: '100%',
  },
  description: {
    color: WHITE,
    fontSize: vh(1.5),
    height: vh(3.75),
    width: '100%',
  },
});
