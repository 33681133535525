export function objectToFormData(data: { [key: string]: any }) {
  const formData = new FormData();

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      appendFormData(data[key], key, formData);
    }
  }

  return formData;
}

function appendFormData(data: any, root: string, formData: FormData) {
  root = root || '';
  if (data instanceof Blob) {
    formData.append(root, data);
  } else if (data instanceof Date) {
    formData.append(root, data.toISOString());
  } else if (data instanceof Set) {
    const arrayData = Array.from(data);
    for (var i = 0; i < arrayData.length; i++) {
      appendFormData(arrayData[i], root + '[' + i + ']', formData);
    }
  } else if (Array.isArray(data)) {
    for (var i = 0; i < data.length; i++) {
      appendFormData(data[i], root + '[' + i + ']', formData);
    }
  } else if (data instanceof Object && data) {
    // Special case, if data is a blob/file or a react native picked file
    // add it directly and skip encoding
    if (data.uri && data.type && data.name) {
      formData.append(root, data);
    } else {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          if (root === '') {
            appendFormData(data[key], key, formData);
          } else {
            appendFormData(data[key], root + '.' + key, formData);
          }
        }
      }
    }
  } else {
    if (data !== null && typeof data !== 'undefined') {
      formData.append(root, data);
    }
  }
}

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
