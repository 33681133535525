import React from 'react';
import { StyleSheet, View } from 'react-native';
import { CheckBox } from '@rneui/base';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { FormField } from './FormField';

export type AppRadioGroupProps<T> = {
  title: string;
  value: T;
  onChange: (value: T) => void;
  options: {
    value: T;
    label: string;
  }[];
  renderLeft?: () => React.ReactElement | null;
  vertical?: boolean;
};

export default function AppRadioGroup<T>({ value, onChange, options, renderLeft, vertical }: AppRadioGroupProps<T>) {
  const { deviceSize } = useDeviceSize();

  return (
    <View
      style={[
        style.radioContainer,
        deviceSize !== 'extra-large' ? style.radioContainerSmall : undefined,
        vertical ? style.verticalRadioContainer : undefined,
      ]}
    >
      <FormField title="Type*" style={[style.radioTitle]} children={undefined} />
      {options &&
        options.map((option) => (
          <CheckBox
            key={option.label}
            center
            title={option.label}
            checkedIcon="dot-circle-o"
            uncheckedIcon="circle-o"
            checkedColor={PRIMARY_COLOR}
            uncheckedColor={LIGHT_GRAY}
            checked={option.value === value}
            onPress={() => onChange(option.value)}
            containerStyle={[style.radio, deviceSize !== 'extra-large' ? style.radioSmall : undefined]}
          />
        ))}
      {renderLeft && renderLeft()}
    </View>
  );
}

const style = StyleSheet.create({
  radioTitle: {},
  radio: {
    backgroundColor: 'transparent',
    border: 'none',
    borderColor: 'transparent',
    alignItems: 'flex-start',
    marginVertical: 0,
    paddingVertical: 0,
    marginHorizontal: 0,
  },
  radioSmall: {
    padding: 0,
    paddingBottom: 10,
  },
  radioContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  radioContainerSmall: {
    flexDirection: 'column',
  },
  verticalRadioContainer: {
    flexDirection: 'row',
  },
});
