import React from 'react';
import { FlatList, RefreshControl, StyleSheet } from 'react-native';
import { isMobile } from '../../services/utils';
import { ProfileCard } from '../atomic/ProfileCard';
import useLoggedUser from '../../hooks/useLoggedUser';
import usePaginatedList from '../../hooks/usePaginatedList';
import AppText from '../atomic/AppText';
import { LIGHT_GRAY } from '../../styles/appColor';
import { t } from '../../services/translations';
import { listContributionInterestedUsers } from '../../services/api/contribution.api';

export type ContributionInterestedUserListProps = {
  id: number;
};

export default function ContributionInterestedUserList({ id }: ContributionInterestedUserListProps) {
  const user = useLoggedUser();

  const { data, isLoading, fetchNext, refresh } = usePaginatedList(
    (cursor: string | null) => listContributionInterestedUsers(user, id, cursor),
    ['news-interested-users', id]
  );

  return (
    <FlatList
      horizontal={false}
      numColumns={isMobile() ? 2 : undefined}
      contentContainerStyle={styles.contentContainer}
      data={data}
      renderItem={({ item }) => <ProfileCard user={item} />}
      keyExtractor={(item) => item.id.toString()}
      refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
      ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
      onEndReached={fetchNext}
    />
  );
}

const styles = StyleSheet.create({
  contentContainer: isMobile()
    ? {}
    : {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
});
