import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import AdminScreenTabsRoute from './AdminScreenTabsRoute';
import ScreenPlaylistCreateScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenPlaylistCreateScreen';
import ScreenPlaylistEditScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenPlaylistEditScreen';
import ScreenSettingsCreateScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenSettingsCreateScreen';
import ScreenSettingsUpdateScreen from '../screens/loggedIn/admin/konecteamPlay/ScreenSettingsUpdateScreen';
import { t } from '../services/translations';
import BackIcon from '../components/atomic/BackIcon';

const Stack = createStackNavigator();

export default function AdminScreenRoute() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.ScreenAdmin}
        options={{
          title: t('public_tv_screen'),
        }}
        component={AdminScreenTabsRoute}
      />
      <Stack.Screen
        name={ScreenNames.ScreenPlaylistCreate}
        options={{
          title: t('create_playlists'),
        }}
        component={ScreenPlaylistCreateScreen}
      />
      <Stack.Screen
        name={ScreenNames.ScreenPlaylistUpdate}
        options={{
          title: t('create_playlists'),
        }}
        component={ScreenPlaylistEditScreen}
      />
      <Stack.Screen
        name={ScreenNames.ScreenSettingsCreate}
        options={{
          title: t('create_screen'),
        }}
        component={ScreenSettingsCreateScreen}
      />
      <Stack.Screen
        name={ScreenNames.ScreenSettingsUpdate}
        options={{
          title: t('create_screen'),
        }}
        component={ScreenSettingsUpdateScreen}
      />
    </Stack.Navigator>
  );
}
