import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useField, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { Icon } from '@rneui/base';
import { FormField } from '../FormField';
import { availableLanguages, t } from '../../../services/translations';
import AppText, { Mode } from '../AppText';
import { getLibelleFromCode } from '../../../languages/language_mapping';
import LanguageSelector from '../LanguageSelector';
import translate, { DeeplLanguages } from 'deepl';
import { setLoading } from '../../../store/action';

export type TranslationsFieldProps = {
  name: string;
  prefillFields: {
    source: string;
    destination: string;
  }[];
  render: (args: { name: string; lng: string }) => React.ReactNode;
};

export default function TranslationsField({ name, prefillFields, render }: TranslationsFieldProps) {
  const dispatch = useDispatch();
  const formikContext = useFormikContext<any>();
  const [field, , helper] = useField(name);
  const value = field.value as { lng: string }[];

  const addNewTranslation = useCallback(
    async (lng: string) => {
      const translation = { lng } as any;

      if (prefillFields) {
        dispatch(setLoading(true));

        for (const key in prefillFields) {
          try {
            const response = await translate({
              free_api: true,
              text: formikContext.values[prefillFields[key].source] as string,
              target_lang: lng.split('-')[0] as DeeplLanguages,
              auth_key: '94f4223b-fcc2-1bee-34e3-4c8f3f62a6b8:fx',
            });

            const translatedValue = response.data.translations[0].text;
            translation[prefillFields[key].destination] = translatedValue;
          } catch (e) {
            console.error(e);
          }
        }

        dispatch(setLoading(false));
      }

      helper.setValue([...value, translation]);
    },
    [dispatch, prefillFields, formikContext.values, helper, value]
  );

  return (
    <FormField title={t('translations')}>
      {value.map((tr, k) => (
        <View key={tr.lng} style={styles.translationContainer}>
          <View style={styles.translationHeaderContainer}>
            <AppText mode={Mode.BOLD} style={styles.translationTitle}>
              {getLibelleFromCode(tr.lng)}
            </AppText>
            <TouchableOpacity
              onPress={() => {
                let newTranslations = value.filter((el) => el.lng !== tr.lng);
                helper.setValue(newTranslations);
              }}
            >
              <Icon name="close" />
            </TouchableOpacity>
          </View>
          {render({ name: name + '[' + k + ']', lng: tr.lng })}
        </View>
      ))}
      <View style={styles.availableLanguageContainer}>
        {availableLanguages
          .filter((e) => value.findIndex((x) => x.lng === e.code) < 0)
          .map((e) => (
            <LanguageSelector key={e.code} language={e} onPress={() => addNewTranslation(e.code)} />
          ))}
      </View>
    </FormField>
  );
}

const styles = StyleSheet.create({
  translationContainer: { borderTopWidth: 1 },
  translationHeaderContainer: { flexDirection: 'row', justifyContent: 'center', alignItems: 'center' },
  translationTitle: {
    marginBottom: 10,
    fontSize: 20,
    textAlign: 'center',
    flex: 1,
    marginTop: 10,
  },
  availableLanguageContainer: { flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 },
});
