let installed = false;

const toIgnore = 'Warning: `flexWrap: `wrap`` is not supported';

const Ignore = () => {
  if (installed) return;
  installed = true;
  const originalWarn = console.warn.bind(console);
  const originalErr = console.error.bind(console);
  console.warn = (...args: Parameters<Console['error']>) => {
    if (args.length > 0 && typeof args[0] === 'string' && args[0].startsWith(toIgnore)) {
      return;
    }
    originalWarn(...args);
  };
  console.error = (...args: Parameters<Console['error']>) => {
    if (args.length > 0 && typeof args[0] === 'string' && args[0].startsWith(toIgnore)) {
      return;
    }
    originalErr(...args);
  };
};

export default Ignore;
