import { AnyAction, Dispatch } from 'redux';
import * as Notifications from 'expo-notifications';
import { ScreenNames } from '../ScreenNames';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { UserSaved } from '../services/storage';
import { ContributionType } from '../types';
import { getCustomModuleContributionsDetails } from '../services/api/custom.module.api';
import { setLoading } from '../store/action';
import { NavigationProp } from '@react-navigation/native';
import { match } from 'ts-pattern';
import { markAsReadNotification } from '../services/api/notification.api';
import { isMobile } from '../services/utils';
import { navigateToMessaging, navigateToMessagingThread } from './Messaging';
import { messagingMessageReceived, messagingThreadSeenReceived } from '../store/messaging';

export type NotificationType =
  | {
      type: 'redirection';
      notificationId: string;
      contributionId: string;
      contributionType: string;
      isAdminView: string;
    }
  | {
      type: 'new_message';
      id: string;
      threadId: string;
      content: string;
      fileId: string;
      dateCreated: string;
      userId: string;
      profileFileId: string;
      firstName: string;
      lastName: string;
    }
  | {
      type: 'thread_seen';
      threadId: string;
      userId: string;
      date: string;
    };

export const navigateToNotification = (
  contributionType: ContributionType,
  postId: number,
  isAdminView: boolean,
  user: UserSaved,
  dispatch: Dispatch<AnyAction>,
  navigation: NavigationProp<any>
) => {
  if (contributionType === ContributionType.ALL) {
    navigation.navigate(ScreenNames.CompanyAdminMain, {
      screen: ScreenNames.ContributionsAdministration,
      initial: false,
      params: {
        getContributionsMode: GetContributionsMode.all,
        contributionType: ContributionType.ALL,
        isSignaled: true,
        isAdminView: true,
        isNotif: true,
      },
    });
  } else if (contributionType === ContributionType.COMPANY_NEWS) {
    navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ScreenNames.DetailCompanyNews,
      params: {
        id: postId,
        isNotif: true,
      },
    });
  } else if (contributionType === ContributionType.CUSTOM_MODULE) {
    navigateToCustomModuleDetails(postId, user, dispatch, navigation);
  } else {
    navigation.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ScreenNames.ContributionItemDetailScreen,
      params: {
        id: postId,
        contributionType: contributionType,
        isNotif: true,
        isAdminView,
      },
    });
  }
};

async function navigateToCustomModuleDetails(
  postId: number,
  user: UserSaved,
  dispatch: Dispatch<AnyAction>,
  navigation: NavigationProp<any>
) {
  dispatch(setLoading(true));
  let customModule = await getCustomModuleContributionsDetails(user, postId);
  dispatch(setLoading(false));

  navigation.reset({
    index: 1,
    routes: [
      {
        name: customModule.module?.identifier || '',
        state: {
          index: 0,
          routes: [{ name: ScreenNames.CustomModuleTabs }],
        },
      },
      {
        name: ScreenNames.CustomModuleDetails,
        params: {
          id: postId,
          isNotif: true,
        },
      },
    ],
  });
}

export async function onNotificationOpenedAppHandler(
  messageData: NotificationType | undefined,
  user: UserSaved,
  dispatch: Dispatch,
  navigation: NavigationProp<any>
) {
  match(messageData)
    .with({ type: 'redirection' }, ({ notificationId, contributionId, contributionType, isAdminView }) => {
      markAsReadNotification(parseInt(notificationId, 10)).finally(async () => {
        try {
          if (isMobile()) {
            await Notifications.setBadgeCountAsync((await Notifications.getBadgeCountAsync()) - 1);
          }
        } catch (error) {
          console.error('Failed to decrease badge count');
        }

        navigateToNotification(
          parseInt(contributionType, 10) as ContributionType,
          parseInt(contributionId, 10),
          isAdminView === 'true',
          user,
          dispatch,
          navigation
        );
      });
    })
    .with({ type: 'new_message' }, (data) => {
      navigateToMessaging(navigation);
      navigateToMessagingThread(navigation, {
        id: parseInt(data.threadId, 10),
        contactFirstName: data.firstName,
        contactLastName: data.lastName,
      });
    })
    .with({ type: 'thread_seen' }, () => {})
    .with(undefined, () => {})
    .exhaustive();
}

export async function onMessageHandler(messageData: NotificationType | undefined, dispatch: Dispatch) {
  match(messageData)
    .with({ type: 'redirection' }, () => {})
    .with({ type: 'new_message' }, (data) => {
      messagingMessageReceived(dispatch, {
        ...data,
        id: parseInt(data.id, 10),
        threadId: parseInt(data.threadId, 10),
        fileId: parseInt(data.fileId, 10),
        userId: parseInt(data.userId, 10),
        profileFileId: parseInt(data.profileFileId, 10),
      });
    })
    .with({ type: 'thread_seen' }, (data) => {
      messagingThreadSeenReceived(
        dispatch,
        parseInt(data.threadId, 10),
        parseInt(data.userId, 10),
        new Date(data.date)
      );
    })
    .with(undefined, () => {})
    .exhaustive();
}
