import { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import * as Progress from 'react-native-progress';
import { Icon } from '@rneui/base';

export type ProgressCircleOverlayProps = {
  progress: number;
  color: string;
  display?: boolean;
  children?: React.ReactNode;
};

export default function ProgressCircleOverlay({ progress, color, display, children }: ProgressCircleOverlayProps) {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const circleSize = Math.min(containerSize.width, containerSize.height) / 2;

  return (
    <View
      style={styles.container}
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        setContainerSize({ width, height });
      }}
    >
      {(display ?? true) && (
        <>
          <div style={styles.layer}>
            <Icon name="play" type="font-awesome" size={circleSize / 2} color={color} />
          </div>
          <div style={styles.layer}>
            <Progress.Circle
              progress={progress}
              size={circleSize}
              color={color}
              borderWidth={0}
              thickness={circleSize / 10}
              strokeCap="round"
              unfilledColor="transparent"
            />
          </div>
        </>
      )}
      <div style={styles.layer}>{children}</div>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  layer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
