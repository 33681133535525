import { useEffect } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import Form from '../../../components/atomic/formik/Form';
import { t } from '../../../services/translations';
import { contributionSpreadingFieldValidators } from '../../../components/atomic/formik/ContributionSpreadingField';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { SendSmsEmergency } from '../../../entities/SendSmsEmergency';
import { sendSmsEmergency } from '../../../services/api/sms.emergency.api';
import { alertInfo } from '../../../services/utils';
import TextField from '../../../components/atomic/formik/TextField';
import SubmitButton from '../../../components/atomic/formik/SubmitButton';
import { AppScreen } from '../../../components/containers/AppScreen';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import SmsSendContributionSpreadingField from '../../../components/atomic/formik/SmsSendContributionSpreadingField';

export const schema = Yup.object({
  message: Yup.string().min(1).max(160).required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export default function EmergencySmsSendScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: SendSmsEmergency) => {
      await sendSmsEmergency(user, values);
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        keyboardDismissMode="on-drag"
      >
        <Form<SendSmsEmergency>
          initialValues={{ message: '' } as any}
          onSubmit={mutateAsync as any}
          validationSchema={schema}
          validateOnMount
        >
          <TextField name="message" mandatory required title={t('message')} maxLength={160} multiline />
          <SmsSendContributionSpreadingField />
          <SubmitButton text={t('send')} />
        </Form>
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
