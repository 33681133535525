import React, { useState } from 'react';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { EditCategory } from './EditCategory';
import { ManageCategoriesOrder } from './ManageCategoriesOrder';
import { Category } from '../../entities/Category';
import { AppScreen } from '../../components/containers/AppScreen';

export function ManageCategoryScreen(props: { navigation: NavigationProp<ParamListBase> }) {
  const [categoryEditing, setCategoryEditing] = useState<undefined | Category>(undefined);

  const display = !!categoryEditing ? (
    <EditCategory close={() => setCategoryEditing(undefined)} category={categoryEditing} />
  ) : (
    <ManageCategoriesOrder onEdit={setCategoryEditing} />
  );

  return <AppScreen>{display}</AppScreen>;
}
