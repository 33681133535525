import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';

export interface CompanyServiceReduced {
  id: number;
  libelle: string;
  count?: number;
}

export async function getCompanyServices(companyId: number | string, token: string): Promise<CompanyServiceReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseServices/entreprise/' + companyId, {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.id, libelle: r.libelle })));
}

export async function getCompanyServicesWithCount(
  companyId: number | string,
  token: string
): Promise<CompanyServiceReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseServices/entrepriseWithCount/' + companyId, {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.service.id, libelle: r.service.libelle, count: r.count })));
}

export interface CompanyGeoSiteReduced {
  id: number;
  geoSite: string;
  count?: number;
}

export async function getCompanyGeoSites(companyId: number | string, token: string): Promise<CompanyGeoSiteReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseSiteGeos/entreprise/' + companyId, {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.id, geoSite: r.siteGeo })));
}

export async function getCompanyGeoSitesWithCount(
  companyId: number | string,
  token: string
): Promise<CompanyGeoSiteReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseSiteGeos/entrepriseWithCount/' + companyId, {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.sitegeo.id, geoSite: r.sitegeo.siteGeo, count: r.count })));
}

export async function getCompanyGeoSitesWithServices(
  companyId: number | string,
  token: string
): Promise<Record<string, string[]>> {
  return axios
    .get(apiUrlBase + 'EntrepriseSiteGeos/entreprise/' + companyId + '/services', {
      headers: headers(token),
    })
    .then((response) => response.data);
}

export async function getCompanyGeoSitesWithServicesWithCount(
  companyId: number | string,
  token: string
): Promise<{
  [key: number]: { service: number; count: number }[];
}> {
  return axios
    .get(apiUrlBase + 'EntrepriseSiteGeos/entreprise/' + companyId + '/servicesWithCount', {
      headers: headers(token),
    })
    .then((response) => response.data);
}

export interface CompanyGroupReduced {
  id: number;
  label: string;
  count?: number;
}

export async function getCompanyGroups(token: string): Promise<CompanyGroupReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseGroup', {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.id, label: r.label })));
}

export async function getLoggedUserCompanyGroups(token: string): Promise<CompanyGroupReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseGroup/logged-user', {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.id, label: r.label })));
}

export async function getLoggedUserCompanyGroupsWithCount(token: string): Promise<CompanyGroupReduced[]> {
  return axios
    .get(apiUrlBase + 'EntrepriseGroup/logged-userWithCount', {
      headers: headers(token),
    })
    .then((response) => response.data)
    .then((response: any[]) => response.map((r) => ({ id: r.group.id, label: r.group.label, count: r.count })));
}

export async function getCompany(companyId: number | string): Promise<CompanyResponse> {
  return axios.get(apiUrlBase + 'Entreprises/' + companyId, {
    headers: headers(),
  });
}

export async function getCompanyUsersCount(companyId: number | string, token: string): Promise<number> {
  return axios
    .get(apiUrlBase + 'Entreprises/userentreprisecount/' + companyId, {
      headers: headers(token),
    })
    .then((r) => r.data);
}

export type CompanyResponse = {
  id: number;
  fichierIdLogo: number;
};
