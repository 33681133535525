import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { ResponsiveView } from './ResponsiveView';

export interface ResponsiveColumnProps {
  children?: ReactNode;
  first?: boolean;
  bigScreenStyle?: StyleProp<ViewStyle>;
}

export function ResponsiveColumn(props: ResponsiveColumnProps) {
  return (
    <ResponsiveView
      style={null}
      bigScreenStyle={[
        {
          marginRight: props.first ? 10 : 0,
          flex: 1,
        },
        props.bigScreenStyle,
      ]}
    >
      {props.children}
    </ResponsiveView>
  );
}
