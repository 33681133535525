import { useState } from 'react';
import { View, ViewProps } from 'react-native';

export type SquaredViewProps = ViewProps & {
  fit: 'vertical' | 'horizontal';
};

export default function SquaredView({ fit, style, ...props }: SquaredViewProps) {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });

  return (
    <View
      onLayout={(event) => {
        const { width, height } = event.nativeEvent.layout;
        setSize({ width, height });
      }}
      style={[fit === 'vertical' ? { width: size.height } : { height: size.width }, style]}
      {...props}
    />
  );
}
