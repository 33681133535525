import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppScreen } from '../../../components/containers/AppScreen';
import { User } from '../../../entities/User';
import { UserProfileOverlay } from '../../../components/atomic/UserProfileOverlay';
import { GetUsersFilters } from '../../../entities/GetUsersFilters';
import UserFilters from '../../../components/molecules/UserFilters';
import { addDays, startOfToday } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { getUserBirthDays } from '../../../services/api/user.api';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { SectionList, StyleSheet, View } from 'react-native';
import { ProfileRow } from '../../../components/atomic/ProfileRow';
import AppText, { Mode } from '../../../components/atomic/AppText';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { t } from '../../../services/translations';
import { DateTime } from 'luxon';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { setLoading } from '../../../store/action';

export default function BirthdaysScreen() {
  const dispatch = useDispatch();
  const loggedUser = useLoggedUser();
  const [showProfileUser, setShowProfileUser] = useState<null | User>(null);

  const [filters, setFilters] = useState<GetUsersFilters>({
    search: '',
    services: [],
    siteGeos: [],
  });

  const { data, isLoading, refetch } = useQuery(['birthdays', JSON.stringify(filters)], () =>
    getUserBirthDays(loggedUser, filters)
  );

  const today = startOfToday();
  const tomorrow = addDays(startOfToday(), 1);

  const todayItems = [
    ...(data?.todayBirthDays.map((user) => ({ user, seniorityYears: undefined })) || []),
    ...(data?.todaySeniorityDates || []),
  ];

  const tomorrowItems = [
    ...(data?.tomorowBirthDays.map((user) => ({ user, seniorityYears: undefined })) || []),
    ...(data?.tomorowSeniorityDates || []),
  ];

  const weekItems = [
    ...(data?.weekBirthDays.map((user) => ({ user, seniorityYears: undefined })) || []),
    ...(data?.weekSeniorityDates || []),
  ];

  const monthItems = [
    ...(data?.monthBirthDays.map((user) => ({ user, seniorityYears: undefined })) || []),
    ...(data?.monthSeniorityDates || []),
  ];

  const otherItems = [
    ...(data?.otherBirthDays.map((user) => ({ user, seniorityYears: undefined })) || []),
    ...(data?.otherSeniorityDates || []),
  ];

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [dispatch, isLoading]);

  return (
    <AppScreen>
      <UserFilters filters={filters} onChange={setFilters} />
      <SectionList
        onRefresh={refetch}
        refreshing={isLoading}
        style={styles.list}
        sections={[
          ...(todayItems.length > 0
            ? [
                {
                  title: `${t('today')} (${DateTime.fromJSDate(today).toFormat('dd/MM/yyyy')})`,
                  data: todayItems.map((item) => ({ ...item, section: 'today' })),
                },
              ]
            : []),
          ...(tomorrowItems.length
            ? [
                {
                  title: `${t('tomorow')} (${DateTime.fromJSDate(tomorrow).toFormat('dd/MM/yyyy')})`,
                  data: tomorrowItems.map((item) => ({ ...item, section: 'tomorrow' })),
                },
              ]
            : []),
          ...(weekItems.length
            ? [
                {
                  title: t('this_week'),
                  data: weekItems.map((item) => ({ ...item, section: 'week' })),
                },
              ]
            : []),
          ...(monthItems.length
            ? [
                {
                  title: t('this_month'),
                  data: monthItems.map((item) => ({ ...item, section: 'month' })),
                },
              ]
            : []),
          ...(otherItems.length
            ? [
                {
                  title: t('others'),
                  data: otherItems.map((item) => ({ ...item, section: 'other' })),
                },
              ]
            : []),
        ]}
        keyExtractor={(item) => {
          return `${item.section}__${item.seniorityYears}__${item.user.id.toString()}`;
        }}
        renderItem={({ item }) => (
          <TouchableOpacity style={styles.userRow} onPress={() => setShowProfileUser(item.user)}>
            <ProfileRow user={item.user} style={styles.profile} />
            <View style={styles.right}>
              <View style={styles.userInfos}>
                <FontAwesome6
                  size={18}
                  name={item.seniorityYears && item.seniorityYears > 0 ? 'medal' : 'cake-candles'}
                  color={PRIMARY_COLOR}
                />
                {item.seniorityYears && item.seniorityYears > 0 ? (
                  <AppText mode={Mode.BOLD} style={styles.userRowText}>
                    {item.seniorityYears > 1
                      ? t('seniority_x_years', { count: item.seniorityYears })
                      : t('seniority_x_year', { count: item.seniorityYears })}
                  </AppText>
                ) : (
                  <AppText mode={Mode.BOLD} style={styles.userRowText}>
                    {t('birthday')}
                  </AppText>
                )}
              </View>
              {['week', 'month', 'other'].includes(item.section) && (
                <AppText style={styles.userRowText}>
                  {DateTime.fromJSDate(
                    new Date(
                      (item.seniorityYears && item.seniorityYears > 0
                        ? item.user.seniorityDate
                        : item.user.birthDate) as string
                    )
                  ).toFormat(item.seniorityYears && item.seniorityYears > 0 ? 'dd/MM/yyyy' : 'dd/MM')}
                </AppText>
              )}
            </View>
          </TouchableOpacity>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <AppText style={styles.sectionTitle} mode={Mode.HEADER2}>
            {title}
          </AppText>
        )}
        ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_birthdays')}</AppText>}
      />
      {showProfileUser && (
        <UserProfileOverlay
          showAdminTag={true}
          onBackdropPress={() => setShowProfileUser(null)}
          userProfileData={showProfileUser}
          isVisible={!!showProfileUser}
        />
      )}
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  list: {
    paddingVertical: 16,
  },
  sectionTitle: {
    color: PRIMARY_COLOR,
  },
  userRow: {
    flexDirection: 'row',
    gap: 20,
  },
  userRowText: {
    color: PRIMARY_COLOR,
  },
  profile: {
    flex: 1,
    borderBottomWidth: 0,
    overflow: 'hidden',
  },
  right: {
    gap: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  userInfos: {
    gap: 16,
    alignItems: 'center',
    flexDirection: 'row',
    maxWidth: 220,
    width: '100%',
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
});
