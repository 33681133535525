import { View, Dimensions, StyleProp, ViewStyle } from 'react-native';
import React, { ReactNode } from 'react';
import { isMobile } from '../../services/utils';

export interface ResponsiveViewProps {
  style?: StyleProp<ViewStyle>;
  bigScreenStyle?: StyleProp<ViewStyle>;
  children: ReactNode;
  breakpoint?: any;
}

export function ResponsiveView(props: ResponsiveViewProps) {
  const windowWidth = Dimensions.get('window').width;

  return (
    <View style={isMobile() || windowWidth < (props.breakpoint || 1080) ? props.style : props.bigScreenStyle}>
      {props.children}
    </View>
  );
}
