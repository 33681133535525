import React from 'react';
import { View } from 'react-native';
import AppText, { Mode } from './AppText';

export type BadgeProps = {
  value?: number | null;
};

export function Badge({ value }: BadgeProps) {
  return value ? (
    <View
      style={{
        backgroundColor: 'red',
        justifyContent: 'center',
        alignItems: 'center',
        width: 20,
        height: 20,
        padding: 0,
        borderRadius: 20,
        marginRight: -7,
        zIndex: 10,
      }}
    >
      <AppText style={{ color: 'white', fontSize: 10 }} mode={Mode.BOLD}>
        {value}
      </AppText>
    </View>
  ) : null;
}
