import { TouchableOpacity } from 'react-native';
import { Icon } from '@rneui/base';
import React from 'react';
import { AppSafeAreaView } from '../containers/AppSafeAreaView';
import { ScreenNames } from '../../ScreenNames';
import { useSelector } from 'react-redux';
import { RootState } from '../../services/storage';
import { ContributionType } from '../../types';
import { getRouteFromContributionType } from '../../services/utils';
import { useNavigation } from '@react-navigation/native';

export function BackButton(props: {
  // navigation?: DrawerNavigationProp<any>;
  goBack?: () => void;
  absoluteScreenName?: string;
  navigationDetails?: any;
  higher?: boolean;
  relative?: boolean;
}) {
  const detailContributionType = useSelector<RootState, ContributionType | undefined>(
    (state) => state.detailContributionType
  );
  const currentContribution = useSelector<RootState, any | undefined>((state) => state.currentContribution);
  const isNotif = useSelector<RootState, boolean | undefined>((state) => state.isNotif);
  const forceBack = useSelector<RootState, boolean | undefined>((state) => state.forceBack);
  const navigation = useNavigation<any>();

  const handlePress = props.goBack
    ? props.goBack
    : () => {
        if (detailContributionType === ContributionType.SURVEY && forceBack) {
          if (currentContribution.lovIdEtat === 57) {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: ScreenNames.AdminRoute,
                  state: {
                    index: 1,
                    routes: [
                      { name: ScreenNames.CompanyAdminMain },
                      {
                        name: ScreenNames.SurveyAdministration,
                        params: {
                          screen: ScreenNames.SurveysToApproveList,
                        },
                      },
                    ],
                  },
                },
              ],
            });
          } else {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: ScreenNames.AdminRoute,
                  state: {
                    index: 1,
                    routes: [
                      { name: ScreenNames.CompanyAdminMain },
                      {
                        name: ScreenNames.SurveyAdministration,
                        params: {
                          screen: ScreenNames.ApprovedSurveysList,
                        },
                      },
                    ],
                  },
                },
              ],
            });
          }
        } else if (props.absoluteScreenName) {
          navigation.navigate(props.absoluteScreenName, props.navigationDetails);
        } else if ((!isNotif || !detailContributionType || !!forceBack) && navigation.canGoBack()) {
          navigation.goBack();
        } else if (!!detailContributionType) {
          if (detailContributionType === ContributionType.COMPANY_NEWS && currentContribution?.lovIdEtat === 42) {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: ScreenNames.AdminRoute,
                  state: {
                    index: 1,
                    routes: [{ name: ScreenNames.CompanyAdminMain }, { name: ScreenNames.CompanyNewsAdministration }],
                  },
                },
              ],
            });
          } else if (detailContributionType === ContributionType.SUGGESTION) {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: ScreenNames.AdminRoute,
                  state: {
                    index: 1,
                    routes: [
                      { name: ScreenNames.CompanyAdminMain },
                      {
                        name: ScreenNames.SuggestionsAdministration,
                        params: {
                          screen: ScreenNames.SuggestionsToApproveList,
                        },
                      },
                    ],
                  },
                },
              ],
            });
          } else if (detailContributionType === ContributionType.CUSTOM_MODULE && currentContribution) {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: currentContribution.module?.identifier || '',
                  state: {
                    index: 0,
                    routes: [{ name: ScreenNames.CustomModuleTabs }],
                  },
                },
              ],
            });
          } else {
            navigation.navigate(getRouteFromContributionType(detailContributionType));
          }
        } else {
          navigation.navigate(ScreenNames.LoggedInHome);
        }
      };

  let renderedButton = (
    <TouchableOpacity
      onPress={handlePress}
      style={{
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255,255,255,0.4)',
        borderRadius: 60,
        margin: 10,
      }}
    >
      <Icon name="arrow-back" />
    </TouchableOpacity>
  );

  return props.relative ? (
    renderedButton
  ) : (
    <AppSafeAreaView style={{ position: 'absolute' }}>{renderedButton}</AppSafeAreaView>
  );
}
