import React from 'react';
import { Icon } from '@rneui/base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { NEWS_TRANSLATION_ENABLED } from '../../services/features';
import { PRIMARY_COLOR } from '../../styles/appColor';
import AppText from './AppText';
import { StyleSheet } from 'react-native';

export interface LanguageSelectorProps {
  language: {
    code: string;
    text: string;
  };
  onPress: (code: string) => void;
}

export default function LanguageSelector({ onPress, language: e }: LanguageSelectorProps) {
  return (
    <TouchableOpacity
      key={e.code}
      disabled={NEWS_TRANSLATION_ENABLED}
      style={styles.container}
      onPress={() => onPress(e.code)}
    >
      <Icon name="add" />
      <AppText>{e.text}</AppText>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    backgroundColor: 'white',
    borderColor: PRIMARY_COLOR,
    borderWidth: 1,
    marginRight: 10,
    padding: 5,
    borderRadius: 5,
    opacity: !NEWS_TRANSLATION_ENABLED ? 1 : 0.5,
  },
});
