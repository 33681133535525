import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../AppButton';
import { setLoading } from '../../../store/action';
import { useNavigation } from '@react-navigation/native';

export type SubmitButtonProps = {
  text: string;
  style?: StyleProp<ViewStyle>;
};

export default function SubmitButton({ text, style }: SubmitButtonProps) {
  const { submitForm, validateForm, isValid, isSubmitting, resetForm } = useFormikContext();
  const dispatch = useDispatch();
  const navigation = useNavigation();

  // Display big loading indicator if loading
  useEffect(() => {
    dispatch(setLoading(isSubmitting));
  }, [isSubmitting, dispatch]);

  const handleSubmitForm = useCallback(async () => {
    if (await validateForm()) {
      submitForm();
    }
  }, [submitForm]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetForm();
    });

    return unsubscribe;
  }, [navigation, resetForm]);

  return (
    <View style={{ marginTop: 30, marginBottom: 16 }}>
      <AppButton title={text} type={Type.PRIMARY} onPress={handleSubmitForm} enabled={isValid} style={style} />
    </View>
  );
}
