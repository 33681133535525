import { Image, StyleSheet, View } from 'react-native';
import AppText, { Mode } from './AppText';
import { vh } from '../../utils/Utils';

export default function AppQrCode() {
  return (
    <View>
      <View style={styles.container}>
        <Image source={require('../../../assets/app-qr-code.png')} style={styles.qrCodeImage} />
        <View style={styles.storeImagesContainer}>
          <Image source={require('../../../assets/drawable/download-app-store.png')} style={styles.appleStoreImage} />
          <Image source={require('../../../assets/drawable/google-play-badge.png')} style={styles.googlePlayImage} />
        </View>
      </View>
      <AppText style={styles.text} mode={Mode.SMALL}>
        Google Play and the Google Play logo are trademarks of Google LLC.
      </AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  qrCodeImage: {
    width: vh(8),
    height: vh(8),
  },
  storeImagesContainer: {
    justifyContent: 'space-between',
    flex: 1,
  },
  appleStoreImage: {
    width: vh(13),
    height: vh(4.2),
  },
  googlePlayImage: {
    width: vh(13),
    height: vh(3.88),
  },
  text: {
    width: vh(24),
    fontSize: vh(0.7),
  },
});
