import React from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as Yup from 'yup';
import ContributionAuthorizationsField from '../../../../components/atomic/formik/ContributionAuthorizationsField';
import ContributionSpreadingField, {
  contributionSpreadingFieldValidators,
} from '../../../../components/atomic/formik/ContributionSpreadingField';
import FileField from '../../../../components/atomic/formik/FileField';
import FolderPickerField from '../../../../components/atomic/formik/FolderPickerField';
import RichTextField from '../../../../components/atomic/formik/RichTextField';
import SubmitButton from '../../../../components/atomic/formik/SubmitButton';
import TextField from '../../../../components/atomic/formik/TextField';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { t } from '../../../../services/translations';

export const schema = Yup.object({
  title: Yup.string().min(1).required(t('field_required')),
  description: Yup.string().min(1).required(t('field_required')),
  document: Yup.mixed().required(t('field_required')),
  commentsActivated: Yup.boolean().required(t('field_required')),
  notifActivated: Yup.boolean().required(t('field_required')),
  emailActivated: Yup.boolean().required(t('field_required')),
  ...contributionSpreadingFieldValidators,
});

export type DocumentFormFieldsProps = {
  isEditing?: boolean | undefined;
  defaultFilename?: string | undefined;
};

export default function DocumentFormFields({ isEditing, defaultFilename }: DocumentFormFieldsProps) {
  return (
    <AppScreen>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <TextField name="title" mandatory required title={t('document_title')} />
        <RichTextField name="description" title={t('description')} required />
        <FileField name="document" title={t('document')} required defaultFilename={defaultFilename} maxSizeMB={10} />
        <FolderPickerField name="folderId" title={t('folder')} />
        <ContributionSpreadingField filterByUserRights />
        <ContributionAuthorizationsField />
        <SubmitButton text={!isEditing ? t('put') : t('update_button')} />
      </KeyboardAwareScrollView>
    </AppScreen>
  );
}
