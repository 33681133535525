import {
  activityImage,
  carPoolImage,
  documentImage,
  eventImage,
  lostPropertyImage,
  mutualAidImage,
  newsImage,
  sellImage,
  suggestionImage,
  surveyImage,
} from '../assets';
import { apiUrlBase } from '../../../configuration';
import { ContributionType } from '../../types';
import { MediaToPictureSource } from '../../utils/Medias';
const defaultProfilePic = require('../../../assets/avDefault.png');

export function getPictureURI(pictureId: number | string) {
  if (pictureId == null || pictureId == undefined || Number(pictureId) < 0) return defaultProfilePic;
  return { uri: apiUrlBase + 'Fichiers/image/' + pictureId };
}

export function getPictureSource(pictureId?: number | string | null) {
  if (pictureId == null || pictureId == undefined || Number(pictureId) < 0) return defaultProfilePic;
  return { uri: apiUrlBase + 'Fichiers/image/' + pictureId };
}
export function getDocumentURI(id: string | number) {
  return apiUrlBase + 'Fichiers/image/' + id;
}

export function _getPrefixByContributionType(contributionType: ContributionType) {
  let urlPrefixType = '';

  switch (contributionType) {
    case ContributionType.SELL:
      urlPrefixType = 'Ventes';
      break;
    case ContributionType.ACTIVITY:
      urlPrefixType = 'Hobbies';
      break;
    case ContributionType.MUTUAL_AID:
      urlPrefixType = 'Services';
      break;
    case ContributionType.CARPOOL:
      urlPrefixType = 'Covoiturages';
      break;
    case ContributionType.EVENT:
      urlPrefixType = 'Evenements';
      break;
    case ContributionType.FORMATION:
      urlPrefixType = 'Formations';
      break;
    case ContributionType.LOST_PROPERTY:
      urlPrefixType = 'Objets';
      break;
    case ContributionType.SURVEY:
      urlPrefixType = 'Sondages';
      break;
    case ContributionType.SUGGESTION:
      urlPrefixType = 'Idees';
      break;
    case ContributionType.COMPANY_NEWS:
      urlPrefixType = 'CompanyNews';
      break;
    case ContributionType.DOCUMENT:
      urlPrefixType = 'Kiosques';
      break;
    default:
      throw Error('Contribution type is not set');
  }
  return urlPrefixType;
}

export function _postPrefixByContributionType(contributionType: ContributionType) {
  let urlPrefixType = '';
  switch (contributionType) {
    case ContributionType.SELL:
      urlPrefixType = 'vente';
      break;
    case ContributionType.ACTIVITY:
      urlPrefixType = 'hobbies';
      break;
    case ContributionType.MUTUAL_AID:
      urlPrefixType = 'service';
      break;
    case ContributionType.CARPOOL:
      urlPrefixType = 'covoiturage';
      break;
    case ContributionType.EVENT:
      urlPrefixType = 'event';
      break;
    case ContributionType.FORMATION:
      urlPrefixType = 'training';
      break;
    case ContributionType.LOST_PROPERTY:
      urlPrefixType = 'objet';
      break;
    case ContributionType.SURVEY:
      urlPrefixType = 'sondage';
      break;
    default:
      throw Error('Contribution type is not set');
  }
  return urlPrefixType;
}

export function getContributionImageSource(
  id: string | number | undefined,
  contributionType: ContributionType,
  typedData?: any
) {
  if (id === null || id === undefined) {
    switch (contributionType) {
      case ContributionType.SELL:
        return sellImage;
      case ContributionType.ACTIVITY:
        return activityImage;
      case ContributionType.MUTUAL_AID:
        return mutualAidImage;
      case ContributionType.CARPOOL:
        return carPoolImage;
      case ContributionType.EVENT:
        return eventImage;
      case ContributionType.FORMATION:
        return eventImage;
      case ContributionType.LOST_PROPERTY:
        return lostPropertyImage;
      case ContributionType.SURVEY:
        return surveyImage;
      case ContributionType.SUGGESTION:
        return suggestionImage;
      case ContributionType.COMPANY_NEWS:
        if (!!typedData?.media) return MediaToPictureSource(typedData.media);
        return newsImage;
      case ContributionType.DOCUMENT:
        return documentImage;
    }
    return newsImage;
  }
  return getPictureURI(id);
}

export function isAnonymous(item: any, contributionType: ContributionType) {
  if (contributionType === ContributionType.SUGGESTION) {
    return item.anonyme;
  }

  return false;
}
