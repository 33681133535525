import React from 'react';
import { View } from 'react-native';
import { BaseMediaProps, getYoutubeIdFromURL } from '../../../models/Media';

export interface YoutubeProps extends BaseMediaProps {}

const Youtube = ({ url }: YoutubeProps) => (
  <View>
    <iframe width="720" height="480" src={`https://www.youtube.com/embed/${getYoutubeIdFromURL(url)}`}></iframe>
  </View>
);

export default Youtube;
