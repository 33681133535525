import { Image, ImageBackground, SafeAreaView, View } from 'react-native';
import { globalStyle } from '../../styles/globalStyle';
import React, { ReactNode } from 'react';
import Logo from '../atomic/Logo';
import { Space3 } from '../../styles/spaces';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { isMobile } from '../../services/utils';
import { useDeviceSize } from '../../hooks/useDeviceSize';

const mobileBackground = require('../../../assets/background-white-shapes.png');

export function TwoColumnsLayout(props: { children: ReactNode; background: any; contentOnRight?: boolean }) {
  const { isSmallDevice } = useDeviceSize();
  const isInCompactMode = isSmallDevice || isMobile();

  const column1 = (
    <>
      {isInCompactMode ? (
        <View />
      ) : (
        <View style={globalStyle.column}>
          <Image style={{ flex: 1 }} resizeMode="cover" source={props.background} />
        </View>
      )}
    </>
  );

  const column2 = (
    <View style={globalStyle.column}>
      <ImageBackground source={isMobile() ? mobileBackground : null} style={{ flex: 1 }}>
        <SafeAreaView style={globalStyle.main_column}>
          <View
            style={[
              { flex: 1, alignContent: 'center', justifyContent: 'center', padding: 10 },
              isInCompactMode ? {} : { maxWidth: 600 },
            ]}
          >
            <Logo style={isInCompactMode ? { width: '70%', alignSelf: 'center' } : {}} />
            <Space3 />
            {props.children}
          </View>
        </SafeAreaView>
      </ImageBackground>
    </View>
  );

  if (props.contentOnRight)
    return (
      <KeyboardAwareScrollView
        bounces={false}
        style={[globalStyle.two_columns_layout]}
        contentContainerStyle={globalStyle.two_columns_layout}
      >
        {column1}
        {column2}
      </KeyboardAwareScrollView>
    );
  return (
    <KeyboardAwareScrollView
      bounces={false}
      style={[globalStyle.two_columns_layout]}
      contentContainerStyle={globalStyle.two_columns_layout}
    >
      {column2}
      {column1}
    </KeyboardAwareScrollView>
  );
}
