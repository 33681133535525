import React from 'react';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import AdminListCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/AdminListCustomModuleContributionScreen';
import CreateCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/CreateCustomModuleContributionScreen';
import ListCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/ListCustomModuleContributionScreen';
import UpdateCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/UpdateCustomModuleContributionScreen';
import { t } from '../services/translations';
import { isAdmin, isMobile, isSubAdmin } from '../services/utils';
import useLoggedUser from '../hooks/useLoggedUser';
import BackIcon from '../components/atomic/BackIcon';

type MainScreenProps = {
  route: {
    params: {
      moduleIdentifier: string;
    };
  };
};

function MainScreen({ route }: MainScreenProps) {
  const Tab = createMaterialTopTabNavigator();
  const user = useLoggedUser();
  const displayMine = isAdmin(user) || isSubAdmin(user);

  return displayMine ? (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.CustomModuleList}
        component={ListCustomModuleContributionScreen}
        options={{ title: t('all') }}
        initialParams={route.params}
      />
      <Tab.Screen
        name={ScreenNames.CustomModuleAdminList}
        options={{ title: t('my_contributions') }}
        component={AdminListCustomModuleContributionScreen}
        initialParams={route.params}
      />
    </Tab.Navigator>
  ) : (
    <ListCustomModuleContributionScreen route={route} />
  );
}

export function CustomModuleRouter(props: DrawerScreenProps<any>) {
  const Stack = createStackNavigator();
  const moduleIdentifier: string = props.route.params?.moduleIdentifier;

  return (
    <Stack.Navigator screenOptions={{ headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.CustomModuleTabs}
        component={MainScreen}
        options={{ title: '', headerShown: false }}
        initialParams={{
          moduleIdentifier,
        }}
      />
      <Stack.Screen
        name={ScreenNames.CustomModuleCreate}
        component={CreateCustomModuleContributionScreen}
        options={{ title: t('publish') }}
        initialParams={{
          moduleIdentifier,
        }}
      />
      <Stack.Screen
        name={ScreenNames.CustomModuleUpdate}
        component={UpdateCustomModuleContributionScreen}
        options={{ title: t('edit') }}
        initialParams={{
          moduleIdentifier,
        }}
      />
    </Stack.Navigator>
  );
}
