import React, { useCallback, useState } from 'react';
import { useField } from 'formik';
import PhotoFormField, { PhotoFormFieldProps } from '../../containers/PhotoFormField';
import { PickPhotoOrDocumentResult } from '../../../services/utils';

export type PhotoFieldProps = Omit<
  Omit<Omit<Omit<PhotoFormFieldProps, 'photo'>, 'onPhotoChanged'>, 'videoUrl'>,
  'onVideoUrlChanged'
> & {
  name: string;
};

export default function PhotoField({ name, ...rest }: PhotoFieldProps) {
  const [, , helper] = useField(name);
  const [videoUrlField, , videoUrlHelper] = useField('videoUrl');
  const [, , imageIdHelper] = useField('imageId');
  const [, , documentIdHelper] = useField('documentId');
  const [photo, setPhoto] = useState<PickPhotoOrDocumentResult | null>(null);

  const onPhotoChanged = useCallback(
    (file: PickPhotoOrDocumentResult | null) => {
      helper.setValue(file || undefined, true);
      imageIdHelper.setValue(undefined);
      documentIdHelper.setValue(undefined);
      setPhoto(file);
    },
    [helper, imageIdHelper, documentIdHelper]
  );

  const onVideoUrlChanged = useCallback(
    (videoUrl: string | null) => {
      videoUrlHelper.setValue(videoUrl, true);
      imageIdHelper.setValue(undefined);
      documentIdHelper.setValue(undefined);
    },
    [videoUrlHelper, imageIdHelper, documentIdHelper]
  );

  return (
    <PhotoFormField
      {...rest}
      photo={photo}
      videoUrl={videoUrlField.value}
      onPhotoChanged={onPhotoChanged}
      onVideoUrlChanged={onVideoUrlChanged}
    />
  );
}
