import { ContributionType } from '../../types';
import { Label } from './FeaturedLabel';
import React from 'react';
import { contributionTypeToColor, contributionTypeToText } from '../../services/api/contributions';

export function ContributionLabel(props: { contributionType: ContributionType }) {
  return (
    <Label
      text={contributionTypeToText(props.contributionType)}
      style={{ flex: undefined }}
      color={contributionTypeToColor(props.contributionType)}
    />
  );
}
