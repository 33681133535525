import { TextInput } from 'react-native';
import { LIGHT_GRAY } from '../../styles/appColor';
import React from 'react';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';

export function ValidationCodeField(props: { onCodeEntered: (value: string) => void }) {
  return (
    <TextInput
      onChangeText={(value) => (value.length == 4 ? props.onCodeEntered(value) : null)}
      keyboardType="number-pad"
      style={{
        borderWidth: 1,
        borderColor: LIGHT_GRAY,
        width: 100,
        height: 50,
        textAlign: 'center',
        textAlignVertical: 'center',
        borderRadius: 8,
        fontSize: 40,
        fontFamily: FONT_FAMILY_DEFAULT.bold,
        alignSelf: 'center',
      }}
    />
  );
}
