import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type BackIconProps = {
  tintColor: string;
};

export default function BackIcon({ tintColor, ...props }: BackIconProps) {
  return (
    <FontAwesome6
      name={Platform.OS === 'ios' ? 'chevron-left' : 'arrow-left'}
      size={20}
      color={tintColor}
      style={styles.icon}
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 10,
  },
});
