import React from 'react';
import { ImageSourcePropType } from 'react-native';
import Youtube from '../components/containers/medias/Youtube';
import { getYoutubeIdFromURL } from '../models/Media';
import { youtube } from '../services/assets';

export interface MediaInfoProps {
  imagePreview: (url: string) => string;
  isMedia: (url: string) => boolean;
  component: (props: { url: string }) => JSX.Element;
  logo?: ImageSourcePropType;
}

export const ExtractUri = (media: string) => SplitMedia(media)[1];

export const MediaToPictureSource = (media: string) => {
  const [mediaName, url] = SplitMedia(media);

  return { uri: MediasInfos[mediaName].imagePreview(url) };
};

export const MediaComponent = (key: string, url: string) => {
  const Component = MediasInfos[key].component;

  return <Component url={url} />;
};

export const SplitMedia = (media: string) => {
  const idx = media.indexOf(':');
  const mediaName = media.substring(0, idx);
  const url = media.substring(idx + 1);

  return [mediaName, url];
};

export const GetMediaInfo = (url: string): MediaInfoProps | undefined => {
  for (var mediaInfoName of Object.keys(MediasInfos)) {
    const mediaInfo = MediasInfos[mediaInfoName];
    if (mediaInfo.isMedia(url)) return mediaInfo;
  }
  return undefined;
};

export const MediasInfos: { [key: string]: MediaInfoProps } = {
  Youtube: {
    imagePreview: (url) => `http://img.youtube.com/vi/${getYoutubeIdFromURL(url)}/0.jpg`,
    isMedia: (url) => getYoutubeIdFromURL(url) !== '' && getYoutubeIdFromURL(url) !== undefined,
    component: Youtube,
    logo: youtube,
  },
};
