import { Image, ImageStyle, StyleProp, View, ViewStyle } from 'react-native';
import { getPictureSource } from '../../services/api/helper.api';
import React from 'react';

export function ProfilePicture(props: {
  borderStyle?: ViewStyle;
  borderColor?: string;
  pictureId?: number | string | null;
  style?: StyleProp<ImageStyle>;
  size: 'small' | 'regular' | 'small-medium' | 'medium' | 'big';
}) {
  let size = 24;
  switch (props.size) {
    case 'small':
      size = 24;
      break;
    case 'regular':
      size = 78;
      break;
    case 'small-medium':
      size = 50;
      break;
    case 'medium':
      size = 60;
      break;
    case 'big':
      size = 90;
      break;
  }
  return (
    <View
      style={
        props.borderColor !== undefined
          ? {
              ...props.borderStyle,
              backgroundColor: props.borderColor,
              padding: 3,
              borderRadius: size,
            }
          : {}
      }
    >
      <Image
        source={getPictureSource(props.pictureId)}
        style={[props.style, { width: size, height: size, borderRadius: size }]}
      />
    </View>
  );
}
