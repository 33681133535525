// A text view that will truncate the text and show a three dot at the end
// if the text goes over the dimensions of the view

import React, { useMemo, useState } from 'react';
import { View, StyleSheet, Text, TextProps } from 'react-native';
import Truncate from 'react-truncate';

export type ThreeDotTextViewProps = Omit<TextProps, 'numberOfLines'> & {
  lineHeights: number;
};

export default function ThreeDotText({ children, lineHeights, ...props }: ThreeDotTextViewProps) {
  const [containerHeight, setContainerHeight] = useState(0);
  const [reload, setReload] = useState(0);

  const numberOfLines = useMemo(() => {
    if (containerHeight === 0) {
      return 1;
    }

    return Math.floor(containerHeight / lineHeights) || 1;
  }, [containerHeight, lineHeights]);

  const truncatedText = useMemo(() => {
    return (
      <Truncate key={reload} lines={numberOfLines} ellipsis="...">
        {children}
      </Truncate>
    );
  }, [numberOfLines, reload, children]);

  // Reload after 100ms seconds to update the number of lines
  React.useEffect(() => {
    const interval = setTimeout(() => {
      setReload((prev) => prev + 1);
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <View
      style={styles.container}
      onLayout={(event) => {
        setContainerHeight(event.nativeEvent.layout.height);
      }}
    >
      <Text {...props}>{truncatedText}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
});
