import React from 'react';
import { StyleSheet, View } from 'react-native';
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT, SECONDARY_COLOR } from '../../styles/appColor';
import AppText, { Mode } from './AppText';

export type StatisticsSquareProps = {
  title: string;
  value: string | React.ReactNode;
};

export default function StatisticsSquare({ title, value }: StatisticsSquareProps) {
  return (
    <View style={styles.container}>
      <AppText style={styles.title} mode={Mode.BOLD}>
        {title}
      </AppText>
      <AppText style={styles.value} mode={Mode.HEADER2}>
        {value}
      </AppText>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: 150,
    height: 150,
    justifyContent: 'space-around',
    alignItems: 'center',
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  title: {
    color: PRIMARY_COLOR_LIGHT,
    textAlign: 'center',
  },
  value: {
    color: PRIMARY_COLOR,
    textAlign: 'center',
    marginBottom: 0,
    fontSize: 16,
  },
});
