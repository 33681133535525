import { Licence } from '../entities/Licence';
import { UserSaved } from './storage';

export const hasLicence = (user: UserSaved, selector: (licence: Licence) => boolean): boolean => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;

  for (const licence of user.entreprise.entreprisesLicences) {
    if (selector(licence.licence)) return true;
  }

  return false;
};

export const hasLicenceSell = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionVente;
};

export const hasLicenceActivity = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionHobbie;
};

export const hasLicenceMutualAid = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionService;
};

export const hasLicenceCarPool = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionCovoiturage;
};

export const hasLicenceEvent = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionEvenement;
};

export const hasLicenceTraining = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionFormation;
};

export const hasLicenceOrganizationalChart = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionOrganigrame;
};

export const hasLicenceLostProperty = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.contributionObjet;
};

export const hasLicenceSurveyOrSuggestion = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return (
    user.entreprise.entreprisesLicences[0].licence.cSondage || user.entreprise.entreprisesLicences[0].licence.cBoiteIdee
  );
};

export const hasLicenceSurvey = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.cSondage;
};

export const hasLicenceSuggestion = (user: UserSaved) => {
  if (user?.entreprise?.entreprisesLicences === undefined) return false;
  return user.entreprise.entreprisesLicences[0].licence.cBoiteIdee;
};

export const hasLicenceDocument = (user: UserSaved) => hasLicence(user, (x) => x.contributionKiosque ?? true);

export const hasLicenceTeams = (user: UserSaved) => hasLicence(user, (x) => x.teams);

export const hasLicenceSlack = (user: UserSaved) => hasLicence(user, (x) => x.slack);

export const hasLicenceGoogleChat = (user: UserSaved) => hasLicence(user, (x) => x.googleChat);

export const hasLicenceMessaging = (user: UserSaved) => hasLicence(user, (x) => x.messagerie);

export const hasLicenceExternalScreen = (user: UserSaved) => hasLicence(user, (x) => x.externalScreen);

export const hasLicenceEmergencySms = (user: UserSaved) => hasLicence(user, (x) => x.emergencySms);
