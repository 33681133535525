import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { t } from '../../services/translations';
import { CompanyGeoSiteReduced, CompanyServiceReduced } from '../../services/api/company.api';
import { ContributionsByIntervalLineChart } from '../atomic/ContributionsByIntervalLineChart';
import { FormFieldPicker } from '../atomic/FormField';
import { getContributionsCountByIntervalStatistics } from '../../services/api/statistics.api';
import { alertInfo } from '../../services/utils';
import { contributionTypeToText } from '../../services/api/contributions';
import { ContributionType } from '../../types';
import useLoggedUser from '../../hooks/useLoggedUser';

export type ContributionCountStatisticsProps = {
  years: {
    label: string;
    value: number;
  }[];
  services: CompanyServiceReduced[];
  siteGeos: CompanyGeoSiteReduced[];
};

const contributionTypes = [
  { label: contributionTypeToText(ContributionType.SELL), value: ContributionType.SELL },
  { label: contributionTypeToText(ContributionType.ACTIVITY), value: ContributionType.ACTIVITY },
  { label: contributionTypeToText(ContributionType.MUTUAL_AID), value: ContributionType.MUTUAL_AID },
  { label: contributionTypeToText(ContributionType.CARPOOL), value: ContributionType.CARPOOL },
  { label: contributionTypeToText(ContributionType.EVENT), value: ContributionType.EVENT },
  { label: contributionTypeToText(ContributionType.FORMATION), value: ContributionType.FORMATION },
  { label: contributionTypeToText(ContributionType.LOST_PROPERTY), value: ContributionType.LOST_PROPERTY },
  { label: contributionTypeToText(ContributionType.SURVEY), value: ContributionType.SURVEY },
  { label: contributionTypeToText(ContributionType.SUGGESTION), value: ContributionType.SUGGESTION },
  { label: contributionTypeToText(ContributionType.DOCUMENT), value: ContributionType.DOCUMENT },
];

export function ContributionCountStatistics({ years, services, siteGeos }: ContributionCountStatisticsProps) {
  const user = useLoggedUser();

  const [contributionType, setContributionType] = useState<null | ContributionType>(null);
  const [year, setYear] = useState<null | number>(null);
  const [contributionsServiceId, setContributionsServiceId] = useState<null | number>(null);
  const [contributionsSiteGeoId, setContributionsSiteGeoId] = useState<null | number>(null);

  const {
    data: contributionsData,
    isLoading: isLoadingContributions,
    isError,
  } = useQuery(
    ['contributions-count-by-interval', year, contributionsServiceId, contributionsSiteGeoId, contributionType],
    () =>
      getContributionsCountByIntervalStatistics(user, {
        type: contributionType,
        year,
        serviceId: contributionsServiceId,
        siteGeoId: contributionsSiteGeoId,
      })
  );

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <>
      <View style={styles.filtersContainer}>
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={contributionType}
          onChangeText={(value: number | string) => {
            setContributionType(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={contributionTypes}
          title={t('contribution_type')}
        />
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={year}
          onChangeText={(value: number | string) => {
            setYear(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={years}
          title={t('time_interval')}
          defaultButtonText={t('since_launch')}
        />
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={contributionsServiceId}
          onChangeText={(value: number | string) => {
            setContributionsServiceId(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={services.map((service) => ({
            label: service.libelle,
            value: service.id,
          }))}
          title={t('service')}
        />
        <FormFieldPicker
          style={styles.filtersDropdown}
          defaultChoiceOnMobile
          defaultValue={contributionsSiteGeoId}
          onChangeText={(value: number | string) => {
            setContributionsSiteGeoId(!Number.isNaN(parseInt(value.toString())) ? parseInt(value.toString()) : null);
          }}
          data={siteGeos.map((siteGeo) => ({
            label: siteGeo.geoSite,
            value: siteGeo.id,
          }))}
          title={t('geo_site')}
        />
      </View>
      <ContributionsByIntervalLineChart data={contributionsData} isLoading={isLoadingContributions} />
    </>
  );
}

const styles = StyleSheet.create({
  filtersContainer: {
    flexDirection: 'row',
  },
  filtersDropdown: {
    padding: 10,
    flex: 1,
  },
});
