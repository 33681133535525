import React from 'react';
import { StyleSheet } from 'react-native';
import { Icon } from '@rneui/base';
import { useDispatch } from 'react-redux';
import { t } from '../../services/translations';
import { alertInfo } from '../../services/utils';
import { setLoading } from '../../store/action';
import { ALERT_COLOR } from '../../styles/appColor';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';

export type RemoveLittleButtonProps = {
  onPress: () => Promise<any>;
};

export default function RemoveLittleButton({ onPress }: RemoveLittleButtonProps) {
  const dispatch = useDispatch();

  return (
    <TwoActionsGenericPopup
      title={t('wish_to_remove')}
      button1Title={t('yes')}
      button2Title={t('no')}
      onPressButton1={() => {
        dispatch(setLoading(true));
        onPress()
          .then(() => alertInfo(t('removed_ok')))
          .finally(() => {
            dispatch(setLoading(false));
          });
      }}
      onPressButton2={() => {}}
    >
      <Icon name="close" type="ionicon" color={ALERT_COLOR} size={12} style={styles.icon} />
    </TwoActionsGenericPopup>
  );
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 10,
  },
});
