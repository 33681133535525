import React, { ReactNode } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Icon } from '@rneui/base';
import { LIGHT_GRAY, PRIMARY_COLOR } from '../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import AppText from './AppText';

export type DataTableHeaderProps<T> = {
  children: ReactNode;
  sortKey?: keyof T;
  sortBy?: null | { key: string; order: 'asc' | 'desc' };
  setSortBy?: (value: null | { key: keyof T; order: 'asc' | 'desc' }) => void;
  containerStyle?: ViewStyle;
  style?: ViewStyle;
};

export function DataTableHeader<T>({
  children,
  sortKey,
  sortBy,
  setSortBy,
  style,
  containerStyle,
}: DataTableHeaderProps<T>) {
  const content = (
    <AppText
      style={[styles.text, setSortBy && sortKey && sortBy && sortBy.key === sortKey ? styles.sorted : undefined, style]}
    >
      {setSortBy && sortKey && (
        <Icon
          name={sortBy && sortBy.key === sortKey && sortBy.order === 'asc' ? 'arrow-up' : 'arrow-down'}
          type="feather"
          size={18}
          style={styles.sortIcon}
          color={sortBy && sortBy.key === sortKey ? PRIMARY_COLOR : LIGHT_GRAY}
        />
      )}
      {children}
    </AppText>
  );

  return setSortBy && sortKey ? (
    <TouchableOpacity
      style={containerStyle}
      onPress={() => {
        if (!sortBy || sortBy.key !== sortKey) {
          setSortBy({ key: sortKey, order: 'desc' });
        } else if (sortBy.order === 'desc') {
          setSortBy({ key: sortKey, order: 'asc' });
        } else {
          setSortBy(null);
        }
      }}
    >
      {content}
    </TouchableOpacity>
  ) : (
    <View style={containerStyle}>{content}</View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    marginBottom: 11,
    color: PRIMARY_COLOR,
  },
  sorted: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
  },
  sortIcon: {
    paddingRight: 10,
  },
});
