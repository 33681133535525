import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import Form from '../../../../components/atomic/formik/Form';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { CompanyGroupFormFields, schema } from '../../../../components/molecules/CompanyGroupFormFields';
import { User } from '../../../../entities/User';
import { createCompanyGroup } from '../../../../services/api/company.group.api';
import { t } from '../../../../services/translations';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import useLoggedUser from '../../../../hooks/useLoggedUser';

export default function CreateCompanyGroupScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: { label: string; users: User[] }) => {
      try {
        await createCompanyGroup(user, { label: values.label, userIds: values.users.map((e) => e.id) });
        dispatch(setContributionsListNeedsRefresh(true));
        navigation.goBack();
      } catch (e) {
        alertInfo(t('unknown_error'));
      }
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <AppScreen>
      <Form initialValues={{ users: [user] }} onSubmit={mutateAsync as any} validationSchema={schema} validateOnMount>
        <CompanyGroupFormFields />
      </Form>
    </AppScreen>
  );
}
