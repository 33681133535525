import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import * as Localization from 'expo-localization';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import { DARK_GRAY, SMOKE_WHITE } from '../../styles/appColor';
import { apiTimeZone } from '../../../configuration';
import { User } from '../../entities/User';
import { ProfilePicture } from './ProfilePicture';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { dateLocale } from '../../services/translations';
import RemoveLittleButton from './RemoveLittleButton';
import useLoggedUser from '../../hooks/useLoggedUser';

export type CommentBubbleProps = {
  content: string;
  user: User;
  date: Date;
  remove?: () => Promise<any>;
};

export function CommentBubble({ content, date, user, remove }: CommentBubbleProps) {
  const locale = dateLocale();
  const loggedUser = useLoggedUser();
  const hasEditAccess = loggedUser.lovIdType == 13 || user.id === loggedUser.id;

  return (
    <View style={styles.container}>
      <ProfilePicture pictureId={user.fichierIdProfil} size="small-medium" />
      <View style={styles.contentContainer}>
        <View style={styles.header}>
          <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Text style={styles.author} numberOfLines={1}>
              {user.prenom} {user.nom}
            </Text>
          </View>
          {date && (
            <Text style={styles.dateText}>
              {' '}
              {formatInTimeZone(zonedTimeToUtc(date, apiTimeZone), Localization.timezone, 'd MMMM yyyy HH:mm', {
                locale,
              })}
            </Text>
          )}
          {remove && hasEditAccess && <RemoveLittleButton onPress={remove} />}
        </View>
        <Text style={styles.text}>{content}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 10,
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    flex: 1,
  },
  contentContainer: {
    padding: 8,
    marginVertical: 4,
    marginLeft: 10,
    borderRadius: 8,
    backgroundColor: SMOKE_WHITE,
    flex: 1,
  },
  author: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 12,
    flexWrap: 'nowrap',
    flex: 1,
  },
  text: {
    flexWrap: 'wrap',
    fontSize: 14,
  },
  dateText: {
    color: DARK_GRAY,
    fontSize: 12,
    paddingLeft: 8,
    textAlign: 'right',
    marginLeft: 'auto',
  },
});
