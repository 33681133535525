import React, { useState } from 'react';
import { t } from '../../services/translations';
import { TextFormField } from '../../components/atomic/FormField';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { isMailValid } from '../../services/utils';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { Space2 } from '../../styles/spaces';

const registrationBackground = require('../../../assets/registration-background.png');

export function NoProfessionalAddress(props: { route; navigation }) {
  const [emailAddress, setEmailAddress] = useState('');
  const [company, setCompany] = useState('');

  const _send = () => {
    if (!isMailValid(emailAddress)) return;
  };
  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <TextFormField title={t('your_company')} value={company} onChangeText={setCompany} />
        <TextFormField
          keyboardType="email-address"
          title={t('email_address')}
          value={emailAddress}
          onChangeText={setEmailAddress}
          onSubmitEditing={_send}
        />
      </FormBody>
      <FormButtons>
        <AppButton title={t('send_button')} onPress={_send} type={Type.PRIMARY} enabled={isMailValid(emailAddress)} />
        <Space2 />
        <AppButton title={t('back_button')} onPress={() => props.navigation.goBack()} enabled={true} />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
