import React from 'react';
import { ImageBackground, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { genericBackground } from '../../services/assets';
import { isMobile } from '../../services/utils';

export function DefaultBackground(props: { children?: React.ReactNode; style?: StyleProp<ViewStyle> }) {
  return (
    <ImageBackground
      source={genericBackground}
      imageStyle={{ opacity: 0.5 }}
      style={[
        {
          backgroundColor: '#fff',
          flex: 1,
        },
        props.style,
      ]}
    >
      {props.children}
    </ImageBackground>
  );
}

export function AppScreen(props: {
  children: React.ReactNode;
  header?: React.ReactNode;
  style?: StyleProp<ViewStyle>;
  headerStyle?: StyleProp<ViewStyle>;
}) {
  const padding = isMobile() ? 10 : 30;

  return (
    <DefaultBackground>
      {props.header && (
        <View
          style={[
            {
              paddingHorizontal: padding,
            },
            styles.header,
            props.headerStyle,
          ]}
        >
          {props.header}
        </View>
      )}
      <View
        style={[
          {
            paddingHorizontal: padding,
          },
          styles.body,
          props.style,
        ]}
      >
        {props.children}
      </View>
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  header: {
    paddingTop: 10,
  },
  body: {
    paddingTop: 10,
    flex: 1,
    flexDirection: 'column',
  },
});
