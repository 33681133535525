import React, { useEffect, useMemo, useState } from 'react';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { useQuery } from '@tanstack/react-query';
import { AppScreen } from '../../components/containers/AppScreen';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { RootState } from '../../services/storage';
import { FlatList, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ProfileRow } from '../../components/atomic/ProfileRow';
import { t } from '../../services/translations';
import { setContributeurListNeedsRefresh, setDirectoryListNeedsRefresh, setLoading } from '../../store/action';
import { getCompanyUsers, getUsersExportCsvUrl } from '../../services/api/user.api';
import { User } from '../../entities/User';
import { DataTableHeader } from '../atomic/DataTableHeader';
import { isMobile } from '../../services/utils';
import { PRIMARY_COLOR } from '../../styles/appColor';
import AppText from '../atomic/AppText';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { GetUsersFilters } from '../../entities/GetUsersFilters';
import UserFilters from './UserFilters';
import useIsSsoEmail from '../../hooks/useIsSsoEmail';

export type UsersListProps = {
  allowEdit?: boolean;
  onPress?: (user: User) => void;
  showAdminTag?: boolean;
};

export function UsersList({ allowEdit, onPress, showAdminTag }: UsersListProps) {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const isSsoEmail = useIsSsoEmail();

  const [filters, setFilters] = useState<GetUsersFilters>({
    search: '',
    services: [],
    siteGeos: [],
  });

  const [sortBy, setSortBy] = useState<null | { key: keyof User; order: 'asc' | 'desc' }>(null);
  const directoryListNeedsRefresh = useSelector<RootState>((state) => state.directoryListNeedsRefresh);
  const contributeursListNeedsRefresh = useSelector<RootState>((state) => state.contributeursListNeedsRefresh);

  const {
    data: usersList,
    isLoading,
    isRefetching,
    refetch: refresh,
  } = useQuery(['users', user.entrepriseId], () => getCompanyUsers(user));

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useEffect(() => {
    if (directoryListNeedsRefresh) {
      refresh();
      dispatch(setDirectoryListNeedsRefresh(false));
    }
  }, [refresh, dispatch, directoryListNeedsRefresh]);

  // Refresh after an update
  useEffect(() => {
    if (contributeursListNeedsRefresh) {
      refresh();
      dispatch(setContributeurListNeedsRefresh(false));
    }
  }, [refresh, dispatch, contributeursListNeedsRefresh]);

  async function exportToCsv() {
    let fileUrl = getUsersExportCsvUrl(user);

    if (!isMobile()) {
      window.open(fileUrl, '_blank');
    } else {
      let uri = FileSystem.documentDirectory + 'export.csv';
      await FileSystem.downloadAsync(fileUrl, uri);
      await Sharing.shareAsync(uri, { dialogTitle: 'CSV' });
    }
  }

  const filteredData = useMemo(() => {
    let data = usersList || [];

    const search = filters.search || '';
    if (search.length > 0) {
      data = data.filter((u) => {
        return (
          (u.prenom && u.prenom.toLowerCase().includes(search.toLowerCase())) ||
          (u.nom && u.nom.toLowerCase().includes(search.toLowerCase()))
        );
      });
    }

    const services = filters.services || [];
    if (services.length > 0) {
      data = data.filter((u) => services.includes(u.entrepriseServiceId));
    }

    const siteGeos = filters.siteGeos || [];
    if (siteGeos.length > 0) {
      data = data.filter((u) => siteGeos.includes(u.entrepriseSiteGeoId));
    }

    if (sortBy) {
      data = _.sortBy(data, (item) => item[sortBy.key]);

      if (sortBy.order === 'asc') {
        data.reverse();
      }
    }

    return data;
  }, [usersList, filters, sortBy]);

  return (
    <AppScreen
      header={
        <View>
          <UserFilters
            right={
              allowEdit && (
                <TouchableOpacity style={styles.exportContainer} onPress={exportToCsv}>
                  <FontAwesome6 size={24} name="file-csv" color={PRIMARY_COLOR} />
                  <AppText style={styles.exportLabel}>{t('export')}</AppText>
                </TouchableOpacity>
              )
            }
            filters={filters}
            onChange={setFilters}
          />
          <View style={styles.headerContainer}>
            <DataTableHeader<User> style={styles.userNameCountContainer}>
              {allowEdit && <FontAwesome6 name="user" size={18} style={styles.userIcon} />}
              {t('users_count', { count: filteredData.length })}
            </DataTableHeader>
            {allowEdit && (
              <DataTableHeader<User>
                sortKey="dateInscription"
                containerStyle={styles.dateContainer}
                sortBy={sortBy}
                setSortBy={setSortBy}
              >
                {t('register_date')}
              </DataTableHeader>
            )}
          </View>
        </View>
      }
    >
      <FlatList
        style={styles.list}
        data={filteredData}
        renderItem={(u) => (
          <ProfileRow
            showAdminTag={showAdminTag}
            user={u.item}
            allowEdit={allowEdit}
            showRecoveryEmail={allowEdit && !isSsoEmail(u.item.aspNetUsers.email)}
            onPress={onPress && (() => onPress(u.item))}
          />
        )}
        keyExtractor={(item) => item.id.toString()}
        refreshControl={<RefreshControl refreshing={isRefetching} onRefresh={refresh} />}
      />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  exportContainer: { alignItems: 'center', marginLeft: 24, marginBottom: 14 },
  exportLabel: { fontSize: 10 },
  dateContainer: {
    flex: 1,
    alignItems: 'center',
    marginRight: 50,
  },
  userNameCountContainer: { width: 250 },
  userIcon: { paddingRight: 10 },
  list: {
    marginTop: 10,
  },
});
