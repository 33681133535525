import React, { useCallback, useMemo } from 'react';
import { Text, View, ViewStyle } from 'react-native';
import uuid from 'react-native-uuid';
import { globalStyle } from '../../styles/globalStyle';
import { getGoogleAPIKey, isMobile } from '../../services/utils';
import AppAutoComplete from './AppAutoComplete';
import axios from 'axios';

declare var google: any;

export type AddressAutocompleteProps = {
  title: string;
  value: string;
  style?: ViewStyle;
  setValue: (a: string) => void;
  setCoordinates: (a: string) => void;
};

export type PlaceResult = {
  description: string;
  matched_substrings: {
    length: number;
    offset: number;
  }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      length: number;
      offset: number;
    }[];
    secondary_text: string;
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
};

async function getLatitudeLongitudeFromPlaceId(googleApiKey: string, placeId: string): Promise<string> {
  if (isMobile()) {
    const result = await axios
      .get('https://maps.googleapis.com/maps/api/place/details/json', {
        params: {
          place_id: placeId,
          key: googleApiKey,
          language: 'fr',
          fields: ['geometry'],
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response?.data?.result?.geometry?.location) {
          return `${response?.data?.result?.geometry?.location?.lat};${response?.data?.result?.geometry?.location?.lng}`;
        } else {
          return '';
        }
      });

    return result;
  } else {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ placeId }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            resolve(`${results[0].geometry.location.lat()};${results[0].geometry.location.lng()}`);
          } else {
            resolve('');
          }
        } else {
          resolve('');
        }
      });
    });
  }
}

export default function AddressAutocomplete({
  title,
  value,
  style,
  setValue,
  setCoordinates,
}: AddressAutocompleteProps) {
  const key = useMemo(() => getGoogleAPIKey(), []);
  const sessionToken = useMemo(() => uuid.v4() as string, []);
  const language = 'fr';

  const handleSearch = useCallback(
    async (query: string) => {
      if (!query || query.length < 2) {
        return [];
      }

      if (isMobile()) {
        const result = await axios
          .get('https://maps.googleapis.com/maps/api/place/autocomplete/json', {
            params: {
              input: query,
              key,
              sessiontoken: sessionToken,
              language,
            },
            withCredentials: true,
          })
          .then((response) => response?.data?.predictions || []);

        return result as PlaceResult[];
      } else {
        const service = new google.maps.places.AutocompleteService();
        const result = await service.getPlacePredictions(
          {
            input: query,
            key,
            sessiontoken: sessionToken,
            language,
          },
          () => {}
        );

        return (result?.predictions || []) as PlaceResult[];
      }
    },
    [key, language, sessionToken]
  );

  return (
    <AppAutoComplete<PlaceResult>
      title={title}
      defaultQuery={value}
      placeholder=""
      style={style}
      renderItem={({ item }) => (
        <View
          style={{
            height: 24,
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Text numberOfLines={1}>{item.description}</Text>
        </View>
      )}
      onSelect={(item) => {
        setValue(item.description);

        getLatitudeLongitudeFromPlaceId(key, item.place_id).then((coordinates) => {
          setCoordinates(coordinates);
        });
      }}
      search={handleSearch}
      keyExtractor={(item) => item.place_id.toString()}
      noModal
    />
  );
}
