import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import CreateOrganizationalChartScreen from '../screens/loggedIn/admin/organizationalChart/CreateOrganizationalChartScreen';
import ListOrganizationalChartsScreen from '../screens/loggedIn/admin/organizationalChart/ListOrganizationalChartsScreen';
import UpdateOrganizationalChartScreen from '../screens/loggedIn/admin/organizationalChart/UpdateOrganizationalChartScreen';
import { t } from '../services/translations';
import BackIcon from '../components/atomic/BackIcon';

const Stack = createStackNavigator();

export function OrganizationalChartAdminRouter() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.OrganizationalChartAdminMain}
        component={ListOrganizationalChartsScreen}
        options={{
          title: t('organizational_charts'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.OrganizationalChartAdminCreate}
        component={CreateOrganizationalChartScreen}
        options={{
          title: t('organizational_charts'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.OrganizationalChartAdminUpdate}
        component={UpdateOrganizationalChartScreen}
        options={{
          title: t('organizational_charts'),
        }}
        initialParams={{ allowEdit: true }}
      />
    </Stack.Navigator>
  );
}
