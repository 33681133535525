import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Category } from '../../entities/Category';
import { Chip } from './Chip';

export interface CategoryChipProps {
  category: Category;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  disable?: boolean;
  selected?: boolean;
}

export const CategoryChip = ({ category, style, disable, onPress, selected }: CategoryChipProps) => (
  <Chip
    text={category.name}
    color={category.color}
    style={style}
    onPress={onPress}
    disable={disable}
    selected={selected}
  />
);
