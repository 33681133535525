import { Dispatch } from 'redux';
import { match } from 'ts-pattern';
import { getMinimumVersion } from '../services/api/version.api';
import { RootState } from '../services/storage';
import { ActionType } from './action';

export type VersionState = {
  minimumVersion: string;
};

export const defaultVersionState = {
  minimumVersion: '1.0',
};

export type VersionActionType = {
  type: 'VERSION_MINIMUM_RECEIVED';
  payload: string;
};

export function versionReducer(state: RootState, action: ActionType): RootState {
  return match(action)
    .with({ type: 'VERSION_MINIMUM_RECEIVED' }, (action) => ({
      ...state,
      version: {
        ...state.version,
        minimumVersion: action.payload,
      },
    }))
    .otherwise(() => state);
}

export async function fetchMinimumApiVersion(dispatch: Dispatch<ActionType>) {
  const result = await getMinimumVersion();

  dispatch({ type: 'VERSION_MINIMUM_RECEIVED', payload: result });
}
