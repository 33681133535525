import React, { useState } from 'react';
import { AppScreen } from '../../components/containers/AppScreen';
import { TextFormField } from '../../components/atomic/FormField';
import { t } from '../../services/translations';
import { AppCheckbox } from '../../components/atomic/AppCheckbox';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { setLoading } from '../../store/action';
import { useDispatch } from 'react-redux';
import { alertInfo } from '../../services/utils';
import { sendSuggestion } from '../../services/api/suggestions.contributions.api';
import useLoggedUser from '../../hooks/useLoggedUser';

export function SuggestionBoxScreen() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [anonymous, setAnonymous] = useState(false);
  const dispatch = useDispatch();
  const user = useLoggedUser();

  function reset() {
    setTitle('');
    setDescription('');
    setAnonymous(false);
  }

  function _send() {
    dispatch(setLoading(true));
    sendSuggestion(user, title, description, anonymous)
      .then((r) => {
        alertInfo(t('suggestion_sent'));
        reset();
      })
      .catch((e) => {
        alertInfo(t('suggestion_error'));
      })
      .finally(() => dispatch(setLoading(false)));
  }

  const isFormReady = title.length > 4 && description.length > 4;

  return (
    <AppScreen>
      <TextFormField mandatory title={t('title') + '*'} onChangeText={setTitle} value={title} />
      <TextFormField mandatory title={t('description') + '*'} onChangeText={setDescription} value={description} />
      <AppCheckbox title={t('anonymous')} onValueChange={setAnonymous} value={anonymous} />
      <AppButton title={t('send_my_idea')} onPress={_send} enabled={isFormReady} type={Type.PRIMARY} />
    </AppScreen>
  );
}
