import React from 'react';
import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import { t } from '../../services/translations';
import { AppButton } from '../../components/atomic/AppButton';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import AppText, { Mode } from '../../components/atomic/AppText';
import { Space1, Space3 } from '../../styles/spaces';
import { ValidationCodeField } from '../../components/atomic/ValidationCodeField';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { validate } from '../../services/api/login';
import { alertInfo } from '../../services/utils';
import { setUser } from '../../store/user';
import { ScreenNames } from '../../ScreenNames';

const registrationBackground = require('../../../assets/registration-background.png');

export type ValidationCodeProps = {
  route: RouteProp<any, any>;
  navigation: NavigationProp<ParamListBase>;
};

export function ValidationCode({ route, navigation }: ValidationCodeProps) {
  const dispatch = useDispatch();
  const email = route?.params?.email as string;

  function _validate(code: string) {
    dispatch(setLoading(true));
    validate(email, code)
      .then(() => {
        alertInfo(t('mail_validated_successfully'));
        return setUser(null, dispatch);
      })
      .then(() => navigation.navigate(ScreenNames.Login))
      .catch((e) => {
        alertInfo(t('invalid_code'));
      })
      .finally(() => dispatch(setLoading(false)));
  }

  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <AppText>{t('enter_validation_code')}</AppText>
        <Space3 />
        <AppText mode={Mode.BOLD}>{t('your_4_digit_code')}</AppText>
        <Space1 />
        <ValidationCodeField onCodeEntered={_validate} />
      </FormBody>
      <FormButtons>
        <AppButton title={t('back_button')} onPress={() => navigation.goBack()} />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
