import { AppPickerSelect } from './FormField';
import React, { useState } from 'react';
import { PROFILE_LANGUAGE_ENABLED } from '../../services/features';
import { StyleProp, ViewStyle } from 'react-native';

export interface AppLanguagePickerProps {
  defaultValue?: string;
  containerStyle?: StyleProp<ViewStyle>;
  onLanguageChanged?: (language: string) => void;
}

export const AppLanguagePicker = (props: AppLanguagePickerProps) => (
  <AppPickerSelect<string>
    displayOnly={PROFILE_LANGUAGE_ENABLED}
    pickerStyle={props.containerStyle}
    defaultValue={props.defaultValue}
    onChangeText={(itemValue) => props.onLanguageChanged?.(itemValue)}
    data={[
      { label: 'Français 🇫🇷', value: 'fr-FR' },
      { label: 'English 🇬🇧 🇺🇸', value: 'en-GB' },
      { label: 'Deutsch 🇩🇪', value: 'de-DE' },
      { label: 'Español 🇪🇸', value: 'es-ES' },
      { label: 'Italiano 🇮🇹', value: 'it-IT' },
    ]}
  ></AppPickerSelect>
);
