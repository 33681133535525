import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { KeyboardType, StyleSheet, View } from 'react-native';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Picker, { EmojiClickData } from 'emoji-picker-react';

export const colors = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];

var Link = Quill.import('formats/link');
var builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput: string) {
  let PROTOCOL_WHITELIST = ['http:', 'https:', 'mailto:'];
  for (let i = 0; i < PROTOCOL_WHITELIST.length; i++) {
    if (linkValueInput.startsWith(PROTOCOL_WHITELIST[i])) {
      return builtInFunc.call(this, linkValueInput);
    }
  }

  return builtInFunc.call(this, `https://${linkValueInput}`);
};

let id = 0;

const getId = (): number => {
  id += 1;
  return id;
};

export function AppRTEEditor(props: {
  maxLength?: number;
  displayOnly?: boolean;
  style: any;
  onSubmitEditing?: any;
  title: any;
  defaultValue: string;
  onChangeText: any;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
}) {
  const reactQuill = createRef<ReactQuill>();
  const containerRef = useRef<View>(null);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [id] = useState(getId().toString());

  // Refresh after 100ms
  // Sometimes, the quill editor is not initialized correctly, so we refresh it
  const [, setRefresh] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setRefresh(true);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const onEmojiClick = useCallback(
    (data: EmojiClickData) => {
      if (!reactQuill.current) {
        return;
      }
      const quill = reactQuill.current.getEditor();
      if (!quill) {
        return null;
      }
      const position = quill.getSelection()?.index ?? quill.getLength() - 1;

      quill.insertText(position, data.emoji, 'silent');
      setOpenEmoji(false);
    },
    [reactQuill]
  );

  const Toolbar = useCallback(
    () => (
      <div id={'toolbar-' + id}>
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-link" />
        <select className="ql-color">
          {colors.map((color) => (
            <option value={color} key={color} />
          ))}
        </select>
        <button onClick={() => setOpenEmoji((prev) => !prev)}>🙂</button>
      </div>
    ),
    [id, setOpenEmoji]
  );

  return (
    <View style={styles.container} ref={containerRef}>
      {openEmoji && <Picker onEmojiClick={onEmojiClick} />}
      <Toolbar />
      {containerRef.current && (
        <ReactQuill
          ref={reactQuill}
          formats={['bold', 'italic', 'link', 'color']}
          theme="snow"
          bounds={containerRef.current}
          modules={{
            toolbar: {
              container: '#toolbar-' + id,
            },
          }}
          onFocus={() => setOpenEmoji(false)}
          value={props.defaultValue ?? ''}
          onChange={props.onChangeText}
          style={styles.quill}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flex: 1, minHeight: 400 },
  quill: { minHeight: 300, marginBottom: 100 },
});
