export const language_mapping = [
  {
    id: 1,
    code: 'fr-FR',
    libelle: 'Français',
  },
  {
    id: 2,
    code: 'en-GB',
    libelle: 'English',
  },
  {
    id: 3,
    code: 'es-ES',
    libelle: 'Español',
  },
  {
    id: 4,
    code: 'de-DE',
    libelle: 'Deutsch',
  },
  {
    id: 5,
    code: 'it-IT',
    libelle: 'Italiano',
  },
];

export function getIdFromCode(code: string) {
  for (const lng of language_mapping) {
    if (lng.code.startsWith(code)) return lng.id;
  }
  return 0;
}

export function getLibelleFromCode(code: string) {
  for (const lng of language_mapping) {
    if (lng.code.startsWith(code)) return lng.libelle;
  }
  return 0;
}

export function getCodeFromId(id: number) {
  for (const lng of language_mapping) {
    if (lng.id == id) return lng.code;
  }
  return 'fr-FR';
}
