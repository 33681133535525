import { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { FAB } from '@rneui/base';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { setLoading } from '../../../store/action';
import usePaginatedList from '../../../hooks/usePaginatedList';
import { listSmsEmergencyTemplates } from '../../../services/api/sms.emergency.api';
import { AppScreen } from '../../../components/containers/AppScreen';
import { SearchTextInput } from '../../../components/atomic/SearchTextInput';
import AppText from '../../../components/atomic/AppText';
import { t } from '../../../services/translations';
import ListItemRow from '../../../components/atomic/ListItemRow';
import { DARK_GRAY, LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { isAdmin, isSubAdmin } from '../../../services/utils';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { ScreenNames } from '../../../ScreenNames';
import Separator from '../../../components/atomic/Separator';
import { AppButton } from '../../../components/atomic/AppButton';

export default function EmergencySmsListTemplatesScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const hasWriteAccess = isAdmin(user) || isSubAdmin(user);

  const [search, setSearch] = useState('');

  const {
    data: templates,
    isLoading,
    fetchNext,
    refresh,
  } = usePaginatedList((cursor: string | null) => listSmsEmergencyTemplates(user, cursor, search), [search, user]);

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh on page focus
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refresh();
    });

    return unsubscribe;
  }, [navigation, refresh]);

  return (
    <AppScreen>
      <SearchTextInput
        onPressIcon={() => setSearch('')}
        leftIcon="arrow-back"
        onChangeText={setSearch}
        value={search}
        style={{ marginBottom: 10 }}
      />
      <FlatList
        data={templates}
        renderItem={({ item }) => {
          return (
            <ListItemRow
              defaultImage={
                <FontAwesome6 name="comment-sms" size={80} color={DARK_GRAY} style={styles.pictureContainer} />
              }
              title={item.title}
              date={item.dateCreated}
              user={
                item.author && {
                  profileFileId: item.author.fichierIdProfil,
                  lastName: item.author.nom,
                  firstName: item.author.prenom,
                }
              }
              left={
                <View style={styles.actionContainer}>
                  <AppButton
                    onPress={() => {
                      navigation.navigate(ScreenNames.EmergencySmsSendTemplate, { id: item.id });
                    }}
                    title={t('send')}
                    style={{ marginTop: 5, marginBottom: 5, width: 130 }}
                    textStyle={{ fontSize: 16 }}
                  />
                </View>
              }
              onPress={() => {
                navigation.navigate(ScreenNames.EmergencySmsSendTemplate, { id: item.id });
              }}
            />
          );
        }}
        ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
        ItemSeparatorComponent={() => <Separator />}
        onEndReached={fetchNext}
      />
      {hasWriteAccess && (
        <FAB
          color={PRIMARY_COLOR}
          icon={<FontAwesome6 size={30} name="comment-medical" color="#fff" />}
          onPress={() => {
            navigation.navigate(ScreenNames.EmergencySmsSend);
          }}
          placement="left"
        />
      )}
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  pictureContainer: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 20,
  },
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
