import { ScreenNames } from '../ScreenNames';
import { navigationRef } from '../services/utils';

export function navigateToAdminOrganizationalChart() {
  navigationRef.current?.navigate(ScreenNames.OrganizationalChartAdmin);
}

export function navigateToAdminCreateOrganizationalChart() {
  navigationRef.current?.navigate(ScreenNames.OrganizationalChartAdminCreate);
}

export function navigateToAdminUpdateOrganizationalChart(id: number) {
  navigationRef.current?.navigate(ScreenNames.OrganizationalChartAdminUpdate, {
    id,
  });
}
