import React from 'react';
import * as Yup from 'yup';
import { t } from '../../services/translations';
import { AppButton } from '../../components/atomic/AppButton';
import AppText, { Mode } from '../../components/atomic/AppText';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import { Space1, Space2 } from '../../styles/spaces';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { alertInfo } from '../../services/utils';
import { ScreenNames } from '../../ScreenNames';
import Form from '../../components/atomic/formik/Form';
import SubmitButton from '../../components/atomic/formik/SubmitButton';
import { useMutation } from '@tanstack/react-query';
import { login, resetPassword } from '../../services/api/login';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import TextField from '../../components/atomic/formik/TextField';
import { saveToken } from '../../services/storage';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/user';

const registrationBackground = require('../../../assets/registration-background.png');

export const schema = Yup.object({
  password: Yup.string().min(4, t('field_required')).required(t('field_required')),
  passwordConfirmation: Yup.string()
    .required(t('field_required'))
    .when('password', (password, schema) => {
      if (password !== undefined) {
        return schema.test('password-confirmation', t('passwords_dont_match'), (value: string) => {
          return value === password;
        });
      }
      return schema;
    }),
});

export type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export type ResetPasswordProps = {
  route: {
    params: {
      email: string;
      code: string;
    };
  };
};

export function ResetPassword({ route }: ResetPasswordProps) {
  const dispatch = useDispatch();
  const navigation = useNavigation<NavigationProp<any>>();
  const email = route.params.email;
  const code = route.params.code;

  const { mutateAsync } = useMutation({
    mutationFn: async (values: FormValues) => {
      await resetPassword(email, code, values.password);

      var userData = await login(email, values.password);
      await saveToken(userData.token);
      setUser({ ...userData }, dispatch);
    },
    onSuccess: () => {
      alertInfo(t('new_password_success'));
      navigation.navigate(ScreenNames.Main);
    },
    onError: () => {
      alertInfo(t('unknown_error'));
    },
  });

  return (
    <Form
      initialValues={{
        password: '',
        passwordConfirmation: '',
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <TwoColumnsLayout background={registrationBackground}>
        <FormBody>
          <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_3_title')}</AppText>
          <AppText>{t('forgotten_password_step_3_text')}</AppText>
          <Space1 />
          <TextField name="password" title={t('password')} secureTextEntry />
          <TextField name="passwordConfirmation" title={t('password_confirmation')} secureTextEntry />
        </FormBody>
        <FormButtons>
          <Space2 />
          <SubmitButton text={t('send_button')} />
          <Space2 />
          <AppButton title={t('back_button')} onPress={() => navigation.navigate(ScreenNames.Login)} enabled={true} />
        </FormButtons>
      </TwoColumnsLayout>
    </Form>
  );
}
