import { DrawerScreenProps } from '@react-navigation/drawer';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ScreenNames } from '../ScreenNames';
import CreateDocumentScreen from '../screens/loggedIn/contribution/documents/CreateDocumentScreen';
import CreateFolderScreen from '../screens/loggedIn/contribution/documents/CreateFolderScreen';
import { DocumentsMainScreen } from '../screens/loggedIn/contribution/documents/DocumentsMainScreen';
import UpdateDocumentScreen from '../screens/loggedIn/contribution/documents/UpdateDocumentScreen';
import UpdateFolderScreen from '../screens/loggedIn/contribution/documents/UpdateFolderScreen';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { t } from '../services/translations';
import { isAdmin, isMobile, isSubAdmin } from '../services/utils';
import { ContributionType, IntToContributionType } from '../types';
import useLoggedUser from '../hooks/useLoggedUser';
import BackIcon from '../components/atomic/BackIcon';

function Tabs(props: { route; navigation }) {
  const Tab = createMaterialTopTabNavigator();
  const user = useLoggedUser();

  if (isAdmin(user) || isSubAdmin(user)) {
    return (
      <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
        <Tab.Screen
          name={ScreenNames.ContributionListScreen}
          component={DocumentsMainScreen}
          options={{ title: t('all') }}
          initialParams={{
            contributionsMode: GetContributionsMode.all.toString(),
            contributionType: ContributionType.DOCUMENT.toString(),
          }}
        />
        <Tab.Screen
          name={ScreenNames.ContributionListScreenMine}
          component={DocumentsMainScreen}
          options={{ title: t('my_documents') }}
          initialParams={{
            contributionsMode: GetContributionsMode.mine.toString(),
            contributionType: ContributionType.DOCUMENT.toString(),
            isAdminView: true,
          }}
        />
      </Tab.Navigator>
    );
  } else {
    return (
      <DocumentsMainScreen
        navigation={props.navigation}
        route={{
          params: {
            contributionsMode: GetContributionsMode.all.toString(),
            contributionType: ContributionType.DOCUMENT.toString(),
          },
        }}
      />
    );
  }
}

const Stack = createStackNavigator();

export function DocumentsRouter(props: DrawerScreenProps<any>) {
  const contributionType: ContributionType = IntToContributionType(props.route.params?.contributionType);

  return (
    <Stack.Navigator screenOptions={{ headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.ContributionTabs}
        component={Tabs}
        options={{ headerShown: false, title: props.route.params?.title }}
        initialParams={{ contributionType: contributionType }}
      />
      <Stack.Screen
        name={ScreenNames.KiosqueFolder}
        component={DocumentsMainScreen}
        options={{
          headerShown: true,
          title: '',
        }}
      />
      <Stack.Screen
        name={ScreenNames.KiosqueCreateDocument}
        options={{ title: t('deposit') }}
        component={CreateDocumentScreen}
      />
      <Stack.Screen
        name={ScreenNames.KiosqueCreateFolder}
        options={{ title: t('create_folder') }}
        component={CreateFolderScreen}
      />
      <Stack.Screen
        name={ScreenNames.KiosqueUpdateDocument}
        options={{ title: t('edit') }}
        component={UpdateDocumentScreen}
      />
      <Stack.Screen
        name={ScreenNames.KiosqueUpdateFolder}
        options={{ title: t('edit') }}
        component={UpdateFolderScreen}
        initialParams={{
          contributionType: contributionType,
        }}
      />
    </Stack.Navigator>
  );
}
