import React, { ReactNode, useState } from 'react';
import { TouchableOpacity, View, Dimensions, Platform, Image } from 'react-native';
import { isMobile, responsiveStyle } from '../../services/utils';
import { BackButton } from '../atomic/BackButton';
import { AppModalGallery } from '../atomic/AppModalGallery';
import { ResponsiveView } from './ResponsiveView';
import { ResponsiveRow } from './ResponsiveRow';
import { ResponsiveColumn } from './ResponsiveColumn';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Media from './Media';
import { getContributionImageSource, getDocumentURI } from '../../services/api/helper.api';
import { ContributionType } from '../../types';
import { Pdf } from '../atomic/Pdf';
import { useQuery } from '@tanstack/react-query';
import { getFileDetails } from '../../services/api/file.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';

export function ContentWithImageHeaderScreen(props: {
  contribution: {
    imageId?: number | null | undefined;
    documentId?: number | null | undefined;
    videoUrl?: string | null | undefined;
  };
  contributionType: ContributionType;
  imageContentOverride?: ReactNode;
  bottomContent?: ReactNode;
  underImageContent?: ReactNode;
  children: ReactNode;
  navigation: any;
  absoluteBackNavigation?: string;
  absoluteBackNavigationDetail?: any;
}) {
  const [zoomableImageVisible, setZoomableImageVisible] = useState(false);
  const user = useLoggedUser();

  const imageStyle = responsiveStyle(
    {
      width: '100%',
      height: isMobile() ? Dimensions.get('window').width - 50 : 300,
      marginBottom: 0,
      top: 0,
    },
    {
      width: 540,
      height: 540,
      marginTop: 10,
      marginLeft: 10,
      borderRadius: 20,
    }
  );

  const documentStyle = responsiveStyle(
    {
      width: '100%',
      marginBottom: 0,
      top: 0,
    },
    {
      width: 540,
      marginTop: 10,
      marginLeft: 10,
      borderRadius: 20,
    }
  );

  // Get document informations if needed
  const { data: documentDetails } = useQuery(
    ['document', props.contribution.documentId],
    async () => {
      if (props.contribution.documentId) {
        return await getFileDetails(user, props.contribution.documentId);
      }
    },
    {
      enabled: !!props.contribution.documentId,
    }
  );

  return (
    <SafeAreaProvider>
      <SafeAreaView edges={['top']} style={{ flex: 1 }}>
        <KeyboardAwareScrollView
          bounces={false}
          style={{
            flex: 1,
            flexDirection: 'column',
            // justifyContent: 'center',
          }}
          scrollEventThrottle={1}
          contentContainerStyle={{ paddingBottom: 20 }}
          showsVerticalScrollIndicator={Platform.OS === 'ios' ? false : true}
        >
          <ResponsiveRow breakpoint={3000}>
            <ResponsiveColumn first={true} bigScreenStyle={{ display: 'flex', alignItems: 'center' }}>
              {props.imageContentOverride ? (
                <View
                  style={responsiveStyle(
                    {
                      height: 300,
                    },
                    {
                      height: 500,
                      marginTop: 10,
                      marginLeft: 10,
                    }
                  )}
                >
                  {props.imageContentOverride}
                </View>
              ) : props.contribution.videoUrl ? (
                <Media media={props.contribution.videoUrl} />
              ) : props.contribution.documentId && documentDetails?.format === 'application/pdf' ? (
                <Pdf source={getDocumentURI(props.contribution.documentId)} style={documentStyle} />
              ) : (
                <TouchableOpacity onPress={() => setZoomableImageVisible(true)}>
                  <Image
                    style={imageStyle}
                    source={getContributionImageSource(props.contribution.imageId, props.contributionType)}
                  />
                </TouchableOpacity>
              )}
              {props.underImageContent}
            </ResponsiveColumn>
            <ResponsiveView bigScreenStyle={{ marginTop: 0 }}>{props.children}</ResponsiveView>
          </ResponsiveRow>
          {props.bottomContent}
        </KeyboardAwareScrollView>
        <BackButton
          absoluteScreenName={props.absoluteBackNavigation}
          navigationDetails={props.absoluteBackNavigationDetail}
        />
        <AppModalGallery
          isVisible={zoomableImageVisible}
          onBackdropPress={() => setZoomableImageVisible(false)}
          imageSource={getContributionImageSource(props.contribution.imageId, props.contributionType)}
        />
      </SafeAreaView>
    </SafeAreaProvider>
  );
}
