import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { ContributionPreviewItem, ContributionPreviewItemProps } from './ContributionItemSquare';
import { AppButton } from './AppButton';
import { t } from '../../services/translations';
import { PRIMARY_COLOR } from '../../styles/appColor';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type ContributionToValidateItemProps = ContributionPreviewItemProps & {
  onEdit: () => void;
  onApprove: () => void;
  onDisapprove: () => void;
};

export default function ContributionToValidateItem({
  onEdit,
  onApprove,
  onDisapprove,
  ...rest
}: ContributionToValidateItemProps) {
  return (
    <View>
      <ContributionPreviewItem {...rest} />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <AppButton
          onPress={onApprove}
          title={t('approve')}
          style={{ marginTop: 5, marginBottom: 5, width: 130 }}
          textStyle={{ fontSize: 16 }}
        />
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', flex: 1, marginTop: 5, marginBottom: 5 }}>
          <TouchableOpacity onPress={onEdit}>
            <FontAwesome6 size={18} name="edit" color={PRIMARY_COLOR} />
          </TouchableOpacity>
        </View>
        <AppButton
          onPress={onDisapprove}
          title={t('disapprove')}
          style={{
            width: 130,
          }}
          textStyle={{ fontSize: 16 }}
        />
      </View>
    </View>
  );
}
