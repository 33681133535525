import React from 'react';
import { ContributionListScreen } from '../screens/loggedIn/contribution/ContributionListScreen';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { ContributionType } from '../types';
import { t } from '../services/translations';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { isMobile } from '../services/utils';
import { ScreenNames } from '../ScreenNames';

export function CompanyNewsAdministrationRoute(props) {
  const Tab = createMaterialTopTabNavigator();
  return (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.CompanyNewsToApproveList}
        component={ContributionListScreen}
        options={{ title: t('to_approve') }}
        initialParams={{
          getContributionsMode: GetContributionsMode.toApprove,
          isAdminView: true,
          hideCategory: true,
          contributionType: ContributionType.COMPANY_NEWS,
        }}
      />
      <Tab.Screen
        name={ScreenNames.ReviewedCompanyNewsList}
        component={ContributionListScreen}
        options={{ title: t('approved_plural_female') }}
        initialParams={{
          getContributionsMode: GetContributionsMode.approved,
          isAdminView: true,
          contributionType: ContributionType.COMPANY_NEWS,
        }}
      />
    </Tab.Navigator>
  );
}
