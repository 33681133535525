import { TouchableOpacity, View } from 'react-native';
import React from 'react';
import AppText, { Mode } from './AppText';
import { LIGHT_GRAY, PRIMARY_COLOR_LIGHT } from '../../styles/appColor';

export type AppCheckboxProps = {
  title: string | React.ReactNode;
  subtitle?: string;
  onValueChange: any;
  value: boolean;
};

export function AppCheckbox(props: AppCheckboxProps) {
  return (
    <TouchableOpacity
      onPress={() => {
        props.onValueChange(!props.value);
      }}
      style={{ flexDirection: 'row', alignContent: 'flex-start' }}
    >
      <TickableBox value={props.value} onValueChange={props.onValueChange} />
      <View style={{ flexDirection: 'column', flex: 1, paddingTop: 3 }}>
        <AppText style={{ paddingLeft: 10 }}>{props.title}</AppText>
        <AppText style={{ paddingLeft: 10 }} mode={Mode.SMALL}>
          {props.subtitle}
        </AppText>
      </View>
    </TouchableOpacity>
  );
}

function TickableBox(props: { value: boolean; onValueChange: any }) {
  return (
    <View style={{ width: 20, height: 20, borderRadius: 3, borderColor: LIGHT_GRAY, borderWidth: 1 }}>
      {props.value ? <View style={{ margin: 2, flex: 1, backgroundColor: PRIMARY_COLOR_LIGHT }}></View> : null}
    </View>
  );
}
