import React, { useMemo, useState } from 'react';
import { AppScreen } from '../../components/containers/AppScreen';
import { TextFormField } from '../../components/atomic/FormField';
import { t } from '../../services/translations';
import { AppSafeAreaView } from '../../components/containers/AppSafeAreaView';
import AppText from '../../components/atomic/AppText';
import { BackButton } from '../../components/atomic/BackButton';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { putNewPassword } from '../../services/api/user.api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { alertInfo } from '../../services/utils';
import { ScreenNames } from '../../ScreenNames';
import useLoggedUser from '../../hooks/useLoggedUser';

export function EditPasswordScreen(props: { navigation }) {
  const [previousPassword, setPreviousPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const user = useLoggedUser();
  const dispatch = useDispatch();

  function _updatePassword() {
    dispatch(setLoading(true));
    putNewPassword(user, previousPassword, password)
      .then(() => {
        alertInfo(t('password_changed_successfully'));
        props.navigation.navigate(ScreenNames.Profile);
      })
      .catch((error) => {
        if (error.response) {
          alertInfo(t(error.response.data));
        } else {
          alertInfo(t('unknown_error'));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  const valid = useMemo(() => {
    return previousPassword.length > 0 && password == passwordConfirmation && password.length > 0;
  }, [previousPassword, password, passwordConfirmation]);

  return (
    <AppScreen>
      <AppSafeAreaView style={{ flex: 1 }}>
        <AppText style={{ marginVertical: 22, fontSize: 20, textAlign: 'center' }}>{t('change_password')}</AppText>
        <TextFormField
          title={t('previous_password')}
          value={previousPassword}
          onChangeText={setPreviousPassword}
          secureTextEntry={true}
        />
        <TextFormField title={t('new_password')} value={password} onChangeText={setPassword} secureTextEntry={true} />
        <TextFormField
          title={t('new_password_confirmation')}
          value={passwordConfirmation}
          onChangeText={setPasswordConfirmation}
          secureTextEntry={true}
        />
        <AppButton title={t('confirm')} type={Type.PRIMARY} onPress={_updatePassword} enabled={valid} />
      </AppSafeAreaView>
      <BackButton />
    </AppScreen>
  );
}
