import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { ContributionType } from '../../types';
import { UserSaved } from '../storage';

export async function setContributionInterest(
  user: UserSaved,
  contributionId: number,
  postId: number,
  contributionType: ContributionType,
  title: string
) {
  let url = apiUrlBase + 'ContributionInterests';

  let body: any = {
    contributionId,
    postId,
    userId: user.id,
    title,
    contributionType,
  };

  return axios
    .post(url, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}

export const setCompanyNewsView = (user: UserSaved, id: number) =>
  axios.post(
    `${apiUrlBase}MActuUserViews`,
    { mActuId: id, userId: user.id },
    {
      headers: headers(user.token),
    }
  );

export async function setContributionView(user: UserSaved, contributionId: number, contributionType: ContributionType) {
  let url = apiUrlBase + 'MActuUserViews';

  let body = {
    mActuId: contributionId,
    userId: user.id,
  };
  if (contributionType == ContributionType.COMPANY_NEWS) {
    url = apiUrlBase + 'MActuUserViews';
    body = {
      mActuId: contributionId,
      userId: user.id,
    };
  }
  return axios
    .post(url, body, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}

export async function removeContributionInterest(user: UserSaved, interestId: number) {
  let url = apiUrlBase + 'ContributionInterests/' + interestId;

  return axios
    .delete(url, {
      headers: headers(user.token),
    })
    .then((response) => {
      return response;
    });
}
