import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';
import { View } from 'react-native';
import { DARK_GRAY } from '../../styles/appColor';
import { AppLoader } from './AppLoader';
import { vh } from '../../utils/Utils';
import WeatherIcon from './WeatherIcon';

export type OpenMeteoResponse = {
  current: {
    temperature_2m: number;
    rain: number;
    showers: number;
    snowfall: number;
    cloud_cover: number;
    is_day: number;
  };
};

async function getWheather(latitude: number, longitude: number): Promise<OpenMeteoResponse> {
  const result = await axios.get(
    `https://api.open-meteo.com/v1/dwd-icon?latitude=${latitude}&longitude=${longitude}&current=is_day,temperature_2m,rain,showers,snowfall,weather_code,cloud_cover`
  );
  return result.data;
}

export type WeatherProps = {
  latitude: number;
  longitude: number;
};

export default function Weather({ latitude, longitude }: WeatherProps) {
  const { data, error, isLoading } = useQuery(
    ['weather-small', latitude, longitude],
    () => getWheather(latitude, longitude),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );

  return isLoading ? (
    <AppLoader />
  ) : error ? (
    <View style={styles.container}>
      <Text style={styles.text}>!</Text>
    </View>
  ) : (
    <View style={styles.container}>
      <Text style={styles.text}>{Math.round(data?.current.temperature_2m || 0)}°</Text>
      <WeatherIcon
        rain={(data?.current.rain || 0) > 0}
        showers={(data?.current.showers || 0) > 0}
        snowfall={(data?.current.snowfall || 0) > 0}
        cloudCover={data?.current.cloud_cover || 0}
        isDay={data?.current.is_day === 1}
        color={DARK_GRAY}
        style={styles.icon}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  text: {
    color: DARK_GRAY,
    fontSize: vh(6),
  },
  icon: {
    width: vh(6),
    height: vh(6),
    marginTop: 10,
  },
});
