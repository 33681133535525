import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Feather from 'react-native-vector-icons/Feather';
import { useDispatch } from 'react-redux';
import Separator from '../../../../components/atomic/Separator';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { TwoActionsGenericPopup } from '../../../../components/containers/TwoActionsButtonPopup';
import { EntrepriseGroup } from '../../../../entities/EntrepriseGroup';
import { deleteCompanyGroup, getCompanyGroups } from '../../../../services/api/company.group.api';
import { t } from '../../../../services/translations';
import { alertInfo } from '../../../../services/utils';
import { setLoading } from '../../../../store/action';
import { ALERT_COLOR, DARK_GRAY, PRIMARY_COLOR } from '../../../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../../../styles/globalStyle';
import { navigateToAdminCreateCompanyGroup, navigateToAdminUpdateCompanyGroup } from '../../../../utils/CompanyGroup';
import { FloatingButtons } from '../../contribution/FloatingButtons';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../../hooks/useContributionListRefresh';

export default function ListCompanyGroupsScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<EntrepriseGroup[]>([]);
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const refresh = useCallback(async () => {
    setIsLoading(true);

    try {
      setItems(await getCompanyGroups(user));
    } catch (e) {
      alertInfo(t('unknown_error'));
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  const handleDelete = useCallback(
    async (id: number) => {
      await deleteCompanyGroup(user, id);
      refresh();
    },
    [user]
  );

  return (
    <AppScreen>
      <FlatList
        data={items}
        renderItem={({ item }) => (
          <View style={styles.itemContainer}>
            <TouchableOpacity
              containerStyle={styles.contentContainer}
              style={styles.contentContainer}
              onPress={() => navigateToAdminUpdateCompanyGroup(item.id)}
            >
              <Text style={styles.itemLabel}>{item.label}</Text>
              <Feather size={24} name="edit" color={PRIMARY_COLOR} style={styles.editButton} />
            </TouchableOpacity>
            <TwoActionsGenericPopup
              title={t('wish_to_remove')}
              button1Title={t('yes')}
              button2Title={t('no')}
              onPressButton1={() => handleDelete(item.id)}
              onPressButton2={() => {}}
            >
              <Feather size={24} name="trash" color={ALERT_COLOR} />
            </TwoActionsGenericPopup>
          </View>
        )}
        keyExtractor={(item) => item.id.toString()}
        ItemSeparatorComponent={() => <Separator />}
      />
      <FloatingButtons onPress={navigateToAdminCreateCompanyGroup} />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  contentContainer: {
    flex: 1,
    marginVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemLabel: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  editButton: {
    marginRight: 10,
  },
});
