export const PRIMARY_COLOR = '#45AFE8ff';
export const PRIMARY_COLOR_LIGHT = '#addaf1ff';
export const PRIMARY_COLOR_DARKEN = '#1b465c';
export const SECONDARY_COLOR = '#4FDFC4ff';
export const SECONDARY_COLOR_LIGHT = '#95ebdb';
export const SECONDARY_COLOR_DARKEN = '#17423a';
export const LIGHT_GRAY = '#cccccc';
export const GRAY = '#999999';
export const SMOKE_WHITE = '#eeeeee';
export const WHITE = '#ffffff';
export const DARK_GRAY = '#333333';
export const FORM_LIGHT_GRAY = '#E8E7E7';
export const ALERT_COLOR = '#ad1d1f';
