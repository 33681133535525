import React from 'react';
import { useField } from 'formik';
import * as Yup from 'yup';
import { PickUsers } from '../PickUsers';
import { SpreadingType } from '../../../types';
import { t } from '../../../services/translations';

export type ContributionSpreadingFieldProps = {
  filterByUserRights?: boolean;
  subAdminCanShareWithEveryone?: boolean;
  left?: React.ReactNode;
  onNeedsValidationChange?: (hasAdminRight: boolean) => void;
};

export const contributionSpreadingFieldValidators = {
  spreadingType: Yup.mixed().required(t('field_required')),
  userIds: Yup.array().when('spreadingType', {
    is: (spreadingType: SpreadingType) => spreadingType === SpreadingType.USERS,
    then: Yup.array().min(1, t('field_required')),
  }),
  entrepriseServiceIds: Yup.array().when('spreadingType', {
    is: (spreadingType: SpreadingType) =>
      spreadingType === SpreadingType.SERVICES || spreadingType === SpreadingType.SITES_AND_SERVICES,
    then: Yup.array().min(1, t('field_required')).required(t('field_required')),
  }),
  entrepriseSiteGeoIds: Yup.array().when('spreadingType', {
    is: (spreadingType: SpreadingType) =>
      spreadingType === SpreadingType.SITES || spreadingType === SpreadingType.SITES_AND_SERVICES,
    then: Yup.array().min(1, t('field_required')).required(t('field_required')),
  }),
  entrepriseGroupIds: Yup.array().when('spreadingType', {
    is: (spreadingType: SpreadingType) => spreadingType === SpreadingType.GROUPS,
    then: Yup.array().min(1, t('field_required')).required(t('field_required')),
  }),
};

export default function ContributionSpreadingField({
  filterByUserRights,
  subAdminCanShareWithEveryone,
  left,
  onNeedsValidationChange,
}: ContributionSpreadingFieldProps) {
  const [spreadingTypeField, , spreadingTypeHelper] = useField('spreadingType');
  const [usersField, , usersHelper] = useField('userIds');
  const [servicesField, , servicesHelper] = useField('entrepriseServiceIds');
  const [siteGeosField, , siteGeosHelper] = useField('entrepriseSiteGeoIds');
  const [groupsField, , groupsHelper] = useField('entrepriseGroupIds');

  return (
    <PickUsers
      enabled
      subAdminCanShareWithEveryone={subAdminCanShareWithEveryone}
      filterByUserRights={filterByUserRights}
      left={left}
      spreadingType={spreadingTypeField.value}
      pickedUsers={usersField.value || []}
      pickedServices={servicesField.value || []}
      pickedGeoSites={siteGeosField.value || []}
      pickedGroups={groupsField.value || []}
      setSpreadingType={spreadingTypeHelper.setValue}
      setPickedUsers={usersHelper.setValue}
      setPickedServices={servicesHelper.setValue}
      setPickedGeoSites={siteGeosHelper.setValue}
      setPickedGroups={groupsHelper.setValue}
      onNeedsValidationChange={onNeedsValidationChange}
    />
  );
}
