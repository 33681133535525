import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { FONT_FAMILY_DEFAULT, globalStyle } from '../../styles/globalStyle';
import AppText from './AppText';
import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../styles/appColor';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export enum Type {
  PRIMARY,
  LIGHT,
  ULTRA_LIGHT,
}

export function AppButton(props: {
  textStyle?: TextStyle;
  title: string;
  onPress: () => void;
  enabled?: boolean;
  type?: Type;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  iconOnTopEdge?: boolean;
  fontSize?: number;
}) {
  const isEnabled = props.enabled || props.enabled == null;

  let typeStyle = {};
  let colors = [];
  let textColor = '';
  let iconColor = PRIMARY_COLOR;
  switch (props.type) {
    case Type.PRIMARY:
      typeStyle = globalStyle.button_primary;
      colors = [PRIMARY_COLOR, SECONDARY_COLOR];
      textColor = '#fff';
      iconColor = '#fff';
      break;
    case Type.ULTRA_LIGHT:
      typeStyle = globalStyle.button_light;
      colors = ['#fff', '#fff'];
      textColor = '#bfbfbf';
      break;
    default:
    case Type.LIGHT:
      typeStyle = globalStyle.button_light;
      colors = ['#fff', '#fff'];
      textColor = PRIMARY_COLOR;
      break;
  }
  if (!isEnabled) {
    colors = ['#ccc', '#ccc'];
  }
  return (
    <TouchableOpacity
      testID="button"
      onPress={isEnabled ? props.onPress : () => {}}
      disabled={!isEnabled}
      style={[globalStyle.button, typeStyle, props.style]}
    >
      <LinearGradient
        colors={colors}
        start={{ x: 0, y: 0.5 }}
        end={{ x: 1, y: 0.5 }}
        style={[globalStyle.button_content]}
      >
        {props.icon !== undefined ? (
          <View
            style={
              !!props.iconOnTopEdge && {
                position: 'absolute',
                backgroundColor: 'white',
                top: -16,
              }
            }
          >
            <FontAwesome6 size={18} name={props.icon} color={iconColor} style={{ marginLeft: 10, marginRight: 10 }} />
          </View>
        ) : null}
        <AppText
          style={{
            textAlign: 'center',
            fontSize: props.fontSize ?? 20,
            color: textColor,
            fontFamily: FONT_FAMILY_DEFAULT.regular,
            flex: 1,
            textTransform: 'uppercase',
            paddingTop: 2,
            ...props.textStyle,
          }}
        >
          {props.title}
        </AppText>
      </LinearGradient>
    </TouchableOpacity>
  );
}
