import React from 'react';
import { useDispatch } from 'react-redux';
import { removeContribution } from '../../services/api/contribution.api';
import { UserSaved } from '../../services/storage';
import { t } from '../../services/translations';
import { alertInfo } from '../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../store/action';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { TwoActionsGenericPopup } from '../containers/TwoActionsButtonPopup';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

export type RemoveButtonProps = { item: any; user: UserSaved };

export function RemoveButton({ item, user }: RemoveButtonProps) {
  const dispatch = useDispatch();

  return (
    <TwoActionsGenericPopup
      title={t('wish_to_remove')}
      button1Title={t('yes')}
      button2Title={t('no')}
      onPressButton1={() => {
        dispatch(setLoading(true));
        removeContribution(user, item.contributionId)
          .then(() => alertInfo(t('removed_ok')))
          .finally(() => {
            dispatch(setContributionsListNeedsRefresh(true));
            dispatch(setLoading(false));
          });
      }}
      onPressButton2={() => {}}
    >
      <FontAwesome6 size={18} name="trash-alt" color={PRIMARY_COLOR} />
    </TwoActionsGenericPopup>
  );
}
