import React, { createContext } from "react";

interface categoriesContextProps {
  register(callback: () => void): void;
  unregister(callback: () => void): void;
  call(): void;
}

export const CategoriesContext = createContext<categoriesContextProps>({
  call: () => {},
  register: (_: () => void) => {},
  unregister: (_: () => void) => {},
});