import { View, ViewStyle } from 'react-native';
import AppText from './AppText';
import { t } from '../../services/translations';
import React from 'react';

export function FeaturedLabel(props: { style?: ViewStyle }) {
  return <Label text={t('featured')} style={{ flex: undefined, ...props.style }} />;
}

export function Label(props: { text: string; color?: string; style?: ViewStyle }) {
  return (
    <View
      style={{
        backgroundColor: props.color === undefined ? '#4FDFC4' : props.color,
        flex: 0,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRadius: 80,
        ...props.style,
      }}
    >
      <AppText style={{ color: 'white', flex: 0 }}>{props.text}</AppText>
    </View>
  );
}
