import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { setDirectoryListNeedsRefresh } from '../../../store/action';
import { alertInfo } from '../../../services/utils';
import { AppScreen } from '../../../components/containers/AppScreen';
import { AppSafeAreaView } from '../../../components/containers/AppSafeAreaView';
import Form from '../../../components/atomic/formik/Form';
import { ScrollView } from 'react-native-gesture-handler';
import { CreateUserForm } from '../../../components/molecules/CreateUserForm';
import { t } from '../../../services/translations';

export default function CreateUserScreen() {
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const handleSubmitEnd = useCallback(async (values: any) => {
    alertInfo(t('profile_created'));
    navigation.goBack();
    dispatch(setDirectoryListNeedsRefresh(true));
  }, []);

  return (
    <AppScreen>
      <AppSafeAreaView style={{ flex: 1 }}>
        <Form onSubmit={() => {}} initialValues={{}}>
          <ScrollView>
            <CreateUserForm onSubmitEnd={handleSubmitEnd} allowEmailEdit displayGender displayEmailWarning />
          </ScrollView>
        </Form>
      </AppSafeAreaView>
    </AppScreen>
  );
}
