import React from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { getPictureSource } from '../../services/api/helper.api';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { DARK_GRAY } from '../../styles/appColor';
import { getReadableDate } from '../../services/utils';
import AppText from './AppText';
import { UserSubRow } from './UserSubRow';

export type ListItemRowProps = {
  imageId?: number | null;
  defaultImage: React.ReactNode;
  title: string;
  date: string;
  user: {
    profileFileId: number;
    firstName: string;
    lastName: string;
  };
  left: React.ReactNode;
  onPress?: () => void;
};

export default function ListItemRow({ imageId, defaultImage, title, date, user, left, onPress }: ListItemRowProps) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        {imageId ? <Image source={getPictureSource(imageId)} style={styles.pictureContainer} /> : defaultImage}
        <View style={styles.contentContainer}>
          <AppText style={styles.title} numberOfLines={1}>
            {title}
          </AppText>
          {date && <AppText style={styles.dateText}>{getReadableDate(date, true)}</AppText>}
          {user && (
            <UserSubRow
              user={{
                fichierIdProfil: user.profileFileId,
                nom: user.firstName,
                prenom: user.lastName,
              }}
              withPicture={true}
              textColor="black"
            />
          )}
        </View>
        {left}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    marginVertical: 10,
  },
  pictureContainer: {
    width: 80,
    height: 80,
    borderRadius: 10,
    marginRight: 20,
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 16,
    color: DARK_GRAY,
  },
  dateText: {
    flex: 1,
    fontSize: 12,
    fontStyle: 'italic',
    color: 'black',
    paddingRight: 7,
  },
});
