import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { ScreenNames } from '../ScreenNames';
import { t } from '../services/translations';
import { isAdmin, isMobile, isSubAdmin } from '../services/utils';
import useLoggedUser from '../hooks/useLoggedUser';
import EmergencySmsListTemplatesScreen from '../screens/loggedIn/emergencySms/EmergencySmsListTemplatesScreen';
import EmergencySmsListMyTemplatesScreen from '../screens/loggedIn/emergencySms/EmergencySmsListMyTemplatesScreen';
import EmergencySmsCreateTemplateScreen from '../screens/loggedIn/emergencySms/EmergencySmsCreateTemplateScreen';
import EmergencySmsUpdateTemplateScreen from '../screens/loggedIn/emergencySms/EmergencySmsUpdateTemplateScreen';
import EmergencySmsSendScreen from '../screens/loggedIn/emergencySms/EmergencySmsSendScreen';
import EmergencySmsSendTemplateScreen from '../screens/loggedIn/emergencySms/EmergencySmsSendTemplateScreen';
import BackIcon from '../components/atomic/BackIcon';

function Tabs() {
  const Tab = createMaterialTopTabNavigator();
  const user = useLoggedUser();

  if (isAdmin(user) || isSubAdmin(user)) {
    return (
      <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
        <Tab.Screen
          name={ScreenNames.EmergencySmsListTemplates}
          component={EmergencySmsListTemplatesScreen}
          options={{ title: t('all') }}
        />
        <Tab.Screen
          name={ScreenNames.EmergencySmsListMyTemplates}
          component={EmergencySmsListMyTemplatesScreen}
          options={{ title: t('my_templates') }}
        />
      </Tab.Navigator>
    );
  } else {
    return <EmergencySmsListTemplatesScreen />;
  }
}

const Stack = createStackNavigator();

export function EmergencySmsRouter() {
  return (
    <Stack.Navigator screenOptions={{ headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.EmergencySmsTabs}
        component={Tabs}
        options={{ title: t('all'), headerShown: false }}
      />
      <Stack.Screen
        name={ScreenNames.EmergencySmsCreateTemplate}
        options={{ title: t('create_template') }}
        component={EmergencySmsCreateTemplateScreen}
      />
      <Stack.Screen
        name={ScreenNames.EmergencySmsUpdateTemplate}
        options={{ title: t('create_template') }}
        component={EmergencySmsUpdateTemplateScreen}
      />
      <Stack.Screen
        name={ScreenNames.EmergencySmsSend}
        options={{ title: t('send_sms') }}
        component={EmergencySmsSendScreen}
      />
      <Stack.Screen
        name={ScreenNames.EmergencySmsSendTemplate}
        options={{ title: t('send_sms') }}
        component={EmergencySmsSendTemplateScreen}
      />
    </Stack.Navigator>
  );
}
