import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { Icon } from '@rneui/base';
import { useDispatch } from 'react-redux';
import AppText from '../../components/atomic/AppText';
import { CategoryChip } from '../../components/atomic/CategoryChip';
import { Category } from '../../entities/Category';
import { deleteCategory, getCategories, postOrderCategory } from '../../services/api/category.api';
import { t } from '../../services/translations';
import { isMobile } from '../../services/utils';
import { setLoading } from '../../store/action';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { ContributionType, ContributionTypesInfos } from '../../types';
import { CategoriesContributions, GLOBAL_CATEGORY, SERVICE_CATEGORY, GEOSITE_CATEGORY } from '../../utils/Category';
import { CategoriesContext } from './CategoriesContext';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

const EditableChip = ({
  first,
  last,
  category,
  onLeft,
  onRight,
  onEdit,
  onDelete,
}: {
  first: boolean;
  last: boolean;
  category: Category;
  onLeft(): void;
  onRight(): void;
  onEdit(category: Category): void;
  onDelete(category: Category): void;
}) => {
  return (
    <View style={{ marginBottom: 8, alignItems: 'center' }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {!first && (
          <AppText
            onPress={onLeft}
            style={{ marginLeft: 12, marginRight: 8, color: PRIMARY_COLOR, fontWeight: 'bold', fontSize: 18 }}
          >
            {'<'}
          </AppText>
        )}
        <CategoryChip category={category} />
        {!last && (
          <AppText
            onPress={onRight}
            style={{ marginLeft: 8, marginRight: 12, color: PRIMARY_COLOR, fontWeight: 'bold', fontSize: 18 }}
          >
            {'>'}
          </AppText>
        )}
      </View>
      <View style={{ flexDirection: 'row', marginTop: 8, marginLeft: first ? -28 : 0, marginRight: last ? -28 : 0 }}>
        <Icon
          onPress={() => onEdit(category)}
          style={{ marginRight: 24 }}
          name="edit"
          size={18}
          color={PRIMARY_COLOR}
          type="feather"
        />
        <FontAwesome6 onPress={() => onDelete(category)} name="trash-alt" size={18} color={PRIMARY_COLOR} />
      </View>
    </View>
  );
};

export const ManageCategoriesOrder = ({ onEdit }: { onEdit(category: Category): void }) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const dispatch = useDispatch();
  const user = useLoggedUser();

  const { register, unregister } = useContext(CategoriesContext);

  const fetchCategories = useCallback(() => {
    dispatch(setLoading(true));
    getCategories(user.entreprise?.id!, user.token!)
      .then((cat) => setCategories(cat))
      .finally(() => dispatch(setLoading(false)));
  }, [user.entreprise?.id]);

  useEffect(() => {
    const callback = () => {
      fetchCategories();
    };
    register(callback);

    () => unregister(callback);
  }, []);

  const [categories, setCategories] = useState<{ [key: string]: Category[] } | undefined>(undefined);

  useEffect(fetchCategories, [fetchCategories]);

  if (!categories) return null;

  const move = (type: string, id: number, delta: number) => {
    const categoryIndex = categories[type].findIndex((x) => x.id === id);
    const category = categories[type][categoryIndex];
    const filtered = categories[type].filter((x) => x.id !== id);

    filtered.splice(categoryIndex + delta, 0, category);

    setCategories((prev) => ({ ...prev, [type]: filtered }));

    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      dispatch(setLoading(true));
      postOrderCategory(
        filtered.reduce((prev, cat, idx) => ({ ...prev, [cat.id.toString()]: idx }), {}),
        user.token!
      ).finally(() => dispatch(setLoading(false)));
    }, 3000);
  };

  const onLeft = (type: string, id: number) => move(type, id, -1);

  const onRight = (type: string, id: number) => move(type, id, 1);

  const onDelete = (category: Category) => {
    dispatch(setLoading(true));
    deleteCategory(category.id, user.token!)
      .then(() => {
        setCategories((prev) => ({
          ...prev,
          [category.type]: prev![category.type].filter((x) => x.id !== category.id),
        }));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <ScrollView style={{ padding: 16 }}>
      <AppText style={{ fontWeight: 'bold', color: PRIMARY_COLOR, marginBottom: 8 }}>
        {t('existing_categories')}
      </AppText>
      <AppText style={{ width: !isMobile() ? '50%' : undefined }}>{t('existing_categories_details')}</AppText>
      {CategoriesContributions.map((categoryContribution) => (
        <View key={categoryContribution}>
          <AppText style={{ fontWeight: 'bold', fontSize: 16, marginVertical: 18 }}>
            {t(ContributionTypesInfos[categoryContribution].key)}
          </AppText>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <CategoryChip style={{ marginRight: 16, marginBottom: 8 }} category={GLOBAL_CATEGORY} />
            {categoryContribution === ContributionType.COMPANY_NEWS && (
              <>
                <CategoryChip style={{ marginRight: 16, marginBottom: 8 }} category={SERVICE_CATEGORY} />
                <CategoryChip style={{ marginRight: 16, marginBottom: 8 }} category={GEOSITE_CATEGORY} />
              </>
            )}
            {(categories[ContributionTypesInfos[categoryContribution].key] ?? []).map((cat, idx) => (
              <EditableChip
                key={cat.id}
                category={cat}
                first={idx === 0}
                last={categories[ContributionTypesInfos[categoryContribution].key].length - 1 === idx}
                onLeft={() => onLeft(ContributionTypesInfos[categoryContribution].key, cat.id)}
                onRight={() => onRight(ContributionTypesInfos[categoryContribution].key, cat.id)}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))}
          </View>
        </View>
      ))}
    </ScrollView>
  );
};
