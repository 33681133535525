import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { AppScreen } from '../../../../components/containers/AppScreen';
import { SearchTextInput } from '../../../../components/atomic/SearchTextInput';
import usePaginatedList from '../../../../hooks/usePaginatedList';
import { getContributionsList, setApproveContribution } from '../../../../services/api/contribution.api';
import { GetContributionsMode } from '../../../../services/api/getContributionsMode';
import { ContributionType } from '../../../../types';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { ResponsiveFlatList } from '../../../../components/atomic/ResponsiveFlatList';
import { alertInfo, isMobile } from '../../../../services/utils';
import ContributionToValidateItem from '../../../../components/atomic/ContributionToValidateItem';
import { CSondage } from '../../../../entities/CSondage';
import { ScreenNames } from '../../../../ScreenNames';
import { getPictureURI } from '../../../../services/api/helper.api';
import { surveyImage } from '../../../../services/assets';
import AppText from '../../../../components/atomic/AppText';
import { StyleSheet } from 'react-native';
import { LIGHT_GRAY } from '../../../../styles/appColor';
import { t } from '../../../../services/translations';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import useContributionListRefresh from '../../../../hooks/useContributionListRefresh';

export function SurveyToValidateAdminListScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const {
    data: contributions,
    isLoading,
    fetchNext,
    refresh,
  } = usePaginatedList<CSondage, string>(
    (cursor: string | null) =>
      getContributionsList(user, GetContributionsMode.toApprove, ContributionType.SURVEY, search, cursor),
    [search, user]
  );

  function approveContribution(id: number, value: boolean) {
    dispatch(setLoading(true));
    setApproveContribution(user, id, value, ContributionType.SURVEY)
      .then((response) => {
        dispatch(setContributionsListNeedsRefresh(true));
        refresh();
      })
      .catch((e) => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Refresh after an update
  useContributionListRefresh(refresh);

  return (
    <AppScreen>
      <SearchTextInput
        onPressIcon={() => setSearch('')}
        leftIcon="arrow-back"
        onChangeText={setSearch}
        value={search}
        style={{ marginBottom: 10 }}
      />
      <ResponsiveFlatList
        data={contributions}
        numColumns={isMobile() ? 2 : undefined}
        renderItem={({ item }) => {
          return (
            <ContributionToValidateItem
              title={item.titre}
              imageURI={item.imageId ? getPictureURI(item.imageId) : surveyImage}
              videoUrl={item.videoUrl}
              onPress={() => {
                navigation.navigate(ScreenNames.ContributionItemDetailScreen, {
                  id: item.id,
                  contributionType: ContributionType.SURVEY,
                  isNotif: false,
                  isAdminView: true,
                });
              }}
              overlayContent={<></>}
              additionalButtons={<></>}
              labelContent={<></>}
              onEdit={() =>
                navigation.navigate(ScreenNames.ContributionEditFormScreen, {
                  id: item.id,
                  contributionType: ContributionType.SURVEY,
                  isAdminView: true,
                })
              }
              onApprove={() => approveContribution(item.id, true)}
              onDisapprove={() => approveContribution(item.id, false)}
            />
          );
        }}
        ListEmptyComponent={<AppText style={styles.emptyText}>{t('no_contributions')}</AppText>}
        onEndReached={fetchNext}
      />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  emptyText: {
    textAlign: 'center',
    color: LIGHT_GRAY,
  },
});
