import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { ScreenNames } from '../ScreenNames';
import ListValidationMapScreen from '../screens/loggedIn/admin/validationMap/ListValidationMapScreen';
import UpdateValidationMapScreen from '../screens/loggedIn/admin/validationMap/UpdateValidationMapScreen';
import { t } from '../services/translations';
import BackIcon from '../components/atomic/BackIcon';

const Stack = createStackNavigator();

export function ValidationMapAdministrationRoute() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: true, headerBackTitleVisible: false, headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.ValidationMap}
        component={ListValidationMapScreen}
        options={{
          title: t('validation_map'),
        }}
      />
      <Stack.Screen
        name={ScreenNames.ValidationMapUpdate}
        component={UpdateValidationMapScreen}
        options={{
          title: t('validation_map'),
        }}
      />
    </Stack.Navigator>
  );
}
