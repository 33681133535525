import React, { useMemo } from 'react';
import { SectionList, View, Text, StyleSheet, RefreshControl } from 'react-native';
import { format, max } from 'date-fns';
import * as Localization from 'expo-localization';
import { groupByAdjacent } from '../../utils/Utils';
import { ChatBubble } from '../atomic/ChatBubble';
import { ProfilePicture } from '../atomic/ProfilePicture';
import { MessagingMessage } from '../../entities/MessagingMessage';
import { dateLocale, t } from '../../services/translations';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { MessagingThreadMember } from '../../entities/MessagingThreadMember';
import { isMobile } from '../../services/utils';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { apiTimeZone } from '../../../configuration';
import useLoggedUser from '../../hooks/useLoggedUser';

export type MessageListProps = {
  messages: (MessagingMessage & {
    isLoading: boolean;
  })[];
  members: MessagingThreadMember[];
  isLoading: boolean;
  refresh: () => Promise<void>;
  fetchNext: () => Promise<void>;
};

export function MessageList({ messages, members, isLoading, refresh, fetchNext }: MessageListProps) {
  const user = useLoggedUser();
  const locale = dateLocale();

  const lastRead = useMemo(
    () =>
      max(members?.map((e) => new Date(e.lastSeen))) ||
      utcToZonedTime(zonedTimeToUtc(new Date(), Localization.timezone), apiTimeZone),
    [members]
  );

  const messagesByUser = useMemo(
    () =>
      groupByAdjacent(
        messages.map((e) => ({ ...e, seen: new Date(e.dateCreated) <= lastRead })),
        (message) =>
          JSON.stringify({
            userId: message.user.id,
            date: format(new Date(message.dateCreated), 'yyyy-MM-dd'),
            seen: message.seen,
          })
      ).map((messageGroup) => ({
        user: messageGroup[0].user,
        seen: messageGroup[0].seen,
        data: messageGroup,
        dateCreated: new Date(messageGroup[0].dateCreated),
      })),
    [messages, lastRead]
  );

  return (
    <SectionList
      refreshControl={isMobile() ? <RefreshControl refreshing={isLoading} onRefresh={refresh} /> : undefined}
      onEndReached={fetchNext}
      sections={messagesByUser}
      keyExtractor={(item) => item.id.toString()}
      stickySectionHeadersEnabled={false}
      renderItem={({ item }) => (
        <ChatBubble
          message={item.content}
          fileId={item.fileId}
          me={user.id == item.user.id}
          date={new Date(item.dateCreated)}
          isLoading={item.isLoading}
        />
      )}
      renderSectionFooter={({ section }) => (
        <View style={[user.id == section.user.id ? styles.sectionMe : styles.sectionNotMe, styles.commonSection]}>
          <ProfilePicture pictureId={section.user.profileFileId} size="small-medium" />
          <View
            style={[
              user.id == section.user.id ? styles.sectionMeTextArea : styles.sectionNotMeTextArea,
              styles.sectionTextArea,
            ]}
          >
            <Text style={styles.sectionText}>
              {section.user.firstName} {section.user.lastName}
            </Text>
            <Text style={styles.sectionText}>{format(section.dateCreated, 'd MMMM yyyy', { locale })}</Text>
          </View>
        </View>
      )}
      renderSectionHeader={({ section }) =>
        section.user.id === user.id ? (
          <View style={[user.id == section.user.id ? styles.sectionMe : styles.sectionNotMe, styles.sectionFooter]}>
            <Text style={styles.sectionText}>{section.seen ? t('seen') : t('sent')}</Text>
          </View>
        ) : null
      }
      inverted
    />
  );
}

const styles = StyleSheet.create({
  commonSection: {
    paddingVertical: 8,
  },
  sectionMe: {
    flexDirection: 'row',
  },
  sectionNotMe: {
    flexDirection: 'row-reverse',
  },
  sectionTextArea: {
    justifyContent: 'space-around',
  },
  sectionMeTextArea: {
    paddingLeft: 8,
  },
  sectionNotMeTextArea: {
    paddingRight: 8,
    alignItems: 'flex-end',
  },
  sectionText: {
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    fontSize: 12,
  },
  sectionFooter: {
    paddingBottom: 8,
  },
});
