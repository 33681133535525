import { DrawerScreenProps } from '@react-navigation/drawer';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import useLoggedUser from '../hooks/useLoggedUser';
import { ScreenNames } from '../ScreenNames';
import { ContributionFormScreen } from '../screens/loggedIn/contribution/ContributionFormScreen';
import { ContributionListScreen } from '../screens/loggedIn/contribution/ContributionListScreen';
import { EventsMainScreen } from '../screens/loggedIn/contribution/EventsMainScreen';
import { SuggestionBoxScreen } from '../screens/loggedIn/SuggestionBoxScreen';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { t } from '../services/translations';
import { isMobile } from '../services/utils';
import { ContributionType, IntToContributionType } from '../types';
import CreateSurveyScreen from '../screens/loggedIn/contribution/survey/CreateSurveyScreen';
import BackIcon from '../components/atomic/BackIcon';

function Tabs(props: { route; navigation }) {
  const Tab = createMaterialTopTabNavigator();

  const user = useLoggedUser();
  const contributionType: ContributionType = IntToContributionType(props.route.params.contributionType);

  function getMyContributionsTitleByType(contributionType: ContributionType) {
    switch (contributionType) {
      case ContributionType.SELL:
        return t('my_sell');
      case ContributionType.ACTIVITY:
        return t('my_activities');
      case ContributionType.MUTUAL_AID:
        return t('my_requests');
      default:
        return t('my_contributions');
    }
  }

  switch (contributionType) {
    case ContributionType.CARPOOL:
      return (
        <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
          <Tab.Screen
            name={ScreenNames.ContributionListScreenOffers}
            component={ContributionListScreen}
            options={{ title: t('offers') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.offers,
              contributionType: contributionType,
            }}
          />
          {/*<Tab.Screen*/}
          {/*  name={ScreenNames.ContributionListScreenRequests}*/}
          {/*  component={ContributionListScreen}*/}
          {/*  options={{ title: t('requests') }}*/}
          {/*  initialParams={{*/}
          {/*    getContributionsMode: GetContributionsMode.requests,*/}
          {/*    contributionType: contributionType,*/}
          {/*  }}*/}
          {/*/>*/}
          <Tab.Screen
            name={ScreenNames.ContributionFormScreen}
            component={ContributionFormScreen}
            initialParams={{
              contributionType: contributionType,
            }}
            options={{ title: t('propose') }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionListScreenMine}
            component={ContributionListScreen}
            options={{ title: t('my_trips') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.mine,
              contributionType: contributionType,
            }}
          />
        </Tab.Navigator>
      );
    case ContributionType.EVENT:
    case ContributionType.FORMATION:
      return (
        <Tab.Navigator
          screenOptions={{ swipeEnabled: isMobile(), tabBarLabelStyle: { fontSize: 12, padding: 0, margin: 0 } }}
        >
          <Tab.Screen
            name={ScreenNames.ContributionListScreen}
            component={EventsMainScreen}
            options={{ title: t('all') }}
            initialParams={{
              contributionsMode: GetContributionsMode.all,
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionFormScreen}
            component={ContributionFormScreen}
            initialParams={{
              contributionType: contributionType,
            }}
            options={{ title: t('propose') }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionListScreenParticipation}
            component={EventsMainScreen}
            options={{ title: t('my_attendances') }}
            initialParams={{
              contributionsMode: GetContributionsMode.participation,
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionListScreenMine}
            component={EventsMainScreen}
            options={{ title: contributionType === ContributionType.FORMATION ? t('my_trainings') : t('my_events') }}
            initialParams={{
              contributionsMode: GetContributionsMode.mine,
              contributionType: contributionType,
              isAdminView: true,
            }}
          />
        </Tab.Navigator>
      );
    case ContributionType.LOST_PROPERTY:
      return (
        <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
          <Tab.Screen
            name={ScreenNames.ContributionListScreen}
            component={ContributionListScreen}
            options={{ title: t('all') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.all,
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionFormScreen}
            component={ContributionFormScreen}
            options={{ title: t('declare') }}
            initialParams={{
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionListScreenMine}
            component={ContributionListScreen}
            options={{ title: t('my_lost_properties') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.mine,
              contributionType: contributionType,
            }}
          />
        </Tab.Navigator>
      );
    case ContributionType.SURVEY:
      return (
        <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
          <Tab.Screen
            name={ScreenNames.SurveyList}
            component={ContributionListScreen}
            options={{ title: t('all') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.all,
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.SurveyCreate}
            options={{ title: t('publish') }}
            component={CreateSurveyScreen}
          />
          <Tab.Screen
            name={ScreenNames.SurveyAdminList}
            component={ContributionListScreen}
            options={{ title: t('my_survey') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.mine,
              contributionType: contributionType,
            }}
          />
        </Tab.Navigator>
      );
    case ContributionType.SUGGESTION:
      return <SuggestionBoxScreen />;
    case ContributionType.COMPANY_NEWS:
      return (
        <ContributionListScreen
          navigation={props.navigation}
          route={{
            params: {
              getContributionsMode: GetContributionsMode.all,
              contributionType: contributionType,
            },
          }}
        />
      );
    default:
      return (
        <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
          <Tab.Screen
            name={ScreenNames.ContributionListScreen}
            component={ContributionListScreen}
            options={{ title: t('all') }}
            initialParams={{
              getContributionsMode: GetContributionsMode.all,
              getContributionsModeSecondView: GetContributionsMode.interested,
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionFormScreen}
            options={{ title: t('propose') }}
            component={ContributionFormScreen}
            initialParams={{
              contributionType: contributionType,
            }}
          />
          <Tab.Screen
            name={ScreenNames.ContributionListScreenMine}
            component={ContributionListScreen}
            options={{ title: getMyContributionsTitleByType(contributionType) }}
            initialParams={{
              getContributionsMode: GetContributionsMode.mine,
              contributionType: contributionType,
            }}
          />
        </Tab.Navigator>
      );
  }
}

const Stack = createStackNavigator();

export function ContributionRouter(props: DrawerScreenProps<any>) {
  const contributionType: ContributionType = IntToContributionType(props.route.params?.contributionType);

  return (
    <Stack.Navigator screenOptions={{ headerBackImage: BackIcon }}>
      <Stack.Screen
        name={ScreenNames.ContributionTabs}
        component={Tabs}
        options={{ headerShown: false, title: props.route.params?.title }}
        initialParams={{ contributionType: contributionType }}
      />
      <Stack.Screen
        name={ScreenNames.ContributionEditFormScreen}
        component={ContributionFormScreen}
        options={{ headerShown: false, title: props.route.params?.title }}
        initialParams={{
          contributionType: contributionType,
        }}
      />
    </Stack.Navigator>
  );
}
