import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import AppText, { Mode } from '../../../components/atomic/AppText';
import { getContributionItem, report, setApproveContribution } from '../../../services/api/contribution.api';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../../../components/atomic/AppButton';
import { t } from '../../../services/translations';
import {
  setContributionsListNeedsRefresh,
  setCurrentContribution,
  setDetailContributionType,
  setForceBack,
  setIsNotif,
  setLoading,
} from '../../../store/action';
import { Linking, StyleSheet, Text, TouchableOpacity, View, KeyboardAvoidingView, Platform } from 'react-native';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { _getInterestId, alertInfo, getReadableDate, isAdmin, isMobile, openURL } from '../../../services/utils';
import { ContributionType, ContributionTypesInfos, IntToContributionType } from '../../../types';
import {
  contributionTypeToText,
  getDataContentByType,
  getDescriptionByType,
  getIdFromItem,
  getTitleByType,
} from '../../../services/api/contributions';
import { FORM_LIGHT_GRAY, PRIMARY_COLOR } from '../../../styles/appColor';
import { getDocumentURI, isAnonymous } from '../../../services/api/helper.api';
import { AppModalOverlay } from '../../../components/atomic/AppModalOverlay';
import { ContentWithImageHeaderScreen } from '../../../components/containers/ContentWithImageHeaderScreen';
import {
  setAttendEvent,
  getEventExportPdfUrl,
  recallEventNotification,
} from '../../../services/api/event.contributions.api';
import { removeContributionInterest, setContributionInterest } from '../../../services/api/interest.contributions.api';
import { FONT_FAMILY_DEFAULT, globalStyle } from '../../../styles/globalStyle';
import { UserSubRow } from '../../../components/atomic/UserSubRow';
import { DefaultBackground } from '../../../components/containers/AppScreen';
import { AppMapView } from '../../../components/atomic/AppMapView';
import { HtmlText } from '../../../components/atomic/HtmlText';
import { NEWS_INTERESTS_ENABLED } from '../../../services/features';
import { Contribution } from '../../../entities/Contribution';
import { CSondage } from '../../../entities/CSondage';
import { CBoiteIdee } from '../../../entities/CBoiteIdee';
import { RouteProp } from '@react-navigation/native';
import { ContributionVente } from '../../../entities/ContributionVente';
import { ContributionKiosque } from '../../../entities/ContributionKiosque';
import { ContributionObjet } from '../../../entities/ContributionObjet';
import { ContributionCovoiturage } from '../../../entities/ContributionCovoiturage';
import { generateGoogleAgenda, generateOutlookAgenda } from '../../../services/url.utils';
import { DateTime } from 'luxon';
import { hasCategories } from '../../../utils/Category';
import { CategoryChip } from '../../../components/atomic/CategoryChip';
import { formatUrl } from '../../../utils/Utils';
import { CommentList } from '../../../components/molecules/CommentList';
import SpreadingChipList from '../../../components/atomic/SpreadingChipsList';
import { ContributionEvenementDetails } from '../../../entities/ContributionEvenementDetails';
import { recallFormationNotification } from '../../../services/api/formation.contributions.api';
import { TwoActionsGenericPopup } from '../../../components/containers/TwoActionsButtonPopup';
import useLoggedUser from '../../../hooks/useLoggedUser';
import { SurveyDetailsScreen } from './survey/SurveyDetailsScreen';
import ContactUserButton from '../../../components/molecules/ContactUserButton';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { Chip } from '../../../components/atomic/Chip';
import ContributionInterestedUserList from '../../../components/molecules/ContributionInterestedUserList';

export function SimpleDetailRow(props: {
  content: ReactNode | string | number;
  title: string;
  first?: boolean;
  width?: number;
  bottomBorderWidth?: number;
  flex?: 'row' | 'column';
}) {
  return (
    <View
      style={{
        flexDirection: props.flex || 'row',
        borderTopWidth: !!props.first ? 1 : 0,
        borderTopColor: FORM_LIGHT_GRAY,
        borderBottomWidth: props.bottomBorderWidth ?? 1,
        borderBottomColor: FORM_LIGHT_GRAY,
        padding: 6,
        alignItems: props.flex === 'column' ? 'flex-start' : 'center',
        justifyContent: props.flex === 'column' ? undefined : isMobile() ? 'space-between' : undefined,
      }}
    >
      <AppText
        mode={Mode.BOLD}
        style={{
          color: PRIMARY_COLOR,
          fontSize: 19,
          width: props.flex === 'column' ? undefined : isMobile() ? undefined : 300,
        }}
      >
        {props.title}
      </AppText>
      <AppText
        style={{
          width: props.flex === 'column' ? undefined : isMobile() ? 100 : 150,
          textAlign: isMobile() ? 'left' : 'right',
        }}
      >
        {props.content}
      </AppText>
    </View>
  );
}

export function ViewDetailRow(props: {
  content: ReactNode | string | number;
  title: string;
  first?: boolean;
  flex?: 'row' | 'column';
}) {
  return !isMobile() ? (
    <View
      style={{
        flexDirection: props.flex || 'row',
        padding: 6,
        borderTopWidth: !!props.first ? 1 : 0,
        borderTopColor: FORM_LIGHT_GRAY,
        borderBottomWidth: 1,
        borderBottomColor: FORM_LIGHT_GRAY,
      }}
    >
      <AppText
        mode={Mode.BOLD}
        style={{
          color: PRIMARY_COLOR,
          fontSize: 19,
          width: 300,
        }}
      >
        {props.title}
      </AppText>
      <AppText style={{ width: 150, marginRight: 4, textAlign: props.flex === 'column' ? null : 'right' }}>
        {props.content}
      </AppText>
    </View>
  ) : (
    <SimpleDetailRow width={275} first={props.first} content={props.content} title={props.title} flex={props.flex} />
  );
}

function SimpleColumnDetailRow(props: { content: string; title: string }) {
  return (
    <View style={styles.row}>
      <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <AppText
          mode={Mode.BOLD}
          style={{
            flex: 1,
            color: PRIMARY_COLOR,
            fontSize: 19,
            marginBottom: 8,
          }}
        >
          {props.title}
        </AppText>
        <AppText>{props.content}</AppText>
      </View>
    </View>
  );
}

export function BigDetailRow(props: { content: ReactNode; title?: string | undefined; borderBottomWidth?: number }) {
  return (
    <View
      style={{
        padding: 6,
        borderBottomWidth: props.borderBottomWidth ?? 1,
        borderBottomColor: FORM_LIGHT_GRAY,
      }}
    >
      <View style={{ alignItems: 'stretch', alignContent: 'stretch' }}>
        {props.title !== undefined && (
          <AppText mode={Mode.BOLD} style={{ marginBottom: 8, color: PRIMARY_COLOR, fontSize: 19 }}>
            {props.title}
          </AppText>
        )}
        <View style={{ marginTop: 0, marginBottom: 8 }}>{props.content}</View>
      </View>
    </View>
  );
}

export function BigDetailRowInline(props: {
  content: ReactNode;
  title?: string | undefined;
  borderBottomWidth?: number;
}) {
  return (
    <View
      style={{
        padding: 6,
        borderBottomWidth: props.borderBottomWidth ?? 1,
        borderBottomColor: FORM_LIGHT_GRAY,
        display: 'flex',
      }}
    >
      <View
        style={{
          alignItems: 'stretch',
          alignContent: 'stretch',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {props.title !== undefined && (
          <AppText mode={Mode.BOLD} style={{ marginBottom: 8, color: PRIMARY_COLOR, fontSize: 19 }}>
            {props.title}
          </AppText>
        )}
        <View
          style={{
            marginTop: 0,
            marginBottom: 8,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: 10,
          }}
        >
          {props.content}
        </View>
      </View>
    </View>
  );
}

function AttendButton(props: {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  activated?: boolean;
  icon: string;
}) {
  return (
    <TouchableOpacity onPress={props.onPress} disabled={props.disabled}>
      <FontAwesome6
        size={27}
        name={props.icon}
        solid
        color={props.activated ? PRIMARY_COLOR : props.disabled ? FORM_LIGHT_GRAY : 'black'}
        style={{ marginBottom: 8 }}
      />
      <AppText
        style={
          props.activated
            ? {
                color: PRIMARY_COLOR,
                fontFamily: FONT_FAMILY_DEFAULT.bold,
              }
            : props.disabled
            ? { color: FORM_LIGHT_GRAY }
            : { color: 'black' }
        }
      >
        {props.title}
      </AppText>
    </TouchableOpacity>
  );
}

export interface ReportButtonProps {
  buttonTitle: string;
  id: number;
  title: string;
}

export function ReportButton(props: ReportButtonProps) {
  const [visible, setVisible] = useState(false);
  const [reported, setReported] = useState(false);
  const user = useLoggedUser();
  const dispatch = useDispatch();

  if (reported) {
    return <AppText>{t('reported')}</AppText>;
  }

  return (
    <View>
      <AppButton
        textStyle={{ fontSize: 12 }}
        style={{ paddingHorizontal: 10 }}
        title={props.buttonTitle}
        onPress={() => setVisible(true)}
      />
      <AppModalOverlay isVisible={visible} onBackdropPress={() => setVisible(false)}>
        <AppText>{t('wish_report')}</AppText>
        <View style={{ flexDirection: 'row', marginTop: 10, justifyContent: 'space-evenly' }}>
          <AppButton
            title={t('yes')}
            onPress={() => {
              dispatch(setLoading(true));
              report(user, props.id, props.title)
                .then(() => {
                  setReported(true);
                  dispatch(setContributionsListNeedsRefresh(true));
                })
                .finally(() => dispatch(setLoading(false)));
            }}
            style={{ width: 80, marginRight: 10 }}
          />
          <AppButton title={t('no')} onPress={() => setVisible(false)} style={{ width: 80 }} />
        </View>
      </AppModalOverlay>
    </View>
  );
}

export type ContributionItemDetailScreenTypedData =
  | CSondage
  | Contribution
  | CBoiteIdee
  | ContributionEvenementDetails
  | ContributionVente
  | ContributionKiosque
  | ContributionObjet
  | ContributionCovoiturage;

export function ContributionItemDetailScreen(props: { route: RouteProp<any, any>; navigation: any }) {
  const dispatch = useDispatch();
  const contributionType: ContributionType = IntToContributionType(props.route.params?.contributionType);
  const id =
    props.route.params?.id !== undefined
      ? props.route.params.id
      : getIdFromItem(props.route.params?.item, contributionType);

  useEffect(() => {
    dispatch(setDetailContributionType(contributionType));

    if (props.route.params?.isNotif) {
      dispatch(setIsNotif(true));
    }

    if (props.route.params?.isAdminView) {
      dispatch(setForceBack(true));
    }

    props.navigation.setOptions({ title: contributionTypeToText(contributionType) });

    return () => {
      dispatch(setDetailContributionType(undefined));
      dispatch(setIsNotif(false));
      dispatch(setForceBack(false));
    };
  }, [dispatch, contributionType, props.route.params?.isNotif, props.route.params?.isAdminView, props.navigation]);

  const [interestId, setInterestId] = useState<number | undefined>(undefined);
  const user = useLoggedUser();
  const [data, setData] = useState(undefined);
  const [showInterestedPeople, setShowInterestedPeople] = useState(false);

  const canBeInteresting =
    contributionType !== ContributionType.LOST_PROPERTY &&
    contributionType !== ContributionType.EVENT &&
    contributionType !== ContributionType.FORMATION &&
    contributionType !== ContributionType.SUGGESTION &&
    (contributionType !== ContributionType.COMPANY_NEWS || NEWS_INTERESTS_ENABLED);

  const refresh = useCallback(() => {
    dispatch(setLoading(true));
    getContributionItem(user, id, contributionType)
      .then((json) => {
        setData(json);
        const dataContent = getDataContentByType(json, contributionType);
        dispatch(setCurrentContribution(dataContent));

        if (canBeInteresting) {
          let cont = dataContent.contribution ? dataContent.contribution : dataContent.mActu;
          let contId = dataContent.contributionId ? dataContent.contributionId : dataContent.mActu.id;
          setInterestId(_getInterestId(cont, user.id, contId, contributionType));
        } else {
          setInterestId(undefined);
        }
      })
      .catch(() => {
        console.error('Failed to fetch data');
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }, [dispatch, id, contributionType, user, canBeInteresting]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  let typedData: ContributionItemDetailScreenTypedData = getDataContentByType(data, contributionType);

  if (contributionType === ContributionType.SURVEY) {
    return <SurveyDetailsScreen id={id} />;
  }

  if (!typedData) {
    return null;
  }

  const contribution: Contribution =
    (typedData as CSondage).contribution !== undefined && typedData !== null
      ? (typedData as CSondage).contribution
      : (typedData as Contribution);
  const contributionEvenement =
    contributionType === ContributionType.EVENT || contributionType === ContributionType.FORMATION
      ? (typedData as ContributionEvenementDetails)
      : undefined;
  const contributionId = typedData !== undefined ? (typedData as CBoiteIdee).contributionId : undefined;

  function _toggleInterest() {
    dispatch(setLoading(true));
    if (interestId === undefined) {
      setContributionInterest(
        user,
        contributionId as number,
        typedData.id,
        contributionType,
        ContributionTypesInfos[contributionType].titleSelector?.(typedData) ?? '?'
      )
        .then(() => {
          dispatch(setContributionsListNeedsRefresh(true));
          refresh();
        })
        .catch(() => {
          alertInfo(t('error_occurred'));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    } else {
      removeContributionInterest(user, interestId)
        .then(() => {
          dispatch(setContributionsListNeedsRefresh(true));
          refresh();
        })
        .catch(() => {
          alertInfo(t('error_occurred'));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }
  }

  function _attend(value: boolean) {
    dispatch(setLoading(true));
    setAttendEvent(user, typedData.id, value)
      .then(() => {
        dispatch(setContributionsListNeedsRefresh(true));
        refresh();
      })
      .catch(() => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  function _approveContribution(value: boolean) {
    dispatch(setLoading(true));
    setApproveContribution(user, typedData.id, value, contributionType)
      .then(() => {
        dispatch(setContributionsListNeedsRefresh(true));
        refresh();
      })
      .catch(() => {
        alertInfo(t('error_occurred'));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  }

  function _isApproved() {
    return (typedData as CBoiteIdee).reponse === true;
  }

  function _isDisapproved() {
    return (typedData as CBoiteIdee).reponse === false;
  }

  function _canBeReported() {
    return contributionType !== ContributionType.SUGGESTION;
  }

  async function exportEventToPdf() {
    let fileUrl = getEventExportPdfUrl(user, typedData.id);

    if (!isMobile()) {
      window.open(fileUrl, '_blank');
    } else {
      let uri = FileSystem.documentDirectory + 'export.pdf';
      await FileSystem.downloadAsync(fileUrl, uri);
      await Sharing.shareAsync(uri, { dialogTitle: 'PDF' });
    }
  }

  async function recallNotification() {
    dispatch(setLoading(true));
    try {
      if (contributionType === ContributionType.EVENT) {
        await recallEventNotification(user, typedData.id);
      } else if (contributionType === ContributionType.FORMATION) {
        await recallFormationNotification(user, typedData.id);
      }
    } catch (e) {
      alertInfo(t('error_occurred'));
    }

    dispatch(setLoading(false));
  }

  function getInterestCount() {
    if (typedData.interestCount !== null && typedData.interestCount !== undefined) {
      return typedData.interestCount;
    } else if (contribution.cinterest != null && contribution.cinterest != undefined) {
      return contribution.cinterest?.length;
    } else if (
      (contribution as unknown as MActuNews)?.mActu?.mActuInterest != null &&
      (contribution as unknown as MActuNews)?.mActu?.mActuInterest != undefined
    ) {
      return (contribution as unknown as MActuNews)?.mActu?.mActuInterest?.length;
    } else {
      return 0;
    }
  }

  return (
    <DefaultBackground>
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={styles.container}>
        <ContentWithImageHeaderScreen
          contribution={{
            imageId: (typedData as ContributionEvenementDetails).imageId || undefined,
            documentId: (typedData as ContributionEvenementDetails).documentId || undefined,
            videoUrl: (typedData as ContributionEvenementDetails).videoUrl || undefined,
          }}
          contributionType={contributionType}
          bottomContent={
            <>
              {_canBeReported() && (
                <View style={styles.bottomContentContainer}>
                  <ReportButton
                    buttonTitle={t('report')}
                    id={(typedData as CBoiteIdee).contributionId}
                    title={ContributionTypesInfos[contributionType].titleSelector?.(typedData) ?? '?'}
                  />
                </View>
              )}
            </>
          }
          imageContentOverride={
            contributionType === ContributionType.CARPOOL && (
              <AppMapView data={[typedData as ContributionCovoiturage]} setSelectedItem={() => {}} />
            )
          }
          underImageContent={
            <>
              {!isAnonymous(typedData, contributionType) && (
                <View style={styles.userContainer}>
                  <UserSubRow
                    user={contribution?.userIdCreateurNavigation}
                    withPicture={true}
                    style={styles.userSubRow}
                  />
                </View>
              )}
            </>
          }
          navigation={props.navigation}
        >
          <View style={{ padding: 18 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <AppText
                mode={Mode.BOLD}
                style={{
                  flex: 1,
                  fontSize: 20,
                  textAlign: 'center',
                }}
              >
                {getTitleByType(typedData, contributionType)}
              </AppText>
            </View>
            {contributionType === ContributionType.SUGGESTION &&
            isAdmin(user) &&
            isAnonymous(typedData, contributionType) ? (
              <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 10 }}>
                <Chip
                  text={(typedData as CBoiteIdee).contribution.userIdCreateurNavigation.entrepriseSiteGeo.siteGeo}
                  color={PRIMARY_COLOR}
                />
              </View>
            ) : null}
            {(typedData as ContributionVente).prix !== undefined && (typedData as ContributionVente).prix !== null ? (
              <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 5, marginTop: 15 }}>
                <View style={{ flex: 1 }}>
                  <AppText mode={Mode.BOLD} style={{ fontSize: 19 }}>
                    {(typedData as ContributionVente).prix}€
                  </AppText>
                </View>
              </View>
            ) : null}

            {hasCategories(contributionType) &&
              !!ContributionTypesInfos[contributionType].categorySelector!(typedData) && (
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <CategoryChip
                    style={{ marginLeft: 8, marginVertical: 16, height: 24 }}
                    category={ContributionTypesInfos[contributionType].categorySelector!(typedData)}
                  />
                </View>
              )}
            {contributionEvenement ? (
              <>
                {contribution?.userIdCreateurNavigation.id !== user.id && (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      <AttendButton
                        title={t('attend')}
                        icon="check-circle"
                        activated={contributionEvenement.doesCurrentUserParticipate}
                        disabled={contributionEvenement.doesCurrentUserParticipate}
                        onPress={() => _attend(true)}
                      />
                      <AttendButton
                        title={t('not_attend')}
                        icon="times-circle"
                        activated={contributionEvenement.doesCurrentUserRejected}
                        disabled={contributionEvenement.doesCurrentUserRejected}
                        onPress={() => _attend(false)}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: isMobile() ? 'column' : 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <AppButton
                        onPress={() =>
                          openURL(
                            generateGoogleAgenda(
                              contributionEvenement.titre,
                              contributionEvenement.description,
                              contributionEvenement.date,
                              contributionEvenement.duration
                            )
                          )
                        }
                        title="GOOGLE AGENDA"
                        style={{ marginBottom: isMobile() ? 8 : 0 }}
                        fontSize={isMobile() ? 16 : undefined}
                      />
                      <AppButton
                        onPress={() =>
                          openURL(
                            generateOutlookAgenda(
                              contributionEvenement.titre,
                              contributionEvenement.description,
                              contributionEvenement.date,
                              contributionEvenement.duration
                            )
                          )
                        }
                        title="OUTLOOK AGENDA"
                        fontSize={isMobile() ? 16 : undefined}
                      />
                    </View>
                  </>
                )}
              </>
            ) : null}
            {contributionType === ContributionType.SUGGESTION && isAdmin(user) ? (
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  <AttendButton
                    title={t('approve')}
                    icon="check-circle"
                    activated={_isApproved()}
                    onPress={() => _approveContribution(true)}
                  />
                  <AttendButton
                    title={t('disapprove')}
                    icon="times-circle"
                    activated={_isDisapproved()}
                    onPress={() => _approveContribution(false)}
                  />
                </View>
              </>
            ) : null}
          </View>
          <BigDetailRow
            borderBottomWidth={1}
            content={<HtmlText text={getDescriptionByType(typedData, contributionType)} />}
            title={t('description')}
          />

          {contributionType === ContributionType.DOCUMENT && (
            <BigDetailRow
              content={
                <AppButton
                  title={t('download_file')}
                  style={{ margin: 29 }}
                  textStyle={{ fontSize: 16 }}
                  type={Type.PRIMARY}
                  onPress={() => openURL(getDocumentURI((typedData as ContributionKiosque).fichierIdDocument))}
                />
              }
              title={undefined}
            />
          )}

          {contributionType == ContributionType.SELL ? (
            <SimpleDetailRow
              content={
                <AppText>
                  {(typedData as ContributionVente).marque == undefined ||
                  (typedData as ContributionVente).marque == 'undefined'
                    ? ''
                    : (typedData as ContributionVente).marque}
                </AppText>
              }
              title={t('brand')}
            />
          ) : null}

          {contributionType === ContributionType.LOST_PROPERTY ? (
            <>
              <SimpleDetailRow
                content={<AppText>{(typedData as ContributionObjet).lieu}</AppText>}
                title={t('where')}
              />
              <ViewDetailRow
                content={<AppText>{getReadableDate((typedData as ContributionObjet).date)}</AppText>}
                title={t('when')}
              />
            </>
          ) : null}
          {contributionType === ContributionType.CARPOOL ? (
            <>
              <SimpleDetailRow
                title={t('carpool_type')}
                content={(typedData as ContributionCovoiturage).lovIdType === 22 ? t('permanent') : t('temporary')}
              />
              <SimpleColumnDetailRow title={t('from')} content={(typedData as ContributionCovoiturage).libelleDepart} />
              <SimpleColumnDetailRow title={t('to')} content={(typedData as ContributionCovoiturage).libelleArrivee} />
            </>
          ) : null}
          {contributionEvenement ? (
            <>
              {contributionEvenement.cagnotte != undefined &&
                contributionEvenement.cagnotte != 'undefined' &&
                contributionEvenement.cagnotte != '' && (
                  <BigDetailRow
                    title={t('money_pool')}
                    content={
                      <TouchableOpacity onPress={() => openURL(contributionEvenement.cagnotte)}>
                        <AppText>{contributionEvenement.cagnotte}</AppText>
                      </TouchableOpacity>
                    }
                  />
                )}
              <ViewDetailRow content={getReadableDate((data as any)?.date, true, true)} title={t('date')} />
              <ViewDetailRow
                content={getReadableDate(
                  DateTime.fromISO((data as any)?.date)
                    .plus({ seconds: (data as any).duration })
                    .toISO(),
                  true,
                  true
                )}
                title={t('end_date')}
              />
              <ViewDetailRow
                content={
                  contributionEvenement.place !== null && contributionEvenement.url !== null
                    ? t('event_type_both_short')
                    : contributionEvenement.url
                    ? t('event_type_remote')
                    : t('event_type_place')
                }
                title="Type"
              />
              {contributionEvenement.url && (
                <ViewDetailRow
                  content={
                    <TouchableOpacity onPress={() => Linking.openURL(formatUrl(contributionEvenement.url))}>
                      <Text style={{ color: PRIMARY_COLOR, textDecorationLine: 'underline' }}>
                        {formatUrl(contributionEvenement.url)}
                      </Text>
                    </TouchableOpacity>
                  }
                  title={t('event_url')}
                  flex="column"
                />
              )}
              {contributionEvenement.place && (
                <ViewDetailRow content={contributionEvenement.place} title={t('event_place')} flex="column" />
              )}
              <ViewDetailRow
                content={
                  contributionEvenement.availableSeats !== null
                    ? contributionEvenement.availableSeats
                    : t('event_unlimited')
                }
                title={t('event_available_seats')}
              />
              <ViewDetailRow content={contributionEvenement.invitationCount} title={t('invited')} />
              <ViewDetailRow content={contributionEvenement.participationCount} title={t('accepted')} />
              <ViewDetailRow content={contributionEvenement.rejectionCount} title={t('declined')} />
              {contribution?.userIdCreateurNavigation.id === user.id && (
                <>
                  <BigDetailRow
                    content={
                      <>
                        <AppButton
                          title={t('to_pdf')}
                          icon="file-pdf"
                          onPress={() => exportEventToPdf()}
                          style={{ marginBottom: 10 }}
                        />
                        {contributionEvenement.contribution.notifActivated && (
                          <TwoActionsGenericPopup
                            title={t('recall_notification')}
                            button1Title={t('yes')}
                            button2Title={t('no')}
                            onPressButton1={() => recallNotification()}
                            onPressButton2={() => {}}
                            style={[globalStyle.button, globalStyle.button_light, globalStyle.button_content]}
                          >
                            <FontAwesome6
                              size={18}
                              name="bell"
                              color={PRIMARY_COLOR}
                              style={{ marginLeft: 10, marginRight: 10 }}
                            />
                            <AppText
                              style={{
                                textAlign: 'center',
                                fontSize: 20,
                                color: PRIMARY_COLOR,
                                fontFamily: FONT_FAMILY_DEFAULT.regular,
                                flex: 1,
                                textTransform: 'uppercase',
                                paddingTop: 2,
                              }}
                            >
                              {t('recall_notification')}
                            </AppText>
                          </TwoActionsGenericPopup>
                        )}
                      </>
                    }
                    title={t('export_results')}
                  />
                </>
              )}
            </>
          ) : null}
          {contributionType !== ContributionType.SUGGESTION && (
            <BigDetailRowInline
              content={
                <>
                  <SpreadingChipList
                    item={
                      (typedData as Exclude<ContributionItemDetailScreenTypedData, Contribution | CBoiteIdee>)
                        .contribution
                    }
                    monocolor={true}
                    inline={true}
                  />
                </>
              }
              title={t('who_can_see')}
            />
          )}
          {canBeInteresting ? (
            <>
              <TouchableOpacity onPress={() => setShowInterestedPeople(!showInterestedPeople)}>
                <ViewDetailRow
                  content={
                    contributionType != ContributionType.COMPANY_NEWS || NEWS_INTERESTS_ENABLED ? (
                      getInterestCount()
                    ) : (
                      <AppText>{t('available_soon')}</AppText>
                    )
                  }
                  title={t('interested_people')}
                />
              </TouchableOpacity>
              {showInterestedPeople && <ContributionInterestedUserList id={contribution.id} />}
            </>
          ) : null}
          {!isAnonymous(typedData, contributionType) &&
            contribution?.userIdCreateurNavigation !== undefined &&
            contribution?.userIdCreateurNavigation?.aspNetUsers?.deletedAt === null && (
              <ContactUserButton user={contribution.userIdCreateurNavigation} />
            )}
          {canBeInteresting && (
            <AppButton
              title={t('i_am_interested')}
              icon="heart"
              style={{ margin: 10 }}
              textStyle={{ fontSize: 16 }}
              type={interestId !== undefined ? Type.PRIMARY : Type.LIGHT}
              onPress={_toggleInterest}
            />
          )}

          {!!contribution?.commentsActivated && <CommentList contributionId={contribution.id} />}
        </ContentWithImageHeaderScreen>
      </KeyboardAvoidingView>
    </DefaultBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  bottomContentContainer: { flexDirection: 'row', justifyContent: 'flex-end', margin: 18 },
  simpleColumnDetailRowContainer: {
    padding: 6,
    borderBottomWidth: 1,
    borderBottomColor: FORM_LIGHT_GRAY,
  },
  userContainer: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: isMobile() ? undefined : 540,
  },
  userSubRow: {
    flex: 1,
  },
  userName: { width: isMobile() ? undefined : 200, textAlign: 'right' },
});
