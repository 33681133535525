import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { UserSaved } from '../storage';
import { FolderList } from '../../entities/FolderList';
import { FolderCreate } from '../../entities/FolderCreate';
import { FolderUpdate } from '../../entities/FolderUpdate';
import { DocumentCreate } from '../../entities/DocumentCreate';
import { DocumentUpdate } from '../../entities/DocumentUpdate';
import { ContributionKiosque } from '../../entities/ContributionKiosque';
import { ContributionKiosqueFolder } from '../../entities/ContributionKiosqueFolder';
import { objectToFormData } from '../formUtil';

export async function getDocumentDetails(user: UserSaved, id: number): Promise<ContributionKiosque> {
  return axios
    .get(`${apiUrlBase}ContributionKiosques/document/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getFolderDetails(user: UserSaved, id: number): Promise<ContributionKiosqueFolder> {
  return axios
    .get(`${apiUrlBase}ContributionKiosques/folder/${id}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listFolder(user: UserSaved, folderId: number | null): Promise<FolderList> {
  return axios
    .get(folderId !== null ? `${apiUrlBase}ContributionKiosques/${folderId}` : `${apiUrlBase}ContributionKiosques`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function listFolderAsAdmin(user: UserSaved, folderId: number | null): Promise<FolderList> {
  return axios
    .get(
      folderId !== null
        ? `${apiUrlBase}ContributionKiosques/admin/${folderId}`
        : `${apiUrlBase}ContributionKiosques/admin`,
      {
        headers: headers(user.token),
      }
    )
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function createFolder(user: UserSaved, args: FolderCreate) {
  return axios
    .post(`${apiUrlBase}ContributionKiosques/folder`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function updateFolder(user: UserSaved, id: number, args: FolderUpdate) {
  return axios
    .put(`${apiUrlBase}ContributionKiosques/folder/${id}`, args, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function createDocument(user: UserSaved, args: DocumentCreate) {
  return axios
    .postForm(`${apiUrlBase}ContributionKiosques/document`, objectToFormData(args), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export function updateDocument(user: UserSaved, id: number, args: DocumentUpdate) {
  return axios
    .putForm(`${apiUrlBase}ContributionKiosques/document/${id}`, objectToFormData(args), {
      headers: headers(user.token, 'multipart/form-data'),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
