import {
  KeyboardType,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  TextInput as ReactTextInput,
  TextInputContentSizeChangeEventData,
  View,
  ViewStyle,
} from 'react-native';
import React from 'react';
import { Icon } from '@rneui/base';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';

export type TextInputProps = {
  style?: StyleProp<ViewStyle>;
  inputStyle?: StyleProp<ViewStyle>;
  displayOnly?: boolean;
  onContentSizeChange?: ((e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => void) | undefined;
  onSubmitEditing?: () => void;
  onChangeText: (text: string) => void;
  defaultValue?: string;
  value?: string;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
  leftIcon?: string;
  placeholder?: string;
  textColor?: string;
  borderColor?: string;
  onPressIcon?: () => void;
  multiline?: boolean;
  numberOfLines?: number;
};

export function TextInput({
  style,
  inputStyle,
  displayOnly,
  onContentSizeChange,
  onSubmitEditing,
  onChangeText,
  defaultValue,
  value,
  keyboardType,
  secureTextEntry,
  leftIcon,
  placeholder,
  textColor,
  borderColor,
  onPressIcon,
  multiline,
  numberOfLines,
}: TextInputProps) {
  return (
    <View
      style={[
        {
          borderStyle: 'solid',
          borderWidth: 1,
          backgroundColor: 'white',
          borderColor: borderColor || PRIMARY_COLOR,
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: leftIcon ? 15 : 0,
          marginBottom: 22,
        },
        style,
      ]}
    >
      {leftIcon && <Icon onPress={onPressIcon} size={18} name={leftIcon} color={PRIMARY_COLOR} />}
      <ReactTextInput
        editable={!displayOnly}
        style={[
          {
            fontSize: 14,
            marginHorizontal: 15,
            marginVertical: Platform.OS == 'android' ? 7.5 : 15,
            flex: 1,
            color: textColor || PRIMARY_COLOR,
            fontFamily: FONT_FAMILY_DEFAULT.regular,
          },
          inputStyle,
        ]}
        placeholder={placeholder}
        secureTextEntry={secureTextEntry}
        keyboardType={keyboardType}
        defaultValue={defaultValue}
        value={value}
        onContentSizeChange={onContentSizeChange}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
        multiline={multiline}
        numberOfLines={numberOfLines}
      />
    </View>
  );
}
