import React, { useState } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { t } from '../services/translations';
import { isMobile } from '../services/utils';
import { ScreenNames } from '../ScreenNames';
import { CreateCategoryScreen } from '../screens/categories/CreateCategoryScreen';
import { ManageCategoryScreen } from '../screens/categories/ManageCategoryScreen';
import { CategoriesContext } from '../screens/categories/CategoriesContext';

export function CategoriesAdministrationRoute() {
  const Tab = createMaterialTopTabNavigator();
  const [callbacks, setCallbacks] = useState<(() => void)[]>([]);

  const call = () => {
    for (const callback of callbacks) {
      callback();
    }
  };

  const register = (callback: () => void) => setCallbacks((prev) => [...prev, callback]);

  const unregister = (callback: () => void) => setCallbacks((prev) => prev.filter((c) => c !== callback));

  return (
    <CategoriesContext.Provider value={{ call, register, unregister }}>
      <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
        <Tab.Screen
          name={ScreenNames.CreateCategory}
          component={CreateCategoryScreen}
          options={{ title: t('creating_category') }}
        />
        <Tab.Screen
          name={ScreenNames.ManageCategories}
          component={ManageCategoryScreen}
          options={{ title: t('managing_categories') }}
        />
      </Tab.Navigator>
    </CategoriesContext.Provider>
  );
}
